import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { useState } from "react";
import {
  downloadSampleRevenueAutomationData,
  uploadRevenueAutomationDataPoints
} from "../../../../../utils/http/endpoints";
import DragAndDropFileUpload from "../../../../../elements/file-upload/DragAndDropFileUpload";
import { DownloadLink } from "../../../../../elements/download/DownloadFiles";
import { FileDropzoneErrors, Labels } from "../../../../../constants/Constants";
import { SelectBox } from "../../../../../elements/select/Select";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import DisplayAlerts from "../../../../../elements/DisplayAlerts";

export default NiceModal.create(
  ({
    fyEndingOptions,
    title,
    fileHeader,
    revenueDataPoint,
    assetId,
    setDataImportedYear,
  }) => {
    const [fyYear, setFyYear] = useState(null);
    const [attachment, setAttachment] = useState([]);
    const [errors, setErrors] = useState([]);
    const modal = useModal();

    const handleYearChange = (val) => {
      setFyYear(val.value);
    };

    const handleImport = () => {
      const formData = new FormData();
      formData.append("revenueAutomationDataFile", attachment[0]);
      httpRequest({
        method: "POST",
        url: uploadRevenueAutomationDataPoints(
          assetId,
          fyYear,
          revenueDataPoint
        ),
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then((response) => {
          modal.remove();
          setDataImportedYear(fyYear);
        })
        .catch((err) => {
          setErrors(err?.data?.errors);
        });
    };
    
    const uploadOptions = {
      accept: ".csv",
      maxSize: 10485760, // 10MB = 10B * 1024 * 1024
      maxFiles: 1
      // disabled: disableFields
    };

    const onError = (errorList) => {
      const errors = {};
      errorList.forEach((errorObj) => {
        errorObj.errors.forEach((error) => {
          errors[error.code] = {
            errorMessage: FileDropzoneErrors[error.code] || error.message
          };
        });
      });
      setErrors([...Object.values(errors)]);
    };

    const sampleCSVDownloadOptions = {
      url: downloadSampleRevenueAutomationData(revenueDataPoint),
      responseType: "blob"
    };
    return (
      <Modal
        isOpen={true}
        centered={true}
        toggle={modal.remove}
        fade={false}
        keyboard={false}
        backdrop="static"
      >
        <ModalHeader className="pb-0" toggle={modal.remove}>
          <div>
            <h2 className="color-dark modal-title">{title}</h2>
          </div>
        </ModalHeader>
        <ModalBody>
          <DisplayAlerts
            alerts={errors}
            type={"danger"}
            className={"mt-0"}
            onAlertDismiss={() => setErrors([])}
          />
          <Row>
            <Col className="row-header-2 my-auto col-sm-12 col-md-6">
              {Labels.Assets.Settings.integration.fyEnding}
            </Col>
            <Col>
              <SelectBox
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                value={fyEndingOptions.find((each) => each.value === fyYear)}
                onChange={(val) => handleYearChange(val)}
                options={fyEndingOptions}
              />
            </Col>
          </Row>
          <div className="pt-3">
            <span className="row-header-2">{fileHeader}</span>
            <DragAndDropFileUpload
              onFileSelection={setAttachment}
              onError={onError}
              label={Labels.CommonModals.uploadFileLabel}
              button={true}
              uploadConfig={uploadOptions}
              selectedFiles={attachment}
            />
            <DownloadLink {...sampleCSVDownloadOptions} onError={onError}>
              <span className="float-right color-secondary">
                {Labels.CommonModals.downloadCsvTpl}
              </span>
            </DownloadLink>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <button
              className="btn btn-primary mr-2"
              onClick={handleImport}
              disabled={!attachment.length || !fyYear || errors.length}
            >
              {Labels.CommonModals.import}
            </button>
            <button className="btn btn-secondary" onClick={modal.remove}>
              {Labels.CommonModals.cancel}
            </button>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
);
