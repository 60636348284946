import { Labels } from "../constants/Constants";
import PaymentStatuses from "../constants/PaymentStatuses";
import { toStringConstant } from "./string";

export class ServerError extends Error {
  message = "";
  name = "ServerError";
  info = {};
  constructor(message, info) {
    super(message);
    this.message = message;
    this.info = info;
  }
}

// Returns message explaining the applicable workflow for intercompany payments based on given params
export function getInterCompanyPaymentWorkflowMessage({
  isInterCompanyAccount,
  isDirectDebitPayment,
  paymentStatus = PaymentStatuses.DRAFT
}) {
  // Show no note if it is not intercompany payment
  // And message is applicable only in draft & awaiting approval
  if (
    !isInterCompanyAccount ||
    ![PaymentStatuses.DRAFT, PaymentStatuses.AWAITING_APPROVAL]
      .map((status) => status.toLowerCase())
      .includes(toStringConstant(paymentStatus).toLowerCase())
  ) {
    return null;
  }

  return isDirectDebitPayment
    ? Labels.interCompany.interCompanyDirectDebitPaymentMessage
    : Labels.interCompany.interCompanyDirectNonDebitPaymentMessage;
}

export default function processAPIResponse(apiResponse) {
  return apiResponse
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data.errors));
}
