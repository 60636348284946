import React from "react";

const PageLoader = () => {
  return (
    <React.Fragment>
      <div className="fullpage-loader-container">
        <div className="loader"></div>
      </div>
    </React.Fragment>
  );
};

export default PageLoader;
