import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Row,
  Col,
  ModalHeader
} from "reactstrap";
import { Labels } from "../../../constants/Constants";
import { modalNames } from "../../../elements/modals/modal-register";
import { ErrorMessage } from "@hookform/error-message";
import { useFieldArray, useForm } from "react-hook-form";
import classNames from "classnames";
import { useMutation, useQueryClient } from "react-query";
import QueryKeys from "../../../constants/QueryKeys";
import processAPIResponse from "../../../utils/utils";
import { httpRequest } from "../../../utils/http/httpRequest";
import {
  addTaggingCategoryAttribute,
  updateTaggingCategoryAttributeAndValues
} from "../../../utils/http/endpoints";
import DisplayAlerts from "../../../elements/DisplayAlerts";

export default NiceModal.create(
  ({
    attributeData = {},
    params,
    setCreatedAttribute,
    setValuesChangedAttributeId
  }) => {
    // Use a hook to manage the modal state
    const modal = useModal();

    const {
      register,
      control,
      handleSubmit,
      formState: { isDirty, isValid, errors }
    } = useForm({
      defaultValues: {
        attributeName: attributeData.name || null,
        attributeValues: attributeData.id
          ? attributeData.values.map((i) => ({
              value: i.value,
              id: i.id,
              isValueInUse: i.isAttributeValueInUse
            }))
          : [{ value: "" }]
      },
      mode: "onChange"
    });

    const { append, fields, remove } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "attributeValues"
    });

    const handleConfirm = () => {
      modal.resolve();
      modal.remove();
    };

    const queryClient = useQueryClient();
    const mutation = useMutation({
      mutationKey: attributeData.id
        ? [
            QueryKeys.updateTaggingCategoryAttributeAndValues,
            { categoryId: +params.categoryId, attributeId: attributeData.id }
          ]
        : [QueryKeys.addTaggingCategoryAttribute, +params.categoryId],
      mutationFn: (data) =>
        processAPIResponse(
          httpRequest({
            method: "put",
            data,
            url: attributeData.id
              ? updateTaggingCategoryAttributeAndValues({
                  ...params,
                  attributeId: attributeData.id
                })
              : addTaggingCategoryAttribute(params.categoryId)
          })
        ),
      onSuccess: () => {
        handleConfirm();
        queryClient.invalidateQueries([
          QueryKeys.getCategory,
          +params.categoryId
        ]);
        attributeData.id
          ? setValuesChangedAttributeId(attributeData.id)
          : setCreatedAttribute(true);
      }
    });
    const onFormSubmit = (data) => {
      const apiData = {
        id: attributeData.id,
        name: data.attributeName,
        values: data.attributeValues.map((attributeValue) => ({
          id: attributeData.id ? attributeValue.id : undefined,
          value: attributeValue.value.trim()
        }))
      };
      mutation.mutate(apiData);
    };

    const valueValidator = (val) => {
      return val.trim().length >= 3;
    };

    return (
      <Modal
        keyboard={false}
        backdrop="static"
        centered
        fade={false}
        isOpen={modal.visible}
        toggle={modal.remove}
        className="tagging"
      >
        <ModalHeader toggle={modal.remove}>
          <h2 className="color-dark modal-title">
            {attributeData.id
              ? Labels.Tagging.editAttribute
              : Labels.Tagging.createAttribute}
          </h2>
        </ModalHeader>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          {mutation.isError ? (
            <DisplayAlerts
              type="danger"
              className="mx-3"
              alerts={mutation.error}
              onAlertDismiss={() => mutation.reset()}
            />
          ) : null}
          <ModalBody className="mt-0 pt-0 pb-2">
            <Container>
              <Row>
                <Col className="p-0">
                  <input
                    type="text"
                    className={classNames("category-title", {
                      "is-invalid": errors.attributeName
                    })}
                    placeholder={Labels.Tagging.attributeName}
                    {...register("attributeName", {
                      required: {
                        value: true,
                        message: Labels.CommonModals.pleaseFillInThisField
                      },
                      maxLength: {
                        value: 55,
                        message: Labels.Tagging.maxLengthLimit55
                      }
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="attributeName"
                    as="span"
                    className="error-message"
                  />
                </Col>
              </Row>
              <Row className="mt-3 px-3 py-3 category-values-container border">
                <Col sm={12} className="p-0 d-flex">
                  <div>
                    <h3>{Labels.Tagging.values}</h3>
                  </div>
                </Col>
                {fields.map((field, index) => (
                  <Col
                    sm={12}
                    className="p-0 mb-2 d-flex align-items-center"
                    key={field.id}
                  >
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className={classNames("category-values w-100", {
                            "is-invalid": errors.attributeValues?.[index]?.value
                          })}
                          placeholder={Labels.Tagging.attributeValue}
                          {...register(`attributeValues.${index}.value`, {
                            required: {
                              value: true,
                              message: Labels.CommonModals.pleaseFillInThisField
                            },
                            maxLength: {
                              value: 55,
                              message: Labels.Tagging.maxLengthLimit55
                            },
                            validate: (val) =>
                              valueValidator(val) ||
                              Labels.Tagging.minLengthLimit3
                          })}
                        />

                        <button
                          onClick={() => remove(index)}
                          className={`btn btn-link link-primary m-0 p-0 ${
                            field.isValueInUse ? "invisible" : ""
                          }`}
                          type="button"
                          disabled={fields.length === 1}
                        >
                          <FontAwesomeIcon
                            size="sm"
                            className="color-negative ml-2"
                            icon={faTrashAlt}
                          />
                        </button>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name={`attributeValues.${index}.value`}
                        as="span"
                        className="error-message"
                      />
                    </div>
                  </Col>
                ))}
                <Col xs={12} className="p-0">
                  <a
                    className="link-primary m-0 p-0 prepend-plus cursor-pointer"
                    onClick={(event) => {
                      event.preventDefault();
                      append({ value: "" });
                    }}
                  >
                    {Labels.Tagging.addValue}
                  </a>
                </Col>
                <Col xs={12} className="p-0 pt-2">
                  <p>
                    <span className="row-header-2">
                      {Labels.CommonModals.note}
                    </span>
                    {Labels.Tagging.attributeValueNoteMsg}
                  </p>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter className="py-2 category-button-container">
            <Button
              color="primary"
              type="submit"
              disabled={!isDirty || !isValid}
            >
              {Labels.Tagging.save}
            </Button>
            <Button color="secondary" onClick={modal.remove} type="button">
              {Labels.CommonModals.cancel}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
);

export const openEditAttributeModal = (props) => {
  NiceModal.show(modalNames.openEditAttributeModal, props).catch((error) => {
    console.log(error);
  });
};
