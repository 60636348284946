import { useLocation } from "react-router-dom";

/**
 * A custom hook that builds on useLocation to parse
 * the query string.
 * @returns object
 */
export default function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}
