import { Alert } from "reactstrap";
import React from "react";
import { Labels } from "../constants/Constants";

export default function DisplayAlerts({
  alerts = [],
  type,
  className,
  innerClassName,
  onAlertDismiss = null
}) {
  return alerts.length > 0 ? (
    <div className={className || "mt-3 mx-auto"}>
      <Alert
        color={type}
        isOpen={!!alerts.length}
        toggle={onAlertDismiss}
        className={innerClassName}
      >
        {alerts.length === 1 ? (
          alerts[0].errorMessage
        ) : (
          <ul className="m-0 pl-3">
            {alerts.map((error, index) => {
              return (
                <li key={index}>
                  {error.errorMessage || Labels.CommonModals.unknownError}
                </li>
              );
            })}
          </ul>
        )}
      </Alert>
    </div>
  ) : null;
}
