import { throwInvalidRequestError } from "@pdftron/pdfjs-express-utils";
import { getSymbolFromCurrencyCode } from "../utils/currency/currency";

class Auth {
  constructor() {
    this.userId = 0;
    this.famId = 0;
    this.isBackOfficeUser = false;
    this.isRtrUser = false;
    this.userPrivileges = [];
    this.currentAsset = {};
    this.currentUserName = "";
    this.currentUserFullName = "";
    this.organizations = [];
    this.currentOrg = {};
    this.isFullService = false;
    this.logoUrl = "";
    this.faviconUrl = "";
    this.famList = [];
    this.assetList = [];
  }

  setUserId(userId) {
    this.userId = userId;
  }

  getUserId() {
    return this.userId;
  }

  setFamId(famId) {
    this.famId = famId;
  }

  getFamId() {
    return this.famId;
  }

  setUserPrivileges(userPrivileges) {
    this.userPrivileges = userPrivileges;
  }

  getUserPrivileges() {
    return this.userPrivileges;
  }

  hasPrivilege(privilege) {
    return this.userPrivileges.includes(privilege);
  }

  setCurrentAsset(asset) {
    this.currentAsset = asset;
    this.currentAsset.currencySymbol = getSymbolFromCurrencyCode(
      asset.currency
    );
  }

  getCurrentAsset() {
    return this.currentAsset;
  }

  setIsBackOfficeUser(isBackOfficeUser) {
    this.isBackOfficeUser = isBackOfficeUser;
  }

  getIsBackOfficeUser() {
    return this.isBackOfficeUser;
  }

  setIsRtrUser(isRtrUser) {
    this.isRtrUser = isRtrUser;
  }

  getIsRtrUser() {
    return this.isRtrUser;
  }

  setCurrentUserName(currentUserName) {
    this.currentUserName = currentUserName;
  }

  getCurrentUserName() {
    return this.currentUserName;
  }

  setOrganizations(organizations) {
    this.organizations = organizations;
  }

  getOrganizations() {
    return this.organizations;
  }

  setCurrentOrg(currentOrg) {
    this.currentOrg = currentOrg;
  }

  getCurrentOrg() {
    return this.currentOrg;
  }

  setCurrentUserFullName(currentUserFullName) {
    this.currentUserFullName = currentUserFullName;
  }

  getCurrentUserFullName() {
    return this.currentUserFullName;
  }

  setLogoUrl(logoUrl) {
    this.logoUrl = logoUrl;
  }

  getLogoUrl() {
    return this.logoUrl;
  }

  setFavIconUrl(faviconUrl) {
    this.faviconUrl = faviconUrl;
  }

  getFavIconUrl() {
    return this.faviconUrl;
  }

  getFamList() {
    return this.famList;
  }

  setFamList(famList) {
    this.famList = famList;
  }

  getAssetList() {
    return this.assetList;
  }

  setAssetList(assetList) {
    this.assetList = assetList;
  }
}

export default new Auth();
