import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import DocsViewer from "../../../../../elements/DocsViewer";

export default NiceModal.create(
  ({ previewData, handleCreate, isEditRequest }) => {
    const modal = useModal();
    const params = useParams();

    const handleCreateClick = () => {
      modal.resolve();
      modal.remove();
      handleCreate();
    };

    return (
      <Modal
        keyboard={false}
        backdrop="static"
        centered
        fade={false}
        size="xl"
        isOpen={modal.visible}
        toggle={modal.remove}
      >
        <ModalHeader className="pb-0 mx-3" toggle={modal.remove}>
          <h2 className="color-dark modal-title">
            {handleCreate
              ? isEditRequest
                ? Labels.Payments.Utilisations.previewAndSaveModalHeading
                : Labels.Payments.Utilisations.previewAndCreateModalHeading
              : Labels.Payments.Utilisations.viewUtilisationRequest}
          </h2>
        </ModalHeader>
        <ModalBody className="mt-0 py-0">
          <DocsViewer
            containerId={
              "utilisations" + previewData.requestId + params.assetId
            }
            viewerHeight="600px"
            documents={[
              {
                ...previewData.document,
                fileURL: `${previewData.document.cloudStorageUrl}`,
                fileFormat: ".pdf"
              }
            ]}
            displayDocumentTitle={false}
          />
        </ModalBody>
        <ModalFooter className="py-2 mx-4 px-0">
          {handleCreate ? (
            <Button color="primary" onClick={handleCreateClick}>
              {isEditRequest
                ? Labels.Payments.Utilisations.save
                : Labels.Payments.Utilisations.create}
            </Button>
          ) : null}
          <Button color="secondary" onClick={modal.remove}>
            {Labels.Payments.Utilisations.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
