import { useQuery } from "react-query";
import QueryKeys from "../../../../../constants/QueryKeys";
import { getWithholdingTaxPaymentOutstanding } from "../../../../../utils/http/endpoints";
import { nonBlockingHttpRequest } from "../../../../../utils/http/nonBlockingHttpRequest";
import { resolveAPIPromise } from "../../../../../utils/promises";

export default function useGetWithholdingTaxPaymentOutstanding(
  assetId,
  invoiceId
) {
  return useQuery({
    queryKey: [
      QueryKeys.getWithholdingTaxPaymentOutstanding,
      { assetId, invoiceId }
    ],
    queryFn: () =>
      resolveAPIPromise(
        nonBlockingHttpRequest({
          method: "get",
          url: getWithholdingTaxPaymentOutstanding(assetId, invoiceId)
        })
      ),
    enabled: !!(assetId && invoiceId)
  });
}
