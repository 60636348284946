import React from "react";
import { getDateInDisplayFormat } from "../../../../utils/date";

export default function IncurredAmountsDrilldownRoutingColumn(props) {
  let rowData = props.cellProperties.instance.getSourceDataAtRow(
    props.cellProperties.instance.toPhysicalRow(props.row)
  );

  let url;
  const isAtAllAssetLevel = props.path.includes("allassets");
  const isAtOrgLevel = props.path.includes("orgs/org/");
  const isAtAssetLevel = props.path.includes("/assets/asset/");

  props.TD.className = "htDimmed";

  if (rowData.poId) {
    if (isAtAssetLevel) {
      url = `/#/org/${props.orgId}/assets/asset/${props.assetId}/payruns/purchaseorders/edit-po/${rowData.poId}`;
    } else if (isAtOrgLevel) {
      url = `/#/orgs/org/${props.orgId}/payruns/purchaseorders/edit-po/${props.assetId}/${rowData.poId}`;
    } else if (isAtAllAssetLevel) {
      url = `/#/allassets/payments/purchaseorders/edit-po/${props.assetId}/${rowData.poId}`;
    }
  } else if (rowData.invoiceId) {
    if (isAtAssetLevel) {
      url = `/#/org/${props.orgId}/assets/asset/${props.assetId}/payruns/invoices/${rowData.invoiceId}`;
    } else if (isAtOrgLevel) {
      url = `/#/orgs/org/${props.orgId}/payruns/invoices/${props.assetId}/${rowData.invoiceId}`;
    } else if (isAtAllAssetLevel) {
      url = `/#/allassets/payments/invoices/${props.assetId}/${rowData.invoiceId}`;
    }
  } else if (rowData.transactionId) {
    if (isAtAssetLevel) {
      url = `/#/org/${props.orgId}/assets/asset/${props.assetId}/accounting/bankaccounts/${rowData.bankAccountId}/transactions/${rowData.transactionId}`;
    } else if (isAtOrgLevel) {
      url = `/#/orgs/org/${props.orgId}/accounting/bankaccounts/${props.assetId}/${rowData.bankAccountId}/transactions/${rowData.transactionId}`;
    } else if (isAtAllAssetLevel) {
      url = `/#/allassets/accounting/bankaccounts/${props.assetId}/${rowData.bankAccountId}/transactions/${rowData.transactionId}`;
    }
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-underline text-break"
    >
      {!!rowData.transactionId
        ? getDateInDisplayFormat(props.value)
        : props.value}
    </a>
  );
}
