import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(({ handleVoidAction }) => {
  const modal = useModal();
  const handleConfirmClick = () => {
    handleVoidAction();
    modal.remove();
  };

  return (
    <Modal
      keyboard={false}
      isOpen={modal.visible}
      toggle={modal.remove}
      backdrop="static"
      centered
      fade={false}
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">
          {Labels.Organisations.fixedAssetRegister.void}
        </h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={modal.remove}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="mt-0 pt-0">
        {Labels.Organisations.fixedAssetRegister.voidWarningNote}
      </ModalBody>
      <ModalFooter className="py-2">
        <Button color="btn btn-primary" onClick={handleConfirmClick}>
          {Labels.CommonModals.yes}
        </Button>{" "}
        <Button onClick={modal.remove}>{Labels.CommonModals.no}</Button>
      </ModalFooter>
    </Modal>
  );
});
