import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal, ModalHeader, ModalBody, Button, Alert } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import { useMutation } from "react-query";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import { DownloadLink } from "../../../../../elements/download/DownloadFiles";
import {
  downloadSampleTaggedInvoice,
  uploadTaggedInvoices
} from "../../../../../utils/http/endpoints";
import DragAndDropFileUpload from "../../../../../elements/file-upload/DragAndDropFileUpload";

export default NiceModal.create(
  ({ assetId, setImportSuccessMsgVisible, refetch }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const [errors, setErrors] = useState([]);
    const [attachment, setAttachment] = useState([]);

    const errorCodeDesc = {
      "file-invalid-type": Labels.Journals.fileTypeError,
      "file-too-large": Labels.CommonModals.attachmentSizeError
    };

    const resetErrors = () => {
      setErrors([]);
    };

    const uploadOptions = {
      accept: ".csv",
      maxFiles: 1,
      minSize: 0,
      maxSize: 10485760
    };

    const onError = (errorList) => {
      const errors = {};
      errorList.forEach((errorObj) => {
        errorObj.errors.forEach((error) => {
          errors[error.code] = {
            errorMessage: errorCodeDesc[error.code] || error.message
          };
        });
      });
      setErrors([...Object.values(errors)]);
    };

    const onFilesSelection = (files = []) => {
      setAttachment(files);
    };

    const uploadMutation = useMutation(() => {
      const formData = new FormData();
      formData.append("invoiceDataFile", attachment[0]);
      httpRequest({
        method: "post",
        url: uploadTaggedInvoices(assetId),
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then((response) => {
          refetch();
          setImportSuccessMsgVisible(true);
          modal.remove();
        })
        .catch((error) => {
          if (typeof error == "string") {
            setErrors([{ errorMessage: error }]);
          } else {
            setErrors(error.data.errors);
          }
        });
    });

    const uploadTaggedInvoicesMutation = () => {
      uploadMutation.mutate();
    };

    const sampleCSVDownloadOptions = {
      url: downloadSampleTaggedInvoice(),
      responseType: "blob"
    };

    return (
      <Modal
        keyboard={false}
        backdrop="static"
        centered
        fade={false}
        isOpen={modal.visible}
        size="lg"
      >
        <ModalHeader className="pb-2" toggle={modal.remove}>
          <h2 className="color-dark modal-title">
            {Labels.Tagging.Invoices.importInvoices}
          </h2>
        </ModalHeader>
        <ModalBody className="mt-0 pt-0">
          <>
            {errors.length ? (
              <div className="">
                <Alert color="danger" toggle={resetErrors}>
                  <ul className="error-list">
                    {errors.map((error, index) => {
                      return <li key={index}>{error.errorMessage}</li>;
                    })}
                  </ul>
                </Alert>
              </div>
            ) : null}
            <DragAndDropFileUpload
              onFileSelection={onFilesSelection}
              onError={onError}
              label={Labels.CommonModals.uploadFileLabel}
              button={true}
              uploadConfig={uploadOptions}
            />
            <DownloadLink {...sampleCSVDownloadOptions} onError={onError}>
              <span className="float-right color-secondary">
                {Labels.CommonModals.downloadCsvTpl}
              </span>
            </DownloadLink>
          </>
          <div className="pt-2 btns justify-content-center d-flex">
            <Button
              color="primary"
              disabled={attachment.length <= 0}
              onClick={uploadTaggedInvoicesMutation}
            >
              {Labels.CommonModals.import}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
);
