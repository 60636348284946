import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from "reactstrap";
import { useMutation } from "react-query";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import {
  archiveTaggingInvoice,
  unarchiveTaggingInvoice
} from "../../../../../utils/http/endpoints";
import { Labels } from "../../../../../constants/Constants";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TaggingInvoiceActions } from "../../../../../constants/TaggingInvoiceActions";

export default NiceModal.create(
  ({ actionName, assetId, invoiceId, refetch }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const [comment, setComment] = useState("");
    const [errors, setErrors] = useState([]);

    const getTitle = (actionName) => {
      if (actionName === TaggingInvoiceActions.archive) {
        return Labels.Tagging.Invoices.archiveInvoice;
      } else if (actionName === TaggingInvoiceActions.returnToUntagged) {
        return Labels.Tagging.Invoices.returnToUntagged;
      }
    };

    const getActionApi = ({ actionName, assetId, invoiceId }) => {
      if (actionName === TaggingInvoiceActions.archive) {
        return archiveTaggingInvoice({ assetId, invoiceId });
      } else if (actionName === TaggingInvoiceActions.returnToUntagged) {
        return unarchiveTaggingInvoice({ assetId, invoiceId });
      }
    };

    const actionMutation = useMutation(
      (actionName) => {
        return httpRequest({
          method: "put",
          url: getActionApi({
            actionName,
            assetId,
            invoiceId
          }),
          data: { comment }
        })
          .then((res) => Promise.resolve(res.data))
          .catch((error) => Promise.reject(error.data.errors[0].errorMessage));
      },
      {
        onSuccess: () => {
          refetch();
          modal.remove();
        },
        onError: (error) => {
          if (typeof error == "string") {
            setErrors([{ errorMessage: error }]);
          } else {
            setErrors(error.data.errors);
          }
          document.body.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    );

    const handleSubmit = () => {
      actionMutation.mutate(actionName);
    };

    return (
      <Modal
        keyboard={false}
        backdrop="static"
        fade={false}
        isOpen={modal.visible}
        toggle={modal.remove}
        centered
      >
        <ModalHeader className="pb-0" toggle={modal.remove}>
          <div>
            <h2 className="color-dark modal-title">{getTitle(actionName)}</h2>
          </div>
        </ModalHeader>
        <ModalBody>
          <Alert
            color="danger"
            isOpen={errors?.length > 0}
            toggle={() => setErrors([])}
          >
            <ul className="error-list">
              {errors?.map((error, index) => {
                return <li key={index}>{error.errorMessage}</li>;
              })}
            </ul>
          </Alert>
          <div className="comments-box m-0 border-0 shadow-none p-0">
            <label htmlFor="comments">{Labels.Tagging.Invoices.comments}</label>
            <textarea
              id="comments"
              className="form-control"
              rows={10}
              maxLength={512}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            ></textarea>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={actionMutation.isLoading}
          >
            {actionMutation.isLoading
              ? Labels.Tagging.Invoices.submitting
              : Labels.Tagging.Invoices.submit}
          </Button>
          <Button color="secondary" onClick={modal.remove}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
