import { Privileges as privilegesList } from "./Privileges";
import { labelsList } from "./en/Labels";

export const Labels = labelsList;

// React dropzone error code & messages mapping
export const FileDropzoneErrors = {
  "file-invalid-type": Labels.accounting.fileTypeError,
  "file-too-large": Labels.CommonModals.attachmentSizeError,
  "too-many-files": Labels.CommonModals.pleaseUploadOnlyOneFile
};

export const MAX_ALLOWED_AMOUNT = 99999999.9999;
export const MIN_ALLOWED_AMOUNT = -99999999.9999;

export const Privileges = privilegesList;
