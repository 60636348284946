import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Labels } from "../../../../constants/Constants";
import { getMonthName } from "../../../../utils/date";
import { getCostAccrualDataForPOInvoiceLineItem } from "../../../../utils/http/endpoints";
import { useQuery } from "react-query";
import QueryKeys from "../../../../constants/QueryKeys";
import { nonBlockingHttpRequest } from "../../../../utils/http/nonBlockingHttpRequest";
import DisplayAlerts from "../../../../elements/DisplayAlerts";
import {
  getJournalDescription,
  getJournalLink
} from "../Invoices/invoice-enrichment/operational-operational/JournalManagement";
import { formatAmount } from "../../../../utils/number";
import Tooltip from "../../../../elements/ToolTips/Tooltip";
import PurchaseOrderStatuses from "../../../../constants/PurchaseOrderStatuses";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export default NiceModal.create(
  ({ assetId, poId, lineItemId, currency, status }) => {
    const modal = useModal();
    const [costAccrualJournals, setCostAccrualJournals] = useState([]);

    const { data, error, isError, isLoading } = useQuery({
      queryKey: [
        QueryKeys.getCostAccrualDataForPOInvoiceLineItem,
        +assetId,
        +poId,
        +lineItemId
      ],
      queryFn: () =>
        nonBlockingHttpRequest({
          method: "get",
          url: getCostAccrualDataForPOInvoiceLineItem(assetId, poId, lineItemId)
        })
          .then((res) => Promise.resolve(res.data))
          .catch((err) => Promise.reject(err.data.errors)),
      enabled: !!assetId && !!poId && !!lineItemId,
      refetchOnWindowFocus: true
    });

    useEffect(() => {
      if (data) {
        setCostAccrualJournals(data);
      }
    }, [data]);

    const getTooltipDescription = (
      journal,
      currentOpenPeriod,
      scheduledPostingPeriod
    ) => {
      const description = [];
      if (journal?.isAccrualPostedInOtherPeriod) {
        description.push(
          `Journal belongs to ${scheduledPostingPeriod}, which is a closed period. Hence posting in next open period i.e ${currentOpenPeriod}.`
        );
      }
      if (journal?.isAccrualWriteBackJournal) {
        description.push(Labels.Assets.PurchaseOrders.writeBackJournalMessage);
      }
      if (journal?.isAccrualReverseJournal) {
        description.push(Labels.Assets.PurchaseOrders.reversedJournalMessage);
      }
      if (journal?.isAccrualWriteBackReverseJournal) {
        description.push(
          Labels.Assets.PurchaseOrders.ReverseWriteBackJournalMessage
        );
      }
      return description.join(" ");
    };

    const getJournalInfoIcon = (
      isJournalPosted,
      journal,
      monthYear,
      scheduledPostingMonthYear
    ) => {
      return journal?.isAccrualPostedInOtherPeriod ||
        journal?.isAccrualWriteBackJournal ||
        journal?.isAccrualReverseJournal ||
        journal?.isAccrualWriteBackReverseJournal ? (
        <>
          <FontAwesomeIcon
            className={classNames({
              "ml-1": isJournalPosted
            })}
            id={`write-back-journal-icon-` + journal?.journalId}
            icon={faInfoCircle}
          />
          <Tooltip
            placement="top"
            target={`write-back-journal-icon-` + journal?.journalId}
          >
            {getTooltipDescription(
              journal,
              monthYear,
              scheduledPostingMonthYear
            )}
          </Tooltip>
        </>
      ) : null;
    };

    return (
      <Modal
        isOpen={true}
        centered={true}
        toggle={modal.remove}
        fade={false}
        keyboard={false}
        size="lg"
        backdrop="static"
      >
        <div className="modal-header pb-0">
          <h2 class="color-dark modal-title">
            {Labels.Assets.PurchaseOrders.purchaseOrderAccrualSchedule}
          </h2>
          <div>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={modal.remove}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <ModalBody className="pt-2">
          {isError ? (
            <DisplayAlerts alerts={error} type="danger" className="mt-0" />
          ) : isLoading ? null : (
            <>
              {costAccrualJournals?.length ? (
                <>
                  <p className="mb-2">
                    {[PurchaseOrderStatuses.INVOICED].includes(status)
                      ? Labels.Assets.PurchaseOrders
                          .poAccrualScheduleNoteMessage
                      : Labels.Assets.PurchaseOrders
                          .invoicedPoAccrualScheduleNoteMessage}
                  </p>
                  <div className="standard-table-container">
                    <table className="table table-borderless m-0 w-100">
                      <thead>
                        <tr className="column-header-1">
                          <th>{Labels.Assets.PurchaseOrders.period}</th>
                          <th className="text-left">
                            {Labels.Assets.PurchaseOrders.bliGlCode}
                          </th>
                          <th className="text-center">
                            {Labels.Assets.PurchaseOrders.debit}
                          </th>
                          <th className="text-center">
                            {Labels.Assets.PurchaseOrders.credit}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {costAccrualJournals
                          ?.sort((j1, j2) => {
                            return j1.year === j2.year
                              ? j1.month - j2.month
                              : j1.year - j2.year;
                          })
                          .map((journal, index) => {
                            const glCodeRow =
                              journal.journalPostingLineItemDataList[0];
                            const accrualsRow =
                              journal.journalPostingLineItemDataList[1];
                            const monthYear = `${getMonthName(journal.month)}-${
                              journal.year
                            }`;
                            const scheduledPostingMonthYear = journal
                              ?.scheduledPostingDate?.length
                              ? `${getMonthName(
                                  journal?.scheduledPostingDate[1]
                                )}-${journal?.scheduledPostingDate[0]}`
                              : "";

                            return (
                              <>
                                <tr>
                                  <td rowSpan={2} className="align-middle">
                                    {journal?.journalId ? (
                                      <>
                                        <a
                                          href={getJournalLink({
                                            assetId: assetId,
                                            journalId: journal?.journalId
                                          })}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {monthYear}
                                        </a>
                                        {getJournalInfoIcon(
                                          true,
                                          journal,
                                          monthYear,
                                          scheduledPostingMonthYear
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {monthYear}{" "}
                                        {getJournalInfoIcon(
                                          false,
                                          journal,
                                          monthYear,
                                          scheduledPostingMonthYear
                                        )}
                                      </>
                                    )}
                                  </td>
                                  <td
                                    id={"glCodeRow" + index}
                                    style={{ maxWidth: "18rem" }}
                                    className="text-truncate"
                                  >
                                    {getJournalDescription(glCodeRow)}
                                  </td>
                                  <Tooltip
                                    target={"glCodeRow" + index}
                                    placement="bottom-start"
                                  >
                                    {getJournalDescription(glCodeRow)}
                                  </Tooltip>
                                  <td className="text-center">
                                    {formatAmount(glCodeRow.debit, currency)}
                                  </td>
                                  <td className="text-center">
                                    {formatAmount(glCodeRow.credit, currency)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="border-0"
                                    id={"accrualsRow" + index}
                                  >
                                    {getJournalDescription(accrualsRow)}
                                  </td>
                                  <Tooltip
                                    target={"accrualsRow" + index}
                                    placement="bottom-start"
                                  >
                                    {getJournalDescription(accrualsRow)}
                                  </Tooltip>
                                  <td className="text-center border-0">
                                    {formatAmount(accrualsRow.debit, currency)}
                                  </td>
                                  <td className="text-center border-0">
                                    {formatAmount(accrualsRow.credit, currency)}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className="empty-card m-0">
                  <div className="empty-card-content">
                    {Labels.Assets.PurchaseOrders.noJournalsAvailable}
                  </div>
                </div>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={modal.remove}>
            {Labels.CommonModals.close}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
