/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import QueryKeys from "../../../../constants/QueryKeys";
import DragAndDropFileUpload from "../../../../elements/file-upload/DragAndDropFileUpload";
import DisplayNote from "../../../../elements/DisplayNote";
import { DownloadLink } from "../../../../elements/download/DownloadFiles";
import {
  importAssetBudget,
  downloadAssetBudgetSampleTemplate
} from "../../../../utils/http/endpoints";
import { httpRequest } from "../../../../utils/http/httpRequest";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(({ assetBudgetData, assetId }) => {
  const modal = useModal();
  const [errors, setErrors] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const queryClient = useQueryClient();

  const resetErrors = () => {
    setErrors([]);
  };

  const uploadOptions = {
    accept: ".csv",
    maxFiles: 1,
    minSize: 0,
    maxSize: 10485760
  };

  const sampleCSVDownloadOptions = {
    url: downloadAssetBudgetSampleTemplate(),
    responseType: "blob"
  };

  const onFilesSelection = (files = []) => {
    setAttachment(files);
  };

  const errorCodeDesc = {
    "file-invalid-type": Labels.Journals.fileTypeError,
    "file-too-large": Labels.CommonModals.attachmentSizeError
  };

  const onError = (errorList) => {
    const errors = {};
    errorList.forEach((errorObj) => {
      errorObj.errors.forEach((error) => {
        errors[error.code] = {
          errorMessage: errorCodeDesc[error.code] || error.message
        };
      });
    });
    setErrors([...Object.values(errors)]);
  };

  const save = () => {
    const formData = new FormData();
    formData.append("budgetDataFile", attachment[0]);
    httpRequest({
      method: "post",
      url: importAssetBudget(assetId, assetBudgetData.id),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(() => {
        modal.remove();
        queryClient.invalidateQueries(QueryKeys.getAssetBudget);
      })
      .catch((err) => {
        err.data
          ? setErrors(err.data.errors)
          : setErrors([{ errorMessage: err }]);
      });
  };

  const isInvalid = () => {
    return !(attachment.length > 0);
  };

  return (
    <Modal
      isOpen={true}
      centered={true}
      toggle={modal.remove}
      fade={false}
      keyboard={false}
      backdrop="static"
      className="create-new-orgbudget-modal"
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">
          {Labels.Organisations.Budgets.importBudget}
        </h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={modal.remove}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="py-0 px-3">
        {errors.length ? (
          <div>
            <Alert color="danger" toggle={resetErrors}>
              <ul className="error-list">
                {errors.map((error, index) => {
                  return <li key={index}>{error.errorMessage}</li>;
                })}
              </ul>
            </Alert>
          </div>
        ) : null}
        <Row className="mt-2">
          <Col className="row-header-2">
            {Labels.accounting.Budgets.budgetDataFile}
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <DragAndDropFileUpload
              onFileSelection={onFilesSelection}
              onError={onError}
              label={Labels.CommonModals.uploadFileLabel}
              button={true}
              uploadConfig={uploadOptions}
            />
            <DownloadLink {...sampleCSVDownloadOptions} onError={onError}>
              <span className="float-right download-link pt-1">
                {Labels.CommonModals.downloadCsvTpl}
              </span>
            </DownloadLink>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <DisplayNote
              message={Labels.accounting.Budgets.reImportingWarning}
              type="warning"
              className="align-items-center"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isInvalid()} onClick={save}>
          {Labels.CommonModals.import}
        </Button>
        <Button color="secondary" onClick={modal.remove}>
          {Labels.CommonModals.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
});
