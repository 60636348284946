import React from "react";
import { Labels } from "../../../../constants/Constants";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Alert,
  ModalHeader
} from "reactstrap";
import { useMutation } from "react-query";
import { httpRequest } from "../../../../utils/http/httpRequest";
import { cancelInvoice } from "../../../../utils/http/endpoints";
import { useQueryClient } from "react-query";
import QueryKeys from "../../../../constants/QueryKeys";

export default function InvoiceCancellationModal({
  isOpen,
  toggle,
  updateInvoice,
  assetId,
  invoiceId,
  isFormChanged,
  handleSaveAndAction,
  selectedOptionInSave,
  handleAction,
  comment,
  setComment,
  formErrors,
  setFormErrors
}) {
  const queryClient = useQueryClient();

  const putCancellationReason = async () => {
    return httpRequest({
      method: "put",
      url: cancelInvoice(assetId, invoiceId),
      data: {
        comment
      }
    });
  };
  const mutation = useMutation(putCancellationReason, {
    onSuccess: () => {
      toggle();
      updateInvoice();
      queryClient.invalidateQueries([
        QueryKeys.getInvoiceComments,
        +assetId,
        +invoiceId
      ]);
    },
    onError: (err) => {
      setFormErrors(err.data.errors);
    }
  });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (selectedOptionInSave === "Save & Action") {
      handleSaveAndAction();
      return;
    }
    mutation.mutate();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
      fade={false}
      centered
    >
      <ModalHeader className="pb-0" toggle={toggle}>
        <div>
          <h2 className="color-dark modal-title">
            {Labels.Payments.Invoices.cancelInvoice}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody>
        <Alert
          color="danger"
          className=""
          isOpen={formErrors?.length > 0}
          toggle={() => setFormErrors([])}
        >
          <ul className="error-list">
            {formErrors?.map((error, index) => {
              return <li key={index}>{error.errorMessage}</li>;
            })}
          </ul>
        </Alert>
        <div className="comments-box m-0 border-0 shadow-none p-0">
          <label htmlFor="comments">{Labels.Payments.Invoices.comments}</label>
          <textarea
            id="comments"
            className="form-control"
            rows={10}
            maxLength={512}
            value={comment}
            onChange={(event) => {
              setComment(event.target.value);
            }}
          ></textarea>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={!comment || mutation.isLoading}
        >
          {mutation.isLoading
            ? Labels.Payments.Invoices.submitting
            : Labels.Payments.Invoices.submit}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {Labels.CommonModals.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
