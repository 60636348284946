import React from "react";
import { Labels } from "../../../../../../constants/Constants";
import ConditionalRenderer from "../../../../../../elements/ConditionalRenderer";
import { getSymbolFromCurrencyCode } from "../../../../../../utils/currency/currency";
import { formatAmount } from "../../../../../../utils/number";

export default function TotalInvoiceAmount({ invoiceDetails }) {
  return (
    <div>
      <div className="divider-thick"></div>
      <div className="my-2">
        <div className="d-flex align-items-center my-0">
          <h3 className="color-dark my-auto mr-auto">
            {Labels.Payments.Invoices.Details.TotalInvoiceAmount}
          </h3>
          <div className="d-flex">
            <div className="d-flex flex-column">
              <ConditionalRenderer
                conditions={[
                  invoiceDetails.assetCurrency !==
                    invoiceDetails.invoiceCurrency
                ]}
              >
                <span className="column-header-1 pb-2 pr-5">
                  {Labels.Payments.Invoices.Details.invoiceCurrency}
                </span>
              </ConditionalRenderer>
              <h3 className="color-dark m-0 p-0">
                {formatAmount(
                  invoiceDetails.netAmount,
                  getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)
                )}
              </h3>
            </div>
            <ConditionalRenderer
              conditions={[
                invoiceDetails.assetCurrency !== invoiceDetails.invoiceCurrency
              ]}
            >
              <div className="d-flex flex-column">
                <span className="column-header-1 pb-2">
                  {Labels.Payments.Invoices.Details.baseCurrency}
                </span>
                <h3 className="color-dark m-0 p-0">
                  {formatAmount(
                    invoiceDetails.netAmount * invoiceDetails.exchangeRate,
                    getSymbolFromCurrencyCode(invoiceDetails.assetCurrency)
                  )}
                </h3>
              </div>
            </ConditionalRenderer>
          </div>
        </div>
      </div>
    </div>
  );
}
