import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getDataAvailableFromAndToDate } from "../utils/http/endpoints";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

/**
 * Returns available Dates for Journal transaction Date
 * @param {number} assetId
 * @returns object
 */
export default function useGetDateRangeForFinancialYear(assetId, options = {}) {
  const query = useQuery({
    queryKey: [QueryKeys.getDateRangeForFinancialYear, assetId],
    queryFn: () =>
      nonBlockingHttpRequest({
        method: "get",
        url: getDataAvailableFromAndToDate(assetId)
      })
        .then((res) => res.data)
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!assetId,
    placeholderData: [],
    ...options
  });

  return query;
}
