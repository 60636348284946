import { useForm } from "react-hook-form";

/**
 *  Use cases
 *  *********************************************************************************************
        This Hook needs to be used in conjuction with FormInputWarning component. 
        `formState` is a sole prop to this component

        Example:
                <FormInputWarning formState={formState} />
           
  * *********************************************************************************************
  * 
   
    I)      For all [input type=*] validations
                an error message will be appended to the parent element of corresponding input field
                    Example:
                    ------------------
                        <input
                        placeholder={Labels.Assets.displayName}
                        {...register('displayName', { required: true })}
                        className="form-control" />
                        <span className="error-message redText px-2 pt-1 col-12" >Required.</span>

                        Appended error dom element: <span className="error-message redText px-2 pt-1 col-12" >Required.</span>


    II)     For all Third party form input widgets
                the widget needs to be configured with className same as the registerd form field name.
                If registerd name contains `.`, then `.` must be replaced with `-` in the class name.
                With above configurations, an error message will be appended to the parent element of corresponding widget field.

                Example:
                ------------------
                    <Controller
                        control={control}
                        name="asset.type"
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange,onBlur, value, ref } }) => (
                            <SelectBox
                                inputRef={ref}
                                hideSelectedOptions={false}
                                blurInputOnSelect={false}
                                value={value && assetTypes.find((type) => type.value === value)}
                                options={assetTypes}
                                className="asset-type"
                                onChange={val => {
                                    onChange(val.value);
                                }}
                                onBlur:{onBlur}
                            />
                        )}
                    />

    III)    For Input error message to be displayed in targeted containers
                the container needs to be configured with className same as the registerd form field name.
                If registerd name contains `.`, then they must be replaced with `-` in the class name.
                With above configurations, an error message will be appended to the parent element of the container.

    IV)     For Group Validations
                Assume Address group that has multiple fields such as address line 1, address line 2, state , pincode and country.
                    Then we may need to display a common error message, In these case the message is not a string but an object like below
                    {
                        mesaage : "This is Required",
                        containerClassName:"address-err-container-class"
                    }
                    @IMPORTANT
                        Please be noted that it is important serialize the above object and we have method for it which is `serializeMessage`

                    Example: 
                    ------------------
                        const validateAddress = (val, field) => {
                            const [isEmpty] = isEmptyAddress(val, field);
                            const isValid = !!val;
                            return !isEmpty ? isValid || formState.serializeMessage(Labels.Contacts.inValidAddressErrorMessage, formState.getErrorClassForField("address")) : isEmpty;
                        }

                        <div className="address-lines">
                                <input
                                    className="form-control"
                                    type="text"
                                    name="addressLine1"
                                    {...register('address.addressLine1', { validate: (val) => validateAddress(val, "addressLine1") })}
                                    placeholder={Labels.Contacts.addressLine1}
                                />
                                <input
                                    className="form-control"
                                    type="text"
                                    name="addressLine2"
                                    {...register('address.addressLine2', { validate: (val) => validateAddress(val, "addressLine2") })}
                                    placeholder={Labels.Contacts.addressLine2}
                                />
                                <input
                                    className="form-control"
                                    type="text"
                                    name="state"
                                    {...register('address.state', { validate: (val) => validateAddress(val, "state") })}
                                    placeholder={Labels.Contacts.state}
                                />
                                <input
                                    className="form-control"
                                    type="text"
                                    {...register("address.pincode", { validate: (val) => validateAddress(val, "pincode") })}
                                    placeholder={Labels.Contacts.areaCode}
                                />
                                <Controller
                                    control={control}
                                    name="address.country"
                                    rules={{ validate: (val) => validateAddress(val, "country") }}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <SelectBox
                                            inputRef={ref}
                                            hideSelectedOptions={false}
                                            closeMenuOnSelect={true}
                                            id="countriesForAddress"
                                            value={value && countries.find(c => c.value === value)}
                                            options={countries}
                                            placeholder={Labels.Contacts.country}
                                            isClearable={true}
                                            onChange={val => {
                                                onChange(val?.value);
                                            }}
                                            onBlur={onBlur}
                                        />
                                    )}
                                />
                                <div className={`${formState.getErrorClassForField("address")}`}></div>
                            </div>
                            
                            @IMPORTANT
                                `getErrorClassForField` is useful method on hook's formstate object that accepts field name(or any identifier) and returns constructed class name. 

 */

/** @type {*}
 * Default Error Message Object
 */
const ERROR_MESSAGES = {
  required: "Required.",
  maxLength: "Maximum length allowed is {value}",
  minLength: "Minimum length allowed is {value}",
  min: "Minimum value should be {value}",
  max: "Maximum value should be {value}"
};

/**
 * @param {*} takes same props as useForm along with `errorContainerClass` added extra [RegisterOptions] of `register` method
 * @return {*} returns all what useForm returns and below additional methods along with them
 *
 *  @AdditionalMethods
 *    formState:{ getErrors , generateErrorClassForField}
 *    shouldSubmitBtnBeDisabled
 */
const registeredFieldValidations = {};
const useCustomForm = (props) => {
  props.reValidateMode = props.reValidateMode || "onBlur";
  props.mode = "onSubmit";
  const formDetails = useForm(props);
  const registerFn = formDetails.register;
  // fetch the errors
  const fetchError = (errors, fieldNameArrParam) => {
    const formState = formDetails.formState;
    let resultingError = [];
    const fields = Object.keys(errors || {});
    let field;
    for (let i in fields) {
      let fieldNameArr = [...(fieldNameArrParam || [])];
      field = fields[i];
      const isArrayofFields = Array.isArray(errors[field]);
      if (formState.submitCount > 0) {
        const formFieldErrorArr = errors[field];
        if (isArrayofFields) {
          fieldNameArr.push(field);
          for (let idx = 0; idx < formFieldErrorArr.length; idx++) {
            fieldNameArr = fieldNameArr || [];
            if (formFieldErrorArr[idx]) {
              const newFfieldNameArr = [...fieldNameArr, idx];
              const error = fetchError(
                formFieldErrorArr[idx],
                newFfieldNameArr
              );
              const deserializedMessage = deserializeMessage(error);
              if (deserializedMessage.containerClassName) {
                error.message = deserializedMessage.message;
                error.errorContainerClass =
                  deserializedMessage.containerClassName;
              }
              resultingError.push(error);
            }
          }
        } else {
          fieldNameArr.push(field);
          if (errors[field].type) {
            errors[field].name = fieldNameArr.join(".");
            errors[field].message =
              errors[field].message || getErrorMessage(errors[field]);
            const deserializedMessage = deserializeMessage(
              errors[field].message
            );
            if (deserializedMessage.containerClassName) {
              errors[field].message = deserializedMessage.message;
              errors[field].errorContainerClass =
                deserializedMessage.containerClassName;
            }
            resultingError.push({ ...errors[field] });
          } else {
            const error = fetchError(errors[field], fieldNameArr);
            const deserializedMessage = deserializeMessage(error);
            if (deserializedMessage.containerClassName) {
              error.message = deserializedMessage.message;
              error.errorContainerClass =
                deserializedMessage.containerClassName;
            }
            resultingError.push(error);
          }
        }
      }
    }

    return resultingError.flat();
  };
  // get concerned error message for perticular field.
  const getErrorMessage = (params) => {
    let result = params?.message || ERROR_MESSAGES.required;
    if (["maxLength", "minLength", "min", "max"].includes(params?.type)) {
      const data = {
        "{value}": registeredFieldValidations[params?.name]
          ? registeredFieldValidations[params?.name][params?.type] || ""
          : ""
      };
      result = ERROR_MESSAGES[params?.type].replace(
        new RegExp(["{value}"].join("|"), "gi"),
        function (matched) {
          return data[matched].value || data[matched];
        }
      );
    }

    return result || ERROR_MESSAGES[params?.type];
  };
  // deserialze message object
  const deserializeMessage = (message) => {
    try {
      return JSON.parse(message);
    } catch (e) {
      return message;
    }
  };
  //serialize message object
  formDetails.formState.serializeMessage = (message, containerClassName) => {
    return JSON.stringify({
      message,
      containerClassName
    });
  };
  // Get form error list.
  formDetails.formState.getErrors = () => {
    const formState = formDetails.formState;
    const errors = fetchError(formState.errors);
    return errors;
  };
  // a wrapper method for useForm register method
  formDetails.register = (name, options) => {
    registeredFieldValidations[name] = options;
    return registerFn(name, options);
  };
  // returns class name from passed fieldName argument
  formDetails.formState.getErrorClassForField = (fieldName) => {
    return `${fieldName}-error-display-class`;
  };

  return {
    ...formDetails,
    shouldSubmitBtnBeDisabled: () => {
      return formDetails.formState.submitCount > 0
        ? Object.keys(formDetails.formState.errors).length > 0
        : false;
    }
  };
};

export default useCustomForm;
