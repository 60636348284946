import React, { useContext, useEffect } from "react";
import { Labels } from "../../../../../../constants/Constants";
import { OTHER_DEDUCTIONS_UPDATE } from "./actionsTypes";
import InvoiceFormContext from "./InvoiceFormContext";
import { Alert } from "reactstrap";
import { getSymbolFromCurrencyCode } from "../../../../../../utils/currency/currency";
import XEInfoTooltip from "../../../../../../elements/XEInfoTooltip";

export default function WithholdingTaxes({
  invoiceDetails,
  isFormReadOnly,
  assetDetails,
  isCreditNote
}) {
  const { state, dispatch, setIsFormChanged } = useContext(InvoiceFormContext);
  const isWithholdingTaxSystemAccountConfigured =
    !!assetDetails.isWithholdingTaxSystemAccountConfigured;
  const { otherDeductions } = state;

  // Set other deductions also as per invoiceDetails api res
  useEffect(() => {
    // Set otherDeductions value from invoiceDetails if selectedBudgetLineItemId and invoiceDetails.budgetLineItem.id are same
    dispatch({
      type: OTHER_DEDUCTIONS_UPDATE,
      payload: { value: invoiceDetails.otherDeductions }
    });
  }, [invoiceDetails, dispatch]);

  // Read value and error message
  const handleOtherDeductionsChange = (evt) => {
    setIsFormChanged(true);
    dispatch({
      type: OTHER_DEDUCTIONS_UPDATE,
      payload: {
        value: evt.target.value,
        validationErrors: evt.target.validationMessage
      }
    });
  };

  // Subscribe for error messages (OnChange will not be triggered if alphabets are entered by user)
  const handleOtherDeductionsKeyUp = (evt) => {
    setIsFormChanged(true);
    dispatch({
      type: OTHER_DEDUCTIONS_UPDATE,
      payload: {
        value: evt.target.value,
        validationErrors: evt.target.validationMessage
      }
    });
  };

  const clickHereElement = () => (
    <a
      href={`#/org/${invoiceDetails.orgId}/assets/asset/${invoiceDetails.assetId}/accounting/chartofaccounts/mapSystemAccounts`}
      className="link-primary"
      target="_blank"
    >
      <span>{Labels.CommonModals.clickHere}</span>
    </a>
  );

  return (
    <>
      <div className="divider-thick"></div>
      <fieldset disabled={isFormReadOnly || isCreditNote}>
        <div id="withholding-taxes" className="my-2">
          {!isWithholdingTaxSystemAccountConfigured ? (
            <Alert color="info" className="mt-3 mb-3">
              {Labels.Payments.Invoices.Details.whtSystemAccountInfoMsg.line1}{" "}
              {clickHereElement()}
              {Labels.Payments.Invoices.Details.whtSystemAccountInfoMsg.line2}
            </Alert>
          ) : null}

          {invoiceDetails.isNegativeInvoice ? (
            <div className="mt-4 mb-0">
              <Alert role="alert" color="info">
                {Labels.Payments.Invoices.Details.negativeInvoiceWHTInfoMsg}
              </Alert>
            </div>
          ) : null}
          <div className="other-deductions">
            <label htmlFor="other-deductions">
              <h3 className="color-dark mb-0">
                {Labels.Payments.Invoices.Details.WithholdingTaxes}
              </h3>
            </label>
            <span>
              <span className="text-danger font-weight-normal mr-1">
                {otherDeductions.validationErrors}
              </span>

              {!isFormReadOnly ? (
                <>
                  <span>
                    {getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)}{" "}
                  </span>
                  <input
                    value={otherDeductions.value}
                    className="input-field"
                    onChange={handleOtherDeductionsChange}
                    onKeyUp={handleOtherDeductionsKeyUp}
                    disabled={
                      isFormReadOnly ||
                      invoiceDetails.isNegativeInvoice ||
                      !isWithholdingTaxSystemAccountConfigured
                    }
                    type="number"
                    min={0}
                    step={0.01}
                    id="other-deductions"
                  />
                </>
              ) : null}
              <XEInfoTooltip
                bcAmount={(
                  otherDeductions.value * invoiceDetails.exchangeRate
                ).toFixed(2)}
                invoiceDetails={invoiceDetails}
              />
            </span>
            {isFormReadOnly ? (
              <span>
                <h3 className="color-dark mb-0">
                  {getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)}{" "}
                  {otherDeductions?.value.toFixed(2)}
                </h3>
              </span>
            ) : null}
          </div>
        </div>
      </fieldset>
      {isCreditNote && (
        <Alert role="alert" color="info">
          {Labels.Payments.Invoices.Details.notApplicableForWHT}
        </Alert>
      )}
    </>
  );
}
