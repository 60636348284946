import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import QueryKeys from "../../../../constants/QueryKeys";
import { downloadSalesInvoicePDF } from "../../../../utils/http/endpoints";
import { httpRequest } from "../../../../utils/http/httpRequest";
import DisplayAlerts from "../../../../elements/DisplayAlerts";
import PDFPreviewModal from "./PDFPreviewModal";

export default NiceModal.create(({ invoiceData }) => {
  const modal = useModal();

  const [specialInstructions, setSpecialInstructions] = useState(
    invoiceData.specialInstructions || null
  );
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: [QueryKeys.downloadSalesInvoicePDF, invoiceData.id],
    mutationFn: () =>
      httpRequest({
        method: "post",
        url: downloadSalesInvoicePDF({
          assetId: invoiceData.assetId,
          invoiceId: invoiceData.id,
          data: {
            specialInstructions
          }
        }),
        responseType: "blob",
        data: {
          specialInstructions
        }
      })
        .then((res) => Promise.resolve(res))
        .catch((error) => Promise.reject(error.data.errors)),
    onSuccess: async (res) => {
      // Invalidate invoice data to get saved special instructions
      queryClient.invalidateQueries([
        QueryKeys.getSalesInvoice,
        {
          assetId: String(invoiceData.assetId),
          invoiceId: String(invoiceData.id)
        }
      ]);

      modal.resolve();
      modal.remove();
      const fileName = res.headers["content-disposition"].split("filename=")[1];
      // api responds with content type application/force-download, so we need to set the content type to application/pdf
      const newData = new Blob([res.data], {
        type: "application/pdf"
      });
      NiceModal.show(PDFPreviewModal, {
        fileName,
        file: URL.createObjectURL(newData)
      });
      mutation.reset();
    }
  });

  const handleConfirm = () => {
    mutation.mutate();
  };

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      centered
      fade={false}
      isOpen={modal.visible}
      toggle={modal.remove}
      size="md"
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">
          {Labels.Payments.AccountsReceivables.addInformationToDocument}
        </h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={modal.remove}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="mt-0 pt-0">
        {mutation.isError ? (
          <DisplayAlerts
            className="mt-0"
            type={"danger"}
            alerts={mutation.error}
          />
        ) : null}
        <div className="mb-3">
          <p>{Labels.Payments.AccountsReceivables.downloadNote}</p>
        </div>
        <div className="comments-box m-0 border-0 shadow-none p-0">
          <textarea
            id="comments"
            className="form-control"
            cols={20}
            value={specialInstructions}
            onChange={(event) => {
              setSpecialInstructions(event.target.value);
            }}
          ></textarea>
        </div>
      </ModalBody>
      <ModalFooter className="py-2">
        <Button
          color="primary"
          onClick={handleConfirm}
          disabled={mutation.isLoading}
        >
          {Labels.CommonModals.preview}
        </Button>
        <Button color="secondary" onClick={modal.remove}>
          {Labels.CommonModals.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
});
