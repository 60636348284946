import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import DragAndDropFileUpload from "../../../../../elements/file-upload/DragAndDropFileUpload";
import DisplayAlerts from "../../../../../elements/DisplayAlerts";

export default NiceModal.create(({ approveRequest }) => {
  const modal = useModal();
  const [document, setDocument] = useState([]);
  const [errors, setErrors] = useState([]);
  const uploadOptions = {
    accept: ".pdf",
    maxFiles: 1,
    minSize: 0,
    maxSize: 10485760
  };

  const handleApprove = () => {
    approveRequest(document);
    modal.remove();
  };

  const onFilesSelection = (files = []) => {
    setDocument(files);
  };

  const errorCodeDesc = {
    "file-invalid-type":
      Labels.Payments.Utilisations.approveUtilisationRequestfileTypeError,
    "file-too-large": Labels.CommonModals.attachmentSizeError
  };

  const onError = (errorList) => {
    const errors = {};
    errorList.forEach((errorObj) => {
      errorObj.errors.forEach((error) => {
        errors[error.code] = {
          errorMessage: errorCodeDesc[error.code] || error.message
        };
      });
    });
    setErrors([...Object.values(errors)]);
  };
  return (
    <React.Fragment>
      <Modal
        isOpen={true}
        centered={true}
        toggle={modal.remove}
        fade={false}
        keyboard={false}
        backdrop="static"
      >
        <div className="modal-header">
          <h2 className="color-dark modal-title">
            {Labels.Payments.Utilisations.approveUtilisationRequest}
          </h2>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={modal.remove}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {errors.length ? (
            <DisplayAlerts alerts={errors} type="danger" />
          ) : null}
          <label className="py-2">
            {Labels.Payments.Utilisations.attachSignedApproval}
          </label>
          <DragAndDropFileUpload
            onFileSelection={onFilesSelection}
            onError={onError}
            label={Labels.CommonModals.uploadFileLabel}
            button={true}
            uploadConfig={uploadOptions}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={!document.length}
            onClick={handleApprove}
          >
            {Labels.Payments.Utilisations.approve}
          </Button>
          <Button color="secondary" onClick={modal.remove}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
});
