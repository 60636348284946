import React from "react";
import { formatAmountWithThousandSeparators } from "../../../../../utils/number";

export default function InvoiceAmountColumn(props) {
  let rowData = props.cellProperties.instance.getSourceDataAtRow(
    props.cellProperties.instance.toPhysicalRow(props.row)
  );
  props.TD.style.textAlign = "right";
  if (rowData.supplierName === " ") {
    props.TD.className = "fw-700 htDimmed";
  } else {
    props.TD.className = "htDimmed";
  }

  let amount = props.value;
  if (amount !== "") {
    amount = formatAmountWithThousandSeparators(amount, true);
  }

  let url;
  if (rowData.journalId !== null) {
    url = `/#/org/${props.orgId}/assets/asset/${props.assetId}/accounting/journals/manual/journal/${rowData.journalId}`;
  }

  return (
    <React.Fragment>
      {rowData.documents.length ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          className="text-underline"
          onClick={(event) => props.populateInvoices(event, rowData.documents)}
        >
          {amount}
        </a>
      ) : props.title === "FX Gains/Losses (Unrealised)" &&
        rowData.documents.length === 0 &&
        rowData.journalId === null ? (
        <a
          href="#"
          className="text-underline"
          onClick={(event) =>
            props.displayFxDetailedDrilldown(
              event,
              rowData.transactionId,
              rowData.invoiceType
            )
          }
        >
          {amount}
        </a>
      ) : rowData.journalId ? (
        <a href={url} className="text-underline" target="_blank">
          {amount}
        </a>
      ) : (
        <span>{amount}</span>
      )}
    </React.Fragment>
  );
}
