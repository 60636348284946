import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Labels } from "../../constants/Constants";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { modalNames } from "./modal-register";

export default NiceModal.create(() => {
  // Use a hook to manage the modal state
  const modal = useModal();

  const handleConfirm = () => {
    modal.resolve();
    modal.remove();
  };

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      centered
      fade={false}
      isOpen={modal.visible}
      toggle={modal.remove}
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">
          {Labels.CommonModals.createADraftCopy}
        </h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={modal.remove}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="mt-0 pt-0">
        {Labels.CommonModals.createADraftCopyModal}
      </ModalBody>
      <ModalFooter className="py-2">
        <Button color="primary" onClick={handleConfirm}>
          {Labels.CommonModals.ok}
        </Button>
        <Button color="secondary" onClick={modal.remove}>
          {Labels.CommonModals.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
});

/**
 *
 * @param {Function} postConfirmationAction
 */
export const takeCopyActionConfirmation = (postConfirmationAction, ...args) => {
  NiceModal.show(modalNames.copyDraftAction, {})
    .then(() => postConfirmationAction(...args))
    .catch((error) => {
      console.log(error);
    });
};
