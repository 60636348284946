import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from "reactstrap";
import { useMutation } from "react-query";
import { httpRequest } from "../../../../../../utils/http/httpRequest";
import { Labels } from "../../../../../../constants/Constants";
import { returnToDraft } from "../../../../../../utils/http/endpoints";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(
  ({
    assetBankAccountStatementLineItemId,
    refreshTransactionsList,
    refreshTransactionData,
    params
  }) => {
    const modal = useModal();
    const [comment, setComment] = useState("");
    const [formErrors, setFormErrors] = useState([]);

    const postVoidComment = async () => {
      return httpRequest({
        method: "post",
        url: returnToDraft({
          assetBankAccountStatementLineItemId,
          ...params
        }),
        data: {
          comment
        }
      });
    };

    const mutation = useMutation(postVoidComment, {
      onSuccess: () => {
        modal.remove();
        if (refreshTransactionsList) {
          refreshTransactionsList();
        } else {
          refreshTransactionData(); // Refresh transaction line item data to show updated status value
        }
      },
      onError: (err) => {
        setFormErrors(err.data.errors);
      }
    });

    const handleSubmit = (evt) => {
      evt.preventDefault();
      mutation.mutate();
    };

    return (
      <Modal
        isOpen={modal.visible}
        toggle={modal.remove}
        backdrop="static"
        keyboard={false}
        fade={false}
        centered
      >
        <ModalHeader toggle={modal.remove}>
          <div>
            <h2 className="color-dark modal-title">
              {Labels.accounting.returnTransactionToDraft}
            </h2>
          </div>
        </ModalHeader>
        <ModalBody className="mt-0 pt-0">
          {formErrors?.length > 0 ? (
            <Alert
              color="danger"
              className=""
              isOpen={formErrors?.length > 0}
              toggle={() => setFormErrors([])}
            >
              <ul className="error-list">
                {formErrors.map((error, index) => {
                  return <li key={index}>{error.errorMessage}</li>;
                })}
              </ul>
            </Alert>
          ) : null}
          <div className="comments-box m-0 border-0 shadow-none p-0">
            <label htmlFor="comments" className="border-bottom-0">
              {Labels.Assets.comments}
            </label>
            <textarea
              id="comments"
              className="form-control"
              rows={10}
              maxLength={512}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            ></textarea>
          </div>
          <p className="mt-3">
            <span className="row-header-2">{Labels.CommonModals.note}</span>
            {Labels.accounting.retunToDraftConfirmMessage}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={mutation.isLoading}
          >
            {mutation.isLoading
              ? Labels.Payments.Invoices.submitting
              : Labels.Payments.Invoices.submit}
          </Button>
          <Button onClick={modal.remove} disabled={mutation.isLoading}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
