export const ADD_NEW_TRANSACTION_LINEITEM = "ADD_NEW_TRANSACTION_LINEITEM";
export const REMOVE_TRANSACTION_LINEITEM = "REMOVE_TRANSACTION_LINEITEM";
export const UPDATE_TRANSACTION_DATE = "UPDATE_TRANSACTION_DATE";
export const UPDATE_CONTACT_NAME = "UPDATE_CONTACT_NAME";
export const UPDATE_BUDGET_LINE_ITEM = "UPDATE_BUDGET_LINE_ITEM";
export const UPDATE_GL_CODE = "UPDATE_GL_CODE";
export const UPDATE_TAX_RATE = "UPDATE_TAX_RATE";
export const UPDATE_AMOUNT = "UPDATE_AMOUNT";
export const UPDATE_LINE_ITEMS_BY_API_RESPONSE =
  "UPDATE_LINE_ITEMS_BY_API_RESPONSE";
export const TOGGLE_SELECTED_PAYMENT = "TOGGLE_SELECTED_PAYMENT";
export const RESET = "RESET";
export const SET_IS_FORM_CHANGED = "SET_IS_FORM_CHANGED";
export const UPDATE_TRANSFER_TO_BANK_ACCOUNT_ID =
  "UPDATE_TRANSFER_TO_BANK_ACCOUNT_ID";
export const UPDATE_TRANSFER_TO_BANK_LINE_ITEM_ID =
  "UPDATE_TRANSFER_TO_BANK_LINE_ITEM_ID";
export const UPDATE_FORMDATA_FROM_API_DATA = "UPDATE_FORMDATA_FROM_API_DATA";
export const UPDATE_TRANSACTION_STATUS = "UPDATE_TRANSACTION_STATUS";
