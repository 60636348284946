import { rest } from "msw";
import accrualdata from "./mock-data/invoices/accrualdata.json";
import prepaymentdata from "./mock-data/invoices/prepaymentdata.json";
import journalComments from "./mock-data/journal-comments.json";
import categoryData from "./mock-data/categoryData";

const serverError = {
  errors: [{ errorMessage: "500 Internal Server Error" }]
};

export const handlers = [
  rest.get("http://localhost/organizations/contacts/list", (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: [
          {
            id: "1",
            name: "Fincovi"
          }
        ]
      })
    );
  }),
  rest.post(
    "http://localhost/assets/:assetId/kpidata/upload",
    (req, res, ctx) => {
      if (req.params.assetId === "14") {
        return res(ctx.status(500), ctx.json(serverError));
      }
      return res(ctx.status(200), ctx.json({}));
    }
  ),
  rest.get(
    "https://dev-platform.fincovi.com/api/assets/:assetId/invoices/:invoiceId/accrualdata",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(accrualdata));
    }
  ),
  rest.post(
    "https://dev-platform.fincovi.com/api/assets/:assetId/invoices/:invoiceId/prepaymentdata",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(prepaymentdata));
    }
  ),
  rest.post(
    "https://dev-platform.fincovi.com/api/assets/:assetId/invoices/:invoiceId/approve",
    (req, res, ctx) => {
      if (Math.random() < 0.5) {
        return res(ctx.status(200), ctx.json({}));
      } else {
        return res(ctx.status(400), ctx.json(serverError));
      }
    }
  ),
  rest.get(
    "https://dev-platform.fincovi.com/api/assets/65/journals/1192/historyandcomments",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(journalComments));
    }
  ),
  rest.get(
    "http://localhost/assets/tagging/categories/:categoryId",
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(categoryData));
    }
  ),
  rest.get(
    "http://localhost/organizations/17/powergeneration/datasource",
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          data: [
            {
              clientId: "greencoatrenewables",
              clientSecretKey: "secretkey",
              dataSource: "Greenbyte",
              id: 10
            }
          ],
          isSuccess: true,
          isError: false
        })
      );
    }
  ),
  rest.post(
    "http://localhost/organizations/17/powergeneration/datasource",
    (req, res, ctx) => {
      const reqBody = req.body;
      if (reqBody.clientId === "greencoatrenewables") {
        return res(ctx.status(200));
      } else {
        return res(
          ctx.status(400),
          ctx.json({
            errors: [
              {
                errorMessage: "Connection to Greenbyte has failed. Kindly retry"
              }
            ]
          })
        );
      }
    }
  ),
  rest.get(
    "http://localhost/organizations/17/assets/65/powergeneration/devices",
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          data: [
            { id: 164, deviceId: "1" },
            { id: 165, deviceId: "2" },
            { id: 166, deviceId: "3" },
            { id: 167, deviceId: "4" }
          ]
        })
      );
    }
  ),
  rest.get(
    "http://localhost/organizations/17/assets/65/powergeneration/report",
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          data: {
            "Jan-23": { 1: 837513.0, 2: 791829.0, 3: 814808.0, 4: 751714.0 },
            "Feb-23": { 1: 741859.0, 2: 646539.0, 3: 585238.0, 4: 681218.0 },
            "Mar-23": { 1: 502433.0, 2: 624842.0, 3: 578391.0, 4: 605605.0 },
            "Apr-23": { 1: 578359.0, 2: 505616.0, 3: 534449.0, 4: 544092.0 },
            "May-23": { 1: 335803.0, 2: 319348.0, 3: 299229.0, 4: 309736.0 },
            totals: { 1: 2995967.0, 2: 2888174.0, 3: 2812115.0, 4: 2892365.0 }
          }
        })
      );
    }
  )
  // rest.get(
  //   "https://dev-platform.fincovi.com/api/assets/:assetId/invoices/:invoiceId",
  //   (req, res, ctx) => {
  //     return res(ctx.status(200), ctx.json(invoiceData));
  //   }
  // )
];
