import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { PropTypes } from "prop-types";

export default function DisplayNote({ message, type = "info", className }) {
  let colorCls;
  switch (type) {
    case "danger":
      colorCls = "color-negative";
      break;
    case "warning":
      colorCls = "text-amber";
      break;
    default:
      colorCls = "color-info";
  }
  return message ? (
    <div className={`${className || ""} d-flex text-block-primary ${colorCls}`}>
      <FontAwesomeIcon
        icon={type === "info" ? faInfoCircle : faExclamationCircle}
        className={`${colorCls} mr-1`}
      />{" "}
      {message}
    </div>
  ) : (
    ""
  );
}

DisplayNote.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(["info", "danger", "warning"]),
  displayLabel: PropTypes.string
};
