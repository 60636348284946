const TaskStatuses = {
  NEW: "New",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  ARCHIVED: "Archived"
};

export const taskStatusList = Object.entries(TaskStatuses).map(([k, v]) => ({
  id: k,
  value: k,
  label: v
}));

export default TaskStatuses;
