import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getRoles } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

export default function useGetRoles(isBlocking = true) {
  const request = isBlocking ? httpRequest : nonBlockingHttpRequest;
  const query = useQuery({
    queryKey: [QueryKeys.getRoles],
    queryFn: () =>
      request({
        method: "get",
        url: getRoles()
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    select: (data) => data.map((i) => ({ ...i, label: i.name, value: i.id }))
  });

  return query;
}
