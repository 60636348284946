import * as actionTypes from "./transactionDetailActionTypes";
import { nanoid } from "nanoid";

export class LineItem {
  key = nanoid();
  transactionDate = "";
  transactionDateErrors = "";
  contactName = "";
  contactNameErrors = "";
  budgetLineItem = null;
  glCode = null;
  taxRate = null;
  taxRateErrors = "";
  amount = "";
  amountErrors = "";
}
export class InitialState {
  isFormChanged = false;
  selectedPayments = [];
  transactionLineItems = [];
  touchedFormFields = new Set();
  transactionStatus = "";
  transferToBankLineItemId = null;
  transferToBankAccountId = null;
}

export default function transactionDetailReducer(state, action) {
  const { type, payload, meta } = action;
  const updateTransactionLineItem = () => {
    return state.transactionLineItems.map((lineItem) => {
      if (lineItem.key === payload.key) {
        return {
          ...lineItem,
          ...payload
        };
      }
      return lineItem;
    });
  };
  const updateTransactionLineItemsByAPIResponse = () => {
    if (
      payload.transactionDetails !== null &&
      payload.transactionDetails.length
    ) {
      return payload.transactionDetails.map((item) => {
        return {
          key: nanoid(),
          transactionDate: "",
          transactionDateErrors: "",
          contactName: "",
          contactNameErrors: "",
          budgetLineItem: null,
          glCode: null,
          taxRate: "",
          taxRateErrors: "",
          amount: "",
          amountErrors: ""
        };
      });
    }
  };
  // console.log(action);
  switch (type) {
    case actionTypes.ADD_NEW_TRANSACTION_LINEITEM:
      return {
        ...state,
        transactionLineItems: state.transactionLineItems.concat(payload),
        isFormChanged: true
      };
    case actionTypes.REMOVE_TRANSACTION_LINEITEM:
      return {
        ...state,
        transactionLineItems: state.transactionLineItems.filter(
          (lineItem) => lineItem.key !== payload.key
        ),
        isFormChanged: true
      };
    case actionTypes.UPDATE_TRANSACTION_DATE:
      return {
        ...state,
        transactionLineItems: updateTransactionLineItem(),
        touchedFormFields: state.touchedFormFields.add(
          "transactionDate" + payload.key
        ),
        isFormChanged: true
      };
    case actionTypes.UPDATE_CONTACT_NAME:
      return {
        ...state,
        transactionLineItems: updateTransactionLineItem(),
        touchedFormFields: state.touchedFormFields.add(
          "contactName" + payload.key
        ),
        isFormChanged: true
      };
    case actionTypes.UPDATE_BUDGET_LINE_ITEM:
      return {
        ...state,
        transactionLineItems: updateTransactionLineItem(),
        touchedFormFields: state.touchedFormFields.add(
          "budgetLineItem" + payload.key
        ),
        isFormChanged: true
      };
    case actionTypes.UPDATE_GL_CODE:
      return {
        ...state,
        transactionLineItems: updateTransactionLineItem(),
        touchedFormFields: state.touchedFormFields.add("glCode" + payload.key),
        isFormChanged: true
      };
    case actionTypes.UPDATE_TAX_RATE:
      return {
        ...state,
        transactionLineItems: updateTransactionLineItem(),
        touchedFormFields: state.touchedFormFields.add("taxRate" + payload.key),
        isFormChanged: true
      };
    case actionTypes.UPDATE_AMOUNT:
      return {
        ...state,
        transactionLineItems: updateTransactionLineItem(),
        touchedFormFields: state.touchedFormFields.add("amount" + payload.key),
        isFormChanged: true
      };
    case actionTypes.UPDATE_LINE_ITEMS_BY_API_RESPONSE:
      return {
        ...state,
        transactionLineItems: updateTransactionLineItemsByAPIResponse()
      };

    case actionTypes.TOGGLE_SELECTED_PAYMENT:
      return {
        ...state,
        selectedPayments:
          // ID is not unique, so we need to use id & type combination to identify the payment
          state.selectedPayments.findIndex(
            (i) =>
              i.id === payload.payment.id && i.type === payload.payment.type
          ) !== -1
            ? state.selectedPayments.filter(
                (i) =>
                  !(
                    i.id === payload.payment.id &&
                    i.type === payload.payment.type
                  )
              )
            : state.selectedPayments.concat(payload.payment),
        isFormChanged: true
      };

    case actionTypes.UPDATE_TRANSFER_TO_BANK_ACCOUNT_ID:
      return {
        ...state,
        transferToBankAccountId: payload.transferToBankAccountId,
        touchedFormFields: state.touchedFormFields.add(
          "transferToBankAccountId"
        ),
        isFormChanged: meta?.isUserAction === false ? state.isFormChanged : true
      };
    case actionTypes.UPDATE_TRANSFER_TO_BANK_LINE_ITEM_ID:
      return {
        ...state,
        transferToBankLineItemId: payload.transferToBankLineItemId,
        touchedFormFields: state.touchedFormFields.add(
          "transferToBankLineItemId"
        ),
        isFormChanged: meta?.isUserAction === false ? state.isFormChanged : true
      };
    case actionTypes.UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: payload.status
      };

    case actionTypes.SET_IS_FORM_CHANGED:
      return {
        ...state,
        isFormChanged: payload.isFormChanged
      };

    case actionTypes.RESET:
      return new InitialState();

    default:
      throw new Error("Dispatched action is not implemented" + type);
  }
}
