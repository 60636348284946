//@ts-check

import { useQuery } from "react-query";
import QueryKeys from "../../../../../constants/QueryKeys";
import { nonBlockingHttpRequest } from "../../../../../utils/http/nonBlockingHttpRequest";

export default function useGetInvoiceHistoryAndComments(url, options = {}) {
  const query = useQuery({
    queryKey: [QueryKeys.getInvoiceHistoryAndComments, url],
    queryFn: () =>
      nonBlockingHttpRequest({
        method: "get",
        // @ts-ignore
        url
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    ...options
  });

  return query;
}
