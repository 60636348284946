//@ts-check
import { useQuery } from "react-query";
import QueryKeys from "../../constants/QueryKeys";
import { nonBlockingHttpRequest } from "../../utils/http/nonBlockingHttpRequest";

export default function useGetInvoiceAttachments(url) {
  const query = useQuery({
    queryKey: [QueryKeys.getAttachments, url],
    queryFn: () =>
      nonBlockingHttpRequest({
        method: "get",
        url
      })
        .then((res) => res.data)
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!url
  });

  return query;
}
