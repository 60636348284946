import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { Labels } from "../../../constants/Constants";
import DistributionDataTable from "../../Assets/Reports/Checks/DistributionData/DistributionDataTable";
import DistributionDataGraph from "../../Assets/Reports/Checks/DistributionData/DistributionDataGraph";
import { getSymbolFromCurrencyCode } from "../../../utils/currency/currency";

export default NiceModal.create(({ tableData, distributionData }) => {
  const modal = useModal();
  const [isDetailsTableVisible, toggleDetailsTable] = useState(false);

  return (
    <Modal
      isOpen={modal.visible}
      className="modal-fullscreen py-5"
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader className="pb-2" toggle={modal.remove}>
        <h2 className="color-dark modal-title">
          {Labels.Checks.cashFlowAnalysis}
        </h2>
      </ModalHeader>
      <ModalBody className="pt-0">
        <div className="navbar-tabbed bg-transparent">
          <Nav tabs className="tabbed-container justify-content-end">
            <NavItem>
              <NavLink
                className={`px-3 tab cursor-pointer ${
                  !isDetailsTableVisible ? "active" : ""
                }`}
                onClick={() => {
                  toggleDetailsTable(false);
                }}
              >
                {Labels.Checks.graph}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`px-3 tab cursor-pointer ${
                  isDetailsTableVisible ? "active" : ""
                }`}
                onClick={() => {
                  toggleDetailsTable(true);
                }}
              >
                {Labels.Assets.DetailedCashFlow.table}
              </NavLink>
            </NavItem>
          </Nav>
          {!isDetailsTableVisible ? (
            <>
              <div className="border pt-1">
                <DistributionDataGraph
                  distributionData={distributionData}
                  currency={getSymbolFromCurrencyCode("EUR")}
                  graphHeight={"36%"}
                  fromModal={true}
                />
              </div>
            </>
          ) : (
            <div
              style={true ? { minHeight: "56vh" } : {}}
              className={
                true
                  ? "d-flex flex-column justify-content-center border pt-1"
                  : ""
              }
            >
              <DistributionDataTable tableData={tableData} />
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
});
