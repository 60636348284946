import { toTitleCase } from "../utils/string";

const InvoiceStatuses = {
  DRAFT: "Draft",
  AWAITING_APPROVAL: "Awaiting Approval",
  AWAITING_PAYMENT: "Awaiting Payment",
  AWAITING_RECEIPT: "Awaiting Receipt", // -ve invoice specific
  RECEIVED: "Received", // -ve invoice specific
  PAID: "Paid",
  IN_DISPUTE: "In Dispute",
  VOIDED: "Voided",
  CANCELLED: "Cancelled"
};

export const purchaseInvoiceStatusList = Object.entries(InvoiceStatuses).map(
  ([k, v]) => ({
    id: k,
    value: k,
    label: toTitleCase(v)
  })
);

export default InvoiceStatuses;
