const FixedAssetRegisterStatuses = {
  DRAFT: "Draft",
  REGISTERED: "Registered",
  VOIDED: "Voided"
};

export const FixedAssetRegisterStatusesList = Object.entries(
  FixedAssetRegisterStatuses
).map(([k, v]) => ({
  id: k,
  value: k,
  label: v
}));

export default FixedAssetRegisterStatuses;
