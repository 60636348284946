import React from "react";
import { Labels } from "../../../../../constants/Constants";
import HistoryTable from "../../../../../elements/list/HistoryTable/HistoryTable";

export default function InvoicePaymentHistory(props) {
  const goBackToPayments = (event) => {
    event.preventDefault();
    props.togglePaymentsTable(true);
  };

  return (
    <React.Fragment>
      <div className="clearfix border-bottom-fv-border-2px mb-4">
        <div className="float-left">
          <h3 className="color-dark">
            {Labels.Payments.Invoices.Details.paymentHistory}
          </h3>
        </div>
        <div className="float-right">
          <div className="create-payment">
            <a
              href="#"
              className="back-link"
              onClick={(event) => goBackToPayments(event)}
            >
              &nbsp;{Labels.Payments.Invoices.Payments.backToPayments}
            </a>
          </div>
        </div>
      </div>
      <HistoryTable historyList={props.historyList} />
    </React.Fragment>
  );
}
