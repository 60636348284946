import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { Labels } from "../../../constants/Constants";
import { modalNames } from "../../../elements/modals/modal-register";
import { ErrorMessage } from "@hookform/error-message";
import { useMutation, useQueryClient } from "react-query";
import DisplayAlerts from "../../../elements/DisplayAlerts";
import QueryKeys from "../../../constants/QueryKeys";
import processAPIResponse from "../../../utils/utils";
import { httpRequest } from "../../../utils/http/httpRequest";
import { createCategory, updateCategory } from "../../../utils/http/endpoints";
export default NiceModal.create(
  ({ categoryData, params, setIsCategoryCreated }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const handleConfirm = () => {
      modal.resolve();
      modal.remove();
    };
    const queryClient = useQueryClient();

    const mutation = useMutation({
      mutationKey: categoryData
        ? [QueryKeys.updateCategory, +params.categoryId]
        : QueryKeys.createCategory,
      mutationFn: (data) =>
        processAPIResponse(
          httpRequest({
            url: categoryData
              ? updateCategory(+params.categoryId)
              : createCategory(),
            method: categoryData ? "put" : "post",
            data
          })
        ),
      onSuccess: () => {
        handleConfirm();
        if (categoryData) {
          queryClient.invalidateQueries([
            QueryKeys.getCategory,
            +params.categoryId
          ]);
        }
        queryClient.invalidateQueries(QueryKeys.getCategories);
        setIsCategoryCreated(true);
      }
    });

    const {
      register,
      handleSubmit,
      formState: { isValid, isDirty, errors }
    } = useForm({
      defaultValues: {
        categoryName: categoryData?.name || null
      },
      mode: "onChange"
    });
    
    const onFormSubmit = (data) => {
      mutation.mutate({
        name: data.categoryName
      });
    };

    return (
      <Modal
        keyboard={false}
        backdrop="static"
        centered
        fade={false}
        isOpen={modal.visible}
        autoFocus={false}
        toggle={modal.remove}
      >
        <div className="modal-header">
          <h2 className="color-dark modal-title">
            {categoryData
              ? Labels.Tagging.editCategoryName
              : Labels.Tagging.newCategory}
          </h2>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={modal.remove}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
          <ModalBody className="mt-0 pt-0">
            {mutation.isError ? (
              <DisplayAlerts
                className="mt-0"
                alerts={mutation.error}
                toggle={() => mutation.reset()}
              />
            ) : null}
            <div>
              <label htmlFor="categoryName">{Labels.Tagging.name}</label>
              <div>
                <input
                  className={classNames("w-100", {
                    "is-invalid": !!errors.categoryName
                  })}
                  type={"text"}
                  placeholder={Labels.Tagging.setCategoryName}
                  {...register("categoryName", {
                    required: {
                      value: true,
                      message: Labels.CommonModals.pleaseFillInThisField
                    },
                    maxLength: {
                      value: 55,
                      message: Labels.Tagging.maxLengthLimit55
                    }
                  })}
                />
              </div>
              <ErrorMessage
                errors={errors}
                name="categoryName"
                as={"span"}
                className="error-message"
              />
            </div>
          </ModalBody>
          <ModalFooter className="py-2">
            <Button
              color="primary"
              type="submit"
              disabled={!isDirty || !isValid}
            >
              {Labels.CommonModals.save}
            </Button>
            <Button color="secondary" onClick={modal.remove}>
              {Labels.CommonModals.cancel}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
);

export const openAddNewCategoryModal = (props) => {
  NiceModal.show(modalNames.openAddNewCategoryModal, props).catch((error) => {
    console.log(error);
  });
};
