//@ts-check

import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getContanctBankAccountDetails } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";

export default function useGetContanctBankAccountDetails({
  contactId,
  ...options
}) {
  const query = useQuery({
    queryKey: [QueryKeys.getContanctBankAccountDetails, contactId],
    queryFn: () =>
      httpRequest({
        method: "get",
        url: getContanctBankAccountDetails(contactId)
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    select: (data) =>
      data.map((i) => ({ ...i, label: i.bankAccountName, value: i.id })),
    enabled: !!contactId,
    ...options
  });
  return query;
}
