import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Labels } from "../../constants/Constants";

export default NiceModal.create(() => {
  // Use a hook to manage the modal state
  const modal = useModal();

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      fade={false}
      isOpen={modal.visible}
      className="modal-lg"
      size="lg"
    >
      <ModalHeader toggle={modal.remove}>
        <h1 className="color-dark">{Labels.CommonModals.errorTitle}</h1>
      </ModalHeader>
      <ModalBody className="p-3 p-md-4">
        {Labels.CommonModals.firewallErrorMessage}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={modal.remove}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
});
