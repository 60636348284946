import React, { useContext } from "react";
import InvoiceFormContext from "./InvoiceFormContext";
import { Labels } from "../../../../../../constants/Constants";
import ConditionalRenderer from "../../../../../../elements/ConditionalRenderer";

export default function InvoiceFormControls({
  calNetInvoiceAmount,
  calNetPayableAmount,
  invoiceDetails
}) {
  const { formatAmount } = useContext(InvoiceFormContext);

  return (
    <div>
      <div className="divider-thick"></div>
      <table className="invoice-amounts-table w-100 mt-3">
        <ConditionalRenderer
          conditions={[
            invoiceDetails.assetCurrency !== invoiceDetails.invoiceCurrency
          ]}
        >
          <thead>
            <tr>
              <th></th>
              <th className="section-column-header pb-2 text-right">
                {Labels.Payments.Invoices.Details.invoiceCurrency}
              </th>
              <th className="section-column-header pb-2 text-right">
                {Labels.Payments.Invoices.Details.baseCurrency}
              </th>
            </tr>
          </thead>
        </ConditionalRenderer>
        <tbody>
          <tr>
            <th>
              <h4 className="m-0 p-0 column-header-1 pb-3">
                {Labels.Payments.Invoices.netInvoiceAmount}
              </h4>
            </th>
            <ConditionalRenderer
              conditions={[
                invoiceDetails.assetCurrency !== invoiceDetails.invoiceCurrency
              ]}
            >
              <td className="text-right">
                <h4 className="m-0 p-0 column-header-1 pb-3">
                  {formatAmount(calNetInvoiceAmount(), true)}
                </h4>
              </td>
            </ConditionalRenderer>
            <td className="text-right">
              <h4 className="m-0 p-0 column-header-1 pb-3">
                {formatAmount(
                  calNetInvoiceAmount() * invoiceDetails.exchangeRate
                )}
              </h4>
            </td>
          </tr>
          <tr>
            <th>
              <h4 className="m-0 p-0 column-header-1 pb-3">
                {Labels.Payments.Invoices.salesTax}
              </h4>
            </th>
            <ConditionalRenderer
              conditions={[
                invoiceDetails.assetCurrency !== invoiceDetails.invoiceCurrency
              ]}
            >
              <td className="text-right">
                <h4 className="m-0 p-0 column-header-1 pb-3">
                  {formatAmount(invoiceDetails.taxAmount, true)}
                </h4>
              </td>
            </ConditionalRenderer>
            <td className="text-right">
              <h4 className="m-0 p-0 column-header-1 pb-3">
                {formatAmount(
                  invoiceDetails.taxAmount * invoiceDetails.exchangeRate
                )}
              </h4>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <div className="fv-border mb-3"></div>
            </td>
          </tr>
          <tr>
            <th>
              <h3 className="m-0 p-0 color-dark pb-1">
                {Labels.Payments.Invoices.netPayableAfterDeductions}
              </h3>
            </th>
            <ConditionalRenderer
              conditions={[
                invoiceDetails.assetCurrency !== invoiceDetails.invoiceCurrency
              ]}
            >
              <td className="text-right">
                <h3 className="m-0 p-0 color-dark pb-1">
                  {formatAmount(calNetPayableAmount(), true)}
                </h3>
              </td>
            </ConditionalRenderer>
            <td className="text-right">
              <h3 className="m-0 p-0 color-dark pb-1">
                {formatAmount(
                  calNetPayableAmount() * invoiceDetails.exchangeRate
                )}
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
