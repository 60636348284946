import React, { useEffect, useState } from "react";
import PDFObject from "pdfobject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { httpRequest as blockingHttpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";
import RenameTitle from "./RenameTitle";
import EmailBody from "../views/Assets/PayRuns/Invoices/invoice-enrichment/EmailBody";
import useGetAzureSasToken from "../hooks/useGetAzureSasToken";
import Tooltip from "./ToolTips/Tooltip";

export default function DocsViewer({
  documents,
  viewerHeight,
  containerId,
  shouldShowLoader = true,
  allowFileRename = false,
  documentFilename,
  setDocumentFilename,
  documentFilenameExtension,
  assetId,
  invoiceId,
  documentId,
  showEmailBody = false,
  emailCloudStorageUrl,
  displayDocumentTitle = true,
  documentCloudStorageUrl,
  fromEmailAddress
}) {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (current >= documents.length) {
      setCurrent(0);
    }
  }, [documents]);

  const httpClient = shouldShowLoader
    ? blockingHttpRequest
    : nonBlockingHttpRequest;

  let renderedDoc = null;
  if (!documents.length) {
    return <div className="loader">Loading...</div>;
  } else if (
    documents.length &&
    documents[current] &&
    [".jpg", ".png", ".pdf"].indexOf(
      documents[current].fileFormat?.toLowerCase()
    ) === -1
  ) {
    renderedDoc = (
      <UnSupportedFormatViewer
        fileFormat={documents[current].fileFormat}
        cloudURL={documents[current].fileURL}
        httpRequest={httpClient}
      />
    );
  } else if (
    documents[current] &&
    documents[current].fileFormat?.toLowerCase() === ".pdf"
  ) {
    renderedDoc = (
      <PDFObjectViewer
        viewerHeight={viewerHeight}
        cloudURL={documents[current].fileURL}
        containerId={containerId}
        httpRequest={httpClient}
      />
    );
  } else if (
    documents[current] &&
    [".jpg", ".png"].indexOf(documents[current].fileFormat?.toLowerCase()) !==
      -1
  ) {
    renderedDoc = (
      <ImageViewer
        cloudURL={documents[current].fileURL}
        httpRequest={httpClient}
      />
    );
  }

  return (
    <div className="invoice-viewer p-3">
      {displayDocumentTitle ? (
        <div className="buttons">
          {allowFileRename ? (
            <RenameTitle
              title={documentFilename}
              setTitle={setDocumentFilename}
              assetId={assetId}
              invoiceId={invoiceId}
              documentId={documentId}
              documentFilenameExtension={documentFilenameExtension}
            />
          ) : (
            <>
              <h3 className="text-truncate color-dark" id="documentTitle">
                {documents[current]?.fileName}
              </h3>
              <Tooltip target="documentTitle">
                {documents[current]?.fileName}
              </Tooltip>
            </>
          )}
          <div
            className={`mb-2 ${documents.length === 1 ? "d-none" : "d-flex"}`}
          >
            <button
              disabled={current === 0}
              onClick={() => setCurrent(current - 1)}
            >
              <FontAwesomeIcon
                className={current === 0 ? "fa-disabled" : ""}
                icon={faChevronLeft}
              />
            </button>
            <button
              disabled={current === documents.length - 1}
              onClick={() => setCurrent(current + 1)}
            >
              <FontAwesomeIcon
                className={
                  current === documents.length - 1 ? "fa-disabled" : ""
                }
                icon={faChevronRight}
              />
            </button>
          </div>
        </div>
      ) : null}
      {showEmailBody ? (
        <EmailBody
          emailCloudStorageUrl={emailCloudStorageUrl}
          documentCloudStorageUrl={documentCloudStorageUrl} // For react-query unique key
          fromEmailAddress={fromEmailAddress}
        />
      ) : null}
      <div id="pdf-viewer">{renderedDoc}</div>
    </div>
  );
}

function PDFObjectViewer({ cloudURL, viewerHeight, containerId, httpRequest }) {
  const { data: pdfAccessLink } = useGetAzureSasToken(cloudURL);

  useEffect(() => {
    PDFObject.embed(pdfAccessLink, `#${containerId}`, {
      height: viewerHeight
    });
  }, [containerId, pdfAccessLink, viewerHeight]);
  return <div id={containerId}></div>;
}

function ImageViewer({ cloudURL, httpRequest }) {
  const { data: imgSrc } = useGetAzureSasToken(cloudURL);

  return <img className="img-fluid" src={imgSrc} alt="Invoice document" />;
}

function UnSupportedFormatViewer({ cloudURL, fileFormat, httpRequest }) {
  const { data: downloadLink } = useGetAzureSasToken(cloudURL);

  return (
    <p>
      {`Preview for the document format ${
        fileFormat || "Unknown"
      } is not available. `}
      Please <a href={downloadLink}>click here</a> to download.
    </p>
  );
}
