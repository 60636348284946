export const TaskTypes = {
  ASSET_ONBOARDING: "Asset Onboarding",
  AUDIT: "Audit",
  BANK_RECONCILIATIONS: "Bank Reconciliations",
  BUDGETS_AND_NAV_REPORTS: "Budgets and NAV Reports",
  COMPLIANCE: "Compliance",
  ENVIRONMENTAL: "Environmental",
  INVOICE_PROCESSING_OPEX: "Invoice Processing - Opex",
  INVOICE_PROCESSING_REVENUE: "Invoice Processing - Revenue",
  MANAGEMENT_ACCOUNTS: "Management Accounts",
  PAYMENT: "Payment",
  TAX: "Tax",
  TECHNICAL: "Technical",
  ACCOUNTING_RISK: "Accounting Risk",
  OTHER: "Other"
};

export const TasksTypeList = Object.entries(TaskTypes).map(([k, v]) => ({
  label: v,
  value: k
}));
