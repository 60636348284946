/*
validates email id
@example
emailRegexp.test("abc@mail.in")
 //returns true

@example
emailRegexp.test("xyz@mail")
 //returns false
*/
export const emailRegexp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/*
validates numbers
@example
numberRegexp.test(6998293)
 //returns true

@example
numberRegexp.test(123de)
 //returns false

 @example
numberRegexp.test(23.56)
 //returns false
*/
export const numberRegexp = /^[0-9]+$/;

/*
validates positive integers upto two decimals
@example
positiveIntegersUptoTwoDecimalsRegexp.test(5673)
 //returns true

@example
positiveIntegersUptoTwoDecimalsRegexp.test(56.32)
 //returns true

 @example
positiveIntegersUptoTwoDecimalsRegexp.test(873.462)
 //returns false
*/
export const positiveIntegersUptoTwoDecimalsRegexp = /^[0-9]*(\.[0-9]{0,2})?$/;

/*
validates positive integers upto two decimals
@example
negativeIntegersUptoTwoDecimalsRegexp.test(-5673)
 //returns true

@example
negativeIntegersUptoTwoDecimalsRegexp.test(-56.32)
 //returns true

 @example
negativeIntegersUptoTwoDecimalsRegexp.test(-873.462)
 //returns false
*/
export const negativeIntegersUptoTwoDecimalsRegexp = /^-[0-9]*(\.[0-9]{0,2})?$/;

/*
validates positive integers upto five decimals
@example
positiveIntegersUptoTwoDecimalsRegexp.test(5673)
 //returns true

@example
positiveIntegersUptoTwoDecimalsRegexp.test(56.32)
 //returns true

 @example
positiveIntegersUptoTwoDecimalsRegexp.test(873.462)
 //returns true

 @example
positiveIntegersUptoTwoDecimalsRegexp.test(56.3245)
 //returns true

 @example
positiveIntegersUptoTwoDecimalsRegexp.test(56.32456)
 //returns true

 @example
positiveIntegersUptoTwoDecimalsRegexp.test(56.324568)
 //returns false
*/
export const positiveIntegersUptoFiveDecimalsRegexp = /^[0-9]*(\.[0-9]{0,5})?$/;

/* Validate Positive integers with any number of decimal points 
@example
positiveIntegersUptoTwoDecimalsRegexp.test(5673)
 //returns true

@example
positiveIntegersUptoTwoDecimalsRegexp.test(56.32)
 //returns true

 @example
positiveIntegersUptoTwoDecimalsRegexp.test(873.462)
 //returns true

 @example
positiveIntegersUptoTwoDecimalsRegexp.test(56.3245)
 //returns true

 @example
positiveIntegersUptoTwoDecimalsRegexp.test(56.32456)
 //returns true

 @example
positiveIntegersUptoTwoDecimalsRegexp.test(56.32456812332)
 //returns true

  @example
positiveIntegersUptoTwoDecimalsRegexp.test(-56.32)
 //returns false
*/
export const positiveIntegersWithDecimalsRegexp = /^[0-9]\d*(\.\d+)?$/;

/*
validates whole number upto two decimals
@example
integersUptoTwoDecimalsRegexp.test(-5673)
 //returns true

 example
integersUptoTwoDecimalsRegexp.test(78.28)
 //returns true

@example
integersUptoTwoDecimalsRegexp.test(-56.32)
 //returns true

 @example
integersUptoTwoDecimalsRegexp.test(873.462)
 //returns false
*/
export const integersUptoTwoDecimalsRegexp = /^[+-]?[0-9]*(\.[0-9]{0,2})?$/;

/*
validates alpha-numeric characters with spaces
@example
alphaNumericRegexp.test(123de5g7)
 //returns true

@example
alphaNumericRegexp.test(abc4.56)
 //returns false
*/
export const alphaNumericRegexp = /^[a-zA-Z0-9 ]+$/;

/*
validates alpha-numeric characters without spaces
@example
alphaNumericRegexp.test(123de5g7)
 //returns true

@example
alphaNumericRegexp.test(abc4 56)
 //returns false
*/
export const alphaNumericWithoutSpacesRegexp = /^[a-zA-Z0-9]*$/;

/*
validates alphabets with spaces
@example
alphabetsRegexp.test(javaScript)
 //returns true

@example
alphabetsRegexp.test(react4.0)
 //returns false
*/
export const alphabetsRegexp = /^[a-zA-Z ]+$/;

/*
Following special characters to be allowed in contact name
&,-,_,. ,' , space, / , [ , ], ( and )

@example
contactNameRegexp.test(name-_)
 //returns true

@example
contactNameRegexp.test(react@4.6)
 //returns false
*/
export const contactNameRegexp = /^[a-zA-Z0-9- &_.'\s\-/\[\]\(\)]+$/;

/*
Following reg expression validates number less than or equal to 100

@example
contactNameRegexp.test(100.00)
 //returns true

contactNameRegexp.test(100.000)
 //returns true

contactNameRegexp.test(78.829)
 //returns true

@example
contactNameRegexp.test(100.1)
 //returns false
*/
export const positivePercentageRegexp = /^(100(\.0*)?|\d{0,2}(\.\d+)?)$/;

/*
 Following regex validates alpha numerics and the first character is not zero

 @example
deviceIdsRegex.test(100.00)
 //returns false

deviceIdsRegex.test(012)
 //returns false

deviceIdsRegex.test(1023)
 //returns true

@example
deviceIdsRegex.test(AN10)
 //returns true

*/

export const deviceIdsRegex = /^[1-9a-zA-Z][a-zA-Z0-9]*$/;
