import React from "react";
import { useMutation } from "react-query";
import {
  Modal,
  ModalBody,
  Alert,
  ModalFooter,
  Button,
  ModalHeader
} from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import { httpRequest } from "../../../../utils/http/httpRequest";

export default function AddCommentModal({
  isOpen,
  toggle,
  refreshHistoryAndComments,
  commentActionType,
  comment,
  setComment,
  endpoints,
  setCommentActionType,
  selectedCommentData
}) {
  const commentInput = React.createRef();
  const mutation = useMutation(
    (data) =>
      httpRequest({
        method: commentActionType === "Add" ? "post" : "put",
        url:
          commentActionType === "Add"
            ? endpoints.addComment
            : endpoints.updateComment,
        data:
          commentActionType === "Add"
            ? { comment }
            : {
                comment,
                creationDate: data.creationDate,
                timestamp: data.timestamp,
                userName: data.changedBy
              }
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    {
      onSuccess: () => {
        toggle();
        setCommentActionType("");
        refreshHistoryAndComments();
      }
    }
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onOpened={() => {
        commentInput.current.focus();
      }}
      fade={false}
      className="add-comment body-primary"
      centered
    >
      <ModalHeader toggle={toggle}>
        <h2 className="color-dark modal-title">
          {commentActionType === "Add"
            ? Labels.Comments.addCommentTitle
            : Labels.Comments.editCommentTitle}
        </h2>
      </ModalHeader>
      <ModalBody>
        {mutation.isError ? (
          <Alert color="danger">{mutation.error[0].errorMessage}</Alert>
        ) : null}
        <textarea
          value={comment}
          ref={commentInput}
          onChange={(e) => setComment(e.target.value)}
          rows={10}
          className="comment-input p-2"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() =>
            mutation.mutate(
              commentActionType === "Add" ? comment : selectedCommentData
            )
          }
          disabled={mutation.isLoading || !comment}
        >
          {commentActionType === "Add"
            ? Labels.CommonModals.add
            : Labels.CommonModals.update}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {Labels.CommonModals.cancel}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
