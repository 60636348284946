import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { Labels } from "../../constants/Constants";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import Tooltip from "../ToolTips/Tooltip";

export default function DragAndDropFileUpload(props) {
  // files state definitions
  const [selectedFiles, setFiles] = useState([]);
  const [filesListClassNames, setFilesListClassNames] = useState([
    "container",
    "files-list",
    "scrollbar-primary"
  ]);

  //update file list container classNames if propogated from parent
  useEffect(() => {
    setFilesListClassNames([
      ...filesListClassNames,
      ...(props.filesListContainerClass || [])
    ]);
  }, [props.filesListContainerClass]);
  //update files state and propage files to parent....
  const updateSelectedFiles = (files = []) => {
    setFiles(files);
    props.onFileSelection(files.map((fileObj) => fileObj.file));
  };

  // removing selected files
  const removeFile = (fileIndex) => {
    selectedFiles.splice(
      selectedFiles.findIndex((file) => file.index == fileIndex),
      1
    );
    updateSelectedFiles([...selectedFiles]);
  };

  // clear all files

  const clearFiles = () => {
    updateSelectedFiles([]);
  };

  // callback function on file selections
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      const files = acceptedFiles.map((file) => ({
        index: nanoid(),
        file
      }));
      updateSelectedFiles([...files]);
      if (acceptedFiles.length) {
        props.onError([]);
      }
      if (fileRejections.length > 0) {
        props.onError(fileRejections);
      }
    },
    [selectedFiles]
  );

  /**
   * dropzone Config
   */
  const defaultConfig = {
    onDrop,
    noClick: true,
    disabled: false
  };

  const uploadConfig = { ...(props.uploadConfig || {}) };
  // set multiple false if maxfiles to be selected to 1 .... for convennience
  uploadConfig.multiple =
    props.uploadConfig.multiple !== undefined ||
    !(props.uploadConfig.maxFiles == 1);
  // check if there is any upload file types and prepare array of them if any
  const fileTypesOnly =
    !props.hideAllowedFormatTypesHint &&
    (uploadConfig?.accept
      ?.split(",")
      .map((fileType) => (fileType + "").replace(".", "")) ||
      []);
  // preparing final dropzone config merging default config
  const dropZoneOptions = { ...defaultConfig, ...uploadConfig };
  /**  dropzone Config End*/

  /**
   * Button configuratons
   */

  const defaultBtnConfig = {
    text: "Browse Files",
    className: "mb-1",
    onClick: () => {}
  };

  const btnConfig =
    props.button &&
    (props.button === true
      ? defaultBtnConfig
      : { ...defaultBtnConfig, ...props.button });

  const btnClickHandler = (args) => {
    btnConfig.onClick(args);
    open();
  };
  /**  Button configuratons End*/

  /**
   * getting dropzone features
   */
  const { getRootProps, getInputProps, open } = useDropzone(dropZoneOptions);
  // to clear files from parent..
  useEffect(() => {
    if (!props?.selectedFiles || !props?.selectedFiles?.length === 0) {
      clearFiles();
    }
  }, [props?.selectedFiles]);

  return (
    <>
      <div
        {...getRootProps({
          className: "file-upload-container dropzone dropzone-align-center"
        })}
      >
        <>
          <button
            className={`btn btn-link pr-2 clear-all-icon max-files-${dropZoneOptions.maxFiles}-${selectedFiles.length}`}
            onClick={clearFiles}
            type="button"
          >
            <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
          </button>
        </>
        <input {...getInputProps()} data-testid={"drag-and-drop-zone-input"} />
        {!(selectedFiles.length > 0 && dropZoneOptions.maxFiles <= 1) && (
          <>
            <p className="my-1 d-flex flex-column align-items-center justify-content-center">
              <small className="text-muted">
                {props.label || Labels.CommonModals.selectFilesToUpload}
              </small>
              <small className="text-muted">{Labels.CommonModals.or}</small>
            </p>
            {btnConfig && (
              <Button
                className={btnConfig.className}
                onClick={btnClickHandler}
                disabled={dropZoneOptions.disabled}
              >
                {btnConfig.text}
              </Button>
            )}
          </>
        )}
        {selectedFiles.length > 0 && (
          <div className={filesListClassNames.join(" ")}>
            {selectedFiles.map((selectedFile) => {
              return (
                <section key={selectedFile.index}>
                  <div className="row ">
                    <div
                      className={`col-sm text-center-${dropZoneOptions.maxFiles}`}
                    >
                      <label className="small ">{selectedFile.file.path}</label>
                    </div>

                    <div
                      className={`col-sm text-right max-files-${dropZoneOptions.maxFiles}`}
                    >
                      <button
                        className="btn btn-link p-0"
                        onClick={() => removeFile(selectedFile.index)}
                        type="button"
                      >
                        <FontAwesomeIcon
                          id={"deleteIcon" + selectedFile.index}
                          icon={faTrashAlt}
                          className="text-danger"
                        />
                        <Tooltip target={"deleteIcon" + selectedFile.index}>
                          {Labels.CommonModals.delete}
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                </section>
              );
            })}
          </div>
        )}
      </div>
      {fileTypesOnly.length > 0 && (
        <small className="text-muted float-left">
          {Labels.CommonModals.acceptedFilesLabel + " " + fileTypesOnly}
        </small>
      )}
    </>
  );
}

DragAndDropFileUpload.propTypes = {
  onFileSelection: PropTypes.func,
  onError: PropTypes.func,
  label: PropTypes.string,
  hideAllowedFormatTypesHint: PropTypes.bool,
  filesListContainerClass: PropTypes.arrayOf(PropTypes.string),
  button: PropTypes.oneOfType([
    PropTypes.oneOf([
      {
        text: PropTypes.string,
        className: PropTypes.string,
        onClick: PropTypes.func
      }
    ]),
    PropTypes.bool
  ]),
  uploadConfig: PropTypes.any
};
