import { Labels } from "../../../../constants/Constants";

export const purchaseOrderEmailTemplate = (
  supplierName,
  orderNumber
) => `${Labels.Assets.PurchaseOrders.email.step1} ${supplierName},

${Labels.Assets.PurchaseOrders.email.step2} ${orderNumber}.

${Labels.Assets.PurchaseOrders.email.step3}
`;
