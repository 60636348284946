//@ts-check
import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getBudgetLineItemsList } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

export default function useGetBudgetLineItemsList(
  {
    assetId,
    financialYear,
    month,
    prependWithGlCode = false,
    isBlocking = false,
    glAccountId,
    includeAllSystemAccounts,
    systemAccountsToInclude = {}
  },
  options = {}
) {
  const request = isBlocking ? httpRequest : nonBlockingHttpRequest;
  const query = useQuery({
    queryKey: [QueryKeys.getBudgetLineItemsList, assetId, financialYear, month],
    queryFn: () =>
      request({
        method: "get",
        url: getBudgetLineItemsList({
          assetId,
          financialYear,
          month,
          glAccountId,
          includeAllSystemAccounts,
          systemAccountsToInclude
        })
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!(assetId && financialYear && !isNaN(month)),
    select: (data) =>
      data.map((i) => ({
        ...i,
        label: prependWithGlCode
          ? `${i.glCode} - ${i.budgetLineItem}`
          : i.budgetLineItem,
        value: i.id,
        glCodeDescription: i.glName || i.glCodeDescription,
        glName: i.glName
      })),
    ...options
  });

  return query;
}
