const categoryData = {
  id: 3,
  name: "Test",
  attributes: [
    {
      id: 5,
      name: "test 1",
      values: [{ id: 7, value: "a", attributeName: null }]
    },
    {
      id: 4,
      name: "test",
      values: [{ id: 6, value: "abc", attributeName: null }]
    },
    {
      id: 10,
      name: "Technology",
      values: [
        { id: 14, value: "JS ", attributeName: null },
        { id: 15, value: "TS", attributeName: null }
      ]
    }
  ],
  assets: [
    { assetId: 1782, assetName: "Project Test Asset 0001" },
    { assetId: 1765, assetName: "Test Asset EVA" },
    { assetId: 1585, assetName: "pacific" },
    { assetId: 65, assetName: "TESRE" },
    { assetId: 1521, assetName: "TESRE2" },
    { assetId: 30, assetName: "AD TEST ASSET - MIMICKING  BB" },
    { assetId: 1760, assetName: "TESTCLOSINGPERIOD" },
    { assetId: 1780, assetName: "Test Asset 0009" },
    { assetId: 1550, assetName: "test integrated asset" }
  ],
  assetTags: []
};

export default categoryData;
