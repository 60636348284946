import { useParams, useRouteMatch } from "react-router-dom";
import useQueryParams from "./useQueryParams";

/**
 * Takes current URL and parses all route params & query params
 * and tells navigation context ('Under An Asset' | 'Under an Org' | 'Under All Assets')
 */
export const navigationContexts = {
  underAnAsset: "Under An Asset",
  underAnOrg: "Under an Org",
  underAllAssets: "Under All Assets"
};

export default function useNavigationContext() {
  const routeParams = useParams();
  const queryParams = useQueryParams();
  const { path } = useRouteMatch();

  let pageContext = "";
  let prefixPath = "";
  let prefixRoutePath = "";
  if (path.includes("orgs/org/")) {
    pageContext = navigationContexts.underAnOrg;
    prefixPath = `/orgs/org/${routeParams.orgId}`;
    prefixRoutePath = `/orgs/org/:orgId`;
  } else if (path.includes("allassets")) {
    pageContext = navigationContexts.underAllAssets;
    prefixPath = "/allassets";
    prefixRoutePath = "/allassets";
  } else {
    pageContext = navigationContexts.underAnAsset;
    prefixPath = `/org/${routeParams.orgId}/assets/asset/${routeParams.assetId}`;
    prefixRoutePath = `/org/:orgId/assets/asset/:assetId`;
  }

  return {
    routeParams,
    queryParams,
    pageContext,
    prefixPath,
    prefixRoutePath
  };
}
