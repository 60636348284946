import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from "reactstrap";
import moment from "moment";
import { useMutation } from "react-query";
import { httpRequest } from "../../../../utils/http/httpRequest";
import ConfirmDialog from "../../../../elements/modals/ConfirmDialog";
import {
  cancelManualJournal,
  cancelRepeatingJournal,
  returnManualJournalToDraft,
  revertManualJournal
} from "../../../../utils/http/endpoints";
import { JournalActions } from "../../../../constants/JournalActionsMap";
import { Labels } from "../../../../constants/Constants";
import PostingPeriodInfoMessage from "../../../../elements/PostingPeriodInfoMessage";
import PostingPeriodModules from "../../../../constants/PostingPeriodModules";
import useGetPostingPeriod from "../../../../hooks/useGetPostingPeriod";
import { serializeDate } from "../../../../utils/date";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import useGetAssetById from "../../../../hooks/useGetAssetById";

export default NiceModal.create(
  ({
    assetId,
    journalId,
    onComplete,
    isRepeatingJournal,
    action,
    isFormChanged,
    journal
  }) => {
    const modal = useModal();
    const [comment, setComment] = useState("");
    const [errors, setErrors] = useState([]);
    const [isReconfirmDialogOpen, toggleReconfirmDialogOpen] = useState(true);
    const [shouldSaveAndCancel, setShouldSaveAndCancel] = useState(undefined);
    const postingPeriodQuery = useGetPostingPeriod({
      assetId: assetId,
      instrumentDate: serializeDate(moment(journal.transactionDate).toDate()),
      module: PostingPeriodModules.JOURNAL,
      enabled: action?.actionName === JournalActions.revert ? undefined : false
    });

    const assetQuery = useGetAssetById({
      assetId: assetId
    });

    const primaryOrg = assetQuery.data?.assetOrganizations?.find(
      (org) => org?.isPrimaryOrganization
    );

    const getUrl = () => {
      if (isRepeatingJournal) {
        return cancelRepeatingJournal(assetId, journalId);
      } else if (isForCancelJournal) {
        return cancelManualJournal(assetId, journalId);
      } else if (isForReturnJournalToDraft) {
        return returnManualJournalToDraft(assetId, journalId);
      } else if (isForRevertJournal) {
        return revertManualJournal(assetId, journalId);
      }
    };

    const complete = (isSuccess, errors = {}, comment = "") => {
      setComment("");
      modal.remove();
      onComplete(isSuccess, errors, {
        comment,
        isSaveAndCancel: shouldSaveAndCancel
      });
    };

    const mutation = useMutation((comment) =>
      httpRequest({
        url: getUrl(),
        method: "put",
        data: { comment: comment }
      })
        .then((res) => {
          if (res.status === 200) {
            complete(true);
          }
        })
        .catch((err) => {
          if (typeof err == "string") {
            setErrors([{ errorMessage: err }]);
          } else {
            setErrors(err.data.errors);
          }
        })
    );

    const onRepeatingJournalCancel = () => {
      if (shouldSaveAndCancel) {
        complete(true, {});
      } else {
        mutation.mutate();
      }
    };

    const handleSubmit = (evt) => {
      evt.preventDefault();
      if (shouldSaveAndCancel) {
        complete(true, {}, comment);
      } else {
        mutation.mutate(comment);
      }
    };

    const isForCancelJournal = action?.actionName === JournalActions.cancel;

    const isForRevertJournal = action?.actionName === JournalActions.revert;

    const isForReturnJournalToDraft =
      action?.actionName === JournalActions.returnToDraft;

    const hasFormChangesDuringCancellation = isForCancelJournal
      ? isFormChanged && shouldSaveAndCancel === undefined
      : false;

    const getTitle = (action) => {
      let title = "";
      switch (action?.actionName) {
        case JournalActions.cancel:
          title = Labels.Journals.cancelJournal;
          break;
        case JournalActions.returnToDraft:
          title = Labels.Journals.returnToDraftJournal;
          break;
        case JournalActions.revert:
          title = Labels.Journals.reverseJournal;
          break;
        default:
          title = "";
      }
      return title;
    };

    const isJournalApprovedPeriodOpen = postingPeriodQuery?.data?.postingPeriod
      ? postingPeriodQuery?.data?.postingPeriod ===
        journal?.approvedPostingPeriod
      : true;

    const postingPeriodMsg = isJournalApprovedPeriodOpen
      ? Labels.Journals.journalpostingPeriodMsg
      : Labels.Journals.PostingPeriodInfoMessage.line1 +
        journal?.approvedPostingPeriod +
        ". " +
        Labels.Journals.PostingPeriodInfoMessage.line2;

    const reCofirmCancellationJournalDialog = () => (
      <Modal
        isOpen={isReconfirmDialogOpen}
        toggle={toggleReconfirmDialogOpen}
        backdrop="static"
        keyboard={false}
        fade={false}
        centered
      >
        <ModalHeader>{Labels.CommonModals.confirm}</ModalHeader>
        <React.Fragment>
          <ModalBody className="pt-0">
            <div className="m-0 border-0 shadow-none p-0">
              {Labels.Journals.reConfirmOnCancellingJournalMessage}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={(evt) => {
                setShouldSaveAndCancel(true);
              }}
            >
              {Labels.Journals.saveAndCancel}
            </Button>
            <Button
              color="primary"
              onClick={(evt) => {
                setShouldSaveAndCancel(false);
              }}
            >
              {Labels.Journals.cancelJournal}
            </Button>
            <Button onClick={modal.remove}>
              {Labels.CommonModals.continueEditing}
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    );

    const repeatingJournalCancelDialog = () => (
      <ConfirmDialog
        isOpen={modal.visible}
        toggle={modal.remove}
        confirmMessage={Labels.Journals.Repeating.cancelJournalConfirmMessage}
        confirmTitle={Labels.Journals.Repeating.cancelJournalConfirmTitle}
        confirmAction={onRepeatingJournalCancel}
      />
    );

    const cacnellationDialogWithCommentSection = () => (
      <Modal
        isOpen={modal.visible}
        toggle={modal.remove}
        backdrop="static"
        keyboard={false}
        fade={false}
        centered
      >
        <ModalHeader toggle={modal.remove}>
          <div>
            <h2 className="color-dark modal-title">{getTitle(action)}</h2>
          </div>
        </ModalHeader>
        <ModalBody className="pt-0">
          <Alert
            color="danger"
            className=""
            isOpen={errors?.length > 0}
            toggle={() => setErrors([])}
          >
            <ul className="error-list">
              {errors?.map((error, index) => {
                return <li key={index}>{error.errorMessage}</li>;
              })}
            </ul>
          </Alert>
          <div className="comments-box m-0 border-0 shadow-none p-0">
            <label htmlFor="comments">{Labels.Assets.comments}</label>
            <textarea
              id="comments"
              className="form-control"
              rows={10}
              maxLength={512}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            ></textarea>
          </div>
          {isForRevertJournal ? (
            <PostingPeriodInfoMessage
              orgId={primaryOrg?.organizationId}
              assetId={assetId}
              message={postingPeriodMsg}
              postingPeriod={
                postingPeriodQuery?.data?.postingPeriod ||
                journal?.approvedPostingPeriod
              }
              showClosingPeriodMsg={!isJournalApprovedPeriodOpen}
            />
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={!comment || mutation.isLoading}
          >
            {mutation.isLoading
              ? Labels.Journals.submitting
              : Labels.Journals.submit}
          </Button>
          <Button color="secondary" onClick={modal.remove}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );

    return (
      <React.Fragment>
        {hasFormChangesDuringCancellation
          ? reCofirmCancellationJournalDialog()
          : isRepeatingJournal
          ? repeatingJournalCancelDialog()
          : cacnellationDialogWithCommentSection()}
      </React.Fragment>
    );
  }
);
