import React from "react";
import HistoryTile from "./HistoryTile";
import { Labels } from "../../../constants/Constants";

export default function HistoryTable(props) {
  return (
    <div className="history-items-container body-primary">
      {props.historyList.map((history, index) => {
        return <HistoryTile key={index} history={history} />;
      })}
      {props.historyList.length === 0 && (
        <span>{Labels.CommonModals.nothingToDisplay}</span>
      )}
    </div>
  );
}
