import React from "react";
import Select from "react-select";

export function SelectBox(props) {
  const customStyles = {
    dropdownIndicator: (provided, state) => {
      let styles = {
        "& svg": {
          color: state.isDisabled ? "#b3b3b3" : "rgb(0,148,225)"
        },
        height: 32
      };
      return Object.assign(provided, styles);
    },
    clearIndicator: (provided, state) => {
      let styles = {
        "& svg": {
          maxHeight: "14px"
        },
        height: 32
      };
      return Object.assign(provided, styles);
    },
    valueContainer: (provided, state) => {
      let styles = {
        flexWrap: "nowrap",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
      };
      return Object.assign(provided, styles);
    },
    input: (provided, state) => {
      let styles = {
        left: "initial"
      };
      return Object.assign(provided, styles);
    },
    multiValueLabel: (provided, state) => {
      return {
        ...provided,
        background: "purple",
        color: "red"
      };
    },

    control: (provided, state) => {
      return {
        ...provided,
        background: state.isDisabled ? "#ddd !important" : "white",
        cursor: state.isDisabled ? "not-allowed" : "default",
        color: state.isDisabled ? "#b3b3b3" : "inherit",
        height: 32
      };
    },
    ...(props.styles || {})
  };

  let components = {
    IndicatorSeparator: () => null,
    ...props.components
  };

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  };

  const FormatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
    </div>
  );

  const filterOption = (option, inputValue) =>
    (option.label.toString().match(new RegExp(inputValue, "i")) || []).length >
    0;

  return (
    <Select
      {...props}
      className={`react-select ${props?.className}`}
      styles={customStyles}
      components={components}
      filterOption={filterOption}
      classNamePrefix="react-select"
      formatGroupLabel={FormatGroupLabel}
      {...props.extraOptions}
    />
  );
}
