import React, { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Labels } from "../../constants/Constants";
import ConditionalRenderer from "../ConditionalRenderer";

export default function ConfirmDialog({
  confirmTitle,
  confirmMessage,
  confirmAction,
  confirmButtonName = Labels.CommonModals.confirm,
  cancelButtonName = Labels.CommonModals.cancel,
  cancelAction = () => {},
  toggle,
  isOpen,
  confirmButtonVariant = "primary",
  isInformational = false
}) {
  const handleConfirmClick = () => {
    confirmAction();
    toggle();
  };

  const handleCancelClick = () => {
    cancelAction();
    toggle();
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove("modal-open");
    };
  });

  return (
    <Modal
      keyboard={false}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      centered
      fade={false}
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">{confirmTitle}</h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggle}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="mt-0 pt-0">{confirmMessage}</ModalBody>
      <ModalFooter className="py-2">
        <ConditionalRenderer conditions={[!isInformational]}>
          <Button color={confirmButtonVariant} onClick={handleConfirmClick}>
            {confirmButtonName}
          </Button>{" "}
        </ConditionalRenderer>
        <Button onClick={handleCancelClick}>{cancelButtonName}</Button>
      </ModalFooter>
    </Modal>
  );
}
