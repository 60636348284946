import React from "react";
import "./Loader.css";

const loader = (props) => {
  return (
    <React.Fragment>
      {props.isLoading && (
        <div className="spinner show">
          <div className="loader"></div>
        </div>
      )}
    </React.Fragment>
  );
};

export default loader;
