import { useQuery } from "react-query";
import QueryKeys from "../../../../../constants/QueryKeys";
import { getPrepaymentData } from "../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import { resolveAPIPromise } from "../../../../../utils/promises";

function useGetPrepaymentsData({ assetId, invoiceId, data, ...options }) {
  return useQuery({
    queryKey: [QueryKeys.getPrepaymentData, { assetId, invoiceId, data }],
    queryFn: () =>
      resolveAPIPromise(
        httpRequest({
          method: "post",
          url: getPrepaymentData({ assetId, invoiceId }),
          data
        })
      ),
    enabled: !!(assetId && invoiceId),
    ...options
  });
}

export default useGetPrepaymentsData;
