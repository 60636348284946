import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal, ModalBody } from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import { HotColumn, HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import moment from "moment";
import PrepaymentDrillDownAmountColumn from "./PrepaymentDrillDownAmountColumn";

export default NiceModal.create((data) => {
  // Use a hook to manage the modal state
  const modal = useModal();

  const drillDownSchema = {
    description: null,
    date: null,
    amount: null,
    journalId: null
  };

  let readOnlySetting = {
    readOnly: true
  };

  Handsontable.renderers.registerRenderer(
    "dateValueRenderer",
    dateValueRenderer
  );

  function dateValueRenderer(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.DateRenderer.apply(this, arguments);
    if (value !== null) {
      td.innerHTML = moment(value).format("DD MMM YYYY");
    } else {
      td.innerHTML = "";
    }
  }

  function prepaymentsDrillDownRenderer(row, col) {
    const cellProperties = {};
    if (col === 1) {
      cellProperties.renderer = "dateValueRenderer";
    }
    return cellProperties;
  }

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      centered
      fade={false}
      size="lg"
      isOpen={modal.visible}
      toggle={modal.remove}
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">{`${data.title} as on ${data.duration}`}</h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={modal.remove}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="mt-0 pt-0">
        <div>
          <HotTable
            className="balancesheet-prepayment-drilldown-table"
            data={data.balanceSheetDrillDownList}
            dataSchema={drillDownSchema}
            fillHandle={false}
            width={"635"}
            stretchH={"last"}
            height={"600"}
            disableVisualSelection={false}
            manualColumnResize={false}
            licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
            cells={prepaymentsDrillDownRenderer}
          >
            <HotColumn
              title={Labels.Assets.BalanceSheet.description}
              data="description"
              placeholder={Labels.Assets.BalanceSheet.openingBalance}
              settings={readOnlySetting}
              width={235}
            />
            <HotColumn
              title={Labels.Assets.BalanceSheet.transactionDate}
              data="date"
              type="date"
              settings={readOnlySetting}
              width={200}
            />
            <HotColumn
              title={Labels.Assets.BalanceSheet.amount}
              data="amount"
              type="numeric"
              settings={readOnlySetting}
              width={200}
            >
              <PrepaymentDrillDownAmountColumn
                orgId={data.orgId}
                assetId={data.assetId}
                hot-renderer
              />
            </HotColumn>
          </HotTable>
        </div>
      </ModalBody>
    </Modal>
  );
});
