import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getContactsList } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

export default function useGetContactList({
  assetId,
  orgId,
  types = [],
  nonBlocking = true,
  ...options
}) {
  return useQuery({
    queryKey: [QueryKeys.getContacts, +assetId, +orgId, types],
    queryFn: async () => {
      try {
        const request = nonBlocking ? nonBlockingHttpRequest : httpRequest;
        const res = await request({
          method: "get",
          url: getContactsList({ assetId, orgId, types })
        });

        return Promise.resolve(res.data);
      } catch (error) {
        return Promise.reject(error.data.errors);
      }
    },
    select: (data) =>
      data.map((contact) => ({
        ...contact,
        value: contact.id,
        label: contact.name
      })),
    ...options
  });
}
