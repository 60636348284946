import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Labels } from "../../../../../constants/Constants";
import DisplayAlerts from "../../../../../elements/DisplayAlerts";
import { SelectBox } from "../../../../../elements/select/Select";
import useGetTaxList from "../../../../../hooks/useGetTaxList";
import { convertToFloat, formatAmount } from "../../../../../utils/number";
import InvoiceFormContext from "./operational-operational/InvoiceFormContext";
import ErrorMessage from "../../SalesInvoices/ErrorMessage";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import { Alert } from "reactstrap";

export default function EditPILineItem({
  lineItemData,
  invoiceDetails,
  isFormReadOnly,
  invoiceLineItem,
  setInvoiceLineItem
}) {
  const {
    editInvoiceMutation,
    lineItemDataEditStatusHandlers,
    isLineItemDataEdited
  } = useContext(InvoiceFormContext);
  const params = useParams();
  const taxListQuery = useGetTaxList({
    ...params,
    type: "PURCHASE_TAX",
    status: "ACTIVE"
  });

  const taxData = taxListQuery.data?.find(
    (tax) => tax.value === invoiceLineItem.taxId
  );

  const calculateGrossAmount = () => {
    const grossAmount =
      convertToFloat(invoiceLineItem.netAmount.value) +
      convertToFloat(invoiceLineItem.taxAmount.value);
    return grossAmount;
  };

  return (
    <div key={lineItemData.id}>
      {editInvoiceMutation.isError ? (
        <DisplayAlerts alerts={editInvoiceMutation.error} type="danger" />
      ) : null}
      {isLineItemDataEdited && invoiceDetails.enriched ? (
        <Alert color="danger">
          {
            Labels.Payments.Invoices.Details
              .pleaseReviewTheBudgetingAndJournalManagementInformation
          }
        </Alert>
      ) : null}
      <div>
        <label htmlFor={"description" + lineItemData.id}>
          {Labels.Payments.Invoices.Details.Description}
        </label>
        <div>
          <input
            className="w-100 py-2"
            type="text"
            id={"description" + lineItemData.id}
            data-testid="pi-line-item-description"
            required={invoiceLineItem.description.isTouched}
            maxLength={249}
            disabled={isFormReadOnly}
            value={invoiceLineItem.description.value}
            onChange={(e) => {
              setInvoiceLineItem({
                ...invoiceLineItem,
                description: {
                  isTouched: true,
                  value: e.target.value,
                  error: e.target.validationMessage
                }
              });
              lineItemDataEditStatusHandlers.open();
            }}
          />
        </div>
        <ErrorMessage field={invoiceLineItem.description} />
      </div>

      <div className="my-3">
        <label
          htmlFor={"taxRate" + lineItemData.id}
          className="text-nowrap mr-2"
        >
          {Labels.Payments.Invoices.Details.taxRate}
        </label>
        <div className="w-50">
          <SelectBox
            options={taxListQuery.data || []}
            id={"taxRate" + lineItemData.id}
            value={taxData}
            isDisabled={isFormReadOnly}
            onChange={(tax) => {
              setInvoiceLineItem({
                ...invoiceLineItem,
                taxId: tax?.value || null,
                taxAmount: {
                  isTouched: false,
                  value: convertToFloat(
                    (convertToFloat(invoiceLineItem.netAmount.value) *
                      convertToFloat(tax?.taxRate)) /
                      100,
                    2
                  ),
                  error: null
                }
              });
              lineItemDataEditStatusHandlers.open();
            }}
            aria-label="pi-line-item-tax-rate"
          />
        </div>
      </div>

      <div className="line-item-amounts">
        <div>
          <label htmlFor={"netAmount" + lineItemData.id}>
            {Labels.Payments.Invoices.Details.netAmount}
          </label>
          <div>
            <input
              className="w-100"
              type="number"
              min={-99999999.99}
              max={99999999.99}
              step={0.01}
              disabled={isFormReadOnly}
              id={"netAmount" + lineItemData.id}
              onChange={(e) => {
                lineItemDataEditStatusHandlers.open();
                setInvoiceLineItem({
                  ...invoiceLineItem,
                  netAmount: {
                    isTouched: true,
                    value: e.target.value,
                    error: e.target.validationMessage
                  },
                  taxAmount: {
                    isTouched: false,
                    value: convertToFloat(
                      (convertToFloat(e.target.value) * taxData.taxRate) / 100,
                      2
                    ),
                    error: null
                  }
                });
              }}
              value={invoiceLineItem.netAmount.value}
            />
          </div>
          <ErrorMessage field={invoiceLineItem.netAmount} />
        </div>

        <div>
          <label htmlFor={"taxAmount" + lineItemData.id}>
            {Labels.Payments.Invoices.Details.taxAmount}
          </label>
          <div>
            <input
              className="w-100"
              type="number"
              min={-99999999.99}
              max={99999999.99}
              step={0.01}
              disabled={isFormReadOnly}
              id={"taxAmount" + lineItemData.id}
              onChange={(e) => {
                lineItemDataEditStatusHandlers.open();
                setInvoiceLineItem({
                  ...invoiceLineItem,
                  taxAmount: {
                    isTouched: true,
                    value: e.target.value,
                    error: e.target.validationMessage
                  }
                });
              }}
              value={invoiceLineItem.taxAmount.value}
            />
          </div>
          <ErrorMessage field={invoiceLineItem.taxAmount} />
        </div>

        <div>
          <label
            htmlFor={"grossAmount" + lineItemData.id}
            className="text-right"
          >
            {Labels.Payments.Invoices.Details.grossAmount}
          </label>
          <div className="text-right py-auto">
            <h4 className="section-column-header pt-2 pb-1">
              {formatAmount(
                calculateGrossAmount(),
                getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)
              )}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}
