/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { formatAmount } from "../../../../../utils/number";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import { getDeletePurchaseInvoice } from "../../../../../utils/http/endpoints";
import AllocateToInvoiceOrCreditNote from "./AllocateToInvoiceOrCreditNote";
import QueryKeys from "../../../../../constants/QueryKeys";
import { Labels, Privileges } from "../../../../../constants/Constants";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import ConfirmDialog from "../../../../../elements/modals/ConfirmDialog";
import { PurchaseInvoiceTypesEnum } from "../../../../../constants/PurchaseInvoiceTypes";
import useNavigationContext, {
  navigationContexts
} from "../../../../../hooks/useNavigationContext";
import InvoiceStatuses from "../../../../../constants/InvoiceStatuses";
import Auth from "../../../../../auth/Auth";
import { Alert } from "reactstrap";

export default function AllocationRecords({
  invoiceDetails,
  setApiErrors,
  type,
  isAllocationsAllowed,
  ...props
}) {
  const [isAllocationsFormVisible, setIsAllocationsFormVisible] =
    useState(false);
  const params = useParams();
  const navigationContext = useNavigationContext();
  const isInvoice = type === PurchaseInvoiceTypesEnum.PURCHASE_INVOICE;
  const queryClient = useQueryClient();
  const [lineItemToBeDeleted, setLineItemToBeDeleted] = useState(undefined);

  const handleAllocations = (event) => {
    event.preventDefault();
    setIsAllocationsFormVisible(true);
    props.setIsAllocationsFormVisible &&
      props.setIsAllocationsFormVisible(true);
  };

  const resetVisiblity = () => {
    setIsAllocationsFormVisible(false);
    props.setIsAllocationsFormVisible &&
      props.setIsAllocationsFormVisible(false);
  };
  const getFormattedAmount = (amt) =>
    formatAmount(
      amt,
      getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)
    );

  const deleteMutation = useMutation((lineItem) => {
    setApiErrors([]);
    httpRequest({
      method: "delete",
      url: getDeletePurchaseInvoice(
        params?.assetId,
        params?.invoiceId,
        lineItem?.id
      )
    })
      .then((res) => {
        queryClient.invalidateQueries([QueryKeys.INVOICE_DETAILS]);
        props.refreshInvoiceData();
      })
      .catch((error) => {
        if (typeof error == "string") {
          setApiErrors([{ errorMessage: error }]);
        } else {
          setApiErrors(error.data.errors);
        }
        document.body.scrollIntoView({ behavior: "smooth", block: "start" });
      });
  });

  const deleteItem = (lineItem) => {
    if (lineItem?.id) {
      deleteMutation.mutate(lineItem);
    }
  };

  const getInvoiceUrl = (orgId, assetId, invoiceId) => {
    if (navigationContext.pageContext === navigationContexts.underAnOrg) {
      return `/#/orgs/org/${orgId}/payruns/invoices/${assetId}/${invoiceId}`;
    } else if (
      navigationContext.pageContext === navigationContexts.underAnAsset
    ) {
      return `/#/org/${orgId}/assets/asset/${assetId}/payruns/invoices/${invoiceId}`;
    } else {
      return `/#/allassets/payments/invoices/${assetId}/${invoiceId}`;
    }
  };

  return (
    <div className="invoice-payments-container p-0 m-0">
      {!isAllocationsFormVisible ? (
        <>
          {deleteMutation.isSuccess && (
            <Alert toggle={deleteMutation.reset}>
              {Labels.Payments.Allocations.successfulDeleteMessage}
            </Alert>
          )}
          <div className="clearfix border-bottom-fv-border-2px">
            <div className="float-left">
              <h3 className="color-dark">
                {Labels.Payments.Allocations.title}
              </h3>
            </div>
            <div className="float-right">
              <div
                className={`create-payment ${
                  !isAllocationsAllowed ? "cursor-not-allowed" : ""
                }`}
              >
                <a
                  href="#"
                  onClick={(event) => handleAllocations(event)}
                  className={`ml-3 prepend-plus ${
                    !isAllocationsAllowed ? "disabled-link" : "link-primary"
                  }`}
                >
                  {!isInvoice
                    ? Labels.Payments.Allocations.CreditNote.allocateTo
                    : Labels.Payments.Allocations.Invoice.allocateTo}
                </a>
              </div>
            </div>
          </div>
          <div className="payments-table-container small-bottom-bordered-table-container">
            <table className="table table-borderless records-table">
              <thead>
                <tr>
                  <th className="px-0">
                    {
                      Labels.Payments.Allocations[
                        isInvoice ? "Invoice" : "CreditNote"
                      ].entityToAllocateTo
                    }
                  </th>
                  <th className="text-right px-0">
                    {Labels.Payments.Allocations.Headers.allocationAmt}
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceDetails?.invoiceAllocationDataList
                  ?.filter((i) => !i.allocationDeleted)
                  .map((lineItem, index) => (
                    <tr key={lineItem?.associatedInvoiceId}>
                      <td width="46%" className="pt-0 px-0">
                        <a
                          href={getInvoiceUrl(
                            params.orgId,
                            params.assetId,
                            lineItem.associatedInvoiceId
                          )}
                          className="link-primary"
                          target="_blank"
                        >
                          {lineItem.associatedInvoiceNumber}
                        </a>
                      </td>
                      <td width="46%" className="text-right px-0">
                        <label className="font-weight-normal">
                          {getFormattedAmount(lineItem.allocationAmount)}
                        </label>
                      </td>
                      {![
                        InvoiceStatuses.DRAFT,
                        InvoiceStatuses.AWAITING_APPROVAL
                      ].includes(invoiceDetails.invoiceStatus) &&
                        Auth.getUserPrivileges().includes(
                          Privileges.DELETE_ALLOCATION
                        ) && (
                          <td width="8%" className="text-right px-0">
                            <label>
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="fa-trash-alt cursor-pointer"
                                onClick={() => setLineItemToBeDeleted(lineItem)}
                                data-testid="delete-allocation-record"
                              />
                            </label>
                          </td>
                        )}
                    </tr>
                  ))}
                {invoiceDetails?.invoiceAllocationDataList?.filter(
                  (i) => !i.allocationDeleted
                ).length === 0 && (
                  <tr>
                    <td colSpan="5" className="text-center">
                      {Labels.Payments.Allocations.emptyRecords}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <AllocateToInvoiceOrCreditNote
          type={type}
          invoiceDetails={invoiceDetails}
          onGoBack={resetVisiblity}
          refreshInvoiceData={props.refreshInvoiceData}
        />
      )}
      {!!lineItemToBeDeleted && (
        <ConfirmDialog
          confirmTitle={Labels.CommonModals.confirm}
          confirmMessage={
            invoiceDetails.isAutoAllocated
              ? Labels.Payments.Allocations
                  .confirmAutoAllocatedAllocationDeleteMessage
              : Labels.Payments.Allocations.confirmAllocationDeleteMessage
          }
          confirmAction={() => deleteItem(lineItemToBeDeleted)}
          confirmButtonName={Labels.CommonModals.yes}
          cancelButtonName={Labels.CommonModals.no}
          toggle={() => setLineItemToBeDeleted(undefined)}
          isOpen={true}
        />
      )}
    </div>

    // </>
  );
}
