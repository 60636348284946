import { useContext } from "react";
import { useQuery } from "react-query";
import QueryKeys from "../../../../../../constants/QueryKeys";
import { LINE_ITEM_API_ERRORED } from "./actionsTypes";
import InvoiceFormContext from "./InvoiceFormContext";
import { fetchBudgetingDrilldownData } from "./operationalFormApi";

export default function useBudgetingDrilldownData({
  assetId,
  invoiceId,
  accountId,
  budgetLineItemId,
  userClickedRow,
  lineItemId,
  isMTD
}) {
  const { dispatch } = useContext(InvoiceFormContext);
  const { data: drillDownData, error: apiErrors } = useQuery({
    queryKey: [
      QueryKeys.Getinvoiceoperationalbudgetincurredamountdetails,
      assetId,
      {
        invoiceId,
        accountId: userClickedRow === "glLineItem" ? accountId : undefined, // if user clicks on gl line item 3, 4 row
        budgetLineItemId:
          userClickedRow === "budgetLineItem" ? budgetLineItemId : undefined, // if user clicks on budge line item 1, 2 row
        incurredAmountMTD: isMTD
      }
    ],
    queryFn: fetchBudgetingDrilldownData,
    enabled: !!(assetId && invoiceId && accountId), // budgetLineItem optional
    placeholderData: { incurredOpeningBalance: 0, incurredInvoiceList: [] },
    onError: (err) =>
      dispatch({
        type: LINE_ITEM_API_ERRORED,
        payload: {
          apiErrors: err,
          lineItemId: lineItemId
        }
      })
  });

  return { drillDownData, apiErrors };
}
