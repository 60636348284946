import React from "react";
import { useParams } from "react-router-dom";
import {
  getAttachments,
  deleteAttachment,
  uploadAttachment,
  getSalesInvoiceHistoryAndComments,
  addInvoiceComments,
  updateInvoiceComments,
  deleteInvoiceComments,
  getInvoiceAttachments,
  deleteInvoiceAttachment,
  uploadInvoiceAttachment,
  getInvoiceHistoryAndComments,
  addPurchaseInvoiceComment,
  updatePurchaseInvoiceComment,
  deletePurchaseInvoiceComment,
  getJournalAttachments,
  getDeleteJournalAttachmentUrl,
  getUploadJournalAttachmentUrl,
  getJournalHistoryAndComments,
  addJournalComment,
  updateJournalComment,
  deleteJournalComment,
  addTaskComment,
  getTaskHistoryAndComments,
  getBankReconHistoryAndComments,
  addCommentInBankRecon,
  updateCommentInBankRecon,
  deleteCommentInBankRecon,
  getPurchaseOrderHistoryAndComments,
  postPurchaseOrderComments,
  deletePurchaseOrderAttachment,
  uploadPurchaseOrderAttachment,
  getPOAttachments,
  editCommentInPO,
  deleteCommentInPO
} from "../../utils/http/endpoints";
import HistoryCommentsAndAttachments from "./HistoryCommentsAndAttachments";

// Here module can be "sales" or "purchases"
export default function HistoryCommentsAndAttachmentsContainer({
  module,
  disableFileUpload,
  disableAddComments,
  hideAttachments = false
}) {
  const params = useParams();

  let endpoints;
  switch (module) {
    case "sales":
      endpoints = {
        getAttachments: getAttachments(params),
        deleteAttachment: (attachmentId) =>
          deleteAttachment({
            ...params,
            attachmentId
          }),
        uploadAttachment: uploadAttachment(params),
        getInvoiceHistoryAndComments: (historyAndComments) =>
          getSalesInvoiceHistoryAndComments({
            ...params,
            historyAndComments
          }),
        addComment: addInvoiceComments(params),
        updateComment: updateInvoiceComments(params),
        deleteComment: deleteInvoiceComments(params)
      };
      break;
    case "purchases":
      endpoints = {
        getAttachments: getInvoiceAttachments(params),
        deleteAttachment: (attachmentId) =>
          deleteInvoiceAttachment({
            ...params,
            attachmentId
          }),
        uploadAttachment: uploadInvoiceAttachment(params),
        getInvoiceHistoryAndComments: (historyAndComments) =>
          getInvoiceHistoryAndComments({
            ...params,
            historyAndComments
          }),
        addComment: addPurchaseInvoiceComment(params),
        updateComment: updatePurchaseInvoiceComment(params),
        deleteComment: deletePurchaseInvoiceComment(params)
      };
      break;
    case "purchaseOrders":
      endpoints = {
        getAttachments: getPOAttachments(params),
        deleteAttachment: (attachmentId) =>
          deletePurchaseOrderAttachment({
            ...params,
            attachmentId
          }),
        uploadAttachment: uploadPurchaseOrderAttachment(params),
        getInvoiceHistoryAndComments: (historyAndComments) =>
          getPurchaseOrderHistoryAndComments({
            ...params,
            historyAndComments
          }),
        addComment: postPurchaseOrderComments(params),
        updateComment: editCommentInPO(params),
        deleteComment: deleteCommentInPO(params)
      };
      break;
    case "journals":
      endpoints = {
        getAttachments: getJournalAttachments(params),
        deleteAttachment: (attachmentId) =>
          getDeleteJournalAttachmentUrl({
            ...params,
            attachmentId
          }),
        uploadAttachment: getUploadJournalAttachmentUrl(params),
        getInvoiceHistoryAndComments: (historyAndComments) =>
          getJournalHistoryAndComments({
            ...params,
            historyAndComments
          }),
        addComment: addJournalComment(params),
        updateComment: updateJournalComment(params),
        deleteComment: deleteJournalComment(params)
      };
      break;

    case "transactions":
      endpoints = {
        getInvoiceHistoryAndComments: (historyAndComments) =>
          getBankReconHistoryAndComments({
            ...params,
            historyAndComments
          }),
        addComment: addCommentInBankRecon(params),
        updateComment: updateCommentInBankRecon(params),
        deleteComment: deleteCommentInBankRecon(params)
      };
      break;

    case "tasks":
      endpoints = {
        getInvoiceHistoryAndComments: (historyAndComments) =>
          getTaskHistoryAndComments({
            ...params,
            historyAndComments
          }),
        addComment: addTaskComment(params)
      };
      break;
  }

  return (
    <HistoryCommentsAndAttachments
      disableFileUpload={disableFileUpload}
      disableAddComments={disableAddComments}
      endpoints={endpoints}
      module={module}
      hideAttachments={hideAttachments}
    />
  );
}
