/**
 * These keys are uniqueNames copied from api documentation swagger
 */

const QueryKeys = {
  INVOICE_DETAILS: "Invoicedetails",
  ASSET_DETAILS: "Assetdetails",
  Getinvoiceoperationalbudgetdata: "Getinvoiceoperationalbudgetdata",
  Accrualdata: "Accrualdata",
  Enrichinvoicewithoperationalbudget: "Enrichinvoicewithoperationalbudget",
  getCostAccrualDataForPOInvoiceLineItem:
    "getCostAccrualDataForPOInvoiceLineItem",
  Getpoutilisedamount: "Getpoutilisedamount",
  Getinvoiceoperationalbudgetincurredamountdetails:
    "Getinvoiceoperationalbudgetincurredamountdetails",
  Bankaccountdetails: "Bankaccountdetails",
  Getbeneficiaries: "Getbeneficiaries",
  Getsupplierbankaccounts: "Getsupplierbankaccounts",
  GetMyAssets: "GetMyAssets",
  getSuppliersForAsset: "getSuppliersForAsset",
  getSuppliers: "getSuppliers",
  getAssetUsers: "getAssetUsers",
  getPurchaseOrder: "getPurchaseOrder",
  getPOAttachments: "getPOAttachments",
  getDateRangeForFinancialYear: "getDateRangeForFinancialYear",
  getBudgetLineItems: "getBudgetLineItems",
  getTaxCodeListFromAIQ: "getTaxCodeListFromAIQ",
  getGLCodesForAsset: "getGLCodesForAsset",
  getPOAvailableAmount: "getPOAvailableAmount",
  getPOEstimatedInvoices: "getPOEstimatedInvoices",
  getPurchaseOrdersForAssetAndSupplier: "getPurchaseOrdersForAssetAndSupplier",
  getAssetUserConfiguration: "getAssetUserConfiguration",
  getPOGoodsReceivedList: "getPOGoodsReceivedList",
  getInvoiceComments: "getInvoiceComments",
  invoiceDocumentList: "invoiceDocumentList",
  getDocumentDetails: "getDocumentDetails",
  getAwaitingPayments: "getAwaitingPayments",
  getPaymentProcessed: "getPaymentProcessed",
  getBankAccounts: "getBankAccounts",
  getBankStatementTransactionList: "getBankStatementTransactionList",
  getAllCurrencyRates: "getAllCurrencyRates",
  convertCurrency: "convertCurrency",
  getBankReconGLCodesForAsset: "getBankReconGLCodesForAsset",
  downloadSampleNonGlPayment: "downloadSampleNonGlPayment",
  deleteBankStatement: "deleteBankStatement",
  getBankStatement: "getBankStatement",
  journalData: "journalData",
  getJournalAttachments: "getJournalAttachments",
  getAllPortfolio: "getAllPortfolio",
  getPortfolioById: "getPortfolioById",
  appendSASToken: "appendSASToken",
  getEmailMessage: "getEmailMessage",
  getContacts: "getContacts",
  getIntercompanyAccounts: "getIntercompanyAccounts",
  getTaxList: "getTaxList",
  getBalanceAccrued: "getBalanceAccrued",
  getSalesInvoice: "getSalesInvoice",
  updateSalesInvoice: "updateSalesInvoice",
  addInvoiceComments: "addInvoiceComments",
  getAttachments: "getAttachments",
  uploadAttachment: "uploadAttachment",
  deleteAttachment: "deleteAttachment",
  getAllGlAccounts: "getAllGlAccounts",
  getAllSalesInvoices: "getAllSalesInvoices",
  getApprovedSalesInvoiceLineItemData: "getApprovedSalesInvoiceLineItemData",
  getBudgetLineItemsList: "getBudgetLineItemsList",
  getGlCodesList: "getGlCodesList",
  getGlCodesListForCoA: "getGlCodesListForCoA",
  getInvoiceHistoryAndComments: "getInvoiceHistoryAndComments",
  getContactDetails: "getContactDetails",
  getRecentlyViewed: "getRecentlyViewed",
  getContactList: "getContactList",
  getContactActivityDetails: "getContactActivityDetails",
  getAssetOnboardingChecklist: "getAssetOnboardingChecklist",
  getChartOfAccounts: "getChartOfAccounts",
  getChartOfAccountsForAdjustmentJournals:
    "getChartOfAccountsForAdjustmentJournals",
  getChartOfAccountById: "getChartOfAccountById",
  getOrgBudgets: "getOrgBudgets",
  getAssetBudgets: "getAssetBudgets",
  getAssetBudget: "getAssetBudget",
  getAssetBudgetHistory: "getAssetBudgetHistory",
  getSalesReceiptsForSalesInvoice: "getSalesReceiptsForSalesInvoice",
  getSalesReceiptData: "getSalesReceipt",
  getOutstandingSalesReceiptData: "getOutstandingSalesReceiptData",
  createSalesReceipt: "createSalesReceipt",
  deleteSalesReceipt: "deleteSalesReceipt",
  deleteSalesAllocation: "deleteSalesAllocation",
  getAssetClasses: "getAssetClasses",
  getFixedAssetRegisters: "getFixedAssetRegisters",
  getAssetById: "getAssetById",
  getAssetOpeningBalances: "getAssetOpeningBalances",
  getAssetTransactions: "getAssetTransactions",
  getOutstandingPaymentDetails: "getOutstandingPaymentDetails",
  getPaymentsForSalesInvoice: "getPaymentsForSalesInvoice",
  createSalesPayment: "createSalesPayment",
  getSalesPaymentHistory: "getSalesPaymentHistory",
  getSalesPayment: "getPayment",
  updateSalesPayment: "updateSalesPayment",
  addCommentForSalesPayment: "addCommentForSalesPayment",
  rejectSalesPayment: "rejectSalesPayment",
  voidSalesPayment: "voidSalesPayment",
  approvePayment: "approvePayment",
  getAwaitingApprovalPayments: "getAwaitingApprovalPayments",
  getPaymentsPaginated: "getPaymentsPaginated",
  getBankAccountCodesForAsset: "getBankAccountCodesForAsset",
  getBankAccountDetailsForAsset: "getBankAccountDetailsForAsset",
  getContanctBankAccountDetails: "getContanctBankAccountDetails",
  getSalesPaymentsPaginated: "getSalesPaymentsPaginated",
  getPurchasePaymentsPaginated: "getPurchasePaymentsPaginated",
  getAllSalesPaymentsAwaitingPayment: "getAllSalesPaymentsAwaitingPayment",
  getAllPaymentsForPayRunCreation: "getAllPaymentsForPayRunCreation",
  getPurchaseInvoices: "getPurchaseInvoices",
  getAllocationsForSalesInvoice: "getAllocationsForSalesInvoice",
  getSalesInvoiceCreditNoteDetailsForAllocation:
    "getSalesInvoiceCreditNoteDetailsForAllocation",
  historyAndComments: "historyAndComments",
  getPurchaseCreditNoteReceipts: "getPurchaseCreditNoteReceipts",
  purchaseOrdersList: "purchaseOrdersList",
  contactAssets: "contactAssets",
  getAssetClosingPeriods: "getAssetClosingPeriods",
  getGLAccountsForSalesInvoices: "getGLAccountsForSalesInvoices",
  getPostingPeriod: "getPostingPeriod",
  getPaymentsForRemittance: "getPaymentsForRemittance",
  generateRemittance: "generateRemittance",
  getOrgDataAvailableDates: "getOrgDataAvailableDates",
  getPlatformUsers: "getPlatformUsers",
  getRoles: "getRoles",
  getPrivileges: "getPrivileges",
  reversePayRun: "reversePayRun",
  getPayRuns: "getPayRuns",
  copyInstrument: "copyInstrument",
  createOrEditChartOfAccount: "createOrEditChartOfAccount",
  getAssetsUnderPrimaryOrg: "getAssetsUnderPrimaryOrg",
  downloadSalesInvoicePDF: "downloadSalesInvoicePDF",
  getEnabledModulesForFAM: "getEnabledModulesForFAM",
  salesInvoiceSendStatement: "salesInvoiceSendStatement",
  sendRemmittanceEmailStatement: "sendRemmittanceEmailStatement",
  getPrepaymentData: "getPrepaymentData",
  approvePurchaseInvoice: "approvePurchaseInvoice",
  getAllContracts: "getAllContracts",
  getContract: "getContract",
  getContractDocument: "getContractDocument",
  getContractTypes: "getContractTypes",
  getAllManualJournalsList: "getAllManualJournalsList",
  getAllRepeatingJournalsList: "getAllRepeatingJournalsList",
  getContractMilestones: "getContractMilestones",
  getAllProjectInvoices: "getAllProjectInvoices",
  getEVAInvoice: "getEVAInvoice",
  getEVAReport: "getEVAReport",
  getMinimumStartDateForContracts: "getMinimumStartDateForContracts",
  getContractsForContact: "getContractsForContact",
  getAssetInvoices: "getAssetInvoices",
  bulkUpdatePaymentsStatus: "bulkUpdatePaymentsStatus",
  getPaymentsForAsset: "getPaymentsForAsset",
  createBulkPayments: "createBulkPayments",
  PurchaseOrderSendStatement: "PurchaseOrderSendStatement",
  getPaidOutOrInBankStatementLineItems: "getPaidOutOrInBankStatementLineItems",
  getOrganizations: "getOrganizations",
  getCategories: "getCategories",
  createCategory: "createCategory",
  updateCategory: "updateCategory",
  getCategory: "getCategory",
  assignCategoryToAssets: "assignCategoryToAssets",
  addTaggingCategoryAttribute: "addTaggingCategoryAttribute",
  createOrUpdateAssetTag: "createOrUpdateAssetTag",
  getAssetTags: "getAssetTags",
  getUnrealisedContingency: "getUnrealisedContingency",
  getRealisedContingency: "getRealisedContingency",
  updateTaggingCategoryAttributeAndValues:
    "updateTaggingCategoryAttributeAndValues",
  getNonPaginatedAllContracts: "getNonPaginatedAllContracts",
  getBudgetsForAsset: "getBudgetsForAsset",
  getBudgetLineItemsForAssetBudget: "getBudgetLineItemsForAssetBudget",
  getTaggedBudgetLineItems: "getTaggedBudgetLineItems",
  getTaggedBudgetLineItemData: "getTaggedBudgetLineItemData",
  getUtilizationTemplates: "getUtilizationTemplates",
  getUtilizationRequests: "getUtilizationRequests",
  updateTaskStatus: "updateTaskStatus",
  bulkUpdateTaskStatus: "bulkUpdateTaskStatus",
  getTasksPaginatedWithFilter: "getTasksPaginatedWithFilter",
  getTask: "getTask",
  getApproverLimitRulesets: "getApproverLimitRulesets",
  getLatestFXRevaluationDate: "getLatestFXRevaluationDate",
  getAllAssetsCurrencies: "getAllAssetsCurrencies",
  getOutstandingPaymentLineItems: "getOutstandingPaymentLineItems",
  getWithholdingTaxPaymentOutstanding:"getWithholdingTaxPaymentOutstanding",
  getCompaniesForIntegration: "getCompaniesForIntegration",
  getPlatformIntegrationDetail: "getPlatformIntegrationDetail",
  getPlatformIntegrationGlCodes: "getPlatformIntegrationGlCodes",
  getDataSourceDataForOrg: "getDataSourceDataForOrg",
  getDeviceDataForAsset: "getDeviceDataForAsset",
  getFxRevaluationHistory: "getFxRevaluationHistory",
  getConsumerPriceIndexNames:"getConsumerPriceIndexNames",
  getConsumerPriceIndexActualData:"getConsumerPriceIndexActualData",
  getAllGlAccountsForRevenueShedule:"getAllGlAccountsForRevenueShedule",
  getFamCurrencyExchangeUpdateHistory: "getFamCurrencyExchangeUpdateHistory"
};

export default QueryKeys;
