import React from "react";
import moment from "moment";
import Highcharts from "highcharts";
import { Row, Col } from "reactstrap";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import { Labels } from "../../../../../constants/Constants";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../utils/number";

highchartsMore(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ","
  }
});

export default function DistributionDataGraph({
  distributionData,
  currency,
  graphHeight = null,
  fromModal = false
}) {
  const actual = distributionData.map((monthData) => monthData.actual);

  const budget = distributionData.map((monthData) => monthData.budget);

  const closingCashBalanceRevenue = distributionData.map(
    (monthData) => monthData.closingCashBalance
  );

  const months = distributionData.map(
    (monthData) =>
      `${moment(monthData.period).format("MMM")} ${moment(
        monthData.period
      ).format("YYYY")}`
  );

  const getMonthLabels = (months) => {
    if (months.length > 24) {
      return months.map((month, index) => {
        if (index === 0 || index % 3 === 0 || index === months.length - 1) {
          return month;
        } else {
          return "";
        }
      });
    } else {
      return months;
    }
  };

  let options = {
    chart: {
      height: !!graphHeight ? graphHeight : 400,
      scrollablePlotArea: {
        minWidth: 800,
        scrollPositionX: 1
      },
      style: {
        fontFamily:
          'Inter, Lato, "Helvetica Neue", Arial, "Noto Sans", sans-serif'
      },
      borderRadius: 4,
      spacingTop: 20
    },
    credits: false,

    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      symbolWidth: 30,
      width: 800,
      itemDistance: 100,
      x: 160,
      y: 0
    },

    title: {
      text: fromModal ? "" : `<b>${Labels.Checks.cashFlowAnalysis}</b>`,
      align: "left",
      x: 24,
      y: fromModal ? 4 : 10,
      width: 600
    },

    yAxis: {
      title: {
        text: `${Labels.Checks.amount}(${currency})`
      }
    },

    tooltip: {
      formatter: function () {
        return (
          `<b>${months[this.point.index]}<b/>` +
          "<br>" +
          `<b>${
            this.series.data[this.point.index].series.name
          }:</b> ${formatAmountWithThousandSeparatorsToPrecision(
            this.series.data[this.point.index].series.processedYData[
              this.point.index
            ]
          )}`
        );
      }
    },

    xAxis: {
      title: {
        dateTimeLabelFormats: {}
      },
      categories: getMonthLabels(months),
      labels: {
        overflow: "justify"
      }
    },

    plotOptions: {
      series: {
        borderRadius: "0%",
        marker: {
          enabled: true
        }
      }
    },

    colors: ["#0094E1", "#fd5e64", "#FFB72C"],
    series: [
      {
        type: "column",
        name: Labels.Checks.cadsActual,
        data: actual
      },
      {
        type: "column",
        name: Labels.Checks.cadsBudget,
        data: budget
      },
      {
        type: "spline",
        step: "center",
        name: Labels.Checks.closingCashBalance,
        data: closingCashBalanceRevenue,
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: "white"
        }
      }
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5
                }
              }
            }
          }
        }
      ]
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div
            className={`generation-graph-container ${
              fromModal ? "" : "elev--1"
            }`}
          >
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
