/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import moment from "moment";
import CustomDatePicker from "../../../../elements/form-fields/inputs/CustomDatePicker";
import { Labels } from "../../../../constants/Constants";
import { convertDateToString } from "../../../../utils/date";
import { nonBlockingHttpRequest } from "../../../../utils/http/nonBlockingHttpRequest";
import { syncTransactions } from "../../../../utils/http/endpoints";
import LoaderWithMessage from "../../../../elements/LoaderWithMessage";
import useGetAssetById from "../../../../hooks/useGetAssetById";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(
  ({ bankAcData, navigationContext, history }) => {
    const modal = useModal();
    const assetQuery = useGetAssetById({
      assetId: bankAcData.assetId,
      isRecentlyViewedBoolean:
        navigationContext.pageContext === "Under An Asset"
    });
    const [isLoaderOpen, setIsLoaderOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const [syncDateRange, setSyncDateRange] = useState([null, null]);
    const [syncFromDate, syncToDate] = syncDateRange;

    const handleSyncDateChange = (dates) => {
      setSyncDateRange(dates);
    };

    const resetErrors = () => {
      setErrors([]);
    };

    const isInvalid = () => {
      return syncDateRange[0] === null || syncDateRange[1] === null;
    };

    const handleSyncTransactions = () => {
      setIsLoaderOpen(true);
      setErrors([]);
      nonBlockingHttpRequest({
        method: "get",
        url: syncTransactions(
          bankAcData.assetId,
          bankAcData.assetBankAccountId,
          convertDateToString(syncDateRange[0], "YYYY-MM-DD"),
          convertDateToString(syncDateRange[1], "YYYY-MM-DD")
        )
      })
        .then(() => {
          modal.remove();
          setIsLoaderOpen(false);
          let url = navigationContext.prefixPath;
          url = url.includes("assets/asset/")
            ? url +
              `/accounting/bankaccounts/${bankAcData.assetBankAccountId}/transactions?imported=true`
            : url +
              `/accounting/bankaccounts/${bankAcData.assetId}/${bankAcData.assetBankAccountId}/transactions?imported=true`;
          history.push(url);
        })
        .catch((error) => {
          setIsLoaderOpen(false);
          setErrors(error?.data?.errors);
        });
    };

    return (
      <React.Fragment>
        {isLoaderOpen ? (
          <LoaderWithMessage
            message={Labels.accounting.syncInProgress}
            isOpen={isLoaderOpen}
            hideLoader={() => setIsLoaderOpen(false)}
          />
        ) : null}

        <Modal
          isOpen={true}
          centered={true}
          toggle={modal.remove}
          fade={false}
          keyboard={false}
          backdrop="static"
          className="create-new-orgbudget-modal"
        >
          <ModalHeader toggle={modal.remove}>
            <div>
              <h2 className="color-dark modal-title">
                {Labels.accounting.syncBankStatement}
              </h2>
            </div>
          </ModalHeader>
          <ModalBody className="py-0 px-3">
            {errors.length ? (
              <Alert color="danger" className="mb-4" toggle={resetErrors}>
                <ul className="error-list">
                  {errors.map((error, index) => {
                    return <li key={index}>{error.errorMessage}</li>;
                  })}
                </ul>
              </Alert>
            ) : null}
            <Row>
              <Col className="row-header-2 my-auto">
                {Labels.accounting.bankAccount}
              </Col>
              <Col className="column-header-2">{bankAcData.iban}</Col>
            </Row>
            <Row className="my-3">
              <Col className="row-header-2 my-auto">
                {Labels.accounting.bankAccountName}
              </Col>
              <Col className="column-header-2">
                {bankAcData.bankAccountDescription}
              </Col>
            </Row>
            <Row className="my-3">
              <Col className="row-header-2 my-auto">
                {Labels.accounting.syncDateRange}
              </Col>
              <Col>
                <CustomDatePicker
                  id="syncDateRange"
                  selectsRange={true}
                  startDate={syncFromDate}
                  endDate={syncToDate}
                  onChange={handleSyncDateChange}
                  dateFormat="dd/MM/yyyy"
                  className="month-ending"
                  isClearable={true}
                  minDate={moment(assetQuery?.data?.conversionDate).toDate()}
                  maxDate={moment().toDate()}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={isInvalid()}
              onClick={handleSyncTransactions}
            >
              {Labels.accounting.sync}
            </Button>
            <Button color="secondary" onClick={modal.remove}>
              {Labels.CommonModals.cancel}
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
);
