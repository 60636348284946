import { useMutation, useQueryClient } from "react-query";
import { assignCategoryToAssets } from "../../../utils/http/endpoints";
import QueryKeys from "../../../constants/QueryKeys";
import { httpRequest } from "../../../utils/http/httpRequest";
import processAPIResponse from "../../../utils/utils";

function useAssignAssetMutation({ categoryId }, options = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QueryKeys.assignCategoryToAssets, categoryId],
    mutationFn: (data) =>
      processAPIResponse(
        httpRequest({
          url: assignCategoryToAssets({
            categoryId,
            assetIds: new URLSearchParams(
              data.length > 0
                ? data.map((assetId) => ["assetIds", assetId])
                : [["assetIds", ""]]
            ).toString()
          }),
          method: "put"
        })
      ),
    ...options,
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.getCategory, +categoryId]);
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    }
  });
}

export default useAssignAssetMutation;
