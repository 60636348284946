// @ts-nocheck
import classNames from "classnames";
import React, { useState } from "react";
import {
  Col,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { useParams } from "react-router-dom";
import { QUERY_PARAMS_TO_GET_DATA as CommentTypes } from "../../constants/Comments";
import { Labels } from "../../constants/Constants";
import { PurchaseInvoiceTypes } from "../../constants/PurchaseInvoiceTypes";
import useToggle from "../../hooks/useToggle";
import { convertDateFromUTCToLocal } from "../../utils/date";
import AddCommentModal from "../../views/Assets/PayRuns/SalesInvoices/AddCommentModal";
import NiceModal from "@ebay/nice-modal-react";
import { modalNames } from "../../elements/modals/modal-register";
import useGetInvoiceHistoryAndComments from "../../views/Assets/PayRuns/SalesInvoices/hooks/useGetInvoiceHistoryAndComments";
import { httpRequest } from "../../utils/http/httpRequest";
import { getFixedAssetRegister } from "../../utils/http/endpoints";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "react-query";

export default function HistoryAndComments({
  endpoints,
  disableAddComments,
  hideComments
}) {
  const [isCommentModalOpen, toggleCommentModal] = useToggle();
  const [showTab, setShowTab] = React.useState(CommentTypes.All);
  const [comment, setComment] = React.useState("");
  const [selectedCommentData, setSelectedCommentData] = useState({});
  const historyAndCommentsQuery = useGetInvoiceHistoryAndComments(
    endpoints.getInvoiceHistoryAndComments(showTab.toUpperCase())
  );
  const [commentActionType, setCommentActionType] = useState("");

  const deleteCommentMutation = useMutation(
    (data) =>
      httpRequest({
        method: "DELETE",
        url: endpoints.deleteComment,
        data
      }).catch((err) => Promise.reject(err.data.errors[0].errorMessage)),
    {
      onSuccess: () => {
        historyAndCommentsQuery.refetch();
      }
    }
  );

  return (
    <div>
      <div className="mt-3">
        <div className="border-bottom d-inline-block pb-2">
          <span
            onClick={() => setShowTab(CommentTypes.All)}
            className={classNames(
              "cursor-pointer fw-600 color-primary ml-0 pl-0 pb-2 mr-3",
              { "fv-border-bottom-primary": showTab === CommentTypes.All }
            )}
          >
            {Labels.Payments.AccountsReceivables.all}
          </span>
          <span
            onClick={() => setShowTab(CommentTypes.Comments)}
            className={classNames(
              "cursor-pointer fw-600 color-primary ml-0 pl-0 mr-3 pb-2",
              {
                "fv-border-bottom-primary": showTab === CommentTypes.Comments
              }
            )}
          >
            {Labels.Payments.AccountsReceivables.comments}
          </span>
          <span
            onClick={() => setShowTab(CommentTypes.History)}
            className={classNames(
              "cursor-pointer fw-600 color-primary ml-0 pl-0 pb-2",
              {
                "fv-border-bottom-primary": showTab === CommentTypes.History
              }
            )}
          >
            {Labels.Payments.AccountsReceivables.history}
          </span>
        </div>

        <>
          <a
            href="#"
            className={classNames("link-primary m-0 p-0 ml-5 prepend-plus", {
              "disabled-link": disableAddComments
            })}
            onClick={(event) => {
              event.preventDefault();
              toggleCommentModal();
              setComment("");
              setCommentActionType("Add");
            }}
          >
            {Labels.Payments.AccountsReceivables.addcomment}
          </a>
          {isCommentModalOpen ? (
            <AddCommentModal
              isOpen={isCommentModalOpen}
              toggle={() => {
                setCommentActionType("");
                toggleCommentModal();
              }}
              commentActionType={commentActionType}
              setCommentActionType={setCommentActionType}
              comment={comment}
              setComment={setComment}
              endpoints={endpoints}
              refreshHistoryAndComments={historyAndCommentsQuery.refetch}
              selectedCommentData={selectedCommentData}
            />
          ) : null}
        </>
      </div>

      {historyAndCommentsQuery.data?.length ? (
        <div className={hideComments ? "hide" : "comments-section mt-2"}>
          <div className="sales-invoice-history-comments scrollbar-primary mb-2">
            {historyAndCommentsQuery.data?.map((i, index) => (
              <HistoryOrCommentRow
                data={i}
                key={index}
                deleteCommentMutation={deleteCommentMutation}
                setCommentActionType={setCommentActionType}
                toggleCommentModal={toggleCommentModal}
                setComment={setComment}
                setSelectedCommentData={setSelectedCommentData}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="mt-2">{Labels.CommonModals.nothingToDisplay}</div>
      )}
    </div>
  );
}

function HistoryOrCommentRow({
  data,
  deleteCommentMutation,
  setCommentActionType,
  toggleCommentModal,
  setComment,
  setSelectedCommentData
}) {
  const params = useParams();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleDescriptionClick = (event, id) => {
    event.preventDefault();
    httpRequest({
      method: "get",
      url: getFixedAssetRegister(id)
    })
      .then((res) => {
        NiceModal.show(modalNames.registerCardDetailsModal, {
          register: res.data,
          errors: []
        });
      })
      .catch((err) => {
        NiceModal.show(modalNames.registerCardDetailsModal, {
          register: {},
          errors: err.data.errors
        });
      });
  };
  const getCommentBodyMarkup = () => {
    if (!data.historyMetadataList?.length > 0) {
      return data.changedBy !== "The System" ? (
        <>
          <b>{data.changedBy} </b>
          {data.to.replace(data.changedBy, "")}
        </>
      ) : (
        <>{data.to}</>
      );
    }

    return data.historyMetadataList.map((meta, index) => {
      const getInstrumentLink = (instrumentType) => {
        switch (instrumentType) {
          case PurchaseInvoiceTypes["Purchase Invoice"]:
            return `/#/allassets/payments/invoices/${params.assetId}/${meta.instrumentId}`;
          case "PURCHASE_ORDER":
            return `/#/allassets/payments/purchaseorders/edit-po/${params.assetId}/${meta.instrumentId}`;
          default:
            return "#";
        }
      };

      return (
        <React.Fragment key={index}>
          {data.to}
          {[
            PurchaseInvoiceTypes["Purchase Invoice"],
            "PURCHASE_ORDER"
          ].includes(meta.instrumentType) &&
          meta.data &&
          params.assetId ? (
            <a
              href={getInstrumentLink(meta.instrumentType)}
              target="_blank"
              rel="noreferrer"
            >
              {meta.data}
            </a>
          ) : null}
          {meta.instrumentType === "FIXED_ASSET_REGISTER" ? (
            <a
              href={"#"}
              onClick={(event) =>
                handleDescriptionClick(event, meta.instrumentId)
              }
              rel="noreferrer"
            >
              {meta.data}
            </a>
          ) : null}
        </React.Fragment>
      );
    });
  };

  return (
    <Row className="border-bottom py-2 mx-0 align-items-center">
      <Col md={9} className="px-0">
        {data.field === "Comment" ? (
          <>
            <span>
              <b>{Labels.CommonModals.comment}</b> {Labels.CommonModals.by}{" "}
              {data.changedBy}
            </span>
            <div>{data.to}</div>
          </>
        ) : data.field === "Comment Added" ? (
          <span>
            <b>{data.changedBy}</b>
            {` ${Labels.CommonModals.addedTheComment} `}
            <em>{data.to}</em>
          </span>
        ) : data.field === "Comment Edited" ? (
          <span>
            <b>{data.changedBy}</b>
            {` ${Labels.CommonModals.hasEdited} `}
            <em>{data.from}</em> {Labels.CommonModals.to} <em>{data.to}</em>
          </span>
        ) : data.field === "Comment Deleted" ? (
          <span>
            <b>{data.changedBy}</b> {Labels.CommonModals.hasDeletedTheComment}{" "}
            <em>{data.to}</em>
          </span>
        ) : (
          <span>{getCommentBodyMarkup()}</span>
        )}
      </Col>
      <Col md={3} className="px-0 d-flex justify-content-end">
        <span className="my-auto">
          <time dateTime={data.timestamp} className="mr-1">
            {convertDateFromUTCToLocal(data.timestamp, "DD MMM YYYY HH:mm:ss")}
          </time>
        </span>
        <span className="text-right mr-1">
          {data.field === "Comment" ? (
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                tag="span"
                data-testid="hamburger-menu"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                className="cursor-pointer custom-hamburger-menu mx-1"
              >
                <FontAwesomeIcon icon={faEllipsisV} size={"2x"} />
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    toggleCommentModal();
                    setComment(data.to);
                    setCommentActionType("Edit");
                    setSelectedCommentData(data);
                  }}
                >
                  {Labels.CommonModals.edit}
                </DropdownItem>
                <DropdownItem
                  onClick={(event) => {
                    event.preventDefault();
                    deleteCommentMutation.mutate(data);
                  }}
                >
                  {Labels.CommonModals.delete}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : null}
        </span>
      </Col>

      {data.field === "comment" ? <span>{data.to}</span> : null}
    </Row>
  );
}
