import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Auth from "../auth/Auth";
import { getSortedAssetListFromOrgs } from "../utils/assets";

/**
 * Returns asset list, considers the navigation context and filters the asset list using url params
 * @returns Array
 */
function useGetAssetList(includeNonGlAssets = true, filterByOrgId) {
  const { orgId } = useParams();

  const areOrganizationsReady = useSelector(
    (state) => state.areOrganizationsReady
  );
  const assetList = useMemo(() => {
    if (!areOrganizationsReady) {
      return;
    }
    const assetList = getSortedAssetListFromOrgs(
      Auth.getOrganizations(),
      orgId || filterByOrgId,
      includeNonGlAssets
    ).map((asset) => ({
      label: asset.displayName,
      value: asset.id,
      currency: asset.currency,
      assetModules: asset.assetModules,
      organizationId: asset.orgId,
      conversionDate: asset.conversionDate,
      isFullService: asset.isFullService,
      isOnboardingComplete: asset.isOnboardingComplete,
      isSystemAccountConfigured: asset.isSystemAccountConfigured,
      isJournalsImported: asset.isJournalsImported,
      formalBusinessName: asset.formalBusinessName
    }));
    return assetList;
  }, [orgId, areOrganizationsReady, includeNonGlAssets, filterByOrgId]);

  return Array.isArray(assetList) ? assetList : [];
}

export default useGetAssetList;
