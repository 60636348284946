import React, { useState, useMemo } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  ModalFooter
} from "reactstrap";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { IBAN } from "ibankit";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Auth from "../../../../auth/Auth";
import QueryKeys from "../../../../constants/QueryKeys";
import { Labels } from "../../../../constants/Constants";
import { useMutation, useQueryClient } from "react-query";
import DisplayAlerts from "../../../../elements/DisplayAlerts";
import { contactNameRegexp } from "../../../../constants/regexp";
import { createUnverifiedContact } from "../../../../utils/http/endpoints";
import { httpRequest } from "../../../../utils/http/httpRequest";
import {
  CONTACT_TYPES,
  ContactStatuses,
  ContactDataModel,
  BankAccountDetails
} from "../../Accounting/Contacts/ContactsUtils";
import useCustomForm from "../../../../elements/form-fields/useCustomForm";
import { Controller, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectBox } from "../../../../elements/select/Select";
import { CountryMap } from "../../../../utils/country/CountryMap";
import { FormInputWarning } from "../../../../elements/Error/FormInputWarning";
import OptionalLabel from "../../../../elements/OptionalLabel";
import DragAndDropFileUpload from "../../../../elements/file-upload/DragAndDropFileUpload";

export default NiceModal.create(
  ({
    selectedAssetId,
    orgId,
    setIsSupplierAddedSuccessMsgVisible,
    POEditFormSetValue,
    setIsFormChanged
  }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const [errors, setErrors] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const queryClient = useQueryClient();
    const [duplicateAcc, setDuplicateAcc] = useState([]);

    const {
      register,
      unregister,
      handleSubmit,
      formState,
      control,
      setValue,
      trigger,
      getValues,
      setFocus,
      shouldSubmitBtnBeDisabled
    } = useCustomForm({
      defaultValues: { ...ContactDataModel, isContactFromPO: true },
      mode: "onChange"
    });
    const [bankAccountCountryDetails, setBankAccountCountryDetails] = useState(
      {}
    );
    const { fields, append, remove } = useFieldArray({
      control,
      name: "bankAccountDetails",
      shouldUnregister: true,
      keyName: "bankAccountId"
    });

    const getBankAccountRecords = () => {
      return (
        getValues()?.bankAccountDetails?.filter(
          (field) => field.status !== ContactStatuses.IN_ACTIVE.value
        ) || []
      );
    };
    const isIBANCountry = (index) => {
      return (
        bankAccountCountryDetails[index] &&
        bankAccountCountryDetails[index]?.isIBANCountry
      );
    };

    const isInActiveBankAccount = (index) =>
      getValues(`bankAccountDetails.${index}.status`) ===
      ContactStatuses.IN_ACTIVE.value;

    const countries = useMemo(() => {
      const countries = [];
      CountryMap.forEach((item) => {
        const country = {
          label: item.name,
          value: item.code,
          isIBANCountry: item.isIBANCountry
        };
        countries.push(country);
      });
      return countries;
    }, []);

    const removeBankAccount = (bankAccountId, index) => {
      if (bankAccountId) {
        setValue(
          `bankAccountDetails.${index}.status`,
          ContactStatuses.IN_ACTIVE.value,
          { shouldDirty: true }
        );
      } else {
        remove(index);
      }
      trigger();
      validateDuplicateAccount();
    };

    const isCountrySelected = (index) => !!bankAccountCountryDetails[index];

    const validateDuplicateAccount = async () => {
      const bankAccountDetails = await getValues()?.bankAccountDetails;
      let hasDuplicate = false;
      const len = bankAccountDetails.length;
      const duplicateAccounts = {};
      let duplicateAccountsArr = [];
      setDuplicateAcc([]);

      for (let i = 0; i < len; i++) {
        if (bankAccountDetails[i].status === ContactStatuses.ACTIVE.value) {
          const isIBAN = isIBANCountry(fields[i].id || fields[i].bankAccountId);
          duplicateAccounts[Number(isIBAN)] =
            duplicateAccounts[Number(isIBAN)] || {};
          const duplicateData = duplicateAccounts[Number(isIBAN)];
          if (!isIBAN) {
            if (bankAccountDetails[i].bankAccountNumber) {
              duplicateData[bankAccountDetails[i].bankAccountNumber] =
                duplicateData[bankAccountDetails[i].bankAccountNumber] || [];
              duplicateData[bankAccountDetails[i].bankAccountNumber].push(i);
              if (
                duplicateData[bankAccountDetails[i].bankAccountNumber]?.length >
                1
              ) {
                duplicateAccountsArr = [
                  ...duplicateAccountsArr,
                  ...duplicateData[bankAccountDetails[i].bankAccountNumber]
                ];
                hasDuplicate = true;
              }
            }
          } else {
            if (bankAccountDetails[i].iban) {
              duplicateData[bankAccountDetails[i].iban] =
                duplicateData[bankAccountDetails[i].iban] || [];
              duplicateData[bankAccountDetails[i].iban].push(i);
            }
            if (bankAccountDetails[i].bankAccountName) {
              duplicateData[bankAccountDetails[i].bankAccountName] =
                duplicateData[bankAccountDetails[i].bankAccountName] || [];
              duplicateData[bankAccountDetails[i].bankAccountName].push(i);
            }

            if (duplicateData[bankAccountDetails[i].iban]?.length > 1) {
              duplicateAccountsArr = [
                ...duplicateAccountsArr,
                ...duplicateData[bankAccountDetails[i].iban]
              ];
              hasDuplicate = true;
            }
            if (
              duplicateData[bankAccountDetails[i].bankAccountName]?.length > 1
            ) {
              duplicateAccountsArr = [
                ...duplicateAccountsArr,
                ...duplicateData[bankAccountDetails[i].bankAccountName]
              ];
              hasDuplicate = true;
            }
          }
        }
      }
      setDuplicateAcc(duplicateAccountsArr);

      return !hasDuplicate ? true : " ";
    };

    const supplierMutation = useMutation((data) => {
      const accountDetails = [];
      if (data.bankAccountDetails.length > 0) {
        for (let i = 0; i < data.bankAccountDetails.length; i++) {
          if (data.bankAccountDetails[i].country) {
            accountDetails.push(data.bankAccountDetails[i]);
          }
        }
      }
      const payloadData = {
        ...data,
        type: CONTACT_TYPES.SUPPLIER,
        assetIds: [selectedAssetId],
        defaultCurrencyCode: Auth.getOrganizations().filter(
          (org) => +org.id === +orgId
        )[0].currency,
        address: null,
        bankAccountDetails: accountDetails,
        isVerified: false,
        documents: attachments
      };
      let formData = new FormData();
      Object.entries(payloadData).forEach(([key, value]) => {
        if (key === "bankAccountDetails") {
          value.forEach((bankAccount, bankAccountIndex) => {
            Object.entries(bankAccount).forEach(
              ([propertyName, propertyValue]) => {
                if (!!propertyValue) {
                  formData.append(
                    `bankAccountDetails[${bankAccountIndex}].${propertyName}`,
                    propertyValue
                  );
                }
              }
            );
          });
        } else if (key === "documents") {
          if (value?.length) {
            value.forEach((doc, docIndex) => {
              formData.append(`documents[${docIndex}]`, doc);
            });
          }
        } else {
          if (!!value) {
            formData.append(key, value);
          }
        }
      });

      httpRequest({
        method: "post",
        url: createUnverifiedContact(+orgId),
        data: formData,
        headers: { "content-type": "multipart/form-data" }
      })
        .then((res) => {
          const createdSupplier = {
            ...res.data,
            label: res.data.name,
            value: res.data.id
          };
          queryClient.invalidateQueries(QueryKeys.getContacts);
          setIsSupplierAddedSuccessMsgVisible(true);
          modal.remove();
          POEditFormSetValue("supplier", createdSupplier);
          setIsFormChanged(true);
        })
        .catch((error) => {
          if (typeof error == "string") {
            setErrors([{ errorMessage: error }]);
          } else {
            setErrors(error.data?.errors);
          }
        });
    });

    const errorCodeDesc = {
      "file-invalid-type": Labels.Journals.fileTypeError,
      "file-too-large": Labels.CommonModals.attachmentSizeError
    };

    const onFilesSelection = (files = []) => {
      setAttachments(files);
    };
    const uploadOptions = {
      minSize: 0,
      maxSize: 10485760, // 10 MB
      multiple: true
    };

    const onError = (errorList) => {
      const errors = {};
      errorList.forEach((errorObj) => {
        errorObj.errors.forEach((error) => {
          errors[error.code] = {
            errorMessage: errorCodeDesc[error.code] || error.message
          };
        });
      });
      setErrors([...Object.values(errors)]);
    };

    const onSubmit = (data) => {
      supplierMutation.mutate(data);
    };

    return (
      <Modal
        centered
        keyboard={false}
        backdrop="static"
        fade={false}
        size="lg"
        isOpen={modal.visible}
        toggle={modal.remove}
        onOpened={() => {
          setFocus("name");
        }}
      >
        <ModalHeader className="pb-0" toggle={modal.remove}>
          <div>
            <h2 className="color-dark modal-title">
              {Labels.Assets.PurchaseOrders.CreateNewSupplier}
            </h2>
          </div>
        </ModalHeader>
        <React.Fragment>
          <ModalBody className="pt-2 pb-0">
            {errors.length ? (
              <DisplayAlerts
                alerts={errors}
                type="danger"
                onAlertDismiss={() => setErrors([])}
                className="mt-0"
              />
            ) : null}
            <form>
              <Row className="field-row">
                <Col sm="6">
                  <Label className="mb-0 mt-2">
                    {Labels.CommonModals.name}
                  </Label>
                </Col>
                <Col sm="6" className="px-2">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Supplier Name"
                    name="contactName"
                    {...register("name", {
                      required: true,
                      maxLength: 255,
                      pattern: {
                        value: contactNameRegexp,
                        message: Labels.Contacts.contactPatternError
                      }
                    })}
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col sm="12" className="my-3">
                  <OptionalLabel>{Labels.Contacts.attachments}</OptionalLabel>
                </Col>
                <Col sm="12">
                  <DragAndDropFileUpload
                    onFileSelection={onFilesSelection}
                    onError={onError}
                    label={Labels.CommonModals.uploadFileLabel}
                    button={true}
                    uploadConfig={uploadOptions}
                  />
                </Col>
              </Row>
              <>
                <div className="d-flex">
                  <Label className="h4 my-3">
                    {Labels.Contacts.bankAccountDetails}
                  </Label>
                  <a
                    href="#"
                    className="link-primary p-0 prepend-plus my-auto ml-auto"
                    onClick={(event) => {
                      event.preventDefault();
                      append(new BankAccountDetails());
                    }}
                  >
                    {Labels.Contacts.addAnotherAccount}
                  </a>
                </div>
                <div
                  className={`form-group financial-items-container mb-0 ${
                    getBankAccountRecords().length <= 0 ? "d-none" : ""
                  }`}
                >
                  <span>
                    <input
                      type="hidden"
                      {...register("duplicateAccountsValidtions", {
                        validate: {
                          duplicateAcc: (v) => validateDuplicateAccount() || ""
                        }
                      })}
                    />
                  </span>
                  {fields.map((details, index) =>
                    !isInActiveBankAccount(index) ? (
                      <div key={details.bankAccountId + index}>
                        <div className="financial-item pt-1">
                          <Row className="field-row">
                            <input
                              type="hidden"
                              {...register(`bankAccountDetails.${index}.id`)}
                            />
                            <input
                              type="hidden"
                              {...register(
                                `bankAccountDetails.${index}.status`
                              )}
                            />

                            <Col sm="6">
                              <div className="d-flex justify-content-between align-items-center">
                                <Label>{Labels.Contacts.bankAccountName}</Label>
                              </div>
                              <input
                                className="form-control"
                                type="text"
                                placeholder={Labels.Contacts.bankAccountName}
                                defaultValue={details.bankAccountName}
                                {...register(
                                  `bankAccountDetails.${index}.bankAccountName`,
                                  {
                                    required: true,
                                    onChange: () => {
                                      trigger("duplicateAccountsValidtions");
                                    }
                                  }
                                )}
                              />
                              <div
                                className={`${
                                  formState.getErrors().length > 0 &&
                                  duplicateAcc.includes(index)
                                    ? ""
                                    : "d-none"
                                } redText text-block-primary validation-message-${index} pt-1`}
                              >
                                {Labels.Contacts.duplicateAccount}
                              </div>
                            </Col>
                            <Col sm="6">
                              <div className="d-flex justify-content-between align-items-center">
                                <Label>{Labels.Contacts.country}</Label>
                                {fields.length <= 1 ? null : (
                                  <button
                                    className="btn btn-link p-0"
                                    onClick={() =>
                                      removeBankAccount(details.id, index)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      aria-label="delete-icon"
                                    />
                                  </button>
                                )}
                              </div>
                              <Controller
                                control={control}
                                rules={{ required: true }}
                                name={`bankAccountDetails.${index}.country`}
                                render={({
                                  field: { onChange, onBlur, value, ref }
                                }) => (
                                  <SelectBox
                                    inputRef={ref}
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={true}
                                    blurInputOnSelect={false}
                                    value={countries.find(
                                      (c) => c.value === value
                                    )}
                                    options={countries}
                                    onBlur={onBlur}
                                    className={`bankAccountDetails-${index}-country`}
                                    onChange={(val) => {
                                      setBankAccountCountryDetails(
                                        (prevState) => {
                                          const state = { ...prevState };
                                          state[
                                            details.id || details.bankAccountId
                                          ] = val;
                                          return state;
                                        }
                                      );
                                      onChange(val.value);
                                      if (
                                        !isIBANCountry(
                                          details.id || details.bankAccountId
                                        )
                                      ) {
                                        unregister([
                                          `bankAccountDetails.${index}.iban`,
                                          `bankAccountDetails.${index}.bic`
                                        ]);
                                      }
                                      trigger();
                                    }}
                                    aria-label={`bankAccountDetails.${index}.country`}
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                          {isCountrySelected(
                            details.id || details.bankAccountId
                          ) &&
                            (isIBANCountry(
                              details.id || details.bankAccountId
                            ) ? (
                              <>
                                <Row className="mt-2">
                                  <Col sm="6">
                                    <Label>{Labels.Contacts.iban}</Label>
                                    <input
                                      type="text"
                                      placeholder={Labels.Contacts.iban}
                                      defaultValue={details.iban}
                                      className="form-control"
                                      {...register(
                                        `bankAccountDetails.${index}.iban`,
                                        {
                                          shouldUnregister: true,
                                          required: true,
                                          pattern: {
                                            value: /^[A-Z0-9]+$/g,
                                            message:
                                              Labels.Contacts
                                                .ibanShouldntContainSpace
                                          },
                                          validate: {
                                            isValid: (val) =>
                                              IBAN.isValid(val) ||
                                              Labels.Contacts.invalidIban
                                          },
                                          onChange: () => {
                                            trigger(
                                              "duplicateAccountsValidtions"
                                            );
                                          }
                                        }
                                      )}
                                    />
                                  </Col>
                                  <Col sm="6">
                                    <Label>{Labels.Contacts.bic}</Label>
                                    <input
                                      type="text"
                                      placeholder={Labels.Contacts.bic}
                                      className="form-control"
                                      defaultValue={details.bic}
                                      {...register(
                                        `bankAccountDetails.${index}.bic`,
                                        {
                                          shouldUnregister: true,
                                          required: true
                                        }
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </>
                            ) : (
                              <Row className="field-row mt-2">
                                <Col sm="6">
                                  <Label>{Labels.Contacts.bankName}</Label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder={Labels.Contacts.bankName}
                                    defaultValue={details.bankName}
                                    {...register(
                                      `bankAccountDetails.${index}.bankName`,
                                      { shouldUnregister: true, required: true }
                                    )}
                                  />
                                </Col>
                                <Col sm="6">
                                  <Label>
                                    {Labels.Contacts.bankAccountNumber}
                                  </Label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder={
                                      Labels.Contacts.bankAccountNumber
                                    }
                                    defaultValue={details.bankAccountNumber}
                                    {...register(
                                      `bankAccountDetails.${index}.bankAccountNumber`,
                                      {
                                        shouldUnregister: true,
                                        required: true,
                                        onChange: () => {
                                          trigger(
                                            "duplicateAccountsValidtions"
                                          );
                                        }
                                      }
                                    )}
                                  />
                                </Col>
                              </Row>
                            ))}
                        </div>
                        <hr className="mb-2" />
                      </div>
                    ) : null
                  )}
                </div>
              </>
              <p className="my-3 ml-0">
                <span className="row-header-2">{Labels.CommonModals.note}</span>
                {Labels.Contacts.addSupplierWarningText}
              </p>
              <FormInputWarning formState={formState} />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={!formState.isDirty || shouldSubmitBtnBeDisabled()}
              onClick={handleSubmit(onSubmit)}
            >
              {Labels.CommonModals.create}
            </Button>
            <Button color="secondary" className="ml-2" onClick={modal.remove}>
              {Labels.CommonModals.cancel}
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    );
  }
);
