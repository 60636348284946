import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getMyAssets } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import processAPIResponse from "../utils/utils";

function useGetOrganizations() {
  return useQuery({
    queryKey: QueryKeys.getOrganizations,
    queryFn: () =>
      processAPIResponse(
        httpRequest({
          method: "get",
          url: getMyAssets()
        })
      )
  });
}

export default useGetOrganizations;
