const SalesInvoiceStatuses = {
  DRAFT: "Draft",
  AWAITING_APPROVAL: "Awaiting Approval",
  APPROVED: "Approved",
  CANCELLED: "Cancelled",
  PARTIALLY_RECEIVED: "Partially Received",
  PARTIALLY_PAID: "Partially Paid",
  VOIDED: "Voided",
  // For sales credit notes
  PAID: "Paid",
  RECEIVED: "Received"
};

// For dropdowns
export const SalesInvoiceStatusList = Object.entries(SalesInvoiceStatuses).map(
  ([k, v]) => ({ label: v, value: v })
);

export default SalesInvoiceStatuses;
