import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import {
  Input,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
  ModalHeader
} from "reactstrap";
import { SelectBox as Select } from "../../../elements/select/Select";
import { MultiSelect } from "../../../elements/multiselect/MultiSelect";
import { Labels } from "../../../constants/Constants";
import {
  createPlatformUser,
  updatePlatformUser
} from "../../../utils/http/endpoints";
import { httpRequest } from "../../../utils/http/httpRequest";
import DisplayAlerts from "../../../elements/DisplayAlerts";
import useGetAssetList from "../../../hooks/useGetAssetList";
import useGetRoles from "../../../hooks/useGetRoles";
import Auth from "../../../auth/Auth";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { alphabetsRegexp } from "../../../constants/regexp";

export default NiceModal.create(
  ({
    userData,
    setIsCreateSuccessMsgVisible,
    setIsUpdateSuccessMsgVisible,
    refreshUsersList,
    isOtherFamUser
  }) => {
    const modal = useModal();
    const [user, setUser] = useState({
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
      email: userData.email || "",
      errors: {
        firstName: "",
        lastName: "",
        email: ""
      },
      touched: {
        firstName: false,
        lastName: false,
        email: false
      }
    });

    const [selectedRoles, setSelectedRoles] = useState(
      userData.roles?.map((role) => ({
        label: role.name,
        value: role.id,
        id: role.id
      })) || []
    );
    const [isExternalUser, setIsExternalUser] = useState(
      userData.id === undefined
        ? false
        : isOtherFamUser
        ? false
        : userData?.isFAMUser
        ? false
        : true
    );
    const [selectedOrganisation, setSelectedOrganisation] = useState(
      userData.id
        ? {
            label: userData.orgName,
            value: userData.orgId
          }
        : null
    );
    const assetList = useGetAssetList(
      true,
      userData?.isFAMUser ? undefined : selectedOrganisation?.value
    );
    const rolesList = useGetRoles();

    const areOrganizationsReady = useSelector(
      (state) => state.areOrganizationsReady
    );
    const organizationsList = useMemo(() => {
      if (!areOrganizationsReady) {
        return;
      }

      return Auth.getOrganizations().map((organisation) => ({
        ...organisation,
        label: organisation.name,
        value: organisation.id
      }));
    }, [areOrganizationsReady]);

    const [selectedAssetList, setSelectedAssetList] = useState(
      userData.assets?.map((asset) => ({
        label: asset.displayName,
        value: asset.id,
        currency: asset.currency,
        organizationId: userData?.orgId // ? All assets will always belongs to same org in case of non FAM user.
      })) || []
    );

    const mutation = useMutation(
      () => {
        return httpRequest({
          method: userData.id ? "put" : "post",
          data: userData.id ? getPutData() : getPostData(),
          url: userData.id
            ? updatePlatformUser(userData.id)
            : createPlatformUser()
        })
          .then((res) => Promise.resolve(res.data))
          .catch((err) => Promise.reject(err.data.errors));
      },
      {
        onSuccess: () => {
          refreshUsersList();
          userData.id
            ? setIsUpdateSuccessMsgVisible(true)
            : setIsCreateSuccessMsgVisible(true);
          modal.remove();
        }
      }
    );

    const handleIsExternalUserCheckboxChange = (event) => {
      setIsExternalUser(event.target.checked);
      if (!event.target.checked) {
        setSelectedOrganisation(null);
        setSelectedAssetList([]);
      }
    };

    const handleFirstNameChange = (event) => {
      event.target.value =
        event.target.name === "firstName"
          ? event.target.value
          : event.target.value.replace(/\s/g, "");

      event.target.checkValidity();
      const value = event.target.value;

      setUser({
        ...user,
        [event.target.name]:
          event.target.name === "firstName" ? value : value.replace(/\s/g, ""),
        touched: {
          ...useRef.touched,
          [event.target.name]: true
        },
        errors: {
          ...user.errors,
          [event.target.name]: event.target.validationMessage
        }
      });
    };

    const handleLastNameChange = (event) => {
      event.target.value =
        event.target.name === "lastName"
          ? event.target.value
          : event.target.value.replace(/\s/g, "");

      event.target.checkValidity();
      const value = event.target.value;

      setUser({
        ...user,
        [event.target.name]:
          event.target.name === "lastName" ? value : value.replace(/\s/g, ""),
        touched: {
          ...useRef.touched,
          [event.target.name]: true
        },
        errors: {
          ...user.errors,
          [event.target.name]: event.target.validationMessage
        }
      });
    };

    const handleKeyPress = (event) => {
      var keyCode = event.keyCode || event.which;

      if (
        !alphabetsRegexp.test(String.fromCharCode(keyCode)) ||
        (event.target.value.length === 0 && keyCode === 32)
      ) {
        event.preventDefault();
      }
    };

    const handleEmailChange = (event) => {
      event.target.value =
        event.target.name === "email"
          ? event.target.value
          : event.target.value.replace(/\s/g, "");

      event.target.checkValidity();
      const value = event.target.value;

      setUser({
        ...user,
        [event.target.name]:
          event.target.name === "email" ? value : value.replace(/\s/g, ""),
        touched: {
          ...useRef.touched,
          [event.target.name]: true
        },
        errors: {
          ...user.errors,
          [event.target.name]: event.target.validationMessage
        }
      });
    };

    const handleOrgChange = (organisation) => {
      setSelectedOrganisation(organisation);
      setSelectedAssetList([]);
    };

    const handleAssetChange = (assets) => {
      setSelectedAssetList(assets);
    };

    const handleRoleChange = (roles) => {
      setSelectedRoles(roles);
    };

    const isFormValid = () =>
      // Errors should be empty and values should be not empty
      !user.errors.firstName &&
      user.firstName &&
      !user.errors.lastName &&
      user.lastName &&
      !user.errors.email &&
      user.email &&
      selectedRoles.length &&
      selectedAssetList?.length > 0 &&
      (isExternalUser ? !!selectedOrganisation?.value : true); //  If the user is a FAM user then user should be forced to select an org.

    const getPostData = () => {
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        organizationId:
          selectedOrganisation === null ? 0 : selectedOrganisation.value,
        assets: selectedAssetList.map((asset) => asset.value),
        roles: selectedRoles.map((role) => role.id)
      };
    };

    const getPutData = () => {
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userName: user.email,
        organizationId: !!(!userData.isFAMUser && !!userData.orgId)
          ? selectedOrganisation.value
          : sessionStorage.getItem("Auth.tenantId"),
        assets: selectedAssetList.map((asset) => asset.value),
        roles: selectedRoles.map((role) => role.id)
      };
    };

    return (
      <div>
        <Modal
          centered
          isOpen={modal.visible}
          backdrop="static"
          keyboard={false}
          toggle={modal.remove}
        >
          <ModalHeader className="pb-0" toggle={modal.remove}>
            <h2 className="color-dark modal-title">
              {userData.id
                ? Labels.UserManagement.editUser
                : Labels.UserManagement.createUser}
            </h2>
          </ModalHeader>
          <ModalBody className="pt-0">
            {mutation.isSuccess ? (
              <Alert color="success">
                {Labels.UserManagement.userSavedMsg}
              </Alert>
            ) : null}
            <Row>
              <Col>
                {mutation.isError ? (
                  <DisplayAlerts alerts={mutation.error} type="danger" />
                ) : null}
              </Col>
            </Row>
            {!isOtherFamUser ? (
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2">
                  {Labels.UserManagement.isExternalUser}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  <FormGroup className="custom-control custom-switch">
                    <Input
                      type="checkbox"
                      id="isExternalUser"
                      className="custom-control-input"
                      name={"isExternalUser"}
                      onChange={handleIsExternalUserCheckboxChange}
                      defaultChecked={isExternalUser}
                      disabled={!!userData?.id}
                    />
                    <Label
                      check
                      for="isExternalUser"
                      className="custom-control-label"
                    >
                      &nbsp;
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
            <Row className={`${isOtherFamUser ? "mt-3" : "mt-2"}`}>
              <Col sm={12} md={6} className="row-header-2">
                {Labels.UserManagement.firstName}
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <div>
                  <Input
                    type="text"
                    name={"firstName"}
                    maxLength={50}
                    placeholder={Labels.UserManagement.firstName}
                    required={user.touched.firstName}
                    onChange={handleFirstNameChange}
                    onKeyPress={handleKeyPress}
                    value={user.firstName}
                  />
                </div>
                <div>
                  <span className="text-danger error-message error-message">
                    {user.errors.firstName}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6} className="row-header-2">
                {Labels.UserManagement.lastName}
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <div>
                  <Input
                    type="text"
                    name={"lastName"}
                    maxLength={50}
                    placeholder={Labels.UserManagement.lastName}
                    required={user.touched.lastName}
                    onChange={handleLastNameChange}
                    onKeyPress={handleKeyPress}
                    value={user.lastName}
                  />
                </div>
                <div>
                  <span className="text-danger error-message error-message">
                    {user.errors.lastName}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6} className="row-header-2">
                {Labels.UserManagement.emailAddress}
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <div>
                  <Input
                    type="email"
                    name={"email"}
                    maxLength={255}
                    placeholder={Labels.UserManagement.emailAddress}
                    required={user.touched.email}
                    onChange={handleEmailChange}
                    value={user.email}
                    disabled={!!userData?.id}
                  />
                </div>
                <div>
                  <span className="text-danger error-message error-message">
                    {user.errors.email}
                  </span>
                </div>
              </Col>
            </Row>
            {!userData?.id && (
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2">
                  {Labels.UserManagement.organisation}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  <div>
                    {!userData.id ? (
                      <Select
                        value={selectedOrganisation}
                        onChange={handleOrgChange}
                        options={organizationsList}
                        isDisabled={!!userData?.id || !isExternalUser} //  Once user is created, Org. cannot be changed.
                      />
                    ) : (
                      <Input
                        type="text"
                        value={userData.orgName}
                        disabled={true} //  Once user is created, Org. cannot be changed.
                      />
                    )}
                  </div>
                </Col>
              </Row>
            )}
            <Row className="mt-3">
              <Col sm={12} md={6} className="row-header-2">
                {Labels.UserManagement.assets}
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <div>
                  <MultiSelect
                    id="assetName"
                    value={selectedAssetList}
                    onChange={handleAssetChange}
                    isDisabled={
                      userData?.id
                        ? false
                        : isExternalUser
                        ? !selectedOrganisation?.value
                        : false
                    }
                    isMulti
                    hasSelectAllOption={true}
                    className="react-multi-select"
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    options={assetList}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6} className="row-header-2">
                {Labels.UserManagement.roles}
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <div>
                  <MultiSelect
                    id="role"
                    isMulti
                    hasSelectAllOption={true}
                    className="react-multi-select"
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    value={selectedRoles}
                    onChange={handleRoleChange}
                    options={rolesList?.data}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="no-gutters">
              <Col sm={12} md={"auto"} className="mr-3">
                <Button
                  color="primary"
                  className="btn btn-primary"
                  type="button"
                  disabled={mutation.isLoading || !isFormValid()}
                  onClick={() => {
                    mutation.mutate();
                  }}
                >
                  {userData.id
                    ? Labels.CommonModals.save
                    : Labels.CommonModals.create}
                </Button>
              </Col>
              <Col sm={12} md={"auto"}>
                <Button
                  color="secondary"
                  onClick={modal.remove}
                  className="btn btn-secondary"
                  type="button"
                >
                  {Labels.CommonModals.cancel}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
);
