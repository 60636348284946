import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getAssetUserConfiguration } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";

export default function useGetAssetUserConfiguration({ assetId, ...options }) {
  const query = useQuery({
    queryKey: [QueryKeys.getAssetUserConfiguration, assetId],
    queryFn: () =>
      httpRequest({
        method: "get",
        url: getAssetUserConfiguration(assetId)
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!assetId,
    select: (data) => {
      return {
        ...data,
        usersCanApprovePO: data.usersCanApprovePO.map((user) => ({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
          value: user.id
        })),
        usersCanReceiveGoods: data.usersCanReceiveGoods.map((user) => ({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
          value: user.id
        })),
        usersCanReviewPO: data.usersCanReviewPO.map((user) => ({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
          value: user.id
        })),
        usersCanVerifySuppliers: data.usersCanVerifySuppliers.map((user) => ({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
          value: user.id
        }))
      };
    },
    ...options
  });

  return query;
}
