// @ts-check
import { nanoid } from "nanoid";
import { useContext } from "react";
import { useQuery } from "react-query";
import QueryKeys from "../../../../../../constants/QueryKeys";
import { getInvoiceOperationalBudgetData } from "../../../../../../utils/http/endpoints";
import { nonBlockingHttpRequest } from "../../../../../../utils/http/nonBlockingHttpRequest";
import { LINE_ITEM_API_ERRORED } from "./actionsTypes";
import InvoiceFormContext from "./InvoiceFormContext";

export default function useOperationalBudgetingData({
  assetId,
  invoiceId,
  budgetLineItemId,
  accountId,
  lineItemId
}) {
  const { dispatch } = useContext(InvoiceFormContext);
  const enabled = !!(assetId && invoiceId && accountId);
  const { data } = useQuery({
    queryKey: [
      QueryKeys.Getinvoiceoperationalbudgetdata,
      { invoiceId, assetId, budgetLineItemId, accountId }
    ],
    queryFn: () =>
      nonBlockingHttpRequest({
        method: "get",
        url: getInvoiceOperationalBudgetData({
          assetId,
          invoiceId,
          budgetLineItemId,
          accountId
        })
      })
        .then((res) => Promise.resolve({ key: nanoid(), data: res.data }))
        .catch((err) => Promise.reject(err.data.errors)),
    enabled,
    onError: (err) =>
      dispatch({
        type: LINE_ITEM_API_ERRORED,
        payload: {
          apiErrors: err,
          lineItemId: lineItemId
        }
      }),
    placeholderData: {
      key: null,
      data: {
        budgetLineItemMTD: {
          incurredAmount: null,
          budgetAmount: null,
          variance: null
        },
        budgetLineItemYTD: {
          incurredAmount: null,
          budgetAmount: null,
          variance: null
        },
        opexBudgetMTD: {
          incurredAmount: null,
          budgetAmount: null,
          variance: null
        },
        opexBudgetYTD: {
          incurredAmount: null,
          budgetAmount: null,
          variance: null
        }
      }
    }
  });

  return data;
}
