import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getApproverLimitRulesets } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

function useGetApproverLimitRulesets(
  assetId,
  options = {},
  isBlocking = false
) {
  const request = isBlocking ? httpRequest : nonBlockingHttpRequest;
  return useQuery({
    queryKey: [QueryKeys.getApproverLimitRulesets, assetId],
    queryFn: () =>
      request({
        method: "get",
        url: getApproverLimitRulesets(assetId)
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!assetId,
    ...options
  });
}
export default useGetApproverLimitRulesets;
