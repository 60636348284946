import React from "react";
import { Labels } from "../../../../../constants/Constants";
import InvoicePaymentsRow from "./InvoicePaymentsRow";

export default function InvoicePaymentsTable({
  payments,
  invoiceDetails,
  handleEditPayment,
  handleViewPayment,
  handleViewPaymentHistory,
  refreshPayments,
  refreshInvoiceData,
  setApiErrors,
  setAreAlertsVisible,
  outStandingPaymentsQuery,
  outStandingWithholdingPaymentsQuery
}) {
  return (
    <table className="table table-borderless">
      <thead>
        <tr>
          <th className="px-0">
            {Labels.Payments.Invoices.Payments.paymentDate}
          </th>
          <th>{Labels.Payments.Invoices.Payments.type}</th>
          <th className="text-center">
            {Labels.Payments.Invoices.Payments.wht}
          </th>
          <th>{Labels.Payments.Invoices.Payments.status}</th>
          <th className="text-right px-0">
            {Labels.Payments.Invoices.Payments.amount}
          </th>
        </tr>
      </thead>
      <tbody>
        {payments !== null &&
          payments.map((payment) => {
            return (
              <InvoicePaymentsRow
                key={payment.id}
                payment={payment}
                invoiceDetails={invoiceDetails}
                handleEditPayment={handleEditPayment}
                handleViewPayment={handleViewPayment}
                handleViewPaymentHistory={handleViewPaymentHistory}
                refreshPayments={refreshPayments}
                setApiErrors={setApiErrors}
                setAreAlertsVisible={setAreAlertsVisible}
                refreshInvoiceData={refreshInvoiceData}
                outStandingPaymentsQuery={outStandingPaymentsQuery}
                outStandingWithholdingPaymentsQuery={
                  outStandingWithholdingPaymentsQuery
                }
              />
            );
          })}
        {payments !== null && payments.length === 0 && (
          <tr>
            <td colSpan="5" className="text-center">
              {Labels.Payments.Invoices.Payments.noPayments}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
