import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getPOAvailableAmount } from "../utils/http/endpoints";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

export default function useGetPOAvailableAmount(assetId, poId, updateState) {
  const query = useQuery({
    queryKey: [QueryKeys.getPOAvailableAmount, +assetId, +poId],
    queryFn: () =>
      nonBlockingHttpRequest({
        method: "get",
        url: getPOAvailableAmount(assetId, poId)
      })
        .then((res) => Promise.resolve(res.data.poAvailableAmount))
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!(assetId && poId),
    onSuccess: (poAvailableAmount) => updateState(poAvailableAmount),
    onError: () => updateState(null)
  });

  return query;
}
