const SalesCreditNotePaymentStatuses = {
  DRAFT: "Draft",
  AWAITING_APPROVAL: "Awaiting Approval",
  AWAITING_PAYMENT: "Awaiting Payment",
  PAYMENT_PROCESSED: "Payment Processed",
  PAID: "Paid",
  VOIDED: "Voided"
};

export const SalesCreditNotePaymentStatusList = Object.entries(
  SalesCreditNotePaymentStatuses
).map(([k, v]) => ({
  value: v,
  label: v
}));

export default SalesCreditNotePaymentStatuses;
