import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import currencyOptionsList from "../utils/currency/currencyMap";
import { getAssetById } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";
import useNavigationContext, {
  navigationContexts
} from "./useNavigationContext";

function useGetAssetById(
  { assetId, isRecentlyViewedBoolean, ...options },
  nonBlocking = false
) {
  const navigationContext = useNavigationContext();
  const isRecentlyViewed =
    isRecentlyViewedBoolean !== undefined
      ? isRecentlyViewedBoolean
      : navigationContext.pageContext === navigationContexts.underAnAsset;

  const request = nonBlocking ? nonBlockingHttpRequest : httpRequest;
  return useQuery({
    queryKey: [QueryKeys.getAssetById, assetId],
    enabled: !!assetId,
    queryFn: () =>
      request({
        method: "get",
        url: getAssetById(assetId, isRecentlyViewed)
      })
        .then((res) => Promise.resolve(res?.data))
        .catch((err) => Promise.reject(err.data.errors)),

    select: (data) => ({
      ...data,
      // Add symbol to the currencies
      currenciesAvailableForUse: currencyOptionsList.filter((currency) =>
        data.currenciesAvailableForUse?.includes(currency?.value)
      )
    }),
    ...options
  });
}

export default useGetAssetById;
