export const SalesInvoiceTypes = {
  SALES_INVOICE: "Sales Invoice",
  SALES_CREDIT_NOTE: "Sales Credit Note",
  CONTRACT_FOR_DIFFERENCE: "Contract for Difference"
};

// For dropdowns
export const SalesInvoiceTypeList = Object.entries(SalesInvoiceTypes).map(
  ([k, v]) => ({ label: v, value: v })
);
