import { useQuery } from "react-query";
import QueryKeys from "../../../../../../constants/QueryKeys";
import {
  getBankPaidInStatementLineItems,
  getPaidOutBankStatementLineItems
} from "../../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../../utils/http/httpRequest";
import processAPIResponse from "../../../../../../utils/utils";

function useGetPaidOutOrInBankStatementLineItems({
  isPaidIn,
  assetId,
  assetBankAccountId,
  ...options
}) {
  return useQuery({
    queryKey: [
      QueryKeys.getPaidOutOrInBankStatementLineItems,
      { isPaidIn, assetId, assetBankAccountId }
    ],
    queryFn: () =>
      processAPIResponse(
        httpRequest({
          method: "get",
          url: (isPaidIn
            ? getPaidOutBankStatementLineItems
            : getBankPaidInStatementLineItems)({
            assetBankAccountId,
            assetId
          })
        })
      ),
    enabled: !!(assetId && assetBankAccountId),
    ...options
  });
}

export default useGetPaidOutOrInBankStatementLineItems;
