import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal, ModalBody, ModalFooter, Button, Col } from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import { useForm } from "react-hook-form";
import {
  getRemittanceEmailTemplate,
  REMITTANCE_EMAIL_TEMPLATE,
  validateRemittanceEmailMessage
} from "./remittance-email-template";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useListState } from "@mantine/hooks";
import { useMutation } from "react-query";
import { httpRequest } from "../../../../utils/http/httpRequest";
import DisplayAlerts from "../../../../elements/DisplayAlerts";
import Auth from "../../../../auth/Auth";
import { Privileges } from "../../../../constants/Privileges";
import { generateRemittance } from "../../../../utils/http/endpoints";
import QueryKeys from "../../../../constants/QueryKeys";

export default NiceModal.create(({ emails = [], isSalesPayment }) => {
  // Use a hook to manage the modal state
  const [emailContacts, emailContactsHandlers] = useListState(emails);

  const modal = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      message: REMITTANCE_EMAIL_TEMPLATE,
      ccme: false
    }
  });

  const handleConfirm = (data) => {
    mutation.mutate(data);
  };

  const mutation = useMutation({
    mutationKey: [QueryKeys.sendRemmittanceEmailStatement],
    mutationFn: (data) => {
      return httpRequest({
        method: "post",
        data: {
          paymentIdList: emailContacts.map((email) => email.paymentId),
          remittanceEmailTemplate: getRemittanceEmailTemplate(data.message)
        },
        url: generateRemittance(isSalesPayment, data.isSendMeEnabled)
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors));
    },
    onSuccess: () => {
      modal.resolve();
      modal.remove();
      mutation.reset();
    }
  });

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      centered
      fade={false}
      isOpen={modal.visible}
      toggle={modal.remove}
      size="lg"
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">{Labels.CommonModals.email}</h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={modal.remove}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="py-0">
        {mutation.isError ? (
          <div>
            <DisplayAlerts
              alerts={mutation.error}
              className="m-0 pt-0"
              type="danger"
              onAlertDismiss={() => mutation.reset()}
              innerClassName="mb-0"
            />
          </div>
        ) : null}
        <div>
          <table className="table table-borderless mb-0">
            <thead>
              <tr>
                <th className="column-header-1 col-5 m-0 p-0 pb-2">
                  {Labels.Payments.AccountsReceivables.contactName}
                </th>
                <th className="column-header-1 pb-2 pl-0">
                  {Labels.Payments.AccountsReceivables.to}
                </th>
                <th className="text-right column-header-1 m-0 pr-0">
                  {Labels.CommonModals.remove}
                </th>
              </tr>
            </thead>
            <tbody>
              {emailContacts.map((email, index) => (
                <tr key={index}>
                  <td className="body-primary color-text-primary m-0 p-0">
                    {email.name}
                  </td>
                  <td className="body-primary color-text-primary m-0 p-0">
                    {email.email}
                  </td>
                  <td className="text-right m-0 p-0">
                    <button
                      disabled={emailContacts.length <= 1}
                      className="btn btn-link m-0 p-0 text-danger body-primary color-text-primary m-0 p-0"
                      onClick={() => emailContactsHandlers.remove(index)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} className="m-0 p-0" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <div className="d-flex justify-content-between align-items-center border-top py-3">
            <Col md={5} className="pl-0">
              <div className="align-self-start">
                <label className="m-0 p-0" htmlFor="email-body">
                  {Labels.CommonModals.message}
                </label>
              </div>
            </Col>
            <Col className="px-0">
              <div>
                <textarea
                  {...register("message", {
                    required: {
                      value: true,
                      message:
                        Labels.Payments.AccountsReceivables.messageIsRequired
                    },
                    validate: (value) => {
                      return validateRemittanceEmailMessage(value)
                        ? true
                        : Labels.Payments.AccountsReceivables
                            .invaildMessageInputError;
                    }
                  })}
                  type="textarea"
                  className={classNames("form-control p-2 m-0", {
                    "is-invalid p-2 m-0": errors.message
                  })}
                  cols="50"
                  rows="10"
                />
                <span className="error-message">
                  {errors.message && errors.message.message}
                </span>
                <div>
                  <label
                    htmlFor="ccme"
                    className="m-0 p-0 mt-2 d-flex align-items-center body-primary color-text-secondary"
                  >
                    <input
                      {...register("isSendMeEnabled", {})}
                      type="checkbox"
                      id="isSendMeEnabled"
                      className="m-0 p-0 mr-1"
                    />
                    {` Send me (${Auth.getCurrentUserName()}) a copy?`}
                  </label>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="py-2">
        <Button
          color="primary"
          onClick={handleSubmit(handleConfirm)}
          disabled={
            !Auth.getUserPrivileges().includes(
              Privileges.GENERATE_REMITTANCE
            ) ||
            !emailContacts.length ||
            mutation.isLoading ||
            errors.message
          }
        >
          {Labels.CommonModals.send}
        </Button>
        <Button color="secondary" onClick={modal.remove}>
          {Labels.CommonModals.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
});
