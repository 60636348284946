import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import Sage from "../../../../../assets/img/Sage.png";
import Sap from "../../../../../assets/img/SAP.png";
import MSDynamics365 from "../../../../../assets/img/MSDynamics365.png";

export default NiceModal.create(() => {
  // Use a hook to manage the modal state
  const modal = useModal();

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      centered
      fade={false}
      isOpen={modal.visible}
      size="lg"
    >
      <ModalHeader toggle={modal.remove}>
        <h2 className="color-dark modal-title">{"Select platform"}</h2>
      </ModalHeader>
      <ModalBody className="mt-0 pt-0">
        <Row className="text-center">
          <Col>
            <img
              className="card-hover-effect"
              width="135"
              height="90"
              alt="Cash"
              src={Sap}
            />
          </Col>
          <Col>
            <img
              className="card-hover-effect"
              width="135"
              height="90"
              alt="Cash"
              src={Sage}
            />
          </Col>
          <Col>
            <img
              className="card-hover-effect"
              width="135"
              height="90"
              alt="Cash"
              src={MSDynamics365}
            />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
});
