import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment";
import { Labels } from "../../../../constants/Constants";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import useGetAssetById from "../../../../hooks/useGetAssetById";
import {
  getFirstMonthOfCurrentFinancialYear,
  getMonthName
} from "../../../../utils/date";

export default NiceModal.create(
  ({
    assetId,
    onSubmit,
    overBudgtedLineItems,
    isAnyLineItemNetAmountChanged,
    isUnderAnAsset
  }) => {
    const modal = useModal();
    const assetQuery = useGetAssetById({
      assetId,
      isRecentlyViewedBoolean: isUnderAnAsset
    });
    const financialYearStartMonth =
      getFirstMonthOfCurrentFinancialYear(
        assetQuery.data?.financialYearEndDate
      ).getMonth() + 1;

    return (
      <Modal
        isOpen={modal.visible}
        toggle={modal.remove}
        backdrop="static"
        keyboard={false}
        size="lg"
        fade={false}
        centered
      >
        <ModalHeader className="pb-0" toggle={modal.remove}>
          <div>
            <h2 className="color-dark modal-title">
              {isAnyLineItemNetAmountChanged
                ? Labels.CommonModals.confirm
                : Labels.Assets.PurchaseOrders.overBudgetedLineItems}
            </h2>
          </div>
        </ModalHeader>
        <ModalBody className="py-2">
          {isAnyLineItemNetAmountChanged && (
            <span class="d-block mb-2">
              {`1. ${Labels.Assets.PurchaseOrders.poNetAmountChangeWarningMessage}`}
            </span>
          )}
          <span>
            {isAnyLineItemNetAmountChanged ? "2. " : ""}
            {Labels.Assets.PurchaseOrders.overBudgetedPopUp.text1}
          </span>
          <ul className="my-2 pl-4">
            {overBudgtedLineItems.map((lineItem, index) => {
              return (
                <li key={index} className="body-primary color-text-secondary">
                  {"Available Budget for "}
                  <em>{lineItem.budgetLineItemDescription}</em>
                  {" for the period "}
                  <em>
                    {getMonthName(financialYearStartMonth)}
                    {" - "}
                    {getMonthName(moment().toDate().getMonth() + 1)}
                  </em>
                  {" is less than Net Amount for the line item "}
                  <em>{lineItem.lineItemDescription}</em>
                  {" in "}
                  <em>{lineItem.invoiceId}</em>.
                </li>
              );
            })}
          </ul>
          <span>{Labels.Assets.PurchaseOrders.overBudgetedPopUp.text2}</span>
        </ModalBody>
        <ModalFooter>
          <Button onClick={modal.remove}>{Labels.CommonModals.no}</Button>
          <Button
            color="primary"
            onClick={() => {
              onSubmit(true);
              modal.remove();
            }}
          >
            {Labels.CommonModals.yes}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
