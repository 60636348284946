import { useState } from "react";

/**
 * Returns current toggle status & a function to toggle it
 * @param {boolean} defaultState
 * @returns object
 */
export default function useToggle(defaultState = false) {
  const [isOpen, setIsOpen] = useState(defaultState);
  const toggle = () => setIsOpen(!isOpen);

  return [isOpen, toggle];
}
