import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getPostingPeriod } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

const useGetPostingPeriod = ({
  assetId,
  instrumentDate,
  module,
  isBlocking = true,
  options = {}
}) => {
  const request = isBlocking ? httpRequest : nonBlockingHttpRequest;
  const query = useQuery({
    queryKey: [QueryKeys.getPostingPeriod, { assetId, instrumentDate, module }],
    queryFn: () =>
      request({
        method: "get",
        url: getPostingPeriod({
          assetId,
          instrumentDate,
          module
        })
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!assetId && !!instrumentDate && !!module,
    ...options
  });
  return query;
};

export default useGetPostingPeriod;
