import React from "react";
import { Modal } from "reactstrap";
import { Labels } from "../constants/Constants";
import "../scss/elements/pageloader/_progressbar.scss";

export default function LoaderWithMessage({ message, isOpen, hideLoader }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={hideLoader}
      centered
      keyboard={false}
      backdrop="static"
      fade={false}
      size="sm"
      className="progress-bar-modal"
    >
      <div className="p-4 progress-bar-main-container">
        {message ? <h1 className="color-dark text-center">{message}</h1> : null}
        <p>{Labels.CommonModals.thisMayTakeAMoment}</p>
        <div className="progress-bar-container mt-5">
          <span className="progress-bar"></span>
        </div>
      </div>
    </Modal>
  );
}
