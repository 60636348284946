import moment from "moment";
import React, { useState } from "react";
import { Labels, Privileges } from "../../../../../constants/Constants";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  Label,
  Alert,
  ModalHeader
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import CustomDatePicker from "../../../../../elements/form-fields/inputs/CustomDatePicker";
import { updateFixedAssetRegister } from "../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertDateToString } from "../../../../../utils/date";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Tooltip from "../../../../../elements/ToolTips/Tooltip";
import Auth from "../../../../../auth/Auth";

export default NiceModal.create(
  ({
    assetId,
    orgId,
    register,
    refetch,
    setIsRegisteredSuccessMsgVisible,
    setIsUpdateSuccessMsgVisible
  }) => {
    const modal = useModal();
    const [description, setDescription] = useState(register.description);
    const [purchaseDate, setPurchaseDate] = useState(register.purchaseDate);
    const [depreciationStartDate, setDepreciationStartDate] = useState(
      register.depreciationStartDate
    );
    const [JournalsReflectFrom, setCreateDepreciationJournalsFromDate] =
      useState(register.createDepreciationJournalsFromDate);
    const [apiErrors, setApiErrors] = useState([]);
    const [isFormFieldTouched, setIsFormFieldTouched] = useState(false);
    const history = useHistory();
    const assetDetails = Auth.getCurrentAsset();

    const descriptionHandler = (event) => {
      setIsFormFieldTouched(true);
      setDescription(event.target.value);
    };

    const handleDepreciationStartDate = (date) => {
      if (date === null) {
        setIsFormFieldTouched(true);
        setDepreciationStartDate(null);
      } else {
        setIsFormFieldTouched(true);
        setDepreciationStartDate(date);
      }
      setCreateDepreciationJournalsFromDate(null);
    };

    const handlePurchaseDate = (date) => {
      if (date === null) {
        setIsFormFieldTouched(false);
        setPurchaseDate(date);
      } else {
        setIsFormFieldTouched(true);
        setPurchaseDate(date);
      }
    };

    const handleDepreciationStartFromDate = (month) => {
      if (month === null) {
        setIsFormFieldTouched(false);
        setCreateDepreciationJournalsFromDate(null);
      } else {
        setIsFormFieldTouched(true);
        setCreateDepreciationJournalsFromDate(month);
      }
    };

    const fixedAssetRegisterMutation = useMutation(
      (data) => {
        return httpRequest({
          method: "put",
          url: updateFixedAssetRegister(register.id),
          data
        })
          .then((res) => Promise.resolve(res.data))
          .catch((err) => Promise.reject(err));
      },
      {
        onSuccess: () => {
          assetId
            ? history.push({
                pathname: `/org/${orgId}/assets/asset/${assetId}/accounting/fixed-asset-register/register`
              })
            : history.push({
                pathname: `/orgs/org/${orgId}/accounting/fixed-asset-register/register`
              });
          refetch();
          register.status === "Draft"
            ? setIsRegisteredSuccessMsgVisible(true)
            : setIsUpdateSuccessMsgVisible(true);
          document.body.scrollIntoView({ behavior: "smooth", block: "start" });
          modal.remove();
        },
        onError: (error) => {
          if (typeof error == "string") {
            setApiErrors([{ errorMessage: error }]);
          } else {
            setApiErrors(error.data.errors);
          }
        }
      }
    );

    const handleRegister = () => {
      let formData = {
        createDepreciationJournalsFromDate: convertDateToString(
          JournalsReflectFrom,
          "YYYY-MM-DD"
        ),
        description: description,
        depreciationStartDate: depreciationStartDate
          ? convertDateToString(depreciationStartDate, "YYYY-MM-DD")
          : null,
        purchaseDate: purchaseDate
          ? convertDateToString(purchaseDate, "YYYY-MM-DD")
          : null,
        status: "REGISTERED"
      };
      fixedAssetRegisterMutation.mutate(formData);
    };

    const isRegisterDisabled = () =>
      !isFormFieldTouched || description?.length === 0 || !purchaseDate;

    return (
      <Modal centered isOpen={modal.visible} backdrop="static" keyboard={false}>
        <ModalHeader className="pb-0" toggle={modal.remove}>
          <div>
            <h2 className="color-dark modal-title">
              {Labels.Organisations.fixedAssetRegister.registerFixedAsset}
            </h2>
          </div>
        </ModalHeader>
        <ModalBody className="pt-0">
          <Alert
            color="danger"
            className="mt-3 mb-0 mx-3"
            isOpen={apiErrors?.length > 0}
            toggle={() => setApiErrors([])}
          >
            <ul className="error-list">
              {apiErrors?.map((error, index) => {
                return <li key={index}>{error.errorMessage}</li>;
              })}
            </ul>
          </Alert>
          <Row className="mt-3">
            <Col sm={12} md={6} className="row-header-2 my-auto">
              {Labels.Organisations.fixedAssetRegister.assetName}
            </Col>
            <Col className="mt-1 mt-md-0 d-flex flex-column">
              <div>
                <Input
                  className="form-control"
                  name="name"
                  type="text"
                  disabled={register.assetName}
                  value={register.assetName}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={6} className="row-header-2 my-auto">
              {Labels.Organisations.fixedAssetRegister.assetClass}
            </Col>
            <Col className="mt-1 mt-md-0 d-flex flex-column">
              <div>
                <Input
                  className="form-control"
                  name="name"
                  type="text"
                  value={register.fixedAssetClassName}
                  disabled={register.fixedAssetClassName}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="label mt-3" md={6}>
              <Label className="label">{Labels.CommonModals.description}</Label>
            </Col>
            <Col className="field-col mt-3">
              <textarea
                placeholder={Labels.CommonModals.description}
                className="form-control"
                rows="5"
                onChange={descriptionHandler}
                maxLength={500}
                defaultValue={register.description}
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="row-header-2 my-auto">
              <Label htmlFor="depreciationStartDate">
                {Labels.Organisations.fixedAssetRegister.depreciationStartDate}{" "}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  id={"depreciationStartDateTooltip"}
                  className="mt-1"
                />
                <Tooltip
                  target={"depreciationStartDateTooltip"}
                  placement="top"
                >
                  {
                    Labels.Organisations.fixedAssetRegister
                      .depreciationStartDateInfoMsg
                  }
                </Tooltip>
              </Label>
            </Col>
            <Col>
              <CustomDatePicker
                id="depreciationStartDate"
                onChange={handleDepreciationStartDate}
                dateFormat="dd/MM/yyyy"
                className="month-ending"
                isClearable={true}
                selected={
                  depreciationStartDate
                    ? moment(depreciationStartDate).toDate()
                    : depreciationStartDate
                }
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="row-header-2 my-auto">
              <Label htmlFor="purchaseDate">
                {Labels.Organisations.fixedAssetRegister.purchaseDate}
              </Label>
            </Col>
            <Col>
              <CustomDatePicker
                id="purchaseDate"
                selected={
                  purchaseDate ? moment(purchaseDate).toDate() : purchaseDate
                }
                onChange={handlePurchaseDate}
                dateFormat="dd/MM/yyyy"
                isClearable={true}
                minDate={
                  !!register.invoiceId
                    ? moment(assetDetails.conversionDate).toDate()
                    : null
                }
                maxDate={!!register.invoiceId ? null : moment().toDate()}
                className="month-ending"
              />
            </Col>
          </Row>
          <Row className="my-3">
            <Col className="row-header-2 my-auto">
              <Label htmlFor="JournalsReflectFrom">
                {Labels.Organisations.fixedAssetRegister.JournalsReflectFrom}{" "}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  id={"JournalsReflectFromTooltip"}
                  className="mt-1"
                />
                <Tooltip target={"JournalsReflectFromTooltip"} placement="top">
                  {
                    Labels.Organisations.fixedAssetRegister
                      .journalsReflectFromInfoMsg
                  }
                </Tooltip>
              </Label>
            </Col>
            <Col>
              <CustomDatePicker
                id="JournalsReflectFrom"
                selected={
                  JournalsReflectFrom
                    ? moment(JournalsReflectFrom).toDate()
                    : JournalsReflectFrom
                }
                onChange={handleDepreciationStartFromDate}
                selectsStart
                isClearable={true}
                minDate={
                  !!depreciationStartDate
                    ? moment([
                        moment(depreciationStartDate).year(),
                        moment(depreciationStartDate).month(),
                        1
                      ]).toDate()
                    : depreciationStartDate
                }
                dateFormat="MM/yyyy"
                className="month-ending"
                showMonthYearPicker
                disabled={!depreciationStartDate}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={6} className="row-header-2 my-auto">
              {Labels.Organisations.fixedAssetRegister.cost}
            </Col>
            <Col className="mt-1 mt-md-0 d-flex flex-column">
              <div>
                <Input
                  className="form-control"
                  name="cost"
                  type="text"
                  defaultValue={register.cost}
                  disabled={register.cost}
                />
              </div>
            </Col>
          </Row>
          {register.status === "Registered" && (
            <div className="mt-3">
              <p>
                <span className="row-header-2">{Labels.CommonModals.note}</span>
                {Labels.Organisations.fixedAssetRegister.editNote}
              </p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Row className="no-gutters">
            <Col sm={12} md="auto" className="mr-2">
              {Auth.getUserPrivileges().includes(
                Privileges.UPDATE_FIXED_ASSET_REGISTER
              ) && (
                <Button
                  color="primary"
                  className="btn btn-primary"
                  type="button"
                  onClick={handleRegister}
                  disabled={isRegisterDisabled()}
                >
                  {register.status === "Registered"
                    ? Labels.Organisations.fixedAssetRegister.update
                    : Labels.Organisations.fixedAssetRegister.register}
                </Button>
              )}
            </Col>
            <Col sm={12} md="auto">
              <Button
                color="secondary"
                onClick={modal.remove}
                className="btn btn-secondary"
                type="button"
              >
                {Labels.Organisations.fixedAssetRegister.cancel}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
);
