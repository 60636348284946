import React, { useState } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  ModalHeader,
  Label
} from "reactstrap";

import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { SelectBox } from "../../../../../elements/select/Select";

import { Labels } from "../../../../../constants/Constants";
import moment, { isDate } from "moment";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import { createOrUpdateTask } from "../../../../../utils/http/endpoints";
import useGetUsersUnderAsset from "../../../../../hooks/useGetUsersUnderAsset";
import { TasksTypeList } from "../../../../../constants/TasksTypes";
import { MultiSelect } from "../../../../../elements/multiselect/MultiSelect";
import DragAndDropFileUpload from "../../../../../elements/file-upload/DragAndDropFileUpload";
import DisplayAlerts from "../../../../../elements/DisplayAlerts";
import CustomDatePicker from "../../../../../elements/form-fields/inputs/CustomDatePicker";

export default NiceModal.create(
  ({ params, setIsCreateEventSuccessMsgVisible }) => {
    const modal = useModal();
    const defaultFormData = {
      name: "",
      description: "",
      type: { label: "Accounting Risk", value: "ACCOUNTING_RISK" },
      userIds: [],
      dueDate: ""
    };
    const [error, setError] = useState([]);
    const [isError, setIsError] = useState(false);
    const [formData, setFormData] = useState({ ...defaultFormData });
    const [documents, setDocuments] = useState([]);
    const { assetId } = params;

    const {
      data: assignedToList,
      isLoading: assignedToListLoading,
      isError: isAssignedToListError,
      error: assignedToListError
    } = useGetUsersUnderAsset(assetId, true);

    const handleFormFieldChange = (fieldName, value) => {
      setFormData({ ...formData, [fieldName]: value });
    };

    const clearError = () => {
      setIsError(false);
      setError([]);
    };

    const preventDefault = (event) => {
      event.preventDefault();
    };

    const uploadOptions = {
      minSize: 0,
      maxSize: 10485760, // 10 MB
      multiple: true
    };

    const onFilesSelection = (files) => {
      setDocuments(files);
    };

    const errorCodeDesc = {
      "file-too-large": Labels.CommonModals.attachmentSizeError
    };

    const onError = (errorList) => {
      const errors = {};
      errorList.forEach((errorObj) => {
        errorObj.errors.forEach((error) => {
          errors[error.code] = {
            errorMessage: errorCodeDesc[error.code] || error.message
          };
        });
      });
      setIsError(true);
      setError([...Object.values(errors)]);
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const onSubmit = (data) => {
      const postData = data;
      const formData = new FormData();
      formData.append("version", 1);
      formData.append("isRepeat", false);

      if (documents.length) {
        documents.forEach((doc, i) => {
          formData.append(`documents[${i}]`, doc);
        });
      }
      Object.entries(postData).forEach(([k, v]) => {
        if (isDate(v)) {
          formData.append(k, moment(v).format("YYYY-MM-DD"));
        } else if (k === "type") {
          formData.append("type", postData.type.value);
        } else if (k === "userIds" && !!postData.userIds) {
          formData.append(
            "userIds",
            postData.userIds?.map((user) => user.id)
          );
        } else if (v && k !== "asset") {
          formData.append(k, v);
        }
      });

      httpRequest({
        method: "post",
        url: createOrUpdateTask(assetId),
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          setIsCreateEventSuccessMsgVisible(true);
          modal.remove();
        })
        .catch((err) => {
          setIsError(true);
          setError(err.data?.errors);
        });
    };

    const canCreateButtonDisabled = !formData.name || !formData.type.value;

    return (
      <div>
        <Modal
          centered
          isOpen={modal.visible}
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader className="mb-0 pb-0" toggle={modal.remove}>
            <div>
              <h2 className="color-dark modal-title">
                {Labels.Checks.createEvent}
              </h2>
            </div>
          </ModalHeader>

          <ModalBody className="pt-0 mt-2 px-3">
            {isError || isAssignedToListError ? (
              <DisplayAlerts
                className="mx-3 mt-3"
                innerClassName="mb-0"
                alerts={isError ? error : assignedToListError}
                type="danger"
                onAlertDismiss={!isAssignedToListError ? clearError : null}
              />
            ) : null}

            <Row className="mb-3 mx-0">
              <Col className="px-0">
                <Row className="py-2 px-0 mx-0">
                  <Col className="px-0">
                    <Label for="name">{`${Labels.Tasks.name}*`}</Label>
                  </Col>
                  <Col className="px-0">
                    <Input
                      id="name"
                      placeholder={Labels.Checks.eventName}
                      onChange={(event) =>
                        handleFormFieldChange("name", event.target.value)
                      }
                      type="text"
                      value={formData.name}
                      onBlur={preventDefault}
                    />
                  </Col>
                </Row>

                <Row className="pt-2 px-0 mx-0">
                  <Col className="px-0">
                    <Label className="m-0">{Labels.Tasks.description}</Label>
                  </Col>
                  <Col className="px-0">
                    <textarea
                      name="description"
                      onChange={(event) =>
                        handleFormFieldChange("description", event.target.value)
                      }
                      maxLength="255"
                      type="text"
                      placeholder={Labels.Tasks.enterDescription}
                      rows={4}
                      className="mb-1"
                      onBlur={preventDefault}
                    />
                  </Col>
                </Row>
                <Row className="py-2 px-0 align-items-center mx-0">
                  <Col className="px-0">
                    <Label className="m-0">{`${Labels.Tasks.type}*`}</Label>
                  </Col>
                  <Col className="px-0">
                    <SelectBox
                      hideSelectedOptions={false}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      value={formData.type}
                      options={TasksTypeList}
                      onChange={(val) => handleFormFieldChange("type", val)}
                      placeholder={Labels.Tasks.type}
                    />
                  </Col>
                </Row>

                {!assignedToListLoading ? (
                  <Row className="py-2 px-0 align-items-center mx-0">
                    <Col className="px-0">
                      <Label className="m-0">{Labels.Tasks.assignedTo}</Label>
                    </Col>
                    <Col className="px-0">
                      <MultiSelect
                        isMulti
                        value={formData.userIds}
                        onChange={(val) =>
                          handleFormFieldChange("userIds", val)
                        }
                        isDisabled={isAssignedToListError}
                        className="react-multi-select"
                        optionsLabel={Labels.Tasks.assignee}
                        isLoading={assignedToListLoading}
                        hasSelectAllOption={true}
                        options={assignedToList || []}
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                      />
                    </Col>
                  </Row>
                ) : null}
                <Row className="py-2 px-0 align-items-center mx-0">
                  <Col className="px-0">
                    <Label className="m-0">{Labels.Tasks.dueDate}</Label>
                  </Col>
                  <Col className="px-0">
                    <CustomDatePicker
                      selected={formData.dueDate}
                      onChange={(val) => handleFormFieldChange("dueDate", val)}
                      dateFormat="dd/MM/yyyy"
                      placeholderText={Labels.Tasks.dueDate}
                    />
                  </Col>
                </Row>

                <Row className="pt-2 px-0 align-items-center mx-0">
                  <Col sm={12} className="px-0">
                    <Label>{Labels.Tasks.attachments}</Label>
                  </Col>
                  <Col sm={12} className="px-0">
                    <DragAndDropFileUpload
                      label={Labels.CommonModals.uploadFileLabel}
                      button={true}
                      onFileSelection={onFilesSelection}
                      onError={onError}
                      uploadConfig={uploadOptions}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="no-gutters">
              <Col sm={12} md className="mr-3">
                <Button
                  color="primary"
                  className="btn btn-primary"
                  type="button"
                  disabled={canCreateButtonDisabled}
                  onClick={() => onSubmit(formData)}
                >
                  {Labels.Tasks.create}
                </Button>
              </Col>
              <Col sm={12} md>
                <Button
                  color="secondary"
                  onClick={modal.remove}
                  className="btn btn-secondary"
                  type="button"
                >
                  {Labels.Tasks.cancel}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
);
