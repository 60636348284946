import React, { useState } from "react";
import DetailsSection from "./DetailsSection";
import OperationalOperationalForm from "./operational-operational/InvoiceForm";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import { putInvoiceEnrichmentData } from "../../../../../utils/http/endpoints";
import InvoiceStatuses from "../../../../../constants/InvoiceStatuses";
import { useQueryClient } from "react-query";
import QueryKeys from "../../../../../constants/QueryKeys";
import { useParams } from "react-router-dom";
import { Labels } from "../../../../../constants/Constants";

export default function InvoiceDetails({
  assetDetails,
  invoiceDetails,
  setIsFormChanged,
  isFormReadOnly,
  isFormChanged,
  setIsFormReadOnly,
  refreshInvoiceData,
  payments,
  currency,
  isLineItemCardHasErrors,
  postingPeriodQuery
}) {
  const [invoiceSavedMessage, setInvoiceSavedMessage] = useState("");
  const queryClient = useQueryClient();
  const params = useParams();

  const saveInvoiceForm = (
    formData,
    type,
    setSuccessMsgVisible,
    setFormErrors,
    action
  ) => {
    setSuccessMsgVisible(false);
    setFormErrors([]);

    httpRequest({
      method: "put",
      url: putInvoiceEnrichmentData(assetDetails.id, invoiceDetails.id, type),
      data: formData
    })
      .then((res) => {
        setIsFormChanged(false);
        queryClient.setQueryData([
          QueryKeys.INVOICE_DETAILS,
          params.assetId,
          params.invoiceId
        ]);
        setSuccessMsgVisible(true);
        if (action === InvoiceStatuses.DRAFT) {
          setInvoiceSavedMessage(
            Labels.Payments.Invoices.Details.InvoiceSavedMessage
          );
        } else if (action === InvoiceStatuses.AWAITING_APPROVAL) {
          setInvoiceSavedMessage(
            Labels.Payments.Invoices.Details.InvoiceSavedSendForApprovalMessage
          );
        } else if (action === InvoiceStatuses.AWAITING_PAYMENT) {
          setInvoiceSavedMessage(
            Labels.Payments.Invoices.Details.InvoiceSavedAndApprovedMessage
          );
        }
        refreshInvoiceData();
      })
      .catch((e) => {
        setFormErrors(e.data.errors);
      });
  };

  return (
    <div id="invoice-details-tab">
      <DetailsSection
        assetDetails={assetDetails}
        invoiceDetails={invoiceDetails}
        currency={currency}
        postingPeriodQuery={postingPeriodQuery}
      />
      <div className="mt-3">
        <OperationalOperationalForm
          invoiceDetails={invoiceDetails}
          assetDetails={assetDetails}
          setIsFormChanged={setIsFormChanged}
          isFormChanged={isFormChanged}
          currency={currency}
          saveInvoiceForm={saveInvoiceForm}
          isFormReadOnly={isFormReadOnly}
          invoiceSavedMessage={invoiceSavedMessage}
          payments={payments}
          setIsFormReadOnly={setIsFormReadOnly}
          refreshInvoiceData={refreshInvoiceData}
          isLineItemCardHasErrors={isLineItemCardHasErrors}
        />
      </div>
    </div>
  );
}
