export const depreciationMethodLabels = {
  straightLineRate: "Straight Line - Rate(%)",
  straightLineEffectiveYrs: "Straight Line - Effective Life(Months)",
  reducingBalanceRate: "Reducing Balance - Rate(%)"
};

export const depreciationMethodValues = {
  straightLineRate: "STRAIGHT_LINE_RATE",
  straightLineEffectiveYrs: "STRAIGHT_LINE_EFFECTIVE_LIFE",
  reducingBalanceRate: "REDUCING_BALANCE_RATE"
};

export const depreciationMethodOptions = [
  {
    label: depreciationMethodLabels.straightLineRate,
    value: depreciationMethodValues.straightLineRate,
    inputLabel: "Rate(%)"
  },
  {
    label: depreciationMethodLabels.straightLineEffectiveYrs,
    value: depreciationMethodValues.straightLineEffectiveYrs,
    inputLabel: "Effective Life(Months)"
  },
  {
    label: depreciationMethodLabels.reducingBalanceRate,
    value: depreciationMethodValues.reducingBalanceRate,
    inputLabel: "Rate(%)"
  }
];
