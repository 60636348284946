/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { Labels } from "../../../../../constants/Constants";
import useCustomForm from "../../../../../elements/form-fields/useCustomForm";
import { Alert, Button, Col, Row } from "reactstrap";
import { useFieldArray } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import QueryKeys from "../../../../../constants/QueryKeys";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import {
  getMappingDetailsForAllocations,
  getSaveAllocationsUrl
} from "../../../../../utils/http/endpoints";
import { useParams } from "react-router-dom";
import { formatAmount } from "../../../../../utils/number";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import { FormInputWarning } from "../../../../../elements/Error/FormInputWarning";
import { PurchaseInvoiceTypesEnum } from "../../../../../constants/PurchaseInvoiceTypes";

export default function AllocateToInvoiceOrCreditNote({
  type,
  onGoBack,
  invoiceDetails,
  refreshInvoiceData
}) {
  const [apiErrors, setApiErrors] = useState([]);
  const [totalDueAmt, setTotalDueAmt] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const isInvoice = type === PurchaseInvoiceTypesEnum.PURCHASE_INVOICE;
  const labels = isInvoice
    ? Labels.Payments.Allocations.Invoice
    : Labels.Payments.Allocations.CreditNote;
  const params = useParams();
  const {
    handleSubmit,
    control,
    register,
    getValues,
    watch,
    reset,
    trigger,
    formState,
    shouldSubmitBtnBeDisabled
  } = useCustomForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });
  const getFormattedAmount = (amt) =>
    formatAmount(
      amt,
      getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)
    );
  const queryClient = useQueryClient();

  const setTotal = () => {
    const formData = getValues();
    let amt = 0;
    (formData.invoiceAllocationDataList || []).forEach((invoice) => {
      if (!!selectedInvoices[invoice.associatedInvoiceId]) {
        amt += invoice.allocationAmount || 0;
      }
    });
    setTotalAmount(amt);
  };

  const { fields } = useFieldArray({
    control,
    name: "invoiceAllocationDataList",
    shouldUnregister: true,
    keyName: "allocations"
  });

  const isValidAllocationAmount = (val, idx, rec) => {
    return (
      (!!selectedInvoices[rec.associatedInvoiceId]
        ? val > rec.availableAmount
          ? false
          : true
        : true) ||
      `${
        Labels.Payments.Allocations.Validations.maxAllocationAmount +
        " " +
        rec.availableAmount
      }`
    );
  };

  const allocationMutation = useMutation((data) => {
    setApiErrors([]);
    httpRequest({
      method: "post",
      url: getSaveAllocationsUrl(params?.assetId, params?.invoiceId),
      data: data
    })
      .then((res) => {
        onGoBack();
        queryClient.invalidateQueries([QueryKeys.INVOICE_DETAILS]);
        refreshInvoiceData();
      })
      .catch((err) => {
        err.data
          ? setApiErrors(err.data.errors)
          : setApiErrors([{ errorMessage: err }]);
        document.body.scrollIntoView({ behavior: "smooth", block: "start" });
      });
  });

  const getAvailableAmount = (rec, idx) => {
    const availableAmount =
      rec.availableAmount -
      (getValues().invoiceAllocationDataList[idx].allocationAmount || 0);
    return availableAmount;
  };

  const goBack = (event) => {
    event.preventDefault();
    onGoBack();
  };

  const onSubmit = (data) => {
    if (totalAmount > 0) {
      data.invoiceAllocationDataList = data?.invoiceAllocationDataList?.filter(
        (allocation) => {
          if (!!selectedInvoices[allocation.associatedInvoiceId]) {
            allocation.invoiceId = invoiceDetails.id;
            allocation.invoiceNumber = invoiceDetails.invoiceNumber;
            return true;
          }
          return false;
        }
      );
      data.totalAllocationAmount = totalAmount;
      allocationMutation.mutate(data);
    }
  };

  useQuery({
    queryKey: [QueryKeys.getAllocationRecords, type],
    queryFn: () => {
      return httpRequest({
        method: "get",
        url: getMappingDetailsForAllocations(
          params?.assetId,
          params?.invoiceId,
          invoiceDetails?.supplierId
        )
      }).then((res) => {
        setTotalDueAmt(res.data.totalAvailableAmount);
        reset(res?.data);
      });
    }
  });

  const onAllocationSelection = (event, invoiceId) => {
    if (event.target.checked) {
      selectedInvoices[invoiceId] = invoiceId;
    } else {
      delete selectedInvoices[invoiceId];
    }
    trigger();
    setSelectedInvoices(selectedInvoices);
    setTotal();
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setTotal();
      trigger();
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div className="allocations">
      <div className="clearfix border-bottom-fv-border-2px">
        <div className="float-left">
          <h3 className="color-dark">{labels.allocateTo}</h3>
        </div>
        <div className="float-right">
          <div className="create-payment">
            <a href="#" className="back-link" onClick={goBack}>
              &nbsp;{labels.backTo}
            </a>
          </div>
        </div>
      </div>
      <div className="form-container-default allocations-form mb-0">
        {apiErrors.length > 0 ? (
          <div className="my-2">
            <Alert color="danger" toggle={() => setApiErrors([])}>
              <ul className="error-list">
                {apiErrors.map((error, index) => {
                  return <li key={index}>{error.errorMessage}</li>;
                })}
              </ul>
            </Alert>
          </div>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)} name="allocationsForm">
          <Row className="line-items">
            <Col>
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th>{labels.entityToAllocateTo}</th>
                    <th>{Labels.Payments.Allocations.Headers.allocationAmt}</th>
                    <th>{Labels.Payments.Allocations.Headers.availableAmt}</th>
                  </tr>
                </thead>
                <tbody>
                  {fields?.length > 0 ? (
                    fields?.map((rec, idx) => {
                      const availabAmt = getAvailableAmount(rec, idx);
                      return (
                        <tr key={rec.associatedInvoiceId}>
                          <td width="40%">
                            <div className="d-flex flex-row">
                              <input
                                type="checkbox"
                                className="mr-2"
                                id={`invoiceAllocationDataList.${idx}.invoiceId`}
                                value={rec.associatedInvoiceId}
                                onChange={(event) =>
                                  onAllocationSelection(
                                    event,
                                    rec.associatedInvoiceId
                                  )
                                }
                              />
                              <label
                                className="font-weight-normal mb-0"
                                htmlFor={`invoiceAllocationDataList.${idx}.invoiceId`}
                              >
                                {rec.associatedInvoiceNumber}
                              </label>
                            </div>
                          </td>
                          <td width="30%">
                            <input
                              type="number"
                              step="0.01"
                              className="form-control w-100"
                              {...register(
                                `invoiceAllocationDataList.${idx}.allocationAmount`,
                                {
                                  min: 0,
                                  validate: (val) =>
                                    isValidAllocationAmount(val, idx, rec),
                                  valueAsNumber: true
                                }
                              )}
                            />
                          </td>
                          <td width="30%">
                            <span className={availabAmt < 0 ? "redText" : ""}>
                              {getFormattedAmount(availabAmt)}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="3"> {Labels.CommonModals.noRecordsFound}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row className="allocated-amount-row pb-0">
            <Col>
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td className="text-right py-0" width="40%">
                      <h3 className="fv-dark">
                        {Labels.Payments.Allocations.allocatedAmt}
                      </h3>
                    </td>
                    <td colSpan="2" className="py-0">
                      <h3 className="fv-dark">
                        {getFormattedAmount(totalAmount)}
                      </h3>
                      <input
                        type="hidden"
                        {...register("totalAvailableAmount")}
                      />
                      <span
                        className={`${
                          totalAmount > totalDueAmt ? "redText" : "warning-txt"
                        }`}
                      >
                        {Labels.Payments.Allocations.maxAmountAllowedWarning}{" "}
                        {getFormattedAmount(totalDueAmt)}
                      </span>
                      <div className="redText">
                        {formState.submitCount > 0 && totalAmount <= 0
                          ? `Please allocate atleast one ${labels.entityToAllocateTo}`
                          : ""}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col className="text-right mt-2">
              <Button
                color="primary"
                disabled={
                  shouldSubmitBtnBeDisabled() ||
                  (formState.submitCount > 0 && totalAmount <= 0) ||
                  !formState.isDirty ||
                  totalAmount > totalDueAmt
                }
              >
                {Labels.CommonModals.save}
              </Button>
              <Button
                color="secondary"
                type="button"
                className="ml-2"
                onClick={goBack}
              >
                {Labels.CommonModals.cancel}
              </Button>
            </Col>
          </Row>
        </form>
        <FormInputWarning formState={formState} />
      </div>
    </div>
  );
}
