import React, { useRef } from "react";
import { Alert, Modal, ModalBody, Button } from "reactstrap";
import { Labels } from "../../../../../../constants/Constants";
import { SelectBox } from "../../../../../../elements/select/Select";
import { HotColumn, HotTable } from "@handsontable/react";
import { getJournalLink } from "./JournalManagement";
import { formatAmount } from "../../../../../../utils/number";
import { getSymbolFromCurrencyCode } from "../../../../../../utils/currency/currency";
import { getDateInDisplayFormat } from "../../../../../../utils/date";
import { useMemo } from "react";
import classNames from "classnames";
import moment, { isDate } from "moment";
import { useElementSize } from "@mantine/hooks";
import Tooltip from "../../../../../../elements/ToolTips/Tooltip";

export default function AccrualAmountDrilldown({
  assetDetails,
  invoiceDetails,
  getYearOptions,
  selectedYear,
  setSelectedYear,
  accruedAmountsQuery,
  isOpen,
  toggle
}) {
  const tableRef = useRef(null);

  const exportCSVHandler = () => {
    // File Name: Cost Accruals of [BLI-GLCode-GLName - as on[selected period] for [AssetName]
    const exportPlugin = tableRef.current.hotInstance.getPlugin("exportFile");
    exportPlugin.downloadFile("csv", {
      fileExtension: "csv",
      filename: `Cost Accruals of ${
        accruedAmountsQuery.data.budgetLineItemDescription
          ? ` ${accruedAmountsQuery.data.budgetLineItemDescription} - ${accruedAmountsQuery.data.glCode} - ${accruedAmountsQuery.data.glName}`
          : ` ${accruedAmountsQuery.data.glCode} - ${accruedAmountsQuery.data.glName}`
      } - as on ${selectedYear.label} for ${assetDetails.displayName}`,
      mimeType: "text/csv",
      rowDelimiter: "\r\n",
      columnHeaders: true
    });
  };

  const tableData = useMemo(() => {
    const data =
      accruedAmountsQuery?.data?.accruedJournalLineItemDataList?.map(
        (journal) => {
          const modifiedJournalDate = Object.defineProperty(
            moment(journal.journalDate).toDate(),
            "toString",
            {
              value: function () {
                return getDateInDisplayFormat(this);
              },
              writable: false
            }
          );
          return {
            ...journal,
            journalURL: getJournalLink({
              journalId: journal.journalId,
              assetId: assetDetails.id
            }),
            journalId: journal.journalId,
            journalDate: modifiedJournalDate,
            journalLineItemDescription: journal.journalLineItemDescription,
            amount: journal.amount
          };
        }
      ) || [];

    data.push({
      journalDate: {
        value: Labels.CommonModals.total,
        className: "fw-600",
        toValue: () => Labels.CommonModals.total,
        toString: () => Labels.CommonModals.total
      },

      amount: {
        value: accruedAmountsQuery.data?.totalBalanceTillFinancialYear,
        className: "fw-600 pl-1",
        toValue: () => accruedAmountsQuery.data?.totalBalanceTillFinancialYear,
        toString: function () {
          return String(
            accruedAmountsQuery.data?.totalBalanceTillFinancialYear
          );
        }
      }
    });

    return data;
  }, [
    accruedAmountsQuery.data?.accruedJournalLineItemDataList,
    accruedAmountsQuery.data?.totalBalanceTillFinancialYear,
    assetDetails.id
  ]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        keyboard={false}
        fade={false}
        size="xl"
        centered
      >
        {accruedAmountsQuery.data ? (
          <ModalBody className="pt-0">
            <>
              <div className="mb-5">
                <h3 className="color-dark text-center my-3">
                  Cost Accruals of
                  {accruedAmountsQuery.data.budgetLineItemDescription
                    ? ` ${accruedAmountsQuery.data.budgetLineItemDescription} - ${accruedAmountsQuery.data.glCode} - ${accruedAmountsQuery.data.glName}`
                    : ` ${accruedAmountsQuery.data.glCode} - ${accruedAmountsQuery.data.glName}`}
                  {" - as on "}
                  {selectedYear?.label}
                </h3>

                <button
                  type="button"
                  className="close close-drilldown"
                  aria-label="Close"
                  onClick={toggle}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="d-flex align-items-center justify-content-end my-3">
                <div style={{ width: "12rem" }}>
                  <SelectBox
                    className="z-index-1030"
                    options={getYearOptions()}
                    value={selectedYear}
                    onChange={(v) => setSelectedYear(v)}
                    aria-label="accrual-amount-financial-year-select"
                  />
                </div>
                <div className="ml-3">
                  <Button
                    color="primary"
                    onClick={exportCSVHandler}
                    type="button"
                    disabled={
                      !accruedAmountsQuery.data?.accruedJournalLineItemDataList
                        ?.length
                    }
                    className="btn btn-primary"
                  >
                    {Labels.CommonModals.export}
                  </Button>
                </div>
              </div>

              <div className="accrual-drilldown-table-container mb-3">
                {accruedAmountsQuery.data.accruedJournalLineItemDataList
                  .length > 0 ? (
                  <div>
                    <DrilldownTable
                      tableRef={tableRef}
                      currencySymbol={getSymbolFromCurrencyCode(
                        invoiceDetails.assetCurrency
                      )}
                      data={tableData}
                    />
                  </div>
                ) : (
                  <div className="mt-3">
                    <Alert color="info">
                      {Labels.CommonModals.noDataFound}
                    </Alert>
                  </div>
                )}
              </div>
            </>
          </ModalBody>
        ) : null}
      </Modal>
    </div>
  );
}

const readOnlySetting = {
  readOnly: true
};

const dataSchema = {
  // journalId: null,
  journalDate: null,
  journalLineItemDescription: null,
  amount: null
};

function handleAfterChange() {
  this.render();
}

function handleAfterFilter() {
  this.render();
}

function DrilldownTable({ data = [], tableRef, currencySymbol }) {
  const { ref, width } = useElementSize();

  return (
    <div ref={ref}>
      <HotTable
        data={data}
        dataSchema={dataSchema}
        className="accrual-drilldown-table"
        height="auto"
        fillHandle={false}
        // stretchH="last"
        colWidths={100}
        disableVisualSelection={false}
        manualColumnResize={false}
        filters={true}
        // columnSorting
        ref={tableRef}
        afterChange={handleAfterChange}
        afterFilter={handleAfterFilter}
        licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
        width="100%"
        multiColumnSorting={true}
        dropdownMenu={[
          "---------",
          "filter_by_condition",
          "filter_by_value",
          "filter_action_bar"
        ]}
      >
        <HotColumn
          title={Labels.Payments.Invoices.Details.journalDate}
          data="journalDate"
          settings={readOnlySetting}
          width={120}
          type="date"
        >
          <DateCell hot-renderer />
        </HotColumn>
        <HotColumn
          title={Labels.Payments.Invoices.Details.journalLineItemDescription}
          data="journalLineItemDescription"
          settings={readOnlySetting}
          width={width - 240}
        >
          <DescriptionCell hot-renderer />
        </HotColumn>
        <HotColumn
          title={`${Labels.Payments.Invoices.Details.amount} (${currencySymbol})`}
          data="amount"
          settings={readOnlySetting}
          width={120}
        >
          <AmountLinkCell hot-renderer />
        </HotColumn>
      </HotTable>
    </div>
  );
}

function DescriptionCell(props) {
  return (
    <>
      <span id={props.prop + "-" + props.row} className="text-truncate">
        {props.value}
      </span>
      <Tooltip target={props.prop + "-" + props.row}>{props.value}</Tooltip>
    </>
  );
}

function AmountLinkCell(props) {
  const rowData = props.cellProperties.instance.getSourceDataAtRow(
    props.cellProperties.instance.toPhysicalRow(props.row)
  );

  if (typeof props.value === "object") {
    return (
      <span className={classNames(props.value.className)}>
        {formatAmount(props.value.value)}
      </span>
    );
  }

  return (
    <a
      href={rowData.journalURL}
      target={"_blank"}
      rel="noopener noreferrer"
      className="text-right text-underline"
    >
      {formatAmount(props.value)}
    </a>
  );
}

function DateCell(props) {
  return (
    <>
      {!isDate(props.value) ? (
        <span className={classNames(props.value.className)}>
          {props.value.value}
        </span>
      ) : (
        getDateInDisplayFormat(props.value)
      )}
    </>
  );
}
