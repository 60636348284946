import Handsontable from "handsontable";
import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal, ModalBody, Alert, ModalHeader } from "reactstrap";
import { HotColumn, HotTable } from "@handsontable/react";
import { Labels } from "../../../../../constants/Constants";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../utils/number";

export default NiceModal.create(
  ({ tableData, currency, openingBalance, isOpeningBalanceVisible }) => {
    const modal = useModal();

    const tableSchema = {
      id: null,
      type: null,
      date: null,
      description: null,
      glName: null,
      amount: null
    };

    const formatAmounts = (value, handleNegativeAmounts) => {
      if (typeof value === "number") {
        return formatAmountWithThousandSeparatorsToPrecision(
          value,
          handleNegativeAmounts
        );
      } else {
        return value;
      }
    };

    function numericValueRenderer(
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties
    ) {
      Handsontable.renderers.NumericRenderer.apply(this, arguments);
      td.innerHTML = formatAmounts(value, true);
    }
    Handsontable.renderers.registerRenderer(
      "numericValueRenderer",
      numericValueRenderer
    );

    function cellRenderer(row, col) {
      const cellProperties = {};

      if (["amount"].includes(this.instance.colToProp(col))) {
        cellProperties.renderer = "numericValueRenderer";
      }

      return cellProperties;
    }

    function handleAfterGetColHeader(col, TH) {
      if (["Net Amount"].includes(TH.textContent)) {
        TH.className = "text-right";
      }
    }

    return (
      <Modal
        isOpen={modal.visible}
        size="xl"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader className="pb-0" toggle={modal.remove}>
          <div>
            <h2 className="color-dark modal-title">
              {Labels.Checks.totalActualRevenue}
            </h2>
          </div>
        </ModalHeader>
        <ModalBody className="pb-2 pt-0 purchase-order-budget-table">
          {isOpeningBalanceVisible ? (
            <div className="text-right mb-2">
              <span className="color-text-primary fw-600">
                {Labels.Insights.Projects.Contingency.openingBalance}:&nbsp;
              </span>
              <span className="my-auto column-header-2">
                {currency}
                {formatAmountWithThousandSeparatorsToPrecision(openingBalance)}
              </span>
            </div>
          ) : null}
          <div className="pb-3">
            <HotTable
              data={tableData}
              dataSchema={tableSchema}
              height={"auto"}
              stretchH={"all"}
              fillHandle={false}
              autoRowSize={false}
              cells={cellRenderer}
              autoColumnSize={false}
              manualColumnResize={false}
              disableVisualSelection={true}
              afterGetColHeader={handleAfterGetColHeader}
              licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
            >
              <HotColumn
                title={Labels.Checks.transactionId}
                data="id"
                readOnly={true}
                width={100}
              />

              <HotColumn
                title={Labels.Checks.date}
                data="type"
                readOnly={true}
                width={180}
              />

              <HotColumn
                title={Labels.Checks.postingDate}
                data="date"
                readOnly={true}
                width={80}
              />

              <HotColumn
                title={Labels.Checks.description}
                data="description"
                readOnly={true}
                width={180}
              />
              <HotColumn
                title={Labels.Checks.glName}
                data="glName"
                readOnly={true}
                width={180}
              />

              <HotColumn
                title={Labels.Checks.netAmount}
                data="amount"
                readOnly={true}
                width={130}
              />
            </HotTable>
          </div>

          <Alert color="info" className="mt-2" isOpen={tableData.length === 0}>
            {Labels.Checks.noDetailsAvailable}
          </Alert>
        </ModalBody>
      </Modal>
    );
  }
);
