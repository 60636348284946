import React, { useState, useEffect } from "react";
import { HashRouter } from "react-router-dom";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import Loader from "./views/Loader/Loader";
import "./App.scss";
import { ReactQueryDevtools } from "react-query/devtools";
import usePageLoader from "./hooks/usePageLoader";
import { useSelector } from "react-redux";
import ScrollTo from "./elements/ScrollTo";
import { registerAllModules } from "handsontable/registry";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import Auth from "./auth/Auth";
import "./elements/modals/modal-register";
import NiceModal from "@ebay/nice-modal-react";
import { getAuthToken } from "./utils/http/endpoints";
import { lazyWithRetry } from "./utils/lazyWithRetry";

Bugsnag.start({
  apiKey: "e3289ec179f7cfb98461efd665ed6e82",
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    console.log("🚀 ~ file: App.js ~ line 25 ~ event", event);
    event.setUser(Auth.getCurrentUserName());
  },
  appVersion: "alpha",
  enabledReleaseStages: ["production"]
});

const BugSnagErrorBoundary =
  Bugsnag.getPlugin("react").createErrorBoundary(React);

// register Handsontable's modules
registerAllModules();
// TODO: Remove this once we have a better solution
// https://github.com/handsontable/handsontable/issues/9140

//const loading = () => <div className="animated fadeIn pt-3 text-center"></div>;
const loading = () => <Loader isLoading={true} />;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnReconnect: false
    }
  }
});

// Containers
const DefaultLayout = lazyWithRetry(() => import("./containers/DefaultLayout"));
const LoginContainer = lazyWithRetry(() =>
  import("./views/LoginContainer/LoginContainer")
);

function App(props) {
  const [loader, showLoader, hideLoader] = usePageLoader();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(undefined);
  const shouldHideInitialLoader = useSelector(
    (state) => state.hideInitialLoader
  );

  useEffect(() => {
    if (localStorage.getItem("Auth.accessToken") == null) {
      setIsUserLoggedIn(false);
    } else {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            getAuthToken(
              undefined,
              localStorage.getItem("Auth.refreshToken"),
              localStorage.getItem("isPasswordResetFlow")
            )
        )
        .then((response) => {
          localStorage.setItem("Auth.accessToken", response.data.id_token);
          localStorage.setItem(
            "Auth.refreshToken",
            response.data.refresh_token
          );
          setIsUserLoggedIn(true);
        })
        .catch((err) => {
          console.log(err);
          document.cookie.split(";").forEach((c) => {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });
          localStorage.clear();
          sessionStorage.clear();
          window.location.reload(true);
        });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
    document.addEventListener("keydown", function (event) {
      if (
        document.activeElement.type === "number" &&
        (event.keyCode === 38 || event.keyCode === 40)
      ) {
        event.preventDefault();
      }
    });
  }, []);

  useEffect(() => {
    if (shouldHideInitialLoader) {
      hideLoader();
    } else {
      showLoader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHideInitialLoader]);

  useEffect(() => {
    if (isUserLoggedIn !== undefined && isUserLoggedIn === false) {
      hideLoader();
    }
  }, [isUserLoggedIn]);

  return (
    <>
      <BugSnagErrorBoundary>
        <HashRouter>
          <ScrollTo />
          <QueryClientProvider client={queryClient}>
            <NiceModal.Provider>
              {loader}
              <div>
                {isUserLoggedIn === true && (
                  <React.Suspense fallback={loading()}>
                    <DefaultLayout />
                  </React.Suspense>
                )}
                {isUserLoggedIn === false && (
                  <React.Suspense fallback={loading()}>
                    <LoginContainer setIsUserLoggedIn={setIsUserLoggedIn} />
                  </React.Suspense>
                )}
              </div>
              <ReactQueryDevtools
                position={"bottom-left"}
                initialIsOpen={false}
              />
            </NiceModal.Provider>
          </QueryClientProvider>
        </HashRouter>
      </BugSnagErrorBoundary>
    </>
  );
}

export default App;
