import React, { useState } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Button,
  Alert,
  ModalHeader
} from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import { SelectBox } from "../../../../../elements/select/Select";
import {
  depreciationMethodOptions,
  depreciationMethodValues
} from "../../../../../constants/FixedAssetCreateClassOptions";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import { useMutation } from "react-query";
import { createOrganizationFixedAssetClass } from "../../../../../utils/http/endpoints";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(
  ({ refreshAssetClassesList, orgId, setIsCreateSuccessMsgVisible }) => {
    const modal = useModal();
    const [selectedOption, setSelectedOption] = useState();
    const [depreciationMethod, setDepreciationMethod] = useState(null);
    const [assetClassName, setAssetClassName] = useState(null);
    const [rate, setRate] = useState(null);
    const [effectiveLife, setEffectiveLife] = useState(null);
    const [effectiveLifeFieldError, setEffectiveLifeFieldError] = useState("");
    const [rateFieldError, setRateFieldError] = useState(null);
    const [createFixedAssetClassApiErrors, setCreateFixedAssetClassApiErrors] =
      useState([]);

    const depreciationMethodHandler = (option) => {
      setSelectedOption(option);
      setDepreciationMethod(option.value);
    };

    const assetClassNameHandler = (event) => {
      setAssetClassName(event.target.value);
    };

    const rateHandler = (event) => {
      if (!event.target.validationMessage) {
        setRateFieldError("");
        setRate(parseFloat(event.target.value));
      } else if (
        event.target.value.includes("-") ||
        parseFloat(event.target.value) <= 0
      ) {
        setRateFieldError(
          Labels.Organisations.fixedAssetRegister.straightLineValidationError
        );
        setRate(parseFloat(event.target.value));
      } else {
        setRateFieldError(event.target.validationMessage);
        setRate(parseFloat(event.target.value));
      }
    };

    const effectiveLifeHandler = (event) => {
      if (!event.target.validationMessage) {
        setEffectiveLifeFieldError("");
        setEffectiveLife(parseFloat(event.target.value));
      }
      setEffectiveLifeFieldError(event.target.validationMessage);
      setRate(setEffectiveLife(event.target.value));
    };

    const assetClassMutation = useMutation((data) => {
      return httpRequest({
        method: "post",
        url: createOrganizationFixedAssetClass(orgId),
        data: data
      })
        .then((res) => {
          refreshAssetClassesList();
          setIsCreateSuccessMsgVisible(true);
          modal.remove();
        })
        .catch((error) => {
          if (typeof error == "string") {
            setCreateFixedAssetClassApiErrors([{ errorMessage: error }]);
          } else {
            setCreateFixedAssetClassApiErrors(error.data.errors);
          }
        });
    });

    const handleCreate = () => {
      let data = {
        name: assetClassName,
        depreciationMethod: depreciationMethod
      };

      if (
        selectedOption?.value ===
        depreciationMethodValues.straightLineEffectiveYrs
      ) {
        data = {
          ...data,
          rate: null,
          effectiveLife: effectiveLife
        };
      }

      if (
        selectedOption?.value ===
          depreciationMethodValues.reducingBalanceRate ||
        selectedOption?.value === depreciationMethodValues.straightLineRate
      ) {
        data = {
          ...data,
          rate: rate,
          effectiveLife: null
        };
      }
      setCreateFixedAssetClassApiErrors([]);
      return assetClassMutation.mutate(data);
    };

    return (
      <div>
        <Modal
          centered
          isOpen={modal.visible}
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader className="mb-0 pb-0" toggle={modal.remove}>
            <h2 className="color-dark modal-title">
              {Labels.Organisations.fixedAssetRegister.createFixedAssetClass}
            </h2>
          </ModalHeader>
          <Alert
            color="danger"
            className="mt-3 mb-0 mx-3"
            isOpen={createFixedAssetClassApiErrors.length > 0}
            toggle={() => setCreateFixedAssetClassApiErrors([])}
          >
            <ul className="error-list">
              {createFixedAssetClassApiErrors?.map((error, index) => {
                return <li key={index}>{error.errorMessage}</li>;
              })}
            </ul>
          </Alert>
          <ModalBody className="pt-0">
            <Row className="mt-3">
              <Col sm={12} md={6} className="row-header-2 my-auto">
                {Labels.Organisations.fixedAssetRegister.name}
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <div>
                  <Input
                    className="form-control"
                    name={"name"}
                    type="text"
                    maxLength={250}
                    onChange={assetClassNameHandler}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6} className="row-header-2 my-auto">
                {Labels.Organisations.fixedAssetRegister.depreciationMethod}
              </Col>
              <Col className="mt-1 mt-md-0 d-flex flex-column">
                <SelectBox
                  onChange={depreciationMethodHandler}
                  options={depreciationMethodOptions}
                />
              </Col>
            </Row>
            {selectedOption?.value ===
            depreciationMethodValues.straightLineEffectiveYrs ? (
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2 my-auto">
                  {selectedOption?.inputLabel}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  <div>
                    <Input
                      className="form-control w-100"
                      data-testid={selectedOption?.inputLabel}
                      step={0}
                      type="number"
                      min={1}
                      onChange={effectiveLifeHandler}
                    />
                  </div>
                  {effectiveLifeFieldError && (
                    <span className="error-message">
                      {effectiveLifeFieldError}
                    </span>
                  )}
                </Col>
              </Row>
            ) : null}
            {selectedOption?.value ===
              depreciationMethodValues.reducingBalanceRate ||
            selectedOption?.value ===
              depreciationMethodValues.straightLineRate ? (
              <Row className="mt-3">
                <Col sm={12} md={6} className="row-header-2 my-auto">
                  {selectedOption?.inputLabel}
                </Col>
                <Col className="mt-1 mt-md-0 d-flex flex-column">
                  <div>
                    <Input
                      className="form-control w-100"
                      data-testid={selectedOption?.inputLabel}
                      type="number"
                      step={0.01}
                      min={0.01}
                      onChange={rateHandler}
                    />
                  </div>
                  {rateFieldError && (
                    <span className="error-message">{rateFieldError}</span>
                  )}
                </Col>
              </Row>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Row className="no-gutters">
              <Col sm={12} md={"auto"} className="mr-3">
                <Button
                  color="primary"
                  className="btn btn-primary"
                  type="button"
                  onClick={handleCreate}
                  disabled={
                    !(
                      assetClassName?.length &&
                      depreciationMethod &&
                      (rate || effectiveLife) &&
                      !(rateFieldError || effectiveLifeFieldError)
                    )
                  }
                >
                  {Labels.Organisations.fixedAssetRegister.create}
                </Button>
              </Col>
              <Col sm={12} md={"auto"}>
                <Button
                  color="secondary"
                  onClick={modal.remove}
                  className="btn btn-secondary"
                  type="button"
                >
                  {Labels.Organisations.fixedAssetRegister.cancel}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
);
