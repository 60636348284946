/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { SelectBox as Select } from "../../../../elements/select/Select";
import {
  Alert,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import QueryKeys from "../../../../constants/QueryKeys";
import DragAndDropFileUpload from "../../../../elements/file-upload/DragAndDropFileUpload";
import { DownloadLink } from "../../../../elements/download/DownloadFiles";
import useGetOrgBudgets from "../../Settings/Organisations/hooks/useGetOrgBudgets";
import {
  uploadAssetBudget,
  getListOfYearsForBudgetUpload,
  downloadAssetBudgetSampleTemplate
} from "../../../../utils/http/endpoints";
import { httpRequest } from "../../../../utils/http/httpRequest";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(({ params }) => {
  const modal = useModal();
  const { orgId, assetId } = params;
  const orgBudgetsQuery = useGetOrgBudgets(orgId);
  const [budgetList, setBudgetList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [assetBudget, setAssetBudget] = useState({});
  const [financialYear, setFinancialYear] = useState({});
  const [attachment, setAttachment] = useState([]);
  const queryClient = useQueryClient();

  const resetErrors = () => {
    setErrors([]);
  };

  const uploadOptions = {
    accept: ".csv",
    maxFiles: 1,
    minSize: 0,
    maxSize: 10485760
  };

  useEffect(() => {
    if (orgBudgetsQuery?.isSuccess) {
      let budgets = [];
      orgBudgetsQuery?.data?.forEach((budget) => {
        budgets.push({
          id: budget.id,
          value: budget.id,
          label: budget.isDefault
            ? budget.name + "(" + Labels.Assets.Filters.budgetDefault + ")"
            : budget.name
        });
      });
      setBudgetList(budgets);
    }
  }, [orgBudgetsQuery?.data]);

  useEffect(() => {
    httpRequest({
      method: "get",
      url: getListOfYearsForBudgetUpload(assetId)
    })
      .then((response) => {
        let years = [];
        if (response?.data) {
          response?.data?.forEach((item, index) => {
            years.push({
              id: index + 1,
              value: item.year,
              label: item.label
            });
          });
          if (years.length > 1) {
            setYearList(years);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const sampleCSVDownloadOptions = {
    url: downloadAssetBudgetSampleTemplate(),
    responseType: "blob"
  };

  const onFilesSelection = (files = []) => {
    setAttachment(files);
  };

  const errorCodeDesc = {
    "file-invalid-type": Labels.Journals.fileTypeError,
    "file-too-large": Labels.CommonModals.attachmentSizeError
  };

  const onError = (errorList) => {
    const errors = {};
    errorList.forEach((errorObj) => {
      errorObj.errors.forEach((error) => {
        errors[error.code] = {
          errorMessage: errorCodeDesc[error.code] || error.message
        };
      });
    });
    setErrors([...Object.values(errors)]);
  };

  const save = () => {
    const formData = new FormData();
    formData.append("organizationBudgetId", assetBudget.value);
    formData.append("budgetDataFile", attachment[0]);
    formData.append("year", financialYear.value);
    httpRequest({
      method: "post",
      url: uploadAssetBudget(assetId),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(() => {
        modal.remove();
        queryClient.invalidateQueries(QueryKeys.getAssetBudgets);
      })
      .catch((err) => {
        err.data
          ? setErrors(err.data.errors)
          : setErrors([{ errorMessage: err }]);
      });
  };

  const isInvalid = () => {
    return (
      !(Object.keys(assetBudget).length > 0) ||
      !(Object.keys(financialYear).length > 0) ||
      !(attachment.length > 0)
    );
  };

  return (
    <Modal
      isOpen={true}
      centered={true}
      toggle={modal.remove}
      fade={false}
      keyboard={false}
      backdrop="static"
      className="create-new-orgbudget-modal"
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">
          {Labels.Organisations.Budgets.createBudget}
        </h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={modal.remove}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="py-0 px-3">
        {errors.length ? (
          <div>
            <Alert color="danger" toggle={resetErrors}>
              <ul className="error-list">
                {errors.map((error, index) => {
                  return <li key={index}>{error.errorMessage}</li>;
                })}
              </ul>
            </Alert>
          </div>
        ) : null}
        <Row>
          <Col className="row-header-2 my-auto">
            {Labels.accounting.Budgets.name}
          </Col>
          <Col>
            <Select
              id="budgetName"
              value={assetBudget}
              onChange={setAssetBudget}
              options={budgetList}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="row-header-2">
            {Labels.accounting.Budgets.financialYear}
          </Col>
          <Col>
            <Select
              id="financialYear"
              value={financialYear}
              onChange={setFinancialYear}
              options={yearList}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="row-header-2">
            {Labels.accounting.Budgets.budgetDataFile}
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <DragAndDropFileUpload
              onFileSelection={onFilesSelection}
              onError={onError}
              label={Labels.CommonModals.uploadFileLabel}
              button={true}
              uploadConfig={uploadOptions}
            />
            <DownloadLink {...sampleCSVDownloadOptions} onError={onError}>
              <span className="float-right download-link pt-1">
                {Labels.CommonModals.downloadCsvTpl}
              </span>
            </DownloadLink>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={isInvalid()} onClick={save}>
          {Labels.CommonModals.create}
        </Button>
        <Button color="secondary" onClick={modal.remove}>
          {Labels.CommonModals.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
});
