import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";
import Handsontable from "handsontable";
import { HotTable } from "@handsontable/react";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../../utils/number";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import distributionData from "./__mocks__/distributionGraphData.json";

export default function DistributionDataTable({ tableData }) {
  const formatAmounts = (value, handleNegativeAmounts) => {
    if (typeof value === "number") {
      return formatAmountWithThousandSeparatorsToPrecision(
        value,
        handleNegativeAmounts
      );
    } else {
      return value;
    }
  };

  function numericValueRenderer(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
    td.innerHTML = formatAmounts(value, true);
  }
  Handsontable.renderers.registerRenderer(
    "numericValueRenderer",
    numericValueRenderer
  );

  function cellRenderer(row, column) {
    const cellProperties = {};

    if (column > 0) {
      cellProperties.renderer = "numericValueRenderer";
      if (
        ["Variance", "Closing Cash Balance"].includes(
          this.instance.getDataAtRow(row)[0]
        )
      ) {
        this.instance.setCellMeta(row, column, "className", "fw-600 ");
      }
    }
    if (
      column === 0 &&
      ["Variance", "Closing Cash Balance"].includes(
        this.instance.getDataAtRow(row)[0]
      )
    ) {
      cellProperties.readOnly = true;
      this.instance.setCellMeta(row, column, "className", "fw-600 ");
    }

    return cellProperties;
  }

  const tableColHeaders = distributionData.asset1.map((monthData, index) => {
    return `${moment(monthData.period).format("MMM")}-${moment(
      monthData.period
    ).format("YYYY")}${index <= 7 ? "(A)" : "(B)"}`;
  });

  function handleAfterChange() {
    this.render();
  }

  return (
    <div>
      <Row>
        <Col>
          <HotTable
            data={tableData}
            columns={(index) => {
              return {
                type: index > 0 ? "numeric" : "text",
                readOnly: true,
                width: index > 0 ? 100 : 160
              };
            }}
            colHeaders={[
              getSymbolFromCurrencyCode("EUR"),
              ...tableColHeaders,
              "Total"
            ]}
            fixedColumnsLeft={1}
            height={"auto"}
            stretchH="all"
            cells={cellRenderer}
            disableVisualSelection={false}
            afterChange={handleAfterChange}
            manualColumnResize={false}
            licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
          />
        </Col>
      </Row>
    </div>
  );
}
