import React, { useContext, useEffect } from "react";
import InvoiceFormContext from "./InvoiceFormContext";
import {
  TOGGLE_PREPAYMENT_ERROR,
  UPDATE_JOURNAL_RANGE,
  UPDATE_PREPAYMENT_JOURNAL_SCHEDULE
} from "./actionsTypes";
import moment, { isDate } from "moment";
import classNames from "classnames";
import { Labels } from "../../../../../../constants/Constants";
import CustomDatePicker from "../../../../../../elements/form-fields/inputs/CustomDatePicker";
import { isJournalPeriodRangeValid } from "../InvoiceEnrichment";
import useToggle from "../../../../../../hooks/useToggle";
import { Alert, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import InvoiceStatuses from "../../../../../../constants/InvoiceStatuses";
import { convertToFloat, formatAmount } from "../../../../../../utils/number";
import { getMonthName } from "../../../../../../utils/date";
import useGetPrepaymentsData from "../useGetPrepaymentsData";
import { useParams } from "react-router-dom";
import { PrepaymentDataErrorMessage } from "./PrepaymentDataErrorMessage";
import { PurchaseInvoiceTypes } from "../../../../../../constants/PurchaseInvoiceTypes";
import AccruedAmounts from "./AccruedAmounts";
import useUniqueId from "../../../../../../hooks/useUniqueId";
import { toReadableString } from "../../../../../../utils/string";
import Tooltip from "../../../../../../elements/ToolTips/Tooltip";

export default function JournalManagement({
  currency,
  isFormReadOnly,
  invoiceDetails,
  lineItemId,
  lineItem,
  accountId,
  setIsFormChanged,
  setIsLineItemFormChanged,
  isLineItemCardHasErrors,
  getJournalDescriptionBeforeApproval,
  assetDetails
}) {
  const { dispatch } = useContext(InvoiceFormContext);
  const [expandPrepayments, togglePrepayments] = useToggle(false);
  const params = useParams();

  const prepaymentdataQuery = useGetPrepaymentsData({
    assetId: invoiceDetails.assetId,
    invoiceId: invoiceDetails.id,
    // This payload is same as the enrichment api put call payload(of single line item)
    data: {
      budgetLineItemId:
        lineItem.budgetLineItem !== null &&
        lineItem.budgetingOption === "budgetLineItem"
          ? lineItem.budgetLineItem.value
          : undefined,
      purchaseOrderId:
        lineItem.poNumber !== null && lineItem.budgetingOption === "poNumber"
          ? lineItem.poNumber.value
          : undefined,
      poInvoiceScheduleId: lineItem.poInvoiceScheduleId,
      poInvoiceLineItemId: lineItem.poInvoiceLineItemId,
      glCodeId:
        lineItem.budgetingOption === "budgetLineItem"
          ? lineItem.budgetLineItem?.accountId
          : lineItem.glAccountCode?.value,
      id: lineItem.isNew ? null : lineItem.lineItemId,
      invoiceLineItemId: lineItem.invoiceLineItemId,
      invoicePeriodFrom:
        lineItem.prepaymentJournalSchedule === "MONTHLY"
          ? moment(lineItem.journalPeriodRange[0])
              .set("D", 1)
              .format("YYYY-MM-DD")
          : moment(lineItem.journalPeriodRange[0]).format("YYYY-MM-DD"),
      invoicePeriodTo:
        lineItem.prepaymentJournalSchedule === "MONTHLY"
          ? moment(lineItem.journalPeriodRange[1])
              .endOf("month")
              .format("YYYY-MM-DD")
          : moment(lineItem.journalPeriodRange[1]).format("YYYY-MM-DD"),
      noBudgetAvailable: lineItem.budgetingOption === "noBudgetAvailable",
      prepaymentJournalSchedule: lineItem.prepaymentJournalSchedule
    },
    enabled:
      !Boolean(isLineItemCardHasErrors) &&
      !!lineItem.journalPeriodRange?.length &&
      lineItem.journalPeriodRange?.every((i) => isDate(i))
  });

  useEffect(() => {
    dispatch({
      type: TOGGLE_PREPAYMENT_ERROR,
      payload: {
        lineItemId,
        prepaymentError: prepaymentdataQuery.isError
      }
    });
  }, [prepaymentdataQuery.isError, dispatch, lineItemId]);

  const getPrepaymentJournalPostingDataList = () => {
    if (
      [InvoiceStatuses.DRAFT, InvoiceStatuses.AWAITING_APPROVAL].includes(
        invoiceDetails.invoiceStatus
      ) &&
      prepaymentdataQuery.data?.prepaymentJournalDataList?.length > 0
    ) {
      return prepaymentdataQuery.data.prepaymentJournalDataList
        .map((lineItem) => lineItem.journalPostingDataList)
        .flat()
        .filter((i) => i.journalPostingLineItemDataList?.length > 0);
    }

    return lineItem.prepaymentJournalPostingDataList || [];
  };

  const handleJournalPeriodChange = (dateRange) => {
    setIsLineItemFormChanged(true);
    setIsFormChanged(true);
    dispatch({
      type: UPDATE_JOURNAL_RANGE,
      payload: {
        lineItemId,
        journalPeriodRange: dateRange
      }
    });
  };

  const handlePrepaymentJournalScheduleChange = (event) => {
    setIsLineItemFormChanged(true);
    setIsFormChanged(true);
    dispatch({
      type: UPDATE_PREPAYMENT_JOURNAL_SCHEDULE,
      payload: {
        lineItemId,
        prepaymentJournalSchedule: event.target.value
      }
    });
  };

  const journalPeriodRangeError = isJournalPeriodRangeValid(
    lineItem.journalPeriodRange
  )
    ? ""
    : Labels.CommonModals.journalPeriodRangeError;

  /**
   * Start Period:
   * Prior to asset on-boarding: No need of validation
   * After asset on-boarding: Cant be before conversion period
   * End of period:
   * Cant be before "start"
   * Maximum -> can fall in the next +2 financial year
   * @returns object
   */
  const getJournalPeriodDateRange = () => {
    const range = { startDate: null, endDate: null };
    if (assetDetails.isOnboardingComplete && assetDetails.conversionDate) {
      range.startDate = moment(assetDetails.conversionDate).toDate().setDate(1);
      range.endDate = moment(
        assetDetails.financialYearEndDate +
          `-${moment().toDate().getFullYear() + 2}`
      ).toDate();
    }

    return range;
  };

  return (
    <div>
      <div className="prepayments">
        <div className="d-flex d-flex align-items-center justify-content-between">
          <div className="mr-5">
            <label
              className="flex align-items-center"
              htmlFor={"prepaymentJournalSchedule" + lineItemId}
            >
              {Labels.Payments.Invoices.Details.prepaymentJournalSchedule}
              <TooltipMessage
                message={
                  Labels.Payments.Invoices.Details
                    .prepaymentJournalScheduleMessage
                }
              />
            </label>
          </div>
          <div className="d-flex">
            <div className="mr-4">
              <label
                className="d-flex justify-content-center align-items-center body-primary color-text-primary"
                htmlFor={"monthly" + lineItemId}
              >
                <input
                  type="radio"
                  className="mr-1"
                  disabled={isFormReadOnly}
                  name={"prepaymentJournalSchedule" + lineItemId}
                  onChange={handlePrepaymentJournalScheduleChange}
                  value={"MONTHLY"}
                  checked={lineItem.prepaymentJournalSchedule === "MONTHLY"}
                  id={"monthly" + lineItemId}
                />{" "}
                {Labels.Payments.Invoices.Details.monthly}
              </label>
            </div>
            <div className="">
              <label
                className="d-flex justify-content-center align-items-center body-primary color-text-primary"
                htmlFor={"daily" + lineItemId}
              >
                <input
                  type="radio"
                  className="mr-1"
                  disabled={isFormReadOnly}
                  value="DAILY"
                  checked={lineItem.prepaymentJournalSchedule === "DAILY"}
                  name={"prepaymentJournalSchedule" + lineItemId}
                  onChange={handlePrepaymentJournalScheduleChange}
                  id={"daily" + lineItemId}
                />{" "}
                {Labels.Payments.Invoices.Details.daily}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <label className="field-label m-0 p-0" htmlFor="invoiceDates">
          {Labels.Payments.Invoices.Details.lineItemPeriodRange}
          <TooltipMessage
            message={
              Labels.Payments.Invoices.Details.lineItemPeriodRangeMessage
            }
          />
        </label>
        <div>
          <CustomDatePicker
            id="invoiceDates"
            className={classNames("month-ending", {
              "react-datepicker-invalid": !!journalPeriodRangeError
            })}
            selectsRange={true}
            closeOnScroll={true}
            disabled={isFormReadOnly}
            startDate={lineItem.journalPeriodRange[0]}
            endDate={lineItem.journalPeriodRange[1]}
            minDate={getJournalPeriodDateRange().startDate}
            maxDate={getJournalPeriodDateRange().endDate}
            onChange={handleJournalPeriodChange}
            isClearable={!isFormReadOnly}
            autoComplete="off"
            showMonthYearPicker={
              lineItem.prepaymentJournalSchedule === "MONTHLY"
            }
            dateFormat={
              lineItem.prepaymentJournalSchedule === "MONTHLY"
                ? "MM/yyyy"
                : "dd/MM/yyyy"
            }
            placeholderText={Labels.Payments.Invoices.dateRange}
          />
          <span className="text-danger value-text">
            {journalPeriodRangeError}
          </span>
        </div>
      </div>
      <div className="border-bottom-fv-border-2px"></div>
      <div
        className="d-flex justify-items-between align-items-center cursor-pointer"
        onClick={togglePrepayments}
      >
        <span className="h4 sub-heading-small m-0 p-0 py-3">
          {Labels.Payments.Invoices.Details.prepaymentJournals}
          <span className="field-label">
            <TooltipMessage
              message={`${
                Labels.Payments.Invoices.Details.thePrepaymentJournals
              } ${
                Labels.Payments.Invoices.Details.associatedWithThis
              } ${toReadableString(invoiceDetails.invoiceType)} ${
                Labels.Payments.Invoices.Details.willBeIn
              } ${toReadableString(assetDetails.automaticJournalStatus)} ${
                Labels.Payments.Invoices.Details.status
              } ${
                Labels.Payments.Invoices.Details
                  .toChangeKindlyUpdateTheGeneralDetailsInSettingsAre
              }`}
            />
          </span>
        </span>
        <button
          className="btn btn-link m-0 p-0 ml-auto"
          data-testid="pre-payments-expand-collapse"
        >
          {expandPrepayments ? (
            <FontAwesomeIcon
              size="2x"
              icon={faAngleUp}
              className="m-0 p-0 mr-1 ml-1 align-middle"
            />
          ) : (
            <FontAwesomeIcon
              size="2x"
              icon={faAngleDown}
              className="m-0 p-0 mr-1 ml-1 align-middle"
            />
          )}
        </button>
      </div>
      <Collapse isOpen={expandPrepayments}>
        {getPrepaymentJournalPostingDataList()?.length > 0 ? (
          <>
            <div>
              <PrepaymentDataErrorMessage
                errors={prepaymentdataQuery.error}
                primaryOrgId={
                  assetDetails.assetOrganizations.find(
                    (asset) => asset.isPrimaryOrganization
                  ).organizationId
                }
              />
              <Alert color="info">
                {[
                  InvoiceStatuses.DRAFT,
                  InvoiceStatuses.AWAITING_APPROVAL
                ].includes(invoiceDetails.invoiceStatus)
                  ? invoiceDetails.invoiceType ===
                    PurchaseInvoiceTypes["Purchase Invoice"]
                    ? Labels.Payments.Invoices.Details
                        .prepaymentJournalsInfoBeforeApprovalInvoice
                    : Labels.Payments.Invoices.Details
                        .prepaymentJournalsInfoBeforeApprovalCreditNote
                  : Labels.Payments.Invoices.Details
                      .prepaymentJournalsInfoAfterApproval}
              </Alert>
            </div>
            <div className="mb-3 body-primary color-text-primary">
              <table className="w-100">
                <thead>
                  <tr className="column-header-1">
                    <th>{Labels.Payments.Invoices.JournalManagement.period}</th>
                    <th className="text-left">
                      {Labels.Payments.Invoices.JournalManagement.bliGlCode}
                    </th>
                    <th className="text-center">
                      {Labels.Payments.Invoices.JournalManagement.debit}
                    </th>
                    <th className="text-center">
                      {Labels.Payments.Invoices.JournalManagement.credit}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {getPrepaymentJournalPostingDataList()
                    .sort((j1, j2) => {
                      return j1.year === j2.year
                        ? j1.month - j2.month
                        : j1.year - j2.year;
                    })
                    .filter(isJournalEmpty)
                    .map((journal, index) => {
                      let bliGlCodeRow,
                        prepaymentRow = null;

                      if (
                        journal.journalPostingLineItemDataList[0]
                          .budgetLineItemDescription === "Prepayment"
                      ) {
                        prepaymentRow =
                          journal.journalPostingLineItemDataList[0];
                        bliGlCodeRow =
                          journal.journalPostingLineItemDataList[1];
                      } else {
                        prepaymentRow =
                          journal.journalPostingLineItemDataList[1];
                        bliGlCodeRow =
                          journal.journalPostingLineItemDataList[0];
                      }

                      const monthYear = `${getMonthName(journal.month)}-${
                        journal.year
                      }`;

                      return (
                        <React.Fragment key={journal.accountId}>
                          <tr>
                            <td className="pt-3"></td>
                            <td
                              className="text-left"
                              style={{ maxWidth: "10rem" }}
                            >
                              {bliGlCodeRow.budgetLineItemDescription ===
                              "Prepayment"
                                ? "Prepayment"
                                : getJournalDescription(bliGlCodeRow)}
                            </td>
                            <td className="text-center">
                              {formatAmount(bliGlCodeRow.debit, currency)}
                            </td>
                            <td className="text-center py-2">
                              {formatAmount(bliGlCodeRow.credit, currency)}
                            </td>
                          </tr>
                          <tr
                            className={classNames({
                              "border-bottom-fv-border-1px":
                                getPrepaymentJournalPostingDataList().length !==
                                index + 1
                            })}
                          >
                            <td>
                              {journal.journalId ? (
                                <a
                                  href={getJournalLink({
                                    journalId: journal.journalId,
                                    ...params
                                  })}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {monthYear}
                                </a>
                              ) : (
                                <>{monthYear}</>
                              )}
                            </td>
                            <td
                              id={"journalDescription" + index}
                              className="text-left text-truncate"
                            >
                              {prepaymentRow.budgetLineItemDescription ===
                              "Prepayment"
                                ? "Prepayment"
                                : getJournalDescription(prepaymentRow)}
                            </td>
                            <Tooltip
                              target={"journalDescription" + index}
                              placement="bottom-start"
                            >
                              {getJournalDescription(prepaymentRow)}
                            </Tooltip>
                            <td className="text-center">
                              {formatAmount(prepaymentRow.debit, currency)}
                            </td>
                            <td className="text-center py-2">
                              {formatAmount(prepaymentRow.credit, currency)}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </>
        ) : null}
      </Collapse>
      <div className="border-bottom-fv-border-2px"></div>
      {accountId ? (
        <AccruedAmounts
          invoiceDetails={invoiceDetails}
          lineItemId={lineItemId}
          currency={currency}
          isFormReadOnly={isFormReadOnly}
          lineItem={lineItem}
          assetDetails={assetDetails}
          accountId={accountId}
          setIsFormChanged={setIsFormChanged}
          setIsLineItemFormChanged={setIsLineItemFormChanged}
          getJournalDescription={getJournalDescription}
          getJournalDescriptionBeforeApproval={
            getJournalDescriptionBeforeApproval
          }
        />
      ) : null}
    </div>
  );
}

export function getJournalLink({ journalId, assetId }) {
  return `/#/allassets/accounting/journals/manual/asset/${assetId}/journal/${journalId}`;
}

export const getJournalDescription = (bliGlCodeRow) => {
  const {
    budgetLineItemDescription,
    glCodeDescription,
    glCode,
    budgetLineItemId
  } = bliGlCodeRow;
  if (budgetLineItemId) {
    return `${budgetLineItemDescription} / ${glCode} - ${glCodeDescription}`;
  }

  return `${glCode} - ${glCodeDescription}`;
};

// Returns true if API Returned Journal is empty (all the amounts are zero)
export const isJournalEmpty = (journal) => {
  let bliGlCodeRow,
    prepaymentRow = null;

  try {
    if (
      journal.journalPostingLineItemDataList[0].budgetLineItemDescription ===
      "Prepayment"
    ) {
      prepaymentRow = journal.journalPostingLineItemDataList[0];
      bliGlCodeRow = journal.journalPostingLineItemDataList[1];
    } else {
      prepaymentRow = journal.journalPostingLineItemDataList[1];
      bliGlCodeRow = journal.journalPostingLineItemDataList[0];
    }
  } catch (error) {
    return false;
  }

  if (
    [
      convertToFloat(prepaymentRow.debit, 2),
      convertToFloat(prepaymentRow.credit, 2),
      convertToFloat(bliGlCodeRow.debit, 2),
      convertToFloat(bliGlCodeRow.credit, 2)
    ].every((value) => !Boolean(value))
  ) {
    return false;
  } else {
    return true;
  }
};

export function TooltipMessage({ message }) {
  const uniqueId = useUniqueId("invoice-enrichment");
  return (
    <>
      <FontAwesomeIcon id={uniqueId} icon={faInfoCircle} className="ml-1" />
      <Tooltip
        fade={false}
        style={{
          maxWidth: "unset",
          textAlign: "start"
        }}
        innerClassName="prepayment-journal-schedule-message w-100"
        popperClassName="w-25"
        target={uniqueId}
      >
        <div className="w-100">{message}</div>
      </Tooltip>
    </>
  );
}
