import React, { useEffect } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQuery, useQueryClient } from "react-query";
import { Labels } from "../../../../../constants/Constants";
import DisplayAlerts from "../../../../../elements/DisplayAlerts";
import {
  convertDateFromUTCToLocal,
  getDateInDisplayFormat
} from "../../../../../utils/date";
import { getFxRevaluationHistory } from "../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import QueryKeys from "../../../../../constants/QueryKeys";
import "./_fx-revaluation-history-modal.scss";

export default NiceModal.create(({ assetId }) => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const { data, error, isError, isLoading } = useQuery({
    queryKey: [QueryKeys.getFxRevaluationHistory],
    queryFn: () =>
      httpRequest({
        method: "get",
        url: getFxRevaluationHistory(assetId)
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!assetId
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries(QueryKeys.getFxRevaluationHistory);
    };
  }, []);

  return (
    <Modal
      isOpen={true}
      centered={true}
      toggle={modal.remove}
      fade={false}
      keyboard={false}
      backdrop="static"
      className="fx-revaluation-history-modal"
      size="lg"
    >
      <ModalHeader className="pb-0" toggle={modal.remove}>
        <div>
          <h2 className="color-dark modal-title">
            {Labels.Assets.Settings.history}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody>
        {isError ? (
          <DisplayAlerts
            alerts={error}
            type="danger"
            className="my-1"
            innerClassName="mb-0"
          />
        ) : null}
        {!isLoading && !isError ? (
          <div className="history-container scrollbar-primary pr-1">
            <div className="history-items-container body-primary fv-text-primary">
              {data?.length ? (
                <>
                  {data?.map((history, index) => {
                    return (
                      <div className="history-item" key={index}>
                        <Row className="m-0">
                          <Col sm={8} className="pl-0">
                            <strong className="username">
                              {history.changedBy}
                            </strong>
                            {Labels.Assets.Settings.FxRevaluation.ran}
                            {history.field}
                            {Labels.Assets.Settings.FxRevaluation.for}
                            <span className="text-nowrap font-weight-bold">
                              {getDateInDisplayFormat(history.creationDate)}
                            </span>
                          </Col>
                          <Col sm={4} className="timestamp px-0 text-right">
                            {convertDateFromUTCToLocal(
                              history.timestamp,
                              "DD MMM YYYY HH:mm:ss"
                            )}
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p className="fv-text-primary">
                  {Labels.CommonModals.nothingToDisplay}
                </p>
              )}
            </div>
          </div>
        ) : null}
      </ModalBody>
    </Modal>
  );
});
