import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React from "react";
import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";
import { Labels } from "../../../constants/Constants";
import { modalNames } from "../../../elements/modals/modal-register";
import { httpRequest } from "../../../utils/http/httpRequest";
import { getUserByEmail } from "../../../utils/http/endpoints";

export default NiceModal.create(
  ({
    refreshUsersList,
    setIsCreateSuccessMsgVisible,
    setIsUpdateSuccessMsgVisible
  }) => {
    const modal = useModal();
    const [emailAddress, setEmailAddress] = useState();
    const [emailError, setEmailError] = useState();
    const [isArchivedUser, setIsArchivedUser] = useState(false);
    const [isExternalUser, setIsExternalUser] = useState(false);
    const [isFamUser, setIsFamUser] = useState(false);
    const [isOtherFamUser, setIsOtherFamUser] = useState(false);
    const [userDetails, setUserDetails] = useState({});

    const handleEmailChange = (event) => {
      setIsArchivedUser(false);
      setIsExternalUser(false);
      setIsFamUser(false);
      setIsOtherFamUser(false);
      event.target.value =
        event.target.name === "email"
          ? event.target.value
          : event.target.value.replace(/\s/g, "");

      event.target.checkValidity();
      const value = event.target.value;
      setEmailAddress(
        event.target.name === "email" ? value : value.replace(/\s/g, "")
      );
      setEmailError(event.target.validationMessage);
    };

    const isFormValid = () => {
      return !emailError && emailAddress;
    };

    const handleFAMUserInitialCheck = (email) => {
      httpRequest({
        method: "get",
        url: getUserByEmail(email)
      })
        .then((response) => {
          if (!response.data.isActive) {
            setIsArchivedUser(true);
          } else if (response.data.isExternalUser) {
            setIsExternalUser(true);
          } else if (response.data.isFamUser) {
            setIsFamUser(true);
          } else if (response.data.isOtherFamUser) {
            setIsOtherFamUser(true);
            setUserDetails(response.data);
          }
        })
        .catch((err) => {
          modal.remove();
          NiceModal.show(modalNames.createOrEditUser, {
            refreshUsersList,
            setIsCreateSuccessMsgVisible,
            setIsUpdateSuccessMsgVisible,
            userData: { email: emailAddress },
            isNewUser: true
          });
        });
    };

    const handleDiffFAMUser = () => {
      modal.remove();
      NiceModal.show(modalNames.createOrEditUser, {
        refreshUsersList,
        setIsCreateSuccessMsgVisible,
        setIsUpdateSuccessMsgVisible,
        userData: userDetails,
        isOtherFamUser: isOtherFamUser
      });
    };

    return (
      <Modal
        isOpen={modal.visible}
        toggle={modal.remove}
        keyboard={false}
        centered
        backdrop="static"
      >
        <div className="modal-header mb-0 pb-0">
          <h2 className="color-dark modal-title">
            {Labels.UserManagement.createUser}
          </h2>
          <button
            type="button"
            className="close"
            aria-label="close"
            onClick={modal.remove}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody className="pt-2">
          {isArchivedUser && (
            <Alert color="info">
              {Labels.UserManagement.archivedUserInfoMsg}
            </Alert>
          )}
          {isFamUser && (
            <Alert color="info">
              {Labels.UserManagement.sameFAMUserInfoMsg}
            </Alert>
          )}
          {isOtherFamUser && (
            <Alert color="info">
              {Labels.UserManagement.diffFAMUserInfoMsg}
            </Alert>
          )}
          {isExternalUser && (
            <Alert color="info">
              {Labels.UserManagement.diffFAMExternalUserInfoMsg}
            </Alert>
          )}
          <Row className="mt-2">
            <Col sm={12} md={6} className="row-header-2 pt-2">
              {Labels.UserManagement.emailAddress}
            </Col>
            <Col className="mt-1 mt-md-0 d-flex flex-column">
              <div>
                <Input
                  type="email"
                  name={"email"}
                  maxLength={255}
                  placeholder={Labels.UserManagement.emailAddress}
                  value={emailAddress}
                  onChange={(evt) => handleEmailChange(evt)}
                />
              </div>
              <div>
                <span className="text-danger error-message error-message">
                  {emailError}
                </span>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row className="no-gutters">
            <Col sm={12} md={"auto"} className="mr-3">
              {!isOtherFamUser ? (
                <Button
                  color="primary"
                  className="btn btn-primary"
                  type="button"
                  disabled={
                    !isFormValid() ||
                    isFamUser ||
                    isExternalUser ||
                    isArchivedUser
                  }
                  onClick={() => handleFAMUserInitialCheck(emailAddress)}
                >
                  {Labels.CommonModals.next}
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="btn btn-primary"
                  type="button"
                  disabled={!isFormValid() || isFamUser}
                  onClick={handleDiffFAMUser}
                >
                  {Labels.CommonModals.continue}
                </Button>
              )}
            </Col>
            <Col sm={12} md={"auto"}>
              <Button
                color="secondary"
                onClick={modal.remove}
                className="btn btn-secondary"
                type="button"
              >
                {Labels.CommonModals.cancel}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
);
