import {
  faAngleDown,
  faAngleUp,
  faCheckCircle,
  faCircle,
  faEdit,
  faExclamationCircle,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Collapse, FormGroup, Input, Label } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { SelectBox as Select } from "../../../../../../elements/select/Select";
import Budgeting from "./Budgeting";
import JournalManagement from "./JournalManagement";
import InvoiceFormContext from "./InvoiceFormContext";
import {
  CHANGE_PO_INVOICE_SCHEDULED,
  CHANGE_SHOULD_UPDATE_BUDGET,
  LINE_ITEM_API_ERRORED,
  UPDATE_BUDGETING_OPTION,
  UPDATE_JOURNAL_RANGE,
  UPDATE_PO_AVAILABLE_AMOUNT,
  UPDATED_BUDGET_LINE_ITEM,
  UPDATED_GL_ACCOUNT_CODE,
  UPDATED_PO_NUMBER
} from "./actionsTypes";
import { Labels } from "../../../../../../constants/Constants";
import useGetPOAvailableAmount from "../../../../../../constants/useGetPOAvailableAmount";
import { useRouteMatch } from "react-router-dom";
import useToggle from "../../../../../../hooks/useToggle";
import useUniqueId from "../../../../../../hooks/useUniqueId";
import POInvoiceSelectorModal from "./POInvoiceSelectorModal";
import useGetPOEstimatedInvoices from "../../../../../../constants/useGetPOEstimatedInvoices";
import classNames from "classnames";
import { getSymbolFromCurrencyCode } from "../../../../../../utils/currency/currency";
import { PurchaseInvoiceTypesEnum } from "../../../../../../constants/PurchaseInvoiceTypes";
import ConditionalRenderer from "../../../../../../elements/ConditionalRenderer";
import EditPILineItem from "../EditPILineItem";
import { FormValue } from "../../../SalesInvoices/SalesInvoiceInitialFormState";
import { isNumber } from "../../../../../../utils/number";
import { isJournalPeriodRangeValid } from "../InvoiceEnrichment";
import Tooltip from "../../../../../../elements/ToolTips/Tooltip";

export default function LineItemForm({
  currency,
  isFormReadOnly,
  invoiceDetails,
  lineItemId,
  lineItem,
  isOpen,
  toggle,
  budgetLineItemsList,
  isBudgetLineItemsListSuccess,
  poOptionsList,
  isLineItemCardHasErrors,
  isUpdateBudgetNeeded,
  glCodesList,
  isGlCodesListSuccess,
  assetDetails
}) {
  const isCreditNote = () =>
    invoiceDetails?.invoiceType ===
    PurchaseInvoiceTypesEnum.PURCHASE_CREDIT_NOTE;
  const [isLineItemFormChanged, setIsLineItemFormChanged] = useState(false);
  const persistedBudgetLineItem = invoiceDetails.invoiceBudgetLineItems?.find(
    (obj) => obj.invoiceLineItem.id === lineItem.id
  );
  const {
    state,
    dispatch,
    setIsFormChanged,
    formatAmount,
    editInvoiceMutation,
    activeTab,
    handleTabChange,
    saveInvoiceDetails,
    isLineItemDataEdited
  } = useContext(InvoiceFormContext);
  const updatePOAvailableAmount = (poAvailableAmount) => {
    dispatch({
      type: UPDATE_PO_AVAILABLE_AMOUNT,
      payload: {
        lineItemId,
        poAvailableAmount
      }
    });
  };
  useGetPOAvailableAmount(
    invoiceDetails.assetId,
    lineItem.poNumber?.id,
    updatePOAvailableAmount
  );
  const { data: poInvoicesListData } = useGetPOEstimatedInvoices(
    invoiceDetails.assetId,
    lineItem.poNumber?.id
  );
  const [isPOAssociationModalOpen, togglePOAssociationModal] = useToggle();

  const [invoiceLineItem, setInvoiceLineItem] = useState({
    id: lineItem.invoiceLineItem.id,
    taxId: lineItem.invoiceLineItem.taxId,
    netAmount: new FormValue(lineItem.invoiceLineItem.netAmount),
    taxAmount: new FormValue(lineItem.invoiceLineItem.taxAmount),
    description: new FormValue(lineItem.invoiceLineItem.description)
  });

  const handleSave = () => {
    // debugger;
    if (!isLineItemDataValid() || !isLineItemDataEdited) {
      return;
    }

    saveInvoiceDetails(false, {
      id: invoiceLineItem.id,
      taxId: invoiceLineItem.taxId,
      netAmount: invoiceLineItem.netAmount.value,
      taxAmount: invoiceLineItem.taxAmount.value,
      description: invoiceLineItem.description.value
    });
  };

  useEffect(() => {
    handleSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    dispatch({
      type: UPDATE_JOURNAL_RANGE,
      payload: {
        lineItemId,
        journalPeriodRange: lineItem.journalPeriodRange
      }
    });
  }, [invoiceDetails]);

  const isLineItemDataValid = () => {
    const validations = [
      invoiceLineItem.description.value,
      isNumber([
        invoiceLineItem.netAmount.value,
        invoiceLineItem.taxAmount.value
      ]),
      !invoiceLineItem.description.error,
      !invoiceLineItem.netAmount.error,
      !invoiceLineItem.taxAmount.error,
      invoiceLineItem.taxId
    ];

    return validations.every((validation) => !!validation);
  };

  const handleBudgetLineItemChange = (selectedBudgetLineItem) => {
    setIsLineItemFormChanged(true);
    setIsFormChanged(true);
    dispatch({
      type: UPDATED_BUDGET_LINE_ITEM,
      payload: { lineItemId, budgetLineItem: selectedBudgetLineItem }
    });
    dispatch({
      type: CHANGE_SHOULD_UPDATE_BUDGET,
      payload: true
    });
  };

  const handlePONumberChange = (selectedPONumber) => {
    setIsLineItemFormChanged(true);
    setIsFormChanged(true);

    dispatch({
      type: UPDATED_PO_NUMBER,
      payload: { lineItemId, poNumber: selectedPONumber }
    });

    handleGLAccountCodeChange(null);

    dispatch({
      type: CHANGE_PO_INVOICE_SCHEDULED,
      payload: {
        lineItemId,
        // poInvoiceScheduleId: null
        poInvoiceScheduleId:
          selectedPONumber !== null &&
          persistedBudgetLineItem !== undefined &&
          persistedBudgetLineItem.purchaseOrder !== null &&
          persistedBudgetLineItem.purchaseOrder.id === selectedPONumber.id
            ? persistedBudgetLineItem.purchaseOrder.poInvoiceScheduleId
            : null,
        poInvoiceLineItemId: null
      }
    });

    if (selectedPONumber) {
      togglePOAssociationModal();
    }

    if (selectedPONumber === null) {
      dispatch({
        type: UPDATE_JOURNAL_RANGE,
        payload: {
          lineItemId,
          journalPeriodRange: []
        }
      });
    }
  };

  const handleGLAccountCodeChange = (selectedGLAccountCode) => {
    setIsLineItemFormChanged(true);
    setIsFormChanged(true);

    dispatch({
      type: UPDATED_GL_ACCOUNT_CODE,
      payload: { lineItemId, glAccountCode: selectedGLAccountCode }
    });
    dispatch({
      type: CHANGE_SHOULD_UPDATE_BUDGET,
      payload: true
    });
  };

  const handleBudgetingOptionChange = (event) => {
    setIsLineItemFormChanged(true);
    setIsFormChanged(true);
    dispatch({
      type: UPDATE_BUDGETING_OPTION,
      payload: {
        lineItemId,
        budgetingOption: event.target.value
      }
    });

    dispatch({
      type: CHANGE_SHOULD_UPDATE_BUDGET,
      payload: true
    });

    dispatch({
      type: UPDATE_JOURNAL_RANGE,
      payload: {
        lineItemId,
        journalPeriodRange: []
      }
    });
  };

  const onDismiss = () => {
    dispatch({
      type: LINE_ITEM_API_ERRORED,
      payload: {
        apiErrors: [],
        lineItemId
      }
    });
  };

  const accountCode =
    lineItem.budgetingOption === "budgetLineItem"
      ? lineItem.budgetLineItem?.glCode
      : lineItem.glAccountCode?.glCode;

  const accountId =
    lineItem.budgetingOption === "budgetLineItem"
      ? lineItem.budgetLineItem?.accountId
      : lineItem.glAccountCode?.value;

  const getReportingLineItem = (lineItem) => {
    if (lineItem.budgetingOption === "budgetLineItem") {
      return lineItem.budgetLineItem?.reportingLineDescription || null;
    } else {
      return lineItem.glAccountCode?.reportingLineDescription || null;
    }
  };

  const getBudgetLineItemDescOrGLCodeDesc = (lineItem) => {
    if (lineItem.budgetingOption === "budgetLineItem") {
      return lineItem.budgetLineItem?.budgetLineItem || null;
    } else if (lineItem.budgetingOption === "noBudgetAvailable") {
      return lineItem.glAccountCode?.description || null;
    } else if (lineItem.budgetingOption === "poNumber") {
      return selectedEstInvoiceLineItem?.budgetLineItemDescription || null;
    } else {
      return null;
    }
  };

  const getJournalDescriptionBeforeApproval = () => {
    if (lineItem.budgetingOption === "budgetLineItem") {
      return `${lineItem.budgetLineItem?.budgetLineItem} / ${
        lineItem.budgetLineItem?.glName ||
        lineItem.budgetLineItem?.glDescription
      }`;
    } else if (lineItem.budgetingOption === "noBudgetAvailable") {
      return lineItem.glAccountCode?.label || null;
    } else if (lineItem.budgetingOption === "poNumber") {
      return `${selectedEstInvoiceLineItem?.budgetLineItemDescription} / ${lineItem.glAccountCode?.label}`;
    } else {
      return null;
    }
  };

  const { url } = useRouteMatch();
  const purchaseOrderTooltipId = useUniqueId("Tooltip");
  const selectedEstInvoiceLineItem = poInvoicesListData
    ? poInvoicesListData.estimatedInvoices
        .filter((i) => i.id === lineItem?.poInvoiceScheduleId)[0]
        ?.lineItems.find((i) => i.id === lineItem?.poInvoiceLineItemId)
    : null;

  const isJournalManagementValid = () => {
    const validations = [
      isJournalPeriodRangeValid(lineItem.journalPeriodRange)
    ];
    return validations.every((validation) => Boolean(validation));
  };

  const isBudgetingValid = () => {
    const validations = [
      lineItem.budgetingOption === "budgetLineItem"
        ? lineItem.budgetLineItem
        : lineItem.glAccountCode,
      lineItem.budgetingOption === "poNumber"
        ? lineItem.poNumber &&
          lineItem.poInvoiceScheduleId &&
          lineItem.poInvoiceLineItemId &&
          (lineItem.poInvoiceLineItemId ===
            lineItem.persistedPoNumber?.poInvoiceLineItemId &&
          lineItem.poInvoiceScheduleId ===
            lineItem.persistedPoNumber?.poInvoiceScheduleId
            ? true
            : lineItem.poAvailableAmount >= lineItem.netAmount)
        : true,
      lineItem.budgetingOption !== ""
    ];
    return validations.every((validation) => Boolean(validation));
  };

  return (
    <div className="line-item-form">
      <div>
        <Alert
          color="danger"
          toggle={onDismiss}
          isOpen={!!lineItem.apiErrors.length}
        >
          <ul className="error-list">
            {lineItem.apiErrors.map((error, index) => {
              return <li key={index}>{error.errorMessage}</li>;
            })}
          </ul>
        </Alert>
      </div>
      <div>
        {accountId &&
        !getReportingLineItem(lineItem) &&
        (invoiceDetails?.enriched ? true : isLineItemFormChanged) ? (
          <Alert color="warning">
            {`GLCode - ${accountCode} ${Labels.Payments.Invoices.Details.noOpexLineItemError}`}
          </Alert>
        ) : null}
      </div>
      <div className="line-item-header">
        <div className="mr-auto d-flex flex-column m-0 p-0 justify-content-between col">
          <span className="mr-3">
            <span className="d-flex">
              <h3
                id={"lineItemDecsription" + purchaseOrderTooltipId}
                className="cardTitle text-truncate"
              >
                <span>{lineItem.description}</span>
              </h3>
              <Tooltip target={"lineItemDecsription" + purchaseOrderTooltipId}>
                {lineItem.description}
              </Tooltip>
              {isLineItemCardHasErrors ||
              !isLineItemDataValid() ||
              lineItem.prepaymentError ? (
                <>
                  <FontAwesomeIcon
                    id={"prepaymentErr" + purchaseOrderTooltipId}
                    icon={faExclamationCircle}
                    className="text-danger h5 m-0 p-0 pl-1 pt-1"
                  />
                  <Tooltip target={"prepaymentErr" + purchaseOrderTooltipId}>
                    {Labels.CommonModals.lineItemWarnMessage}
                  </Tooltip>
                </>
              ) : null}
            </span>
          </span>
          <span
            id={"RLIDesc" + purchaseOrderTooltipId}
            className="cardSubtitile text-truncate"
          >
            {getReportingLineItem(lineItem)}
          </span>
          <Tooltip
            target={"RLIDesc" + purchaseOrderTooltipId}
            placement="bottom-start"
          >
            {getReportingLineItem(lineItem)}
          </Tooltip>
        </div>
        <div className="d-flex flex-column align-items-right justify-content-between">
          <ConditionalRenderer
            conditions={[
              invoiceDetails.assetCurrency !== invoiceDetails.invoiceCurrency
            ]}
          >
            <span className="field-label pb-2">
              {Labels.Payments.Invoices.Details.invoiceCurrency}
            </span>
          </ConditionalRenderer>
          <span className={classNames("amount pb-1")}>
            <span className="pr-3">{Labels.CommonModals.net}</span>
            {formatAmount(lineItem.netAmount, true)}
          </span>
          <span className={classNames("amount")}>
            <span className="pr-3">{Labels.CommonModals.gross}</span>
            {formatAmount(lineItem.netAmount + lineItem.taxAmount, true)}
          </span>
        </div>
        <ConditionalRenderer
          conditions={[
            invoiceDetails.assetCurrency !== invoiceDetails.invoiceCurrency
          ]}
        >
          <div className="ml-5 d-flex flex-column align-items-right justify-content-between">
            <span className="field-label pb-2">
              {Labels.Payments.Invoices.Details.baseCurrency}
            </span>
            <span className="amount pb-1">
              <span className="pr-3">{Labels.CommonModals.net}</span>
              {formatAmount(lineItem.netAmount * invoiceDetails.exchangeRate)}
            </span>
            <span className="amount">
              <span className="pr-3">{Labels.CommonModals.gross}</span>
              {formatAmount(
                (lineItem.netAmount + lineItem.taxAmount) *
                  invoiceDetails.exchangeRate
              )}
            </span>
          </div>
        </ConditionalRenderer>
        <div className="d-flex align-items-center justify-content-center ml-3">
          <button
            className="btn btn-link link-primary m-0 p-0"
            onClick={(event) => {
              toggle();
              // Reset to details tab on open
              handleTabChange(
                undefined,
                Labels.Payments.Invoices.Payments.details
              );
            }}
            disabled={editInvoiceMutation.isError}
          >
            <FontAwesomeIcon
              className="m-0 p-0 mr-1 ml-1 align-middle"
              size="2x"
              icon={isOpen ? faAngleUp : faAngleDown}
            />
          </button>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="fv-border mt-3"></div>
        <div className="history-comments-attachments body-primary my-3 color-text-primary">
          <div className="tabs-main ">
            <button
              className={classNames("link-primary", {
                "active-tab":
                  activeTab === Labels.Payments.Invoices.Payments.details
              })}
              data-tab-name={Labels.Payments.Invoices.Payments.details}
              onClick={handleTabChange}
            >
              {Labels.Payments.Invoices.Payments.details}
              <TabStatus type={isLineItemDataEdited ? "warning" : "complete"} />
            </button>
            <button
              className={classNames("link-primary", {
                "active-tab":
                  activeTab === Labels.Payments.Invoices.Payments.budgeting
              })}
              data-tab-name={Labels.Payments.Invoices.Payments.budgeting}
              onClick={(event) => {
                handleSave();
                return handleTabChange(event);
              }}
              disabled={
                editInvoiceMutation.isError ||
                editInvoiceMutation.isLoading ||
                !isLineItemDataValid()
              }
            >
              {Labels.Payments.Invoices.Payments.budgeting}
              <TabStatus
                type={
                  !isLineItemDataEdited && isBudgetingValid()
                    ? "complete"
                    : "incomplete"
                }
              />
            </button>
            <button
              className={classNames("link-primary", {
                "active-tab":
                  activeTab ===
                  Labels.Payments.Invoices.Payments.journalManagement
              })}
              data-tab-name={
                Labels.Payments.Invoices.Payments.journalManagement
              }
              onClick={(event) => {
                handleSave();
                return handleTabChange(event);
              }}
              disabled={
                editInvoiceMutation.isError ||
                editInvoiceMutation.isLoading ||
                !isLineItemDataValid()
              }
            >
              {Labels.Payments.Invoices.Payments.journalManagement}
              <TabStatus
                type={
                  !isLineItemDataEdited && isJournalManagementValid()
                    ? "complete"
                    : "incomplete"
                }
              />
            </button>
          </div>
        </div>
        <ConditionalRenderer
          conditions={[
            activeTab === Labels.Payments.Invoices.Payments.details,
            isOpen
          ]}
        >
          <EditPILineItem
            invoiceDetails={invoiceDetails}
            invoiceLineItem={invoiceLineItem}
            lineItemData={lineItem.invoiceLineItem}
            setInvoiceLineItem={setInvoiceLineItem}
            isFormReadOnly={isFormReadOnly}
          />
        </ConditionalRenderer>
        <ConditionalRenderer
          conditions={[
            activeTab === Labels.Payments.Invoices.Payments.budgeting
          ]}
        >
          <div className="m-0 p-0">
            {!isCreditNote() && (
              <span className="field-label">
                {Labels.CommonModals.selectOneOfTheFollowing}
                <span className="text-danger">*</span>
              </span>
            )}
            <div className="d-flex mt-2 pt-1">
              <FormGroup className="custom-control custom-radio m-0">
                <Input
                  type="radio"
                  disabled={isFormReadOnly}
                  name={`budgetingOption-${lineItemId}`}
                  onChange={handleBudgetingOptionChange}
                  value="budgetLineItem"
                  checked={lineItem.budgetingOption === "budgetLineItem"}
                  id={`budgetLineItem-${lineItemId}`}
                  className="custom-control-input with-checked"
                />
                <Label
                  check
                  for={`budgetLineItem-${lineItemId}`}
                  className="custom-control-label row-header-2"
                >
                  {Labels.Payments.Invoices.Details.budgetLineItem}
                </Label>
              </FormGroup>

              <FormGroup className="custom-control custom-radio m-0">
                <Input
                  type="radio"
                  disabled={
                    isFormReadOnly || lineItem.netAmount < 0 || isCreditNote()
                  }
                  name={`budgetingOption-${lineItemId}`}
                  onChange={handleBudgetingOptionChange}
                  value="poNumber"
                  checked={lineItem.budgetingOption === "poNumber"}
                  id={`poNumber-${lineItemId}`}
                  className="custom-control-input with-checked"
                />
                <Label
                  check
                  for={`poNumber-${lineItemId}`}
                  className="custom-control-label row-header-2"
                  id={purchaseOrderTooltipId}
                >
                  {Labels.Payments.Invoices.Details.purchaseOrder}
                  {lineItem.netAmount < 0 ? (
                    <>
                      {" "}
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="text-info"
                      />
                      <Tooltip target={purchaseOrderTooltipId}>
                        {isCreditNote()
                          ? Labels.Payments.Invoices.Details
                              .NoPurchaseCreditNoteToPO
                          : Labels.Payments.Invoices.Details
                              .negativeInvoicePOAssociationInfoMsg}
                      </Tooltip>
                    </>
                  ) : null}
                </Label>
              </FormGroup>
              <FormGroup className="custom-control custom-radio m-0">
                <Input
                  type="radio"
                  disabled={isFormReadOnly}
                  name={`budgetingOption-${lineItemId}`}
                  onChange={handleBudgetingOptionChange}
                  value="noBudgetAvailable"
                  checked={lineItem.budgetingOption === "noBudgetAvailable"}
                  id={`noBudgetAvailable-${lineItemId}`}
                  className="custom-control-input with-checked"
                />
                <Label
                  check
                  for={`noBudgetAvailable-${lineItemId}`}
                  className="custom-control-label row-header-2"
                >
                  {Labels.Payments.Invoices.Details.noBudgetAvailable}
                </Label>
              </FormGroup>
            </div>
          </div>
          {lineItem.budgetingOption === "poNumber" ? (
            <>
              {lineItem.poAvailableAmount < lineItem.netAmount &&
              (!lineItem.persistedPoNumber
                ? true
                : lineItem.poInvoiceLineItemId !==
                    lineItem.persistedPoNumber?.poInvoiceLineItemId &&
                  lineItem.poInvoiceScheduleId !==
                    lineItem.persistedPoNumber?.poInvoiceScheduleId) && // If previously saved PO and currently selected PO are same then do not show this error
              lineItem.poNumber?.value ? (
                <Alert color="danger" className="mt-3">
                  {Labels.Payments.Invoices.Details.poAvailableAmountValidation}
                </Alert>
              ) : null}
              <div className="d-flex justify-content-between mt-3">
                <div className="w-50 ">
                  <label className="field-label">
                    {Labels.Payments.Invoices.Details.purchaseOrder}
                    <span className="text-danger">*</span>
                  </label>
                  <Select
                    isDisabled={isFormReadOnly || lineItem.netAmount < 0}
                    isClearable={true}
                    options={poOptionsList}
                    value={lineItem.poNumber}
                    onChange={handlePONumberChange}
                    aria-label="line-item-po-select"
                  />
                </div>
                <div className="d-flex">
                  <div className="d-flex flex-column align-items-right justify-content-center mr-5">
                    <span className="amount-label field-label">
                      {Labels.Payments.Invoices.Details.poAmount}
                    </span>
                    <span className="amount mt-2">
                      {lineItem.poNumber === null
                        ? "-"
                        : formatAmount(lineItem.poNumber.poAmount, true)}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-right justify-content-center">
                    <span className={classNames("field-label amount-label")}>
                      {Labels.Payments.Invoices.Details.poAvailableAmount}
                    </span>
                    <span
                      className={classNames("amount mt-2", {
                        "text-danger":
                          lineItem.poAvailableAmount < lineItem.netAmount
                      })}
                    >
                      {lineItem.poNumber === null
                        ? "-"
                        : formatAmount(lineItem.poAvailableAmount, true)}
                    </span>
                  </div>
                </div>
              </div>
              <br />
              <div className="fv-border"></div>
              <div>
                <div className="d-flex justify-content-end">
                  <button
                    id={"selectPOAssociation" + purchaseOrderTooltipId}
                    onClick={togglePOAssociationModal}
                    className="btn btn-link p-0 m-2"
                    disabled={!lineItem.poNumber?.value || isFormReadOnly}
                  >
                    {Labels.CommonModals.edit}
                    <span className="pl-2">
                      <FontAwesomeIcon className="h6 p-0 m-0" icon={faEdit} />
                    </span>
                  </button>
                  <Tooltip
                    target={"selectPOAssociation" + purchaseOrderTooltipId}
                  >
                    {Labels.Payments.Invoices.Details.selectPOAssociation}
                  </Tooltip>
                </div>
                <div className="po-association-details row col-12">
                  <div className="d-flex flex-column justify-content-start">
                    <span className="column-header-1 color-dark pb-1">
                      {Labels.Payments.Invoices.Details.productDescDot}
                    </span>
                    <span>
                      {selectedEstInvoiceLineItem?.productDescription || "-"}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-start">
                    <span className="column-header-1 color-dark pb-1">
                      {Labels.Payments.Invoices.Details.budgetLineDescDot}
                    </span>
                    <span>
                      {selectedEstInvoiceLineItem?.budgetLineItemDescription ||
                        "-"}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-start">
                    <span className="column-header-1 color-dark pb-1">
                      {Labels.Payments.Invoices.Details.gLCodeDescDot}
                    </span>
                    <span>
                      {selectedEstInvoiceLineItem?.glCodeDescription || "-"}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <span className="column-header-1 color-dark text-left pb-1">
                      {Labels.Payments.Invoices.Details.netAmount}
                    </span>
                    <span className="text-left">
                      {formatAmount(
                        selectedEstInvoiceLineItem?.netAmount,
                        true
                      )}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <span className="column-header-1 color-dark text-left pb-1">
                      {Labels.Payments.Invoices.Details.grossAmount}
                    </span>
                    <span className="text-left">
                      {formatAmount(
                        selectedEstInvoiceLineItem?.grossAmount,
                        true
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {lineItem.budgetingOption === "budgetLineItem" ? (
            <>
              <div className="w-50 mt-3">
                <label className="field-label">
                  {Labels.Payments.Invoices.Details.budgetLineItem}
                  <span className="text-danger">*</span>
                </label>
                <Select
                  isClearable={true}
                  options={
                    budgetLineItemsList?.filter(
                      (i) =>
                        !state.lineItems
                          .map((i) => i.budgetLineItem?.value)
                          .includes(i.value)
                    ) || []
                  }
                  isDisabled={
                    !isBudgetLineItemsListSuccess ||
                    // lineItem.noBudgetAvailable ||
                    // lineItem.poNumber ||
                    isFormReadOnly
                  }
                  onChange={handleBudgetLineItemChange}
                  value={lineItem.budgetLineItem}
                  aria-label="line-item-bli"
                />
              </div>
            </>
          ) : null}
          <span className="fv-border d-block my-3" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="w-50">
              <label className="field-label">
                {Labels.Payments.Invoices.Details.glName}
                <span className="text-danger">*</span>
              </label>

              {lineItem.budgetingOption === "budgetLineItem" ? (
                <span className="value-text">
                  {lineItem.budgetLineItem !== null
                    ? lineItem.budgetLineItem.glCode +
                      " - " +
                      lineItem.budgetLineItem.glName
                    : "-"}
                </span>
              ) : (
                <Select
                  options={
                    glCodesList?.filter(
                      (i) =>
                        !state.lineItems
                          .map((i) => i.glAccountCode?.value)
                          .includes(i.value)
                    ) || []
                  }
                  isDisabled={
                    !isGlCodesListSuccess ||
                    isFormReadOnly ||
                    lineItem.budgetingOption === "budgetLineItem" ||
                    lineItem.budgetingOption === "poNumber"
                  }
                  value={lineItem.glAccountCode}
                  isClearable={true}
                  onChange={handleGLAccountCodeChange}
                  aria-label="no-budget-gl-name"
                />
              )}
            </div>
            <div>
              <label className="field-label text-right">
                {Labels.Payments.Invoices.Details.reportingLineItem}
              </label>
              <div className="d-flex justify-content-end">
                <span className="value-text">
                  {getReportingLineItem(lineItem)}
                </span>
              </div>
            </div>
          </div>
          {lineItem.budgetLineItem?.value ? (
            <span className="fv-border d-block my-3" />
          ) : null}
          <Budgeting
            assetId={invoiceDetails.assetId}
            invoiceId={invoiceDetails.id}
            currency={currency}
            lineItemId={lineItemId}
            budgetLineItemDescription={getBudgetLineItemDescOrGLCodeDesc(
              lineItem
            )}
            accountId={accountId}
            invoiceDetails={invoiceDetails}
            budgetLineItemId={lineItem.budgetLineItem?.value}
            opexLineItem={
              lineItem.budgetLineItem?.reportingLineDescription ||
              lineItem.glAccountCode?.reportingLineDescription
            }
            lineItem={lineItem}
            isUpdateBudgetNeeded={isUpdateBudgetNeeded}
          />
        </ConditionalRenderer>
        <ConditionalRenderer
          conditions={[
            activeTab === Labels.Payments.Invoices.Payments.journalManagement
          ]}
        >
          <JournalManagement
            currency={currency}
            fromDate={lineItem.fromDate}
            toDate={lineItem.toDate}
            isFormReadOnly={isFormReadOnly}
            invoiceDetails={invoiceDetails}
            accountId={accountId}
            lineItemId={lineItemId}
            lineItem={lineItem}
            setIsFormChanged={setIsFormChanged}
            setIsLineItemFormChanged={setIsLineItemFormChanged}
            getJournalDescriptionBeforeApproval={
              getJournalDescriptionBeforeApproval
            }
            isLineItemCardHasErrors={isLineItemCardHasErrors}
            assetDetails={assetDetails}
          />
        </ConditionalRenderer>
      </Collapse>
      {isPOAssociationModalOpen && poInvoicesListData ? (
        <POInvoiceSelectorModal
          isOpen={isPOAssociationModalOpen}
          poUrl={
            "/#" +
            url
              .replace("invoices", "purchaseorders/edit-po")
              .replace(invoiceDetails.id, lineItem.poNumber?.value)
          }
          poInvoiceScheduleId={lineItem.poInvoiceScheduleId}
          poInvoiceLineItemId={lineItem.poInvoiceLineItemId}
          prevPoInvoiceLineItemId={lineItem?.prevPoInvoiceLineItemId}
          lineItemId={lineItemId}
          handleInvoiceSelection={({
            poInvoiceScheduleId,
            poInvoiceLineItemId,
            // This should be glAccountId
            glAccountId
          }) => {
            setIsFormChanged(true);
            dispatch({
              type: CHANGE_PO_INVOICE_SCHEDULED,
              payload: {
                lineItemId,
                poInvoiceScheduleId,
                poInvoiceLineItemId
              }
            });

            const glAccountCode = glCodesList?.find(
              (glCodeItem) => glCodeItem.value === glAccountId
            );

            console.assert(!glAccountCode, "fv: glAccountCode is not found");
            handleGLAccountCodeChange(glAccountCode);

            togglePOAssociationModal();
          }}
          poInvoicesListData={poInvoicesListData}
          toggle={togglePOAssociationModal}
          currency={getSymbolFromCurrencyCode(invoiceDetails?.invoiceCurrency)}
          lineItemNetAmount={lineItem.netAmount}
        />
      ) : null}
    </div>
  );
}

function TabStatus({ type }) {
  switch (type) {
    case "complete":
      return (
        <FontAwesomeIcon icon={faCheckCircle} className="ml-1 text-success" />
      );
    case "incomplete":
      return (
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="ml-1 text-warning"
        />
      );
    case "warning":
      return <FontAwesomeIcon icon={faCircle} className="ml-1 text-warning" />;
    default:
      return null;
  }
}
