import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getPurchaseOrdersForAssetAndSupplier } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";
import { sortObjectListByKey } from "../utils/string";

/**
 * Returns POs for given asset & supplier
 */
export default function useGetPurchaseOrdersForAssetAndSupplier({
  assetId,
  currencyCode,
  supplierId,
  isBlocking = false
}) {
  const request = isBlocking ? httpRequest : nonBlockingHttpRequest;
  const query = useQuery({
    queryKey: [
      QueryKeys.getPurchaseOrdersForAssetAndSupplier,
      {
        assetId,
        currencyCode,
        supplierId
      }
    ],
    queryFn: () =>
      request({
        method: "get",
        url: getPurchaseOrdersForAssetAndSupplier({
          assetId,
          currencyCode,
          supplierId
        })
      })
        .then((res) =>
          Promise.resolve(
            sortObjectListByKey(
              res.data.map((i) => ({
                ...i,
                value: i.id,
                label: i.id + " - " + i.poDescription
              })),
              "label"
            )
          )
        )
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!(assetId && supplierId && currencyCode)
  });

  return query;
}
