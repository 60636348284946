import React from "react";
import { Labels } from "../../../../../../constants/Constants";
import { Alert } from "reactstrap";
import { generatePath, useParams } from "react-router-dom";

export function PrepaymentDataErrorMessage({
  errors,
  className,
  primaryOrgId
}) {
  const params = useParams();

  let errorMessage = null;
  if (!Array.isArray(errors)) {
    return null;
  }

  if (
    errors.length > 0 &&
    errors[0].errorMessage.includes(
      Labels.Payments.Invoices.JournalManagement.closedPostingPeriodErrorMessage
    )
  ) {
    errorMessage = (
      <>
        {
          Labels.Payments.Invoices.JournalManagement
            .closedPostingPeriodErrorMessage
        }
        <a
          href={generatePath(
            `/#/org/:orgId/assets/asset/:assetId/settings/closingperiod`,
            {
              ...params,
              // At all assets level, orgId is not present on URL
              orgId: params.orgId || primaryOrgId
            }
          )}
          target={"_blank"}
          rel="noreferrer"
        >
          {" "}
          {Labels.Payments.Invoices.JournalManagement.clickHereToReopen}
        </a>
      </>
    );
  } else {
    errorMessage = errors.map((error) => error.errorMessage).join(".");
  }

  return (
    <Alert color="danger" className={className}>
      {errorMessage}
    </Alert>
  );
}
