// @ts-check
import React from "react";
import { Labels } from "../constants/Constants";

export default function CopyInstrumentSuccessMessage({ message, url }) {
  // @ts-ignore

  return (
    <>
      {message}.
      <a href={"/#" + url} target="_blank" rel="noopener noreferrer">
        {Labels.CommonModals.clickHere}.
      </a>
    </>
  );
}
