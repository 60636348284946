import React from "react";
import { useRouteMatch } from "react-router-dom";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Alert,
  ModalHeader
} from "reactstrap";
import { Labels } from "../../../../../../constants/Constants";
import {
  convertToFloat,
  formatAmountWithThousandSeparatorsToPrecision
} from "../../../../../../utils/number";
import useBudgetingDrilldownData from "./useBudgetingDrilldownData";

export default function IncurredAmountDrillDownModal({
  currency,
  title,
  budgetAmount,
  invoiceDetails,
  toggle,
  isOpen,
  accountId,
  budgetLineItemId,
  userClickedRow,
  lineItemId,
  isMTD,
  incurredAmount,
  isUpdateBudgetNeeded
}) {
  const { drillDownData, apiErrors } = useBudgetingDrilldownData({
    assetId: invoiceDetails.assetId,
    invoiceId: invoiceDetails.id,
    accountId,
    budgetLineItemId,
    userClickedRow,
    lineItemId,
    isMTD
  });

  let drillDownDataMutated = [];
  if (drillDownData?.incurredInvoiceList) {
    if (isUpdateBudgetNeeded) {
      drillDownDataMutated = drillDownData.incurredInvoiceList.filter(
        (i) => i.invoiceId !== invoiceDetails.id
      );
      drillDownDataMutated.push({
        invoiceId: invoiceDetails.id,
        invoiceNumber: invoiceDetails.invoiceNumber,
        incurredAmount
      });
    } else {
      drillDownDataMutated = drillDownData.incurredInvoiceList;
    }
  }

  const {
    url,
    params: { invoiceId }
  } = useRouteMatch();

  const formatAmount = (number) => {
    if (!isNaN(parseFloat(number))) {
      return (
        currency +
        formatAmountWithThousandSeparatorsToPrecision(
          parseFloat(number).toFixed(2)
        )
      );
    } else {
      return "-";
    }
  };

  const drillDownTable =
    drillDownDataMutated.length === 0 ? null : (
      <div className="small-bottom-bordered-table-container mb-3">
        <table className="table table-borderless">
          <thead>
            <tr className="text-center bordered-row">
              <th className="text-left">
                {Labels.Payments.Invoices.Details.extRefNo}
              </th>
              <th className="text-right">
                {Labels.Payments.Invoices.Details.incurred}
              </th>
            </tr>
          </thead>
          <tbody>
            {drillDownDataMutated.map((i, index) => (
              <tr key={index} className="bordered-row ">
                <td className="text-left">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"/#" + url.replace(invoiceId, i.invoiceId)}
                  >
                    {i.invoiceNumber}
                  </a>
                </td>
                <td className="text-right">{formatAmount(i.incurredAmount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );

  const getTotalIncurred = () => {
    let totalIncurred = 0;
    drillDownDataMutated.forEach((i) => (totalIncurred += i.incurredAmount));
    totalIncurred = isMTD
      ? totalIncurred
      : totalIncurred + (drillDownData?.incurredOpeningBalance || 0);
    return convertToFloat(totalIncurred, 2);
  };

  const getRemainingAvailableBudget = () => {
    const remainingAvailableAmount = budgetAmount - getTotalIncurred();

    return isNaN(parseFloat(remainingAvailableAmount))
      ? 0
      : remainingAvailableAmount.toFixed(2);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={"className"} centered>
      <ModalHeader className="pb-0" toggle={toggle}>
        <h2 className="color-dark modal-title">{title}</h2>
      </ModalHeader>
      <ModalBody>
        <Alert
          color="danger"
          className="mb-4"
          isOpen={apiErrors?.length > 0}
          // toggle={onErrorsDismiss}
        >
          <ul className="error-list">
            {apiErrors?.map((error, index) => {
              return (
                <li key={index}>
                  {error.errorMessage || Labels.CommonModals.unknownError}
                </li>
              );
            })}
          </ul>
        </Alert>
        <dl className="mb-0">
          <div>
            <div className="d-flex">
              <dt className="mr-1">
                {Labels.Payments.Invoices.Details.availableBudget}:
              </dt>
              <dd>
                {currency}
                {formatAmountWithThousandSeparatorsToPrecision(budgetAmount)}
              </dd>
            </div>
            <div className="d-flex">
              <dt className="mr-1">
                {Labels.Payments.Invoices.Details.incurredOpeningBalance}:
              </dt>
              <dd>
                {currency}
                {formatAmountWithThousandSeparatorsToPrecision(
                  drillDownData.incurredOpeningBalance
                )}
              </dd>
            </div>
          </div>
        </dl>
        {drillDownTable}
        <div>
          <div className="d-flex">
            <dt className="mr-1">
              {Labels.Payments.Invoices.Details.totalIncurred}:
            </dt>
            <dd>
              {currency}
              {formatAmountWithThousandSeparatorsToPrecision(
                getTotalIncurred()
              )}
            </dd>
          </div>
          <div className="d-flex ">
            <dt className="mr-1">
              {Labels.Payments.Invoices.Details.remainingAvailableBudget}:
            </dt>
            <dd>
              {currency}
              {formatAmountWithThousandSeparatorsToPrecision(
                getRemainingAvailableBudget()
              )}
            </dd>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
