import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import reportWebVitals from "./reportWebVitals";

// Add "development" to the list of environments to run the app with mock data
const mockedEnvironments = [];
// const mockedEnvironments = ["development"];
if (mockedEnvironments.includes(process.env.NODE_ENV)) {
  const { worker } = require("./mocks/browser");
  worker.start();
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  // https://reactjs.org/docs/strict-mode.html
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
