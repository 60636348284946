import classNames from "classnames";
import { useState } from "react";
import { Labels } from "../../constants/Constants";
import HistoryAndComments from "./HistoryAndComments";
import ViewAttachments from "./ViewAttachments";

export default function HistoryCommentsAndAttachments({
  disableFileUpload,
  disableAddComments,
  endpoints,
  module,
  hideAttachments
}) {
  const [activeTab, setActiveTab] = useState(
    Labels.Payments.AccountsReceivables.commentsHistory
  );

  const handleTabChange = (event) => {
    event.preventDefault();
    setActiveTab(event.target.dataset.tabName);
  };

  const getTitle = () => {
    if (hideAttachments || module === "transactions") {
      return (
        <div>{Labels.Payments.AccountsReceivables.commentsAndHistory}</div>
      );
    } else {
      return (
        <div>
          {Labels.Payments.AccountsReceivables.commentsHistoryAndAttachments}
        </div>
      );
    }
  };

  return (
    <div
      className={`${
        module !== "transactions" ? "fv-box" : ""
      } history-comments-attachments`}
    >
      <h3
        className={classNames(
          "color-dark",
          hideAttachments ? "pb-3 border-bottom-fv-border-2px" : "mb-3"
        )}
      >
        {getTitle()}
      </h3>
      <div className={classNames("tabs-main", { "d-none": hideAttachments })}>
        <button
          className={classNames({
            "active-tab":
              activeTab === Labels.Payments.AccountsReceivables.commentsHistory
          })}
          data-tab-name={Labels.Payments.AccountsReceivables.commentsHistory}
          onClick={handleTabChange}
        >
          {Labels.Payments.AccountsReceivables.commentsHistory}
        </button>
        <button
          className={classNames({
            "active-tab":
              activeTab === Labels.Payments.AccountsReceivables.attachments
          })}
          data-tab-name={Labels.Payments.AccountsReceivables.attachments}
          onClick={handleTabChange}
        >
          {Labels.Payments.AccountsReceivables.attachments}
        </button>
      </div>
      {activeTab === Labels.Payments.AccountsReceivables.attachments ? (
        <ViewAttachments
          disableFileUpload={disableFileUpload}
          endpoints={endpoints}
          module={module}
        />
      ) : (
        <HistoryAndComments
          endpoints={endpoints}
          disableAddComments={disableAddComments}
        />
      )}
    </div>
  );
}
