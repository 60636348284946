import NiceModal, { useModal } from "@ebay/nice-modal-react";
import HotTable, { HotColumn } from "@handsontable/react";
import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import DocsViewerModal from "../../../../../elements/modals/DocsViewerModal";
import InvoiceAmountColumn from "./InvoiceAmountColumn";
import POColumn from "./POColumn";

export default NiceModal.create(
  ({ transactionsData, title, period, orgId, duration, assetId }) => {
    const modal = useModal();
    const [isDocsViewerModalOpen, setDocsViewerModalState] = useState(false);
    const toggleDocsViewerModal = () =>
      setDocsViewerModalState(!isDocsViewerModalOpen);
    const [invoiceDocs, setInvoiceDocs] = useState([]);
    let readOnlySetting = {
      readOnly: true
    };
    const transactionsDataSchema = {
      accountCode: null,
      amount: null,
      description: null,
      budgetLineItemDescription: null,
      period: null,
      isPOLineItem: null,
      purchaseOrderId: null,
      supplierName: null,
      transactionDate: null,
      transactionType: null,
      documents: null,
      transactionDescription: null
    };

    const populateInvoices = (event, documents) => {
      event.preventDefault();
      let docs = [];
      documents.forEach((doc) => {
        docs.push({
          fileName: doc.fileName,
          fileURL: doc.cloudStorageUrl,
          fileFormat: doc.cloudStorageUrl.match(/\.[a-z]+$/i)[0].toLowerCase()
        });
      });
      setInvoiceDocs(docs);
      setDocsViewerModalState(true);
    };

    function handleAfterFilter() {
      this.render();
    }

    function handleAfterGetColHeader(col, TH) {
      if (col !== 0 && col !== 2 && col !== 7) {
        const button = TH.querySelector(".changeType");
        if (!button) {
          return;
        }
        button.parentElement.removeChild(button);
      }
    }

    return (
      <Modal
        keyboard={false}
        backdrop="static"
        centered
        fade={false}
        size="xl"
        isOpen={modal.visible}
        toggle={modal.remove}
      >
        <div className="modal-header">
          <h2 className="color-dark modal-title">{`${title} ${
            duration > 1 ? "from" : "as on"
          } ${period}`}</h2>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={modal.remove}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody className="mt-0 pt-0">
          <div className="income-statement-transactions-table">
            <HotTable
              data={transactionsData}
              dataSchema={transactionsDataSchema}
              fillHandle={false}
              width={"1105"}
              colWidths={100}
              disableVisualSelection={false}
              manualColumnResize={false}
              licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
              renderAllRows={true}
              filters={true}
              dropdownMenu={[
                "---------",
                "filter_by_condition",
                "filter_by_value",
                "filter_action_bar"
              ]}
              afterGetColHeader={handleAfterGetColHeader}
              afterFilter={handleAfterFilter}
            >
              <HotColumn
                title={Labels.Assets.IncomeStatement.transactionDate}
                data="transactionDate"
                type={"date"}
                settings={readOnlySetting}
                width={130}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.periodPosted}
                data="period"
                settings={readOnlySetting}
                width={100}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.contactName}
                placeholder={Labels.CommonModals.NA}
                data="supplierName"
                settings={readOnlySetting}
                width={120}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.budgetLineItemPO}
                placeholder={Labels.CommonModals.NA}
                data="budgetLineItemDescription"
                settings={readOnlySetting}
                width={150}
              >
                <POColumn orgId={orgId} assetId={assetId} hot-renderer />
              </HotColumn>
              <HotColumn
                title={Labels.Assets.IncomeStatement.glCode}
                data="accountCode"
                className="text-break"
                settings={readOnlySetting}
                width={75}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.glDescription}
                data="description"
                settings={readOnlySetting}
                width={110}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.transactionDescription}
                data="transactionDescription"
                settings={readOnlySetting}
                width={170}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.transactionType}
                data="transactionType"
                settings={readOnlySetting}
                width={140}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.amount}
                data="amount"
                settings={readOnlySetting}
                width={110}
              >
                <InvoiceAmountColumn
                  populateInvoices={populateInvoices}
                  orgId={orgId}
                  assetId={assetId}
                  hot-renderer
                />
              </HotColumn>
            </HotTable>
            <DocsViewerModal
              isDocsViewerModalOpen={isDocsViewerModalOpen}
              toggleDocsViewerModal={toggleDocsViewerModal}
              documents={invoiceDocs}
              containerId="invoice-drilldown"
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
);
