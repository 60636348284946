export const Privileges = {
  VIEW_HOME: "VIEW_HOME",
  VIEW_GROUPS: "VIEW_GROUPS",
  VIEW_TASK: "VIEW_TASK",
  CREATE_TASK: "CREATE_TASK",
  VIEW_TASK_DOCUMENT: "VIEW_TASK_DOCUMENT",
  UPDATE_TASK: "UPDATE_TASK",
  ADD_TASK_COMMENT: "ADD_TASK_COMMENT",
  VIEW_MANAGEMENT_REPORTS: "VIEW_MANAGEMENT_REPORTS",
  EXPORT_MANAGEMENT_REPORTS: "EXPORT_MANAGEMENT_REPORTS",
  VIEW_SETTINGS: "VIEW_SETTINGS",
  VIEW_SETTINGS_IMPORT_DATA: "VIEW_SETTINGS_IMPORT_DATA",
  ADD_MANAGEMENT_REPORTS_COMMENTS: "ADD_MANAGEMENT_REPORTS_COMMENTS",
  UPLOAD_MASTER_DATA: "UPLOAD_MASTER_DATA",
  VIEW_MASTER_DATA: "VIEW_MASTER_DATA",
  UPLOAD_BUDGET_DATA: "UPLOAD_BUDGET_DATA",
  UPDATE_ASSET_BUDGET: "UPDATE_ASSET_BUDGET",
  SEND_ACTUAL_BUDGET: "SEND_ACTUAL_BUDGET",
  GENERATE_ONEVIEW_DATA: "GENERATE_ONEVIEW_DATA",
  UPLOAD_PURCHASE_ORDER_DATA: "UPLOAD_PURCHASE_ORDER_DATA",
  UPLOAD_ASSET_KPI_DATA: "UPLOAD_ASSET_KPI_DATA",
  UPDATE_BANK_ACCOUNT_BALANCE: "UPDATE_BANK_ACCOUNT_BALANCE",
  IMPORT_INVOICES: "IMPORT_INVOICES",
  VIEW_DEMO_SCREENS: "VIEW_DEMO_SCREENS",
  VIEW_PAYMENTS: "VIEW_PAYMENTS",
  VIEW_SALES_INVOICE_PAYMENT: "VIEW_SALES_INVOICE_PAYMENT",
  VIEW_INVOICE_LIST: "VIEW_INVOICE_LIST",
  ASSET_OWNER_APPROVE_PAYMENTS: "ASSET_OWNER_APPROVE_PAYMENTS",
  APPROVE_PAYMENT: "APPROVE_PAYMENT",
  CREATE_INVOICE: "CREATE_INVOICE",
  VIEW_DOCUMENT_LIST: "VIEW_DOCUMENT_LIST",
  ENRICH_INVOICE: "ENRICH_INVOICE",
  EDIT_INVOICE: "EDIT_INVOICE",
  VIEW_INVOICE: "VIEW_INVOICE",
  VIEW_REAL_TIME_REVENUE_DATA: "VIEW_REAL_TIME_REVENUE_DATA",
  REJECT_INVOICE: "REJECT_INVOICE",
  VIEW_PAYMENT_HISTORY: "VIEW_PAYMENT_HISTORY",
  VIEW_INVOICE_HISTORY: "VIEW_INVOICE_HISTORY",
  CREATE_PAYMENT: "CREATE_PAYMENT",
  EDIT_PAYMENT: "EDIT_PAYMENT",
  SUBMIT_PAYMENT_FOR_APPROVAL: "SUBMIT_PAYMENT_FOR_APPROVAL",
  REJECT_PAYMENT: "REJECT_PAYMENT",
  VOID_PAYMENT: "VOID_PAYMENT",
  MARK_PAYMENT_AS_PAID: "MARK_PAYMENT_AS_PAID",
  UPDATE_BANK_ACCOUNT_DATA: "UPDATE_BANK_ACCOUNT_DATA",
  VIEW_BANK_ACCOUNT_DATA: "VIEW_BANK_ACCOUNT_DATA",
  VIEW_INTER_COMPANY_ACCOUNT: "VIEW_INTER_COMPANY_ACCOUNT",
  GOODS_RECEIVABLE: "GOODS_RECEIVABLE",
  APPROVE_PURCHASE_ORDER: "APPROVE_PURCHASE_ORDER",
  VIEW_PURCHASE_ORDER: "VIEW_PURCHASE_ORDER",
  CREATE_PAYRUN: "CREATE_PAYRUN",
  VIEW_PAYRUN: "VIEW_PAYRUN",
  CREATE_PURCHASE_ORDER: "CREATE_PURCHASE_ORDER",
  UPDATE_PURCHASE_ORDER: "UPDATE_PURCHASE_ORDER",
  ADD_PO_GOODS_RECEIVED: "ADD_PO_GOODS_RECEIVED",
  VIEW_PO_GOODS_RECEIVED: "VIEW_PO_GOODS_RECEIVED",
  UPDATE_PO_GOODS_RECEIVED: "UPDATE_PO_GOODS_RECEIVED",
  DELETE_PO_GOODS_RECEIVED: "DELETE_PO_GOODS_RECEIVED",
  UPLOAD_PURCHASE_ORDER_DOCUMENT: "UPLOAD_PURCHASE_ORDER_DOCUMENT",
  VIEW_PURCHASE_ORDER_HISTORY: "VIEW_PURCHASE_ORDER_HISTORY",
  CREATE_JOURNAL: "CREATE_JOURNAL",
  UPLOAD_TRIAL_BALANCE: "UPLOAD_TRIAL_BALANCE",
  VIEW_BANK_ACCOUNT_BALANCE: "VIEW_BANK_ACCOUNT_BALANCE",
  UPLOAD_BANK_ACCOUNT_STATEMENT: "UPLOAD_BANK_ACCOUNT_STATEMENT",
  VIEW_JOURNAL: "VIEW_JOURNAL",
  UPDATE_JOURNAL: "UPDATE_JOURNAL",
  UPDATE_REPEATING_JOURNAL: "UPDATE_REPEATING_JOURNAL",
  CANCEL_REPEATING_JOURNAL: "CANCEL_REPEATING_JOURNAL",
  CREATE_PORTFOLIO: "CREATE_PORTFOLIO",
  VIEW_PORTFOLIO: "VIEW_PORTFOLIO",
  SUBMIT_SALES_INVOICE_FOR_APPROVAL: "SUBMIT_SALES_INVOICE_FOR_APPROVAL",
  UPLOAD_NONGL_PAYMENT_DATA: "UPLOAD_NONGL_PAYMENT_DATA",
  UNRECONCILE_BANK_STATEMENT_LINE_ITEM: "UNRECONCILE_BANK_STATEMENT_LINE_ITEM",
  RETURN_BANK_STATEMENT_LINE_ITEM_TO_DRAFT:
    "RETURN_BANK_STATEMENT_LINE_ITEM_TO_DRAFT",
  VOID_BANK_STATEMENT_LINE_ITEM: "VOID_BANK_STATEMENT_LINE_ITEM",
  APPROVE_BANK_STATEMENT_LINE_ITEM: "APPROVE_BANK_STATEMENT_LINE_ITEM",
  SUBMIT_BANK_STATEMENT_LINE_ITEM_FOR_APPROVAL:
    "SUBMIT_BANK_STATEMENT_LINE_ITEM_FOR_APPROVAL",
  DELETE_BANK_ACCOUNT_STATEMENT: "DELETE_BANK_ACCOUNT_STATEMENT",
  ADD_BANK_ACCOUNT_STATEMENT_LINE_ITEM_COMMENTS:
    "ADD_BANK_ACCOUNT_STATEMENT_LINE_ITEM_COMMENTS",
  VIEW_BANK_ACCOUNT_STATEMENT_LINE_ITEM_COMMENT:
    "VIEW_BANK_ACCOUNT_STATEMENT_LINE_ITEM_COMMENT",
  VIEW_JOURNAL_BUDGET_LINE_ITEM: "VIEW_JOURNAL_BUDGET_LINE_ITEM",
  REVERT_JOURNAL: "REVERT_JOURNAL",
  UPLOAD_JOURNAL_DATA: "UPLOAD_JOURNAL_DATA",
  APPROVE_JOURNAL: "APPROVE_JOURNAL",
  VIEW_REPEATING_JOURNAL: "VIEW_REPEATING_JOURNAL",
  SUBMIT_JOURNAL_FOR_APPROVAL: "SUBMIT_JOURNAL_FOR_APPROVAL",
  RETURN_JOURNAL: "RETURN_JOURNAL",
  VIEW_JOURNAL_HISTORY_COMMENT: "VIEW_JOURNAL_HISTORY_COMMENT",
  CANCEL_JOURNAL: "CANCEL_JOURNAL",
  ADD_JOURNAL_COMMENT: "ADD_JOURNAL_COMMENT",
  CREATE_REPEATING_JOURNAL: "CREATE_REPEATING_JOURNAL",
  CREATE_CONTACT: "CREATE_CONTACT",
  CREATE_UNVERIFIED_CONTACT: "CREATE_UNVERIFIED_CONTACT",
  VIEW_CONTACT: "VIEW_CONTACT",
  UPDATE_CONTACT: "UPDATE_CONTACT",
  ARCHIVE_CONTACT: "ARCHIVE_CONTACT",
  VIEW_ORGANIZATION: "VIEW_ORGANIZATION",
  CREATE_ORGANIZATION: "CREATE_ORGANIZATION",
  DELETE_ORGANIZATION: "DELETE_ORGANIZATION",
  UPDATE_ORGANIZATION: "UPDATE_ORGANIZATION",
  VIEW_TAX: "VIEW_TAX",
  ARCHIVE_TAX: "ARCHIVE_TAX",
  CREATE_TAX: "CREATE_TAX",
  DELETE_TAX: "DELETE_TAX",
  UPDATE_TAX: "UPDATE_TAX",
  VIEW_ORGANIZATION_BUDGET: "VIEW_ORGANIZATION_BUDGET",
  CREATE_ORGANIZATION_BUDGET: "CREATE_ORGANIZATION_BUDGET",
  UPDATE_ORGANIZATION_BUDGET: "UPDATE_ORGANIZATION_BUDGET",
  UPLOAD_ASSET_BUDGET: "UPLOAD_ASSET_BUDGET",
  VIEW_ASSET_BUDGET: "VIEW_ASSET_BUDGET",
  ARCHIVE_GL_ACCOUNT: "ARCHIVE_GL_ACCOUNT",
  CREATE_GL_ACCOUNT: "CREATE_GL_ACCOUNT",
  UPDATE_GL_ACCOUNT: "UPDATE_GL_ACCOUNT",
  UPLOAD_GL_ACCOUNT: "UPLOAD_GL_ACCOUNT",
  VIEW_GL_ACCOUNT: "VIEW_GL_ACCOUNT",
  CREATE_SALES_INVOICE: "CREATE_SALES_INVOICE",
  UPDATE_SALES_INVOICE: "UPDATE_SALES_INVOICE",
  VIEW_SALES_INVOICE: "VIEW_SALES_INVOICE",
  VIEW_TRANSACTIONS: "VIEW_TRANSACTIONS",
  VIEW_FIXED_ASSET_CLASS: "VIEW_FIXED_ASSET_CLASS",
  CREATE_FIXED_ASSET_CLASS: "CREATE_FIXED_ASSET_CLASS",
  ASSIGN_GL_CODES_TO_FIXED_ASSET_CLASS: "ASSIGN_GL_CODES_TO_FIXED_ASSET_CLASS",
  VIEW_FIXED_ASSET_REGISTER: "VIEW_FIXED_ASSET_REGISTER",
  UPDATE_FIXED_ASSET_REGISTER: "UPDATE_FIXED_ASSET_REGISTER",
  UPLOAD_FIXED_ASSET_BLOCK: "UPLOAD_FIXED_ASSET_BLOCK",
  UPDATE_SALES_INVOICE_PAYMENT: "UPDATE_SALES_INVOICE_PAYMENT",
  CREATE_PURCHASE_CREDIT_NOTE_RECEIPT: "CREATE_PURCHASE_CREDIT_NOTE_RECEIPT",
  VIEW_PURCHASE_CREDIT_NOTE_RECEIPT: "VIEW_PURCHASE_CREDIT_NOTE_RECEIPT",
  DELETE_PURCHASE_CREDIT_NOTE_RECEIPT: "DELETE_PURCHASE_CREDIT_NOTE_RECEIPT",
  CREATE_ASSET: "CREATE_ASSET",
  DELETE_ALLOCATION: "DELETE_ALLOCATION",
  UPLOAD_INVOICE_DOCUMENTS: "UPLOAD_INVOICE_DOCUMENTS",
  VIEW_CLOSING_PERIOD: "VIEW_CLOSING_PERIOD",
  CHECKLIST_ASSET: "CHECKLIST_ASSET",
  UPDATE_CLOSING_PERIOD: "UPDATE_CLOSING_PERIOD",
  VIEW_ACCOUNTING: "VIEW_ACCOUNTING",
  SUBMIT_PO_REQUISITION: "SUBMIT_PO_REQUISITION",
  GET_REMITTANCE_DATA: "GET_REMITTANCE_DATA",
  GENERATE_REMITTANCE: "GENERATE_REMITTANCE",
  VIEW_USER: "VIEW_USER",
  CREATE_USER: "CREATE_USER",
  CREATE_ROLE: "CREATE_ROLE",
  VIEW_ROLE: "VIEW_ROLE",
  SEND_SALES_INVOICE_PDF: "SEND_SALES_INVOICE_PDF",
  UPDATE_ROLE: "UPDATE_ROLE",
  UPDATE_MASTER_DATA: "UPDATE_MASTER_DATA",
  VIEW_CONTRACT: "VIEW_CONTRACT",
  GET_CONTRACT_DOCUMENT: "GET_CONTRACT_DOCUMENT",
  CREATE_CONTRACT: "CREATE_CONTRACT",
  UPDATE_CONTRACT: "UPDATE_CONTRACT",
  CODIFY_CONTRACT: "CODIFY_CONTRACT",
  ADD_CONTRACT_DOCUMENTS: "ADD_CONTRACT_DOCUMENTS",
  DELETE_CONTRACT_DOCUMENT: "DELETE_CONTRACT_DOCUMENT",
  CREATE_CONTRACT_MILESTONE: "CREATE_CONTRACT_MILESTONE",
  DELETE_CONTRACT_MILESTONE: "DELETE_CONTRACT_MILESTONE",
  VIEW_CONTRACT_MILESTONES: "VIEW_CONTRACT_MILESTONES",
  UPLOAD_TAGGED_INVOICES: "UPLOAD_TAGGED_INVOICES",
  SEND_PURCHASE_ORDER_PDF: "SEND_PURCHASE_ORDER_PDF",
  CREATE_UTILISATION_TEMPLATE: "CREATE_UTILISATION_TEMPLATE",
  GET_UTILISATION_TEMPLATES: "GET_UTILISATION_TEMPLATES",
  GET_UTILISATION_TEMPLATE: "GET_UTILISATION_TEMPLATE",
  UPDATE_UTILISATION_TEMPLATE: "UPDATE_UTILISATION_TEMPLATE",
  ARCHIVE_UTILISATION_TEMPLATE: "ARCHIVE_UTILISATION_TEMPLATE",
  UPLOAD_EVA_INVOICE_DOCUMENT: "UPLOAD_EVA_INVOICE_DOCUMENT",
  EVA_ASSIGN: "EVA_ASSIGN",
  VIEW_EVA_DASHBOARD: "VIEW_EVA_DASHBOARD",
  VIEW_PROJECT_SPEND_ANALYSIS: "VIEW_PROJECT_SPEND_ANALYSIS",
  VIEW_TAGGED_INVOICE_LIST: "VIEW_TAGGED_INVOICE_LIST",
  ADD_TAGGED_INVOICE_COMMENT: "ADD_TAGGED_INVOICE_COMMENT",
  UPDATE_EVA_CONTRACT_TIMELINES: "UPDATE_EVA_CONTRACT_TIMELINES",
  UPDATE_UNREALIZED_CONTINGENCY: "UPDATE_UNREALIZED_CONTINGENCY",
  TAG_INVOICE: "TAG_INVOICE",
  UPDATE_TAGGED_INVOICE_STATUS: "UPDATE_TAGGED_INVOICE_STATUS",
  VIEW_ASSETS: "VIEW_ASSETS",
  UPDATE_ASSET: "UPDATE_ASSET",
  CREATE_CONTRACT_TASK: "CREATE_CONTRACT_TASK",
  VIEW_UTILISATION_REQUESTS: "VIEW_UTILISATION_REQUESTS",
  CREATE_UTILISATION_REQUEST: "CREATE_UTILISATION_REQUEST",
  UPDATE_UTILISATION_REQUEST: "UPDATE_UTILISATION_REQUEST",
  DOWNLOAD_UTILISATION_REQUEST: "DOWNLOAD_UTILISATION_REQUEST",
  SUBMIT_UTILISATION_REQUEST_FOR_APPROVAL:
    "SUBMIT_UTILISATION_REQUEST_FOR_APPROVAL",
  APPROVE_UTILISATION_REQUEST: "APPROVE_UTILISATION_REQUEST",
  MOVE_TO_DRAWN_UTILISATION_REQUEST: "MOVE_TO_DRAWN_UTILISATION_REQUEST",
  RETURN_UTILISATION_REQUEST: "RETURN_UTILISATION_REQUEST",
  VOID_UTILISATION_REQUEST: "VOID_UTILISATION_REQUEST",
  CREATE_TAGGING_CATEGORY: "CREATE_TAGGING_CATEGORY",
  UPDATE_TAGGING_CATEGORY: "UPDATE_TAGGING_CATEGORY",
  ADD_TAGGING_CATEGORY_ATTRIBUTE: "ADD_TAGGING_CATEGORY_ATTRIBUTE",
  ASSIGN_TAGGING_CATEGORY_TO_ASSET: "ASSIGN_TAGGING_CATEGORY_TO_ASSET",
  GET_TAGGING_CATEGORY: "GET_TAGGING_CATEGORY",
  UPDATE_TAGGING_CATEGORY_ATTRIBUTE: "UPDATE_TAGGING_CATEGORY_ATTRIBUTE",
  ADD_ASSET_TAG: "ADD_ASSET_TAG",
  GET_ASSET_TAG: "GET_ASSET_TAG",
  GET_ASSET_USER_CONFIG: "GET_ASSET_USER_CONFIG",
  UPDATE_ASSET_USER_CONFIG: "UPDATE_ASSET_USER_CONFIG",
  VIEW_APPROVER_LIMIT_RULESET: "VIEW_APPROVER_LIMIT_RULESET",
  CREATE_APPROVER_LIMIT_RULESET: "CREATE_APPROVER_LIMIT_RULESET",
  DELETE_CONTACT_DOCUMENT: "DELETE_CONTACT_DOCUMENT",
  VIEW_FAM_EXCHANGE_RATES: "VIEW_FAM_EXCHANGE_RATES",
  ADD_FAM_EXCHANGE_RATES: "ADD_FAM_EXCHANGE_RATES",
  RE_EVALUATE_FX: "RE_EVALUATE_FX",
  CONFIGURE_DATA_SOURCE: "CONFIGURE_DATA_SOURCE",
  VIEW_DATA_SOURCE: "VIEW_DATA_SOURCE",
  CONFIGURE_DEVICE_DATA: "CONFIGURE_DEVICE_DATA",
  VIEW_DEVICE_DATA: "VIEW_DEVICE_DATA",
  UPLOAD_REVENUE_AUTOMATION_DATA: "UPLOAD_REVENUE_AUTOMATION_DATA",
  UPDATE_REVENUE_AUTOMATION_DATA: "UPDATE_REVENUE_AUTOMATION_DATA",
  VIEW_REVENUE_AUTOMATION_DATA: "VIEW_REVENUE_AUTOMATION_DATA",
  CONFIGURE_CONSUMER_PRICE_INDEX: "CONFIGURE_CONSUMER_PRICE_INDEX",
  VIEW_CONSUMER_PRICE_INDEX: "VIEW_CONSUMER_PRICE_INDEX",
  VIEW_POWER_GENERATION_DATA: "VIEW_POWER_GENERATION_DATA"
};
