import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getTaxList } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

export default function useGetTaxList(
  { orgId, assetId, type, status, isBlocking = false },
  options = {}
) {
  const request = isBlocking ? httpRequest : nonBlockingHttpRequest;
  const query = useQuery({
    queryKey: [QueryKeys.getTaxList, orgId, assetId, type, status],
    queryFn: () =>
      request({
        method: "get",
        url: getTaxList({ orgId, assetId, type, status })
      })
        .then((res) =>
          Promise.resolve(
            res.data.map((i) => ({
              ...i,
              label: `${i.taxName} - ${i.taxRate}%`,
              value: i.id
            }))
          )
        )
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!(assetId || orgId),
    ...options
  });

  return query;
}
