import { toTitleCase } from "../utils/string";

const TransactionStatuses = {
  DRAFT: "DRAFT",
  AWAITING_APPROVAL: "AWAITING_APPROVAL",
  RECONCILED: "RECONCILED",
  VOIDED: "VOIDED"
};

export const transactionStatusList = Object.entries(TransactionStatuses).map(
  ([k, v]) => ({
    id: k,
    value: k,
    label: toTitleCase(v.replace(/_/g, " "))
  })
);

export default TransactionStatuses;
