import axios from "axios";
import { ServerError } from "../utils";
import NiceModal from "@ebay/nice-modal-react";
import { modalNames } from "../../elements/modals/modal-register";

/**
 * Create an Axios Instance which will not show loader and is more generic
 */
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

/**
 * Request Wrapper with default success/error actions
 */
export const nonBlockingHttpRequest = async function (options) {
  options.headers = {
    Authorization: "Bearer " + localStorage.getItem("Auth.accessToken"),
    "X-TENANT-ID": sessionStorage.getItem("Auth.tenantId")
  };

  const onSuccess = function (response) {
    console.debug("Request Successful!", response);
    return response;
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
      if (error.response.status === 403) {
        if (
          error.response.data.includes(
            "<h2>403 - Forbidden: Access is denied.</h2>"
          )
        ) {
          NiceModal.show(modalNames.firewallErrorModal);
        } else {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href =
            process.env.REACT_APP_B2C_LOGOUT_URL +
            encodeURIComponent(window.location.origin);
        }
      }
      if (error.response.status >= 500) {
        throw new ServerError("Status: 500 | API Error", error.response);
      }

      // If auth token is invalid, response is 403 with no response data
      /*if (error.response.status === 403 && !error.response.data) {
        return Promise.reject({
          ...error.response,
          data: [
            {
              errorCode: "403",
              errorMessage:
                "Oops! Something went wrong. Please contact support."
            }
          ]
        });
      }*/
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return axiosInstance(options).then(onSuccess).catch(onError);
};
