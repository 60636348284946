import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Alert, Modal } from "reactstrap";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { nonBlockingHttpRequest } from "../../utils/http/nonBlockingHttpRequest";
import { FileDropzoneErrors, Labels } from "../../constants/Constants";
import QueryKeys from "../../constants/QueryKeys";

export default function UploadAttachmentsComponent({
  isOpen,
  toggle,
  url,
  onUploadSuccess,
  uploadOptions,
  fileErrors,
  uploadDataKey,
  shouldBeInLine,
  autoUpload = true,
  module
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <UploadAttachmentsView
        toggle={toggle}
        onUploadSuccess={onUploadSuccess}
        uploadOptions={uploadOptions}
        fileErrors={fileErrors}
        url={url}
        uploadDataKey={uploadDataKey}
        module={module}
      />
    </Modal>
  );
}

export const UploadAttachmentsView = ({
  onFileAttach,
  onUploadSuccess,
  toggle,
  uploadOptions,
  fileErrors,
  url,
  isEmbeddedInModal = true,
  autoUpload = true,
  uploadDataKey = "invoiceAttachmentFile",
  module
}) => {
  const [fileError, setFileError] = useState("");
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data) =>
      nonBlockingHttpRequest({
        method: "post",
        url: url,
        data,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors[0].errorMessage)),
    {
      onSuccess: () => {
        onUploadSuccess && onUploadSuccess();
        if (module === "purchaseOrders") {
          queryClient.invalidateQueries([QueryKeys.getPOAttachments]);
        }
        toggle();
      }
    }
  );

  const fileUploadErrors = { ...FileDropzoneErrors, ...(fileErrors || {}) };

  const defaultUploadOptions = {
    maxFiles: 1,
    maxSize: 10485760, // 10MB
    multiple: false,
    onDropAccepted: (acceptedFiles) => {
      setFileError("");
      const formData = new FormData();
      formData.append(uploadDataKey, acceptedFiles[0]);
      autoUpload ? mutation.mutate(formData) : onFileAttach(acceptedFiles[0]);
    },
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles[0].errors[0].code) {
        setFileError(
          fileUploadErrors[rejectedFiles[0].errors[0].code] ||
            Labels.CommonModals.unknownError
        );
      }
    }
  };
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ ...defaultUploadOptions, ...(uploadOptions || {}) });

  const removeAttachment = () => {
    acceptedFiles.splice(0, 1);
    // only to reflect file remove UI changes, otherwise not updating UI
    setFileError(undefined);
    onFileAttach(null);
  };

  return (
    <div className="p-3">
      <span className="body-large-primary">
        {Labels.Payments.AccountsReceivables.addAttachment}
      </span>
      {isEmbeddedInModal && (
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggle}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
      {fileError ? (
        <Alert color="danger" className="mt-2">
          {fileError}
        </Alert>
      ) : null}
      {mutation?.isError ? (
        <Alert color="danger" className="mt-2">
          {mutation?.error}
        </Alert>
      ) : null}
      {mutation?.isLoading ? (
        <Alert color="info mt-2 mb-0">
          <span
            className="spinner-border  spinner-border-sm mr-2"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </span>
          {Labels.CommonModals.uploadingFile}
        </Alert>
      ) : (
        <div
          className={classNames("files-dropzone", {
            "border-primary": isDragActive
          })}
          {...getRootProps()}
        >
          <div
            className={classNames("file-delete-button", {
              "d-none": isEmbeddedInModal || acceptedFiles.length <= 0
            })}
          >
            <button
              className="btn btn-link m-0 p-0 "
              onClick={(evt) => {
                evt.preventDefault();
                removeAttachment();
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
            </button>
          </div>
          {!isEmbeddedInModal && acceptedFiles.length > 0 ? (
            <div>
              <div className="d-flex mt-2">
                {acceptedFiles.map((file, index) => (
                  <span key={index}>{file.name}</span>
                ))}
                <div className="ml-2"></div>
              </div>
            </div>
          ) : (
            <>
              <div className="text-center small-text text-muted">
                <small>
                  {Labels.Payments.AccountsReceivables.dropFilesHere}
                </small>
                <br />
                <small>{Labels.CommonModals.or}</small>
              </div>
              <label htmlFor="invoice-files" className="btn btn-secondary">
                {Labels.Payments.AccountsReceivables.browseFiles}
                <input {...getInputProps()} />
              </label>
            </>
          )}
        </div>
      )}
    </div>
  );
};
