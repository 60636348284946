import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Button, Alert } from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import DragAndDropFileUpload from "../../../../elements/file-upload/DragAndDropFileUpload";
import { DownloadLink } from "../../../../elements/download/DownloadFiles";
import {
  downloadSampleTaskData,
  uploadTaskData
} from "../../../../utils/http/endpoints";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { nonBlockingHttpRequest } from "../../../../utils/http/nonBlockingHttpRequest";

export default NiceModal.create(({ areRepeatingTasks, onComplete }) => {
  const modal = useModal();
  const [errors, setErrors] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const sampleCSVDownloadOptions = {
    url: downloadSampleTaskData(areRepeatingTasks),
    responseType: "blob"
  };

  const uploadOptions = {
    accept: ".csv",
    maxFiles: 1,
    minSize: 0,
    maxSize: 10485760
  };

  const resetErrors = () => {
    setErrors([]);
  };

  const errorCodeDesc = {
    "file-invalid-type": Labels.Tasks.fileTypeError,
    "file-too-large": Labels.CommonModals.attachmentSizeError
  };

  const onError = (errorList) => {
    const errors = {};
    errorList.forEach((errorObj) => {
      errorObj.errors.forEach((error) => {
        errors[error.code] = {
          errorMessage: errorCodeDesc[error.code] || error.message
        };
      });
    });
    setErrors([...Object.values(errors)]);
  };

  const isDataInValid = () => {
    return !(attachment.length > 0);
  };

  const onFilesSelection = (files = []) => {
    setAttachment(files);
  };

  useEffect(() => {
    setDisabled(isDataInValid());
  }, [attachment]);

  const uploadTaskCSV = () => {
    resetErrors();
    const formData = new FormData();
    formData.append("taskDataFile", attachment[0]);
    formData.append("areRepeatingTasks", areRepeatingTasks);
    nonBlockingHttpRequest({
      method: "post",
      url: uploadTaskData(),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then((response) => {
        if (response.headers["content-type"] === "application/force-download") {
          setErrors([{ errorMessage: Labels.Tasks.Import.logFileMsg }]);
          let fileName =
            response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        } else {
          onComplete(true);
          modal.remove();
        }
      })
      .catch((error) => {
        console.warn(error);
        setErrors(error.data.errors);
      });
  };

  return (
    <>
      <div>
        <Modal
          isOpen={modal.visible}
          className="task-import-modal"
          centered={true}
          toggle={modal.remove}
          fade={false}
        >
          <div className="modal-header">
            <h2 className="color-dark modal-title">
              {Labels.Tasks.Import.title}
            </h2>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={modal.remove}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            {errors.length ? (
              <div className="mb-2">
                <Alert color="danger" toggle={resetErrors}>
                  <ul className="error-list">
                    {errors.map((error, index) => {
                      return <li key={index}>{error.errorMessage}</li>;
                    })}
                  </ul>
                </Alert>
              </div>
            ) : null}
            <>
              <h4>{Labels.Tasks.Import.Steps.one.heading}</h4>
              <p className="step-content">
                {Labels.Tasks.Import.Steps.one.content}
              </p>
              <DownloadLink {...sampleCSVDownloadOptions} onError={onError}>
                <span className="body-secondary step-content d-inline-flex">
                  {Labels.CommonModals.downloadCsvTpl}
                </span>
              </DownloadLink>
            </>
            <>
              <h4 className="step-content">
                {Labels.Tasks.Import.Steps.two.heading}
              </h4>
            </>
            <>
              <div className=" step-content">
                <DragAndDropFileUpload
                  onFileSelection={onFilesSelection}
                  onError={onError}
                  label={Labels.CommonModals.uploadFileLabel}
                  button={true}
                  uploadConfig={uploadOptions}
                />
              </div>
            </>
            <div className="float-left mb-1"></div>
            <div className="step-content text-center mt-3">
              <Button
                color="primary"
                disabled={disabled}
                onClick={uploadTaskCSV}
              >
                {Labels.Tasks.import}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
});
