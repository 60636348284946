import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Button, Alert } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import { SelectBox as Select } from "../../../../../elements/select/Select";
import DragAndDropFileUpload from "../../../../../elements/file-upload/DragAndDropFileUpload";
import { DownloadLink } from "../../../../../elements/download/DownloadFiles";
import {
  downloadJournalSampleTemplate,
  uploadJournalCSVFile
} from "../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import useGetAssetList from "../../../../../hooks/useGetAssetList";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useQueryClient } from "react-query";
import QueryKeys from "../../../../../constants/QueryKeys";

export default NiceModal.create(
  ({
    setAreSuccessAlertsVisible,
    filters,
    setFilters,
    assetId,
    history,
    fromAssetOnBoarding,
    isJournalsImported
  }) => {
    const modal = useModal();
    const [errors, setErrors] = useState([]);
    const [attachment, setAttachment] = useState([]);
    const queryClient = useQueryClient();
    const [disabled, setDisabled] = useState(true);
    const [selectedAsset, setSelectedAsset] = useState(undefined);
    const [overWriteJournals, setOverWriteJournals] = useState(false);
    const sampleCSVDownloadOptions = {
      url: downloadJournalSampleTemplate(),
      responseType: "blob"
    };

    const uploadOptions = {
      accept: ".csv",
      maxFiles: 1,
      minSize: 0,
      maxSize: 10485760
    };

    const resetErrors = () => {
      setErrors([]);
    };

    const errorCodeDesc = {
      "file-invalid-type": Labels.Journals.fileTypeError,
      "file-too-large": Labels.CommonModals.attachmentSizeError
    };

    const onError = (errorList) => {
      const errors = {};
      errorList.forEach((errorObj) => {
        errorObj.errors.forEach((error) => {
          errors[error.code] = {
            errorMessage: errorCodeDesc[error.code] || error.message
          };
        });
      });
      setErrors([...Object.values(errors)]);
    };

    const isDataInValid = () => {
      return !(!!selectedAsset && attachment.length > 0);
    };

    const onFilesSelection = (files = []) => {
      setAttachment(files);
    };

    const assetList = useGetAssetList();

    useEffect(() => {
      if (assetId && assetList?.length) {
        setSelectedAsset(assetList.find((asset) => asset.value == assetId));
      }
    }, [assetId, assetList]);

    useEffect(() => {
      setDisabled(isDataInValid());
    }, [attachment, selectedAsset]);

    const uploadJournalCSV = () => {
      resetErrors();
      const endpoint = uploadJournalCSVFile(
        selectedAsset.value,
        overWriteJournals
      );
      const formData = new FormData();
      formData.append("journalFile", attachment[0]);
      httpRequest({
        method: "post",
        url: endpoint,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then((response) => {
          if (
            response.headers["content-type"] === "application/force-download"
          ) {
            setErrors([{ errorMessage: Labels.Journals.Import.logFileMsg }]);
            let fileName =
              response.headers["content-disposition"].split("filename=")[1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            if (history.location.pathname.includes("/accounting/")) {
              setAreSuccessAlertsVisible(true);
              setFilters({ ...filters, sortBy: undefined, asc: true });
            } else if (
              history.location.pathname.includes(
                "/settings/assetonboarding/checklist"
              )
            ) {
              queryClient.refetchQueries(QueryKeys.getAssetOnboardingChecklist);
            }
            modal.remove();
          }
        })
        .catch((error) => {
          console.warn(error);
          setErrors(error.data.errors);
        });
    };

    return (
      <>
        <div>
          <Modal
            isOpen={modal.visible}
            className="journals-import"
            centered={true}
            toggle={modal.remove}
            fade={false}
          >
            <div className="modal-header">
              {fromAssetOnBoarding ? (
                <h2 className="color-dark modal-title">
                  {Labels.Journals.Import.title2}
                </h2>
              ) : (
                <h2 className="color-dark modal-title">
                  {Labels.Journals.Import.title}
                </h2>
              )}
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={modal.remove}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <ModalBody>
              {errors.length ? (
                <div className="mb-2">
                  <Alert color="danger" toggle={resetErrors}>
                    <ul className="error-list">
                      {errors.map((error, index) => {
                        return <li key={index}>{error.errorMessage}</li>;
                      })}
                    </ul>
                  </Alert>
                </div>
              ) : null}
              <>
                {" "}
                {fromAssetOnBoarding ? (
                  <h4>{Labels.Journals.Import.Steps.one.heading2}</h4>
                ) : (
                  <h4>{Labels.Journals.Import.Steps.one.heading}</h4>
                )}
                <p className="step-content">
                  {Labels.Journals.Import.Steps.one.content}
                </p>
                <DownloadLink {...sampleCSVDownloadOptions} onError={onError}>
                  <span className="body-secondary step-content d-inline-flex">
                    {Labels.CommonModals.downloadCsvTpl}
                  </span>
                </DownloadLink>
              </>
              <>
                {fromAssetOnBoarding ? (
                  <h4 className=" step-content">
                    {Labels.Journals.Import.Steps.two.heading2}
                  </h4>
                ) : (
                  <h4 className=" step-content">
                    {Labels.Journals.Import.Steps.two.heading}
                  </h4>
                )}{" "}
                {fromAssetOnBoarding ? (
                  <p className=" step-content">
                    {Labels.Journals.Import.Steps.two.content2}
                  </p>
                ) : (
                  <p className=" step-content">
                    {Labels.Journals.Import.Steps.two.content}
                  </p>
                )}
              </>{" "}
              {fromAssetOnBoarding && isJournalsImported && (
                <div className="d-flex mt-4 align-items-center">
                  <label className="m-0 fw-400">
                    {Labels.Journals.Import.Steps.two.overWriteLabel}
                  </label>
                  <input
                    className="ml-2"
                    type={"checkbox"}
                    value={overWriteJournals}
                    onChange={() => setOverWriteJournals(!overWriteJournals)}
                  />
                </div>
              )}
              {!assetId ? (
                <>
                  <h4 className=" step-content">
                    {Labels.Journals.Import.Steps.three.heading}
                  </h4>
                  <div className="step-content container">
                    <div className="row">
                      <div className="col-sm  asset-name-container">
                        <label className="small ">
                          {Labels.CommonModals.assetName}
                        </label>
                      </div>
                      <div className="col-sm">
                        <Select
                          id="assetName"
                          value={selectedAsset}
                          onChange={setSelectedAsset}
                          rules={{
                            required:
                              Labels.Journals.Import.selectAssetErrorText
                          }}
                          options={assetList}
                          placeholder={`Select ${Labels.Journals.asset} Name`}
                          defaultValue={selectedAsset}
                          isDisabled={assetId !== undefined}
                          isClearable
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <>
                <div className=" step-content">
                  <DragAndDropFileUpload
                    onFileSelection={onFilesSelection}
                    onError={onError}
                    label={Labels.CommonModals.uploadFileLabel}
                    button={true}
                    uploadConfig={uploadOptions}
                  />
                </div>
              </>
              <div className="float-left mb-1"></div>
              <div className="step-content text-center">
                <Button
                  color="primary"
                  disabled={disabled}
                  onClick={uploadJournalCSV}
                >
                  {Labels.Journals.import}
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
);
