import { httpRequest } from "../../../../utils/http/httpRequest";
import { useMutation } from "react-query";
import QueryKeys from "../../../../constants/QueryKeys";
import { bulkUpdateTaskStatus } from "../../../../utils/http/endpoints";

// @ts-check
export default function useBulkTaskStatusUpdate(mutationOptions) {
  const mutation = useMutation({
    mutationKey: QueryKeys.bulkUpdateTaskStatus,
    mutationFn: ({ comment, queryParams }) => {
      return httpRequest({
        method: "put",
        url: bulkUpdateTaskStatus(queryParams),
        data: { comment: comment }
      })
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err.data.errors));
    },
    ...mutationOptions
  });

  return mutation;
}
