import { updateTaskStatus } from "../../../../utils/http/endpoints";
import { httpRequest } from "../../../../utils/http/httpRequest";
import { useMutation } from "react-query";
import QueryKeys from "../../../../constants/QueryKeys";

// @ts-check
export default function useTaskStatusUpdate(mutationOptions) {
  const mutation = useMutation({
    mutationKey: QueryKeys.updateTaskStatus,
    mutationFn: ({ comment, queryParams, assetId, taskId }) => {
      return httpRequest({
        method: "put",
        url: updateTaskStatus(assetId, taskId, queryParams),
        data: { comment: comment }
      })
        .then((res) => Promise.resolve(res))
        .catch((err) => Promise.reject(err.data.errors));
    },
    ...mutationOptions
  });

  return mutation;
}
