import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  buildQueryParamsString,
  toReadableString
} from "../../../../../utils/string";
import { useHistory } from "react-router-dom";
import InvoiceStatuses from "../../../../../constants/InvoiceStatuses";
import Auth from "../../../../../auth/Auth";
import { Privileges } from "../../../../../constants/Constants";
import classNames from "classnames";
import { Labels } from "../../../../../constants/Constants";
import DocumentStatuses from "../../../../../constants/DocumentStatuses";
import { PurchaseInvoiceTypesEnum } from "../../../../../constants/PurchaseInvoiceTypes";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import Tooltip from "../../../../../elements/ToolTips/Tooltip";

// Non for non accounts IQ invoices i.e invoice created in oneView itself
export default function DetailsSection({
  assetDetails,
  invoiceDetails,
  postingPeriodQuery
}) {
  const history = useHistory();
  const isCreditNote = () =>
    invoiceDetails.invoiceType ===
    PurchaseInvoiceTypesEnum.PURCHASE_CREDIT_NOTE;

  const handleEditInvoiceDetails = () => {
    if (isEditDisabled) {
      return;
    }
    const invoiceDocument = invoiceDetails.documents.find(
      (doc) => !doc.uploaded
    );
    history.push({
      pathname: `${history?.location?.pathname}/document`,
      search: buildQueryParamsString({
        url: invoiceDocument.cloudStorageUrl,
        assetId: invoiceDetails.assetId,
        invoiceId: invoiceDetails.id
      }),
      state: {
        fromInvoiceEnrichmentPage: true,
        documentDetails: {
          assetId: invoiceDetails.assetId,
          invoiceId: invoiceDetails.id,
          status: DocumentStatuses.PROCESSED,
          cloudStorageUrl: invoiceDocument.cloudStorageUrl,
          attachmentName: invoiceDocument.fileName,
          next: null,
          prev: null
        }
      }
    });
  };

  const isEditDisabled =
    !(invoiceDetails.assetId || invoiceDetails.id) ||
    ![InvoiceStatuses.DRAFT, InvoiceStatuses.AWAITING_APPROVAL].includes(
      invoiceDetails.invoiceStatus
    ) ||
    !Auth.getUserPrivileges().includes(Privileges.EDIT_INVOICE);

  const getPostingPeriod = () => {
    const status =
      invoiceDetails.invoiceStatus === InvoiceStatuses.IN_DISPUTE
        ? toReadableString(invoiceDetails.prevStatusBeforeInDispute)
        : invoiceDetails.invoiceStatus;

    switch (status) {
      case InvoiceStatuses.AWAITING_PAYMENT:
      case InvoiceStatuses.AWAITING_RECEIPT:
      case InvoiceStatuses.RECEIVED:
      case InvoiceStatuses.PAID:
        return invoiceDetails.approvedPostingPeriod;
      case InvoiceStatuses.VOIDED:
        return invoiceDetails.voidedPostingPeriod;
      case InvoiceStatuses.DRAFT:
      case InvoiceStatuses.AWAITING_APPROVAL:
      case InvoiceStatuses.CANCELLED:
      default:
        return postingPeriodQuery.data?.postingPeriod;
    }
  };

  return (
    <div className="details-section-non-accountsIQ card card-body fv-shadow">
      <div className="section-header align-items-center">
        <div>
          <h2 className="m-0 color-dark">
            {isCreditNote()
              ? Labels.Payments.Invoices.Details.CreditNote
                  .purchaseCreditNoteDetails
              : Labels.Payments.Invoices.Details.PurchaseInvoiceDetails}
          </h2>
        </div>
        <div>
          <button
            className={classNames(
              "btn btn-link link-primary p-0 m-0",
              "align-self-start"
            )}
            disabled={isEditDisabled}
            onClick={handleEditInvoiceDetails}
          >
            <FontAwesomeIcon
              icon={faEdit}
              className={classNames({ "fa-disabled": isEditDisabled })}
            />
          </button>
        </div>
      </div>
      <table>
        <tbody className="body-primary">
          <tr>
            <td>
              <span className="row-header-2">
                {Labels.Payments.Invoices.Details.CreditNote.extRefNo}
              </span>
              <span
                id="invoiceNumber"
                className="text-truncate invoice-number column-header-2 fw-400"
              >
                {invoiceDetails.invoiceNumber}
              </span>
              <Tooltip target="invoiceNumber">
                {invoiceDetails.invoiceNumber}
              </Tooltip>
            </td>
            <td>
              <span className="row-header-2">
                {Labels.Payments.Invoices.Details.dueDate}
              </span>
              <span className="column-header-2 fw-400">
                {invoiceDetails.formattedInvoiceDueDate || "-"}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <span className="row-header-2">
                {Labels.Payments.Invoices.Details.InvoiceDate}
              </span>
              <span className="column-header-2 fw-400">
                {invoiceDetails.formattedInvoiceDate}
              </span>
            </td>
            {assetDetails?.isOnboardingComplete && !!getPostingPeriod() ? (
              <td>
                <span className="row-header-2">
                  {Labels.PostingPeriods.postingPeriod}
                </span>
                <span className="column-header-2 fw-400">
                  {getPostingPeriod()}
                </span>
              </td>
            ) : null}
          </tr>

          <tr>
            <td>
              <span className="row-header-2">
                {Labels.Payments.Invoices.Details.GLCode}
              </span>
              <span
                id="opexLine"
                className="w-75 text-truncate column-header-2 fw-400 text-right"
              >
                {invoiceDetails.opexLine || "-"}
              </span>
              <Tooltip target="opexLine" placement="bottom-end">
                {invoiceDetails.opexLine}
              </Tooltip>
            </td>
            {invoiceDetails?.assetCurrency !==
              invoiceDetails?.invoiceCurrency && (
              <td>
                <span className="row-header-2">
                  {Labels.Payments.Invoices.Details.ExchangeRate}
                </span>
                <span className="column-header-2 fw-400">{`${getSymbolFromCurrencyCode(
                  invoiceDetails.invoiceCurrency
                )}1 is ${getSymbolFromCurrencyCode(
                  invoiceDetails.assetCurrency
                )}${invoiceDetails.exchangeRate}`}</span>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
