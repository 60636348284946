import JournalStatuses from "./JournalStatuses";

export const JournalActions = {
  saveAsDraft: "Save",
  submitForApproval: "Submit for Approval",
  save: "Save",
  cancel: "Cancel Journal",
  approve: "Approve",
  returnToDraft: "Return to Draft",
  viewAndEdit: "View and Edit",
  revert: "Reverse Journal",
  createACopy: "Create a Copy"
};

const JournalActionsMap = new Map([
  [JournalActions.saveAsDraft, JournalStatuses.DRAFT],
  [JournalActions.save, JournalStatuses.DRAFT],
  [JournalActions.approve, JournalStatuses.POSTED],
  [JournalActions.cancel, JournalStatuses.CANCELLED],
  [JournalActions.submitForApproval, JournalStatuses.AWAITING_APPROVAL]
]);

export default JournalActionsMap;
