/**
 * Payload follows format as per https://github.com/redux-utilities/flux-standard-action
 */
import { nanoid } from "nanoid";
import { Labels } from "../../../../../../constants/Constants";
import * as actionTypes from "./transactionDetailActionTypes";

export function addNewTransactionLineItem(outByAmount, defaultTransactionDate) {
  return {
    type: actionTypes.ADD_NEW_TRANSACTION_LINEITEM,
    payload: {
      key: nanoid(),
      transactionDate: defaultTransactionDate,
      transactionDateErrors: "",
      payeeName: "",
      payeeNameErrors: "",
      budgetLineItem: null,
      glCode: null,
      taxRate: "",
      taxRateErrors: "",
      amount: outByAmount,
      amountErrors: ""
    }
  };
}

export function removeTransactionLineItem(key) {
  return {
    type: actionTypes.REMOVE_TRANSACTION_LINEITEM,
    payload: { key }
  };
}

export function updateTransactionDate(transactionDate, key) {
  return {
    type: actionTypes.UPDATE_TRANSACTION_DATE,
    payload: { transactionDate, key }
  };
}

export function updateContactName(contact, key) {
  return {
    type: actionTypes.UPDATE_CONTACT_NAME,
    payload: {
      key,
      contactName: contact,
      contactNameErrors: contact ? "" : Labels.Journals.pleaseSelectContact
    }
  };
}

export function updateBudgetLineItem(budgetLineItem, glCodesList = [], key) {
  return {
    type: actionTypes.UPDATE_BUDGET_LINE_ITEM,
    payload: {
      budgetLineItem,
      key,
      glCode: budgetLineItem
        ? glCodesList.find((i) => i.value === budgetLineItem.accountId) || {
            label: budgetLineItem.glCode,
            value: budgetLineItem.glCode
          }
        : null,
      glCodeError: budgetLineItem ? "" : Labels.Journals.pleaseSelectGLCode
    }
  };
}

export function updateGlCode(glCode, key) {
  return {
    type: actionTypes.UPDATE_GL_CODE,
    payload: {
      glCode,
      key,
      glCodeError: glCode ? "" : Labels.Journals.pleaseSelectGLCode
    }
  };
}

export function updateTaxRate(taxRate, key) {
  return {
    type: actionTypes.UPDATE_TAX_RATE,
    payload: {
      key,
      taxRate
    }
  };
}

export function updateAmount(event, key) {
  event.target.checkValidity();
  return {
    type: actionTypes.UPDATE_AMOUNT,
    payload: {
      key,
      amount: event.target.value,
      amountErrors: event.target.validationMessage
    }
  };
}

export function updateLineItemsByApiResponse(transactionDetails) {
  return {
    type: actionTypes.UPDATE_LINE_ITEMS_BY_API_RESPONSE,
    payload: {
      transactionDetails
    }
  };
}

export function toggleSelectedPayment(payment) {
  return {
    type: actionTypes.TOGGLE_SELECTED_PAYMENT,
    payload: {
      payment
    }
  };
}

export function updateTransferToBankAccountId(transferToBankAccountId, meta) {
  return {
    type: actionTypes.UPDATE_TRANSFER_TO_BANK_ACCOUNT_ID,
    payload: {
      transferToBankAccountId
    },
    meta
  };
}

export function updateTransferToBankLineItemId(transferToBankLineItemId, meta) {
  return {
    type: actionTypes.UPDATE_TRANSFER_TO_BANK_LINE_ITEM_ID,
    payload: {
      transferToBankLineItemId
    },
    meta
  };
}

export function updateTransactionStatus(status) {
  return {
    type: actionTypes.UPDATE_TRANSACTION_STATUS,
    payload: {
      status: status
    }
  };
}

export function reset() {
  return {
    type: actionTypes.RESET
  };
}

export function setIsFormChanged(isFormChanged) {
  return {
    type: actionTypes.SET_IS_FORM_CHANGED,
    payload: { isFormChanged }
  };
}
