import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Labels } from "../../../constants/Constants";
import CashBridge from "./CashBridge";

export default NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      fade={false}
      centered={true}
      isOpen={modal.visible}
      className="modal-fullscreen"
    >
      <ModalHeader toggle={modal.remove}>
        <div>
          <h2 className="color-dark modal-title">
            {Labels.Portfolios.cashflow}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody className="p-3 p-md-4">
        <CashBridge isExpanded={true} />
      </ModalBody>
    </Modal>
  );
});
