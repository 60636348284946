import { Labels } from "../../../../constants/Constants";

export const salesInvoiceEmailTemplate = (
  customerName,
  invoiceType,
  invoiceNumber
) => `${Labels.Payments.AccountsReceivables.email.step1} ${customerName},

${Labels.Payments.AccountsReceivables.email.step2} ${invoiceType} ${invoiceNumber}.

${Labels.Payments.AccountsReceivables.email.step3}
`;
