import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Labels } from "../constants/Constants";
import useUniqueId from "../hooks/useUniqueId";
import { getSymbolFromCurrencyCode } from "../utils/currency/currency";
import { getDateInDisplayFormat } from "../utils/date";
import { convertToFloat } from "../utils/number";
import Tooltip from "./ToolTips/Tooltip";

const XEInfoTooltip = ({
  invoiceDetails = {},
  bcAmount,
  isPayment,
  payment = {}
}) => {
  const uniqueId = useUniqueId("Tooltip");

  const { assetCurrency, invoiceCurrency, exchangeDate, exchangeRate } =
    isPayment ? payment : invoiceDetails;

  const getXERateTitleText = () => {
    return (
      <div className="xe-info-tooltip">
        <span>
          {`${Labels.CommonModals.baseCurrency} = ${getSymbolFromCurrencyCode(
            assetCurrency
          )} ${isNaN(bcAmount) ? 0 : convertToFloat(bcAmount).toFixed(2)}`}
        </span>
        <br />
        <span className="text-left">
          {`1 ${getSymbolFromCurrencyCode(
            invoiceCurrency
          )} = ${exchangeRate?.toFixed(4)} ${getSymbolFromCurrencyCode(
            assetCurrency
          )}`}
        </span>
        <br />
        <span className="text-left">
          {`1 ${getSymbolFromCurrencyCode(assetCurrency)} = ${(
            1 / exchangeRate
          )?.toFixed(4)} ${getSymbolFromCurrencyCode(invoiceCurrency)}`}
        </span>
      </div>
    );
  };

  // Hide icon & tooltip if invoice & base currency are same
  if (invoiceCurrency === assetCurrency) {
    return null;
  }

  return (
    <>
      <FontAwesomeIcon icon={faInfoCircle} id={uniqueId} className="ml-1" />
      <Tooltip target={uniqueId} fade={false}>
        {getXERateTitleText()}
      </Tooltip>
    </>
  );
};

export default XEInfoTooltip;
