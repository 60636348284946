import InvoiceStatuses from "./InvoiceStatuses";

export const InvoiceActions = {
  save: "Save",
  submitForApproval: "Submit For Approval",
  markAsDisputed: "Mark As Disputed",
  cancel: "Cancel",
  approve: "Approve",
  reject: "Reject",
  voidInvoice: "Void Invoice",
  resolveDispute: "Resolve Dispute",
  voidPCN: "Void Purchase Credit Note",
  voidCreateDraftInvoice: "Void & Create Draft",
  voidSaveDraftPurchaseCreditNote: "Void & Save Draft Purchase Credit Note"
};

const InvoiceActionsMap = new Map([
  [InvoiceActions.save, InvoiceStatuses.DRAFT],
  [InvoiceActions.submitForApproval, InvoiceStatuses.AWAITING_APPROVAL],
  [InvoiceActions.markAsDisputed, InvoiceStatuses.IN_DISPUTE],
  [InvoiceActions.cancel, InvoiceStatuses.CANCELLED],
  [InvoiceActions.approve, InvoiceStatuses.AWAITING_PAYMENT],
  [InvoiceActions.reject, InvoiceStatuses.DRAFT],
  [InvoiceActions.resolveDispute, ""]
  // [InvoiceActions.resolveDispute, InvoiceStatuses.DRAFT] // Always action api is called as form is readOnly & use can't edit it
]);

export default InvoiceActionsMap;
