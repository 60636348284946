import { nanoid } from "nanoid";
import moment from "moment";

export class FormValue {
  value = null;
  error = null;
  isTouched = false;

  constructor(defaultValue = null) {
    this.value = defaultValue;
  }

  static isInvalid(formValue) {
    if (formValue.isTouched && formValue.error) {
      return true;
    } else {
      return false;
    }
  }
}

export class LineItem {
  id = nanoid();
  isNew = true;
  description = new FormValue("");
  budgetLineItem = new FormValue();
  tax = new FormValue();
  glCode = new FormValue();
  units = new FormValue("");
  costPerUnit = new FormValue("");
  total = new FormValue("");
  accrualReversalPrice = new FormValue("");
  accrualReversalUnits = new FormValue("");
  balanceAccrued = {
    units: null,
    price: null,
    amount: null
  };

  previouslyInvoiced = {
    units: null,
    price: null,
    amount: null
  };
}

export class InitialFormState {
  asset = new FormValue();
  customer = new FormValue("");
  currency = new FormValue("");
  invoiceDate = new FormValue(moment().toDate());
  invoicePeriod = new FormValue(null);
  lineItems = [new LineItem()];
  attachment = new FormValue(null);
  xeRatePriorToAssetOnboarding = new FormValue("");
  isLineitemsListChanged = false; // This will be true when user add/remove a line item, used to determine decide if form has changed or not
}
