import {
  accrualdata,
  getInvoiceOperationalBudgetIncurredAmountDetails,
  putOperationInvoiceEnrichmentData
} from "../../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../../utils/http/httpRequest";

export const getAccrualdata = ({ queryKey }) => {
  const [, params] = queryKey;
  return httpRequest({
    method: "get",
    url: accrualdata(params)
  })
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.data.errors));
};

export const putOperationalFormData = ({ assetId, invoiceId, data }) => {
  return httpRequest({
    method: "put",
    url: putOperationInvoiceEnrichmentData(assetId, invoiceId),
    data
  })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error.data.errors || []));
};

export const fetchBudgetingDrilldownData = async ({ queryKey }) => {
  const [, assetId, data] = queryKey;
  try {
    const response = await httpRequest({
      method: "post",
      url: getInvoiceOperationalBudgetIncurredAmountDetails(assetId),
      data
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error.data.errors);
  }
};
