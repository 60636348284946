const ContractStatuses = {
  DRAFT: "Draft",
  AWAITING_APPROVAL: "Awaiting Approval",
  CANCELLED: "Cancelled",
  APPROVED: "Approved",
  VOIDED: "Voided"
};

export const contractStatusList = Object.entries(ContractStatuses).map(
  ([k, v]) => ({
    id: k,
    value: k,
    label: v
  })
);

export default ContractStatuses;
