import React from "react";
import { useMutation } from "react-query";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Alert
} from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import QueryKeys from "../../../../../constants/QueryKeys";
import DisplayAlerts from "../../../../../elements/DisplayAlerts";
import { getMonthName } from "../../../../../utils/date";
import { approvePurchaseInvoice } from "../../../../../utils/http/endpoints";
import { formatAmount } from "../../../../../utils/number";
import { resolveAPIPromise } from "../../../../../utils/promises";
import { useParams } from "react-router-dom";
import { nonBlockingHttpRequest } from "../../../../../utils/http/nonBlockingHttpRequest";
import { PrepaymentDataErrorMessage } from "./operational-operational/PrepaymentDataErrorMessage";
import { isJournalEmpty } from "./operational-operational/JournalManagement";
import Tooltip from "../../../../../elements/ToolTips/Tooltip";

function PrepaymentScheduleRow({
  journal,
  currencySymbol,
  selectedLineItemMonths,
  invoiceLineItemId,
  setSelectedLineItemMonths
}) {
  let bliGlCodeRow,
    prepaymentRow = null;
  if (
    journal.journalPostingLineItemDataList[0].budgetLineItemDescription ===
    "Prepayment"
  ) {
    prepaymentRow = journal.journalPostingLineItemDataList[0];
    bliGlCodeRow = journal.journalPostingLineItemDataList[1];
  } else {
    prepaymentRow = journal.journalPostingLineItemDataList[1];
    bliGlCodeRow = journal.journalPostingLineItemDataList[0];
  }

  const rowId = [invoiceLineItemId, journal.month, journal.year].join("-");

  const handleCheckboxChange = (event) => {
    const { rowId } = event.target.dataset;
    if (event.target.checked) {
      return setSelectedLineItemMonths([...selectedLineItemMonths, rowId]);
    }
    return setSelectedLineItemMonths(
      selectedLineItemMonths.filter((v) => v !== rowId)
    );
  };

  const getDescription = (bliGlCodeRow) => {
    const {
      budgetLineItemDescription,
      glCodeDescription,
      glCode,
      budgetLineItemId
    } = bliGlCodeRow;
    if (budgetLineItemId) {
      return `${budgetLineItemDescription} / ${glCode} - ${glCodeDescription}`;
    }

    return `${glCode} - ${glCodeDescription}`;
  };

  return (
    <>
      <tr>
        <td className="pt-3"></td>
        <td className="text-left" style={{ maxWidth: "10rem" }}>
          {bliGlCodeRow.budgetLineItemDescription === "Prepayment"
            ? "Prepayment"
            : getDescription(bliGlCodeRow)}
        </td>
        <td className="text-center">
          {formatAmount(bliGlCodeRow.debit, currencySymbol)}
        </td>
        <td className="text-center py-2">
          {formatAmount(bliGlCodeRow.credit, currencySymbol)}
        </td>
      </tr>
      <tr className="border-bottom-fv-border-1px">
        <td>
          <div className="d-flex align-items-end justify-content-start">
            <label
              htmlFor={rowId}
              className="m-0 p-0 align-middle d-flex align-items-center justify-content-start"
            >
              <input
                type="checkbox"
                id={rowId}
                data-row-id={rowId}
                checked={selectedLineItemMonths.includes(rowId)}
                className="form-input"
                onChange={handleCheckboxChange}
              />
              <span className="pl-2">
                {`${getMonthName(journal.month)}-${journal.year}`}
              </span>
            </label>
          </div>
        </td>
        <td id={"description-" + rowId} className="text-left text-truncate">
          {prepaymentRow.budgetLineItemDescription === "Prepayment"
            ? "Prepayment"
            : getDescription(prepaymentRow)}
        </td>
        <Tooltip target={"description-" + rowId} placement="bottom-start">
          {getDescription(prepaymentRow)}
        </Tooltip>
        <td className="text-center">
          {formatAmount(prepaymentRow.debit, currencySymbol)}
        </td>
        <td className="text-center py-2">
          {formatAmount(prepaymentRow.credit, currencySymbol)}
        </td>
      </tr>
    </>
  );
}

function PrepaymentScheduleTable({
  lineItem,
  currencySymbol,
  selectedLineItemMonths,
  setSelectedLineItemMonths
}) {
  const allLineItemMonths = lineItem.journalPostingDataList
    .filter(isJournalEmpty)
    .map((journalMonth) => {
      if (!journalMonth.journalPostingLineItemDataList) {
        return null;
      }
      return [
        lineItem.invoiceLineItemId,
        journalMonth.month,
        journalMonth.year
      ].join("-");
    })
    .filter((v) => v !== null);

  const isLineItemChecked = () =>
    allLineItemMonths.every((v) => selectedLineItemMonths.includes(v));

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      return setSelectedLineItemMonths(
        new Array(...new Set([...selectedLineItemMonths, ...allLineItemMonths]))
      );
    }
    return setSelectedLineItemMonths(
      selectedLineItemMonths.filter((v) => !allLineItemMonths.includes(v))
    );
  };

  if (!allLineItemMonths?.length) {
    return null;
  }

  return (
    <div className="mb-3">
      <div className="pt-3">
        <label
          htmlFor={
            lineItem.invoiceLineItemDescription + lineItem.invoiceLineItemId
          }
          className="m-0 p-0 align-middle d-flex align-items-center justify-content-start py-3"
        >
          <input
            type="checkbox"
            checked={isLineItemChecked()}
            onChange={handleCheckboxChange}
            id={
              lineItem.invoiceLineItemDescription + lineItem.invoiceLineItemId
            }
          />
          <h2 className="m-0 p-0 pl-2 h3 color-dark">
            {lineItem.invoiceLineItemDescription}
          </h2>
        </label>
      </div>
      <table className="w-100">
        <thead>
          <tr className="column-header-1">
            <th className="pb-3 pl-4">
              {Labels.Payments.Invoices.JournalManagement.period}
            </th>
            <th className="text-left">
              {Labels.Payments.Invoices.JournalManagement.bliGlCode}
            </th>
            <th className="text-center">
              {Labels.Payments.Invoices.JournalManagement.debit}
            </th>
            <th className="text-center">
              {Labels.Payments.Invoices.JournalManagement.credit}
            </th>
          </tr>
        </thead>
        <tbody>
          {lineItem.journalPostingDataList
            .filter((i) => i.journalPostingLineItemDataList?.length > 0)
            .sort((j1, j2) => {
              return j1.year === j2.year
                ? j1.month - j2.month
                : j1.year - j2.year;
            })
            .map((journal) => (
              <PrepaymentScheduleRow
                invoiceLineItemId={lineItem.invoiceLineItemId}
                selectedLineItemMonths={selectedLineItemMonths}
                setSelectedLineItemMonths={setSelectedLineItemMonths}
                currencySymbol={currencySymbol}
                key={`${journal.month}-${journal.year}`}
                journal={journal}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default function PrepaymentScheduleModal({
  isOpen,
  toggle,
  invoiceId,
  prepaymentdataQuery,
  currencySymbol,
  selectedLineItemMonths,
  setSelectedLineItemMonths,
  updateInvoice,
  primaryOrgId
}) {
  const getPostData = () => {
    const selectedJournals = prepaymentdataQuery.data.prepaymentJournalDataList
      .map((lineItem) => {
        return {
          ...lineItem,
          journalPostingDataList: lineItem.journalPostingDataList
            .filter((i) => i.journalPostingLineItemDataList?.length > 0)
            .filter((journal) => {
              const rowId = [
                lineItem.invoiceLineItemId,
                journal.month,
                journal.year
              ].join("-");

              return selectedLineItemMonths.includes(rowId) ? journal : null;
            })
            .map((journal) => {
              const updatedJournalData = {
                ...journal,
                journalPostingLineItemDataList:
                  journal.journalPostingLineItemDataList.map(
                    (journalLineItem) => {
                      const lineItemObject = { ...journalLineItem };
                      delete lineItemObject.budgetLineItemDescription;
                      return lineItemObject;
                    }
                  )
              };
              return updatedJournalData;
            })
            .filter((i) => i !== null)
        };
      })
      .filter((lineItem) => lineItem.journalPostingDataList.length > 0);

    return {
      prepaymentJournalDataList: selectedJournals
    };
  };
  const params = useParams();
  const mutation = useMutation({
    mutationKey: QueryKeys.approvePurchaseInvoice,
    mutationFn: () =>
      resolveAPIPromise(
        nonBlockingHttpRequest({
          method: "post",
          data: getPostData(),
          url: approvePurchaseInvoice({ ...params, invoiceId })
        })
      ),
    onSuccess: () => {
      updateInvoice();
      toggle();
    }
  });

  const getIsAllJournalsEmpty = () => {
    try {
      const isAllJournalsEmpty =
        [
          prepaymentdataQuery.data.prepaymentJournalDataList.map((lineItem) => [
            ...lineItem.journalPostingDataList
              .filter((i) => i.journalPostingLineItemDataList?.length > 0)
              .filter(isJournalEmpty)
              .map((journalMonth) =>
                [
                  lineItem.invoiceLineItemId,
                  journalMonth.month,
                  journalMonth.year
                ].join("-")
              )
          ])
        ]
          .flat()
          .flat().length === 0;

      return isAllJournalsEmpty;
    } catch (error) {
      console.log(
        "🚀 ~ file: PrepaymentScheduleModal.js ~ line 301 ~ getIsAllJournalsEmpty ~ error",
        error
      );
      return true;
    }
  };

  return (
    <Modal
      toggle={toggle}
      centered
      fade={false}
      isOpen={isOpen}
      keyboard={false}
      backdrop="static"
      size="xl"
      className="body-primary color-text-primary"
    >
      <ModalHeader toggle={toggle}>
        <div>
          <h2 className="m-0 p-0 color-dark">
            {Labels.Payments.Invoices.JournalManagement.prepaymentSchedule}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody className="mt-0 pt-0 prepayment-schedule-modal">
        <div className="mb-2">
          <span className="color-warning pb-3">
            <span className="fw-600">{Labels.CommonModals.note}</span>
            {Labels.Payments.Invoices.JournalManagement.prepaymentScheduleNote}
          </span>
        </div>
        <PrepaymentDataErrorMessage
          errors={prepaymentdataQuery.error}
          className="mt-3"
          primaryOrgId={primaryOrgId}
        />
        {mutation.isError ? (
          <DisplayAlerts
            alerts={mutation.error}
            type="danger"
            className="mt-3"
          />
        ) : null}
        {mutation.isLoading ? (
          <div className="mt-3 pt-2">
            <Alert color="info">
              <div
                className="spinner-border spinner-border-sm text-primary"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
              <span className="pl-2">
                {prepaymentdataQuery.isSuccess &&
                prepaymentdataQuery.data.prepaymentJournalDataList.length > 0
                  ? Labels.Payments.Invoices.JournalManagement
                      .journalCreationInProgressMessage
                  : Labels.Payments.Invoices.JournalManagement
                      .invoiceApprovalInProgressMessage}
              </span>
            </Alert>
          </div>
        ) : null}

        <div className="prepayment-schedule-tables scrollbar-primary">
          {prepaymentdataQuery.isSuccess &&
          prepaymentdataQuery.data.prepaymentJournalDataList.length > 0 &&
          !getIsAllJournalsEmpty() ? (
            prepaymentdataQuery.data.prepaymentJournalDataList.map(
              (lineItem, index) => (
                <PrepaymentScheduleTable
                  lineItem={lineItem}
                  key={index}
                  currencySymbol={currencySymbol}
                  selectedLineItemMonths={selectedLineItemMonths}
                  setSelectedLineItemMonths={setSelectedLineItemMonths}
                />
              )
            )
          ) : (
            <Alert color="info" className="mt-4">
              {
                Labels.Payments.Invoices.JournalManagement
                  .noJournalsFoundConfirmApprove
              }
            </Alert>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => mutation.mutate()}
          disabled={mutation.isLoading || prepaymentdataQuery.isError}
          // disabled={getPostData().prepaymentJournalDataList.length === 0}
        >
          {Labels.CommonModals.confirm}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {Labels.CommonModals.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
