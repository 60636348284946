import { createStore } from "redux";

const initialFiltersData = {
  salesInvoiceFilters: {
    filters: null,
    queryParams: null
  },
  projectInvoicesFilters: {
    filters: null,
    queryParams: null
  },
  taggingInvoiceFilters: {
    filters: null,
    queryParams: null
  },
  contractFilters: {
    filters: null,
    queryParams: null
  },
  bankAccountsFilters: {
    filters: null,
    queryParams: null
  },
  manualJournalsFilters: {
    filters: null,
    queryParams: null,
    getLinks: null
  },
  repeatingJournalsFilters: {
    filters: null,
    queryParams: null,
    getLinks: null
  },
  manualTaskFilters: {
    filters: null,
    queryParams: null
  },
  repeatingTaskFilters: {
    filters: null,
    queryParams: null
  },
  budgetLineItemFilters: {
    filters: null,
    queryParams: null
  },
  utilisationRequestFilters: {
    filters: null
  }
};

const initialState = {
  sidebarShow: "responsive",
  isSideBarMaximized: undefined,
  fams: [],
  isChecksDataReady: false,
  areOrganizationsReady: false,
  breadCrumbArray: [],
  hideInitialLoader: false,
  areAssetsInvalid: true,
  invoiceBulkActionMessage: null,
  ...initialFiltersData
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "hideInitialLoader":
      return {
        ...state,
        hideInitialLoader: rest.hideInitialLoader
      };
    case "areAssetsInvalid":
      return {
        ...state,
        areAssetsInvalid: rest.areAssetsInvalid
      };
    case "clearFilters":
      return { ...state, ...initialFiltersData };
    default:
      return state;
  }
};

const store = createStore(
  changeState,
  // Enable the Redux DevTools extension if available
  /// See more: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfiblj
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;
