import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import { Labels } from "../../constants/Constants";
import ConfirmDialog from "./ConfirmDialog";

export default function RouteLeavingGuard({
  when,
  navigate,
  shouldBlockNavigation,
  confirmTitle = Labels.CommonModals.confirmTitle,
  confirmMessage = Labels.CommonModals.confirmMessage,
  confirmButtonName = Labels.CommonModals.confirmButtonName,
  cancelButtonName = Labels.CommonModals.cancelButtonName
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <React.Fragment>
      <Prompt when={when} message={handleBlockedNavigation} />
      {modalVisible && (
        <ConfirmDialog
          isOpen={modalVisible}
          confirmTitle={confirmTitle}
          confirmMessage={confirmMessage}
          confirmButtonName={cancelButtonName}
          cancelButtonName={confirmButtonName}
          cancelAction={handleConfirmNavigationClick}
          confirmAction={closeModal}
          toggle={closeModal}
        />
      )}
    </React.Fragment>
  );
}
