import { useQuery } from "react-query";
import { validate } from "validate.js";
import QueryKeys from "../constants/QueryKeys";
import { getGlCodesList } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

export default function useGetGLCodesList({
  assetId,
  glSections = [],
  glTypes = [],
  systemAccountsToInclude = {},
  includeAllSystemAccounts,
  isBlocking = false,
  ...options
}) {
  const request = isBlocking ? httpRequest : nonBlockingHttpRequest;
  const query = useQuery({
    queryKey: [QueryKeys.getGlCodesList, assetId, glSections, glTypes],
    enabled:
      validate(
        { assetId, glSections },
        {
          assetId: {
            presence: {
              allowEmpty: false
            }
          },
          glSections: {
            type: "array",
            presence: true
          }
        }
      ) === undefined,
    queryFn: () =>
      request({
        method: "get",
        url: getGlCodesList({
          assetId,
          glSections,
          glTypes,
          systemAccountsToInclude,
          includeAllSystemAccounts
        })
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    select: (data) =>
      data.map((i) => ({
        ...i,
        value: i.id,
        label: `${i.glCode} - ${i.glName}`
      })),
    ...options
  });

  return query;
}
