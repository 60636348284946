import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";

/**
 * Dialog box for showing warning message if user edits the payment info and tries to perform any action
 */
export default function PaymentActionSaveWarningModal({
  isOpen,
  toggle,
  handleYes,
  isLoading,
  isRejectModal
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
      centered
    >
      <ModalHeader className="pb-0" toggle={toggle}>
        <div>
          <h2 className="color-dark modal-title">
            {Labels.CommonModals.confirm}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody>
        {Labels.Payments.Invoices.Details.invoiceUnsavedWarnMessage}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={isLoading}
          onClick={() => {
            handleYes();
            if (!isRejectModal) {
              toggle();
            }
          }}
        >
          {Labels.CommonModals.yes}
        </Button>{" "}
        <Button color="secondary" disabled={isLoading} onClick={toggle}>
          {Labels.CommonModals.no}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
