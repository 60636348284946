export const PurchaseInvoiceTypes = {
  "Purchase Invoice": "PURCHASE_INVOICE",
  "Purchase Credit Note": "PURCHASE_CREDIT_NOTE"
};

export const PurchaseInvoiceTypesEnum = {
  PURCHASE_CREDIT_NOTE: "PURCHASE_CREDIT_NOTE",
  PURCHASE_INVOICE: "PURCHASE_INVOICE"
};

// For dropdowns
export const PurchaseInvoiceTypeList = Object.entries(PurchaseInvoiceTypes).map(
  ([k, v]) => ({ label: k, value: v })
);
