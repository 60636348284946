import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getAssetUsers } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";
import { sortObjectListByKey } from "../utils/string";

/**
 * Returns list of users for an asset
 * @param {number} assetId
 * @returns object
 */
export default function useGetUsersUnderAsset(
  assetId,
  isBlocking = false,
  privilege
) {
  const request = isBlocking ? httpRequest : nonBlockingHttpRequest;
  const query = useQuery({
    queryKey: [QueryKeys.getAssetUsers, assetId, privilege],
    queryFn: () =>
      request({
        method: "get",
        url: getAssetUsers(assetId, privilege)
      })
        .then((res) =>
          Promise.resolve(
            sortObjectListByKey(
              res.data.map((user) => ({
                ...user,
                label: user.firstName + " " + user.lastName,
                value: user.id
              })),
              "label"
            )
          )
        )
        .catch((err) => Promise.reject(err.data.errors)),
    enabled: !!assetId
  });
  return query;
}
