import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";

const InvoiceActionConfirmModal = ({
  isOpen,
  toggle,
  handleAction,
  handleSaveAndAction,
  isLoading,
  isFormValid,
  currentActionRef,
  actionName,
  selectedOptionInSaveRef
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
      fade={false}
      centered
    >
      <ModalHeader className="pb-0" toggle={toggle}>
        <div>
          <h2 className="color-dark modal-title">
            {Labels.CommonModals.confirm}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody>
        {isFormValid
          ? `You have unsaved changes. You can save the invoice and ${actionName}, ${actionName} the invoice without saving, or continue editing`
          : Labels.Payments.Invoices.Details.invoiceUnsavedWarnMessage}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex">
          {isFormValid ? (
            <Button
              color="primary"
              disabled={isLoading || !isFormValid}
              className="mr-3"
              onClick={() => {
                selectedOptionInSaveRef.current = "Save & Action";
                handleSaveAndAction();
                toggle();
              }}
            >
              {`Save And ${actionName} ${Labels.CommonModals.invoice}`}
            </Button>
          ) : null}
          <Button
            color="primary"
            disabled={isLoading}
            className="mr-3"
            onClick={() => {
              selectedOptionInSaveRef.current = "Action";
              handleAction();
              toggle();
            }}
          >
            {`${actionName} ${Labels.CommonModals.invoice}`}
          </Button>
          <Button color="secondary" disabled={isLoading} onClick={toggle}>
            {Labels.CommonModals.continueEditing}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default InvoiceActionConfirmModal;
