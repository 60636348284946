const TaggingInvoiceStatuses = {
  NOT_TAGGED: "NOT_TAGGED",
  TAGGED: "TAGGED",
  ARCHIVED: "ARCHIVED"
};

// For dropdowns
export const TaggingInvoiceStatusList = Object.entries(
  TaggingInvoiceStatuses
).map(([k, v]) => ({ label: v, value: v }));

export default TaggingInvoiceStatuses;
