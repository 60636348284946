import { toTitleCase } from "../utils/string";

// First 3 are document statues
const PaymentStatuses = {
  DRAFT: "DRAFT",
  AWAITING_APPROVAL: "AWAITING_APPROVAL",
  AWAITING_PAYMENT: "AWAITING_PAYMENT",
  PAID: "PAID",
  VOIDED: "VOIDED",
  PAYMENT_PROCESSED: "PAYMENT_PROCESSED",
  AWAITING_RECEIPT: "AWAITING_RECEIPT", // -ve invoice specific
  RECEIVED: "RECEIVED" // -ve invoice specific
};

export const paymentStatusList = Object.entries(PaymentStatuses).map(
  ([k, v]) => ({
    id: k,
    value: k,
    label: toTitleCase(v.replace(/_/g, " "))
  })
);

export default PaymentStatuses;
