import React, { useContext, useRef, useState } from "react";
import {
  Badge,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Labels, Privileges } from "../../../../../constants/Constants";
import useToggle from "../../../../../hooks/useToggle";
import useQueryParams from "../../../../../hooks/useQueryParams";
import InvoiceFormContext from "./operational-operational/InvoiceFormContext";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import { useMutation, useQueryClient } from "react-query";
import {
  CHANGE_SHOULD_UPDATE_BUDGET,
  SET_INVOICE_SAVED_MESSAGE
} from "./operational-operational/actionsTypes";
import QueryKeys from "../../../../../constants/QueryKeys";
import moment, { isDate } from "moment";
import { putOperationalFormData } from "./operational-operational/operationalFormApi";
import {
  getInvoiceStatusClass,
  toReadableString,
  toStringConstant
} from "../../../../../utils/string";
import { useRouteMatch } from "react-router-dom";
import InvoiceStatuses from "../../../../../constants/InvoiceStatuses";
import VoidActionWarningModal from "./operational-operational/VoidActionWarningModal";
import InvoiceRejectionReasonModal from "../InvoiceRejectionModal";
import InvoiceCancellationModal from "../InvoiceCancellationModal";
import { formatAmount } from "../../../../../utils/number";
import {
  faChevronLeft,
  faChevronRight,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import InvoiceActionsMap, {
  InvoiceActions
} from "../../../../../constants/InvoiceActionsMap";
import Auth from "../../../../../auth/Auth";
import InvoiceActionConfirmModal from "./InvoiceActionConfirmModal";
import { resolveDispute } from "../../../../../utils/http/endpoints";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { getSymbolFromCurrencyCode } from "../../../../../utils/currency/currency";
import { PurchaseInvoiceTypesEnum } from "../../../../../constants/PurchaseInvoiceTypes";
import { nonBlockingHttpRequest } from "../../../../../utils/http/nonBlockingHttpRequest";
import LoaderWithMessage from "../../../../../elements/LoaderWithMessage";
import { useDisclosure } from "@mantine/hooks";
import PrepaymentScheduleModal from "./PrepaymentScheduleModal";
import useGetAssetById from "../../../../../hooks/useGetAssetById";
import Tooltip from "../../../../../elements/ToolTips/Tooltip";

export default function InvoiceControlBar({
  invoiceDetails,
  currency,
  handleBackToListingClick,
  setSuccessMsgVisible,
  onSuccessDismiss,
  refreshInvoiceData,
  setFormErrors,
  formErrors,
  onErrorsDismiss,
  isFormChanged,
  payments,
  isLineItemCardsHasErrors,
  getLinkedInvoiceData,
  currentActionNameRef,
  isUserConfirmedForVoidActionRef,
  primaryOrgId,
  prepaymentdataQuery,
  setSelectedLineItemMonths,
  selectedLineItemMonths,
  selectAllPrepaymentJournals
}) {
  const {
    state,
    dispatch,
    setIsFormChanged,
    isLineItemDataEdited,
    updateAmountsOnlyRef
  } = useContext(InvoiceFormContext);
  const { lineItems, otherDeductions } = state;
  const [comment, setComment] = useState("");

  const match = useRouteMatch();
  const history = useHistory();
  const queryParams = useQueryParams();

  const currentActionRef = useRef(null);
  const currentActionStatusRef = useRef("");
  const selectedOptionInSaveRef = useRef(null);
  const voidAndCreateDraftRef = useRef(false);

  const [isDropdownOpen, toggleDropdown] = useToggle();
  const [isRejectionModalOpen, toggleRejectionModal] = useToggle();
  const [isActionConfirmationModalOpen, toggleActionConfirmationModal] =
    useToggle();
  const [isCancellationModalOpen, toggleCancellationModal] = useToggle();
  const [isVoidActionWarningModalOpen, toggleVoidActionWarningModal] =
    useToggle();
  const [isPrepaymentScheduleModalOpen, prepaymentScheduleModalHandlers] =
    useDisclosure(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState();

  const openPrepaymentsModal = () => {
    if (!prepaymentdataQuery.data) {
      return;
    }
    prepaymentScheduleModalHandlers.open();
  };

  const assetQuery = useGetAssetById({ assetId: invoiceDetails.assetId });
  const isPoAutomationEnabled = lineItems.some(
    (lineitem) => lineitem?.purchaseOrder?.enablePoAutomation
  );
  const { mutate: performAction, isLoading: actionIsLoading } = useMutation(
    (action) => {
      let request = httpRequest;
      if (action.actionName === InvoiceActions.approve) {
        request = nonBlockingHttpRequest;
        setIsLoaderOpen(true);
        openPrepaymentsModal();
      }
      return request({
        method:
          action?.actionName === InvoiceActions.resolveDispute ? "put" : "post",
        url:
          action?.actionName === InvoiceActions.resolveDispute
            ? resolveDispute(invoiceDetails?.assetId, invoiceDetails?.id)
            : action.uri,
        data:
          action?.actionName === InvoiceActions.resolveDispute
            ? undefined
            : action
      });
    },
    {
      onError: (err) => {
        onSuccessDismiss();
        setFormErrors(err.data.errors);
      },
      onSuccess: () => {
        setComment("");
        setFormErrors([]);
        isUserConfirmedForVoidActionRef.current = false;
        voidAndCreateDraftRef.current = false;
        setIsFormChanged(false);
        // const isMoved = moveToNextInvoice();
        // if (isMoved) {
        //   return;
        // }
        refreshInvoiceData();
        onSuccessDismiss();
      },
      onSettled: () => {
        setIsLoaderOpen(false);
        // prepaymentScheduleModalHandlers.close()
      }
    }
  );

  const isCreditNote = () =>
    invoiceDetails.invoiceType ===
    PurchaseInvoiceTypesEnum.PURCHASE_CREDIT_NOTE;

  const handleAction = () => {
    if (currentActionNameRef.current === InvoiceActions.reject) {
      toggleRejectionModal();
      return;
    } else if (currentActionNameRef.current === InvoiceActions.approve) {
      openPrepaymentsModal();
      return;
    } else if (currentActionNameRef.current === InvoiceActions.cancel) {
      toggleCancellationModal();
      return;
    } else if (
      [
        InvoiceActions.voidInvoice,
        InvoiceActions.voidPCN,
        InvoiceActions.voidCreateDraftInvoice,
        InvoiceActions.voidSaveDraftPurchaseCreditNote
      ].includes(currentActionNameRef.current)
    ) {
      if (
        [
          InvoiceActions.voidCreateDraftInvoice,
          InvoiceActions.voidSaveDraftPurchaseCreditNote
        ].includes(currentActionNameRef.current)
      ) {
        voidAndCreateDraftRef.current = true;
      }
      toggleVoidActionWarningModal();
      return;
    }

    performAction(currentActionRef.current);
  };

  const handleSaveAndAction = () => {
    if (currentActionNameRef.current === InvoiceActions.reject && !comment) {
      toggleRejectionModal();
      return;
    } else if (
      currentActionNameRef.current === InvoiceActions.cancel &&
      !comment
    ) {
      toggleCancellationModal();
      return;
    } else if (currentActionNameRef.current === InvoiceActions.voidInvoice) {
      toggleVoidActionWarningModal();
      return;
    } else if (
      [
        InvoiceActions.voidCreateDraftInvoice,
        InvoiceActions.voidSaveDraftPurchaseCreditNote
      ].includes(currentActionNameRef.current)
    ) {
      toggleVoidActionWarningModal();
      voidAndCreateDraftRef.current = true;
      return;
    }

    handleFormSubmit();
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(putOperationalFormData, {
    onError: (error) => {
      setFormErrors(error);
      onSuccessDismiss();
    },
    onSuccess: (data, { showPrepaymentJournalModal }) => {
      prepaymentdataQuery.refetch();
      setComment("");
      if (isRejectionModalOpen) {
        toggleRejectionModal();
      } else if (isCancellationModalOpen) {
        toggleCancellationModal();
      } else if (isVoidActionWarningModalOpen) {
        toggleVoidActionWarningModal();
        voidAndCreateDraftRef.current = false;
      }
      // Set invoice saved message as per user action (data-action)
      onErrorsDismiss();
      setFormErrors([]);
      dispatch({
        type: SET_INVOICE_SAVED_MESSAGE,
        payload: {
          userAction: currentActionRef.current
        }
      });
      setSuccessMsgVisible(true);
      setIsFormChanged(false);
      dispatch({
        type: CHANGE_SHOULD_UPDATE_BUDGET,
        payload: false
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            query.queryKey[0] === QueryKeys.Getinvoiceoperationalbudgetdata
          );
        }
      });
      isUserConfirmedForVoidActionRef.current = false;
      // moveToNextInvoice();
      refreshInvoiceData();
      queryClient.invalidateQueries([QueryKeys.getPOEstimatedInvoices]);
      if (showPrepaymentJournalModal) {
        openPrepaymentsModal();
      }
    }
  });

  const handleFormSubmit = (evt) => {
    let actionName = "";
    let isPIMappedToPN = false;

    if (isCreditNote()) {
      isPIMappedToPN = !!(
        !invoiceDetails?.enriched &&
        invoiceDetails.invoiceAllocationDataList[0]?.associatedInvoiceId
      );
    }
    if (evt) {
      evt.preventDefault();
      actionName = evt.target.dataset.action;
      currentActionStatusRef.current = evt.target.dataset.status;

      currentActionNameRef.current = actionName;

      const action = invoiceDetails?.actions?.find(
        (action) => action.actionName === actionName
      );
      currentActionRef.current = action;

      if (
        [
          InvoiceActions.markAsDisputed,
          InvoiceActions.resolveDispute,
          InvoiceActions.cancel,
          InvoiceActions.voidInvoice,
          InvoiceActions.voidPCN,
          InvoiceActions.reject
        ].includes(actionName) &&
        isFormChanged
      ) {
        toggleActionConfirmationModal();
        return;
      } else if (!isFormChanged && !isPIMappedToPN) {
        handleAction();
        return;
      }
    }

    const formData = {
      invoiceLineItemBudgets: lineItems.map((lineItem) => ({
        budgetLineItemId:
          lineItem.budgetLineItem !== null &&
          lineItem.budgetingOption === "budgetLineItem"
            ? lineItem.budgetLineItem.value
            : undefined,
        purchaseOrderId:
          lineItem.poNumber !== null && lineItem.budgetingOption === "poNumber"
            ? lineItem.poNumber.value
            : undefined,
        poInvoiceScheduleId: lineItem.poInvoiceScheduleId,
        poInvoiceLineItemId: lineItem.poInvoiceLineItemId,
        glCodeId:
          lineItem.budgetingOption === "budgetLineItem"
            ? lineItem.budgetLineItem?.accountId
            : lineItem.glAccountCode?.value,
        accrualWriteBackData: +lineItem.totalAccruedAmount
          ? {
              totalWriteBackAmount: +lineItem.totalAccruedAmount,
              openingBalance: lineItem.openingBalance
            }
          : null,
        id: lineItem.isNew ? null : lineItem.lineItemId,
        invoiceLineItemId: lineItem.invoiceLineItemId,
        invoicePeriodFrom: isDate(lineItem.journalPeriodRange[0])
          ? lineItem.prepaymentJournalSchedule === "MONTHLY"
            ? moment(lineItem.journalPeriodRange[0])
                .set("D", 1)
                .format("YYYY-MM-DD")
            : moment(lineItem.journalPeriodRange[0]).format("YYYY-MM-DD")
          : null,
        invoicePeriodTo: isDate(lineItem.journalPeriodRange[1])
          ? lineItem.prepaymentJournalSchedule === "MONTHLY"
            ? moment(lineItem.journalPeriodRange[1])
                .endOf("month")
                .format("YYYY-MM-DD")
            : moment(lineItem.journalPeriodRange[1]).format("YYYY-MM-DD")
          : null,
        noBudgetAvailable: lineItem.budgetingOption === "noBudgetAvailable",
        prepaymentJournalSchedule: lineItem.prepaymentJournalSchedule,
        selectedAllAccruals: !!lineItem.showAllAccruals
      })),
      otherDeductions: otherDeductions.value,
      // Approve action can't be done using operational/enrichment api, save and prompt prepayments model
      status: [InvoiceActions.save, InvoiceActions.approve].includes(
        currentActionNameRef.current
      )
        ? toStringConstant(
            invoiceDetails.invoiceStatus || InvoiceStatuses.DRAFT
          )
        : toStringConstant(currentActionStatusRef.current),
      rejectionOrCancellationReason: comment || null,
      version: invoiceDetails.version
    };
    mutation.mutate({
      assetId: invoiceDetails.assetId,
      invoiceId: invoiceDetails.id,
      data: formData,
      showPrepaymentJournalModal:
        currentActionNameRef.current === InvoiceActions.approve
    });
  };

  const isFormValid = () => {
    // Form validations withing each lineItem cards are handled separately - for showing error icon beside opexLineDesc title
    return (
      !isLineItemDataEdited &&
      // other deductions errors should not be there
      !otherDeductions.validationErrors &&
      // No card errors should be there
      !isLineItemCardsHasErrors &&
      //  &&
      // ([InvoiceActions.cancel, InvoiceActions.reject].includes(
      //   currentActionNameRef.current
      // )
      //   ? !!comment
      //   : true)
      lineItems.every((lineItem) => !lineItem.prepaymentError)
    );
  };

  const handleFormCancel = (event) => {
    event.persist();
    handleBackToListingClick(event);
  };

  const invoiceStatus = invoiceDetails?.invoiceStatus;
  const isActionPermitted = (actionName) => {
    if (!invoiceStatus) {
      return false;
    }

    const apiActionsList = invoiceDetails?.actions
      ? [...invoiceDetails.actions]
      : [];

    const isActionPresentInApi =
      apiActionsList.findIndex((action) => action.actionName === actionName) ===
      -1
        ? false
        : true;

    if (actionName === InvoiceActions.save) {
      if (
        [InvoiceStatuses.DRAFT, InvoiceStatuses.AWAITING_APPROVAL].includes(
          invoiceStatus
        )
      ) {
        return true;
      }
    } else if (actionName === InvoiceActions.submitForApproval) {
      if (
        invoiceDetails.invoiceStatus === InvoiceStatuses.DRAFT &&
        isFormValid() &&
        Auth.getUserPrivileges().includes(Privileges.EDIT_INVOICE) &&
        isActionPresentInApi
      ) {
        return true;
      }
    } else if (isActionPresentInApi) {
      return true;
    }
  };

  const shouldActionDisalbed = (actionName) => {
    if (actionName === InvoiceActions.save) {
      if (
        Auth.getUserPrivileges().includes(Privileges.EDIT_INVOICE) &&
        isFormValid() &&
        isFormChanged
      ) {
        return false;
      }
    } else if (actionName === InvoiceActions.submitForApproval) {
      if (
        isFormValid() &&
        Auth.getUserPrivileges().includes(Privileges.EDIT_INVOICE) // & action specific privilliage
      ) {
        return false;
      }
    } else if ([InvoiceActions.approve].includes(actionName)) {
      if (isFormValid() && prepaymentdataQuery.isSuccess) {
        return false;
      }
    } else if (
      [
        InvoiceActions.markAsDisputed,
        InvoiceActions.resolveDispute,
        InvoiceActions.reject,
        InvoiceActions.cancel,
        InvoiceActions.voidInvoice,
        InvoiceActions.voidPCN,
        InvoiceActions.voidCreateDraftInvoice,
        InvoiceActions.voidSaveDraftPurchaseCreditNote
      ].includes(actionName)
    ) {
      return false;
    }

    return true;
  };

  const allActions = Object.entries(InvoiceActions).map(([_, actionName]) => ({
    "data-action": actionName,
    onClick: handleFormSubmit,
    "data-status": InvoiceActionsMap.get(actionName),
    disabled: shouldActionDisalbed(actionName)
  }));

  let permittedActions = allActions.filter((action) =>
    isActionPermitted(action["data-action"])
  );

  let formControls = null;
  if (permittedActions.length !== 0) {
    formControls = (
      <div className="d-flex">
        <div className="mr-2">
          <ButtonDropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
            <button
              color="primary"
              className="btn btn-primary"
              // className="btn btn-primary save-button"
              {...permittedActions[0]}
            >
              {permittedActions[0]["data-action"]}
            </button>
            <DropdownToggle
              split
              color="primary"
              disabled={permittedActions.length === 1}
            />
            <DropdownMenu right>
              {permittedActions.slice(1).map((action, index) => (
                <DropdownItem key={index} role="button" {...action}>
                  {action["data-action"]}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </ButtonDropdown>
        </div>
        <button
          className="btn btn-secondary"
          type="button"
          onClick={handleFormCancel}
        >
          {Labels.Payments.Invoices.Details.Cancel}
        </button>
      </div>
    );
  } else {
    formControls = (
      <div>
        <button
          className="btn btn-secondary"
          type="button"
          onClick={handleFormCancel}
        >
          {Labels.Payments.Invoices.Details.Cancel}
        </button>
      </div>
    );
  }

  return (
    <div className="px-2 control-bar d-flex justify-content-between align-items-center mt-0 body-primary color-text-primary position-sticky">
      {match.path ===
      "/org/:orgId/assets/asset/:assetId/payruns/invoices/:invoiceId" ? null : (
        <div className="d-flex flex-column">
          <span className="row-header-2 mb-1">
            {Labels.Payments.Invoices.Details.asset}
          </span>
          <span id="assetName" className="text-center truncate-data-item-text">
            {invoiceDetails.assetName}
          </span>
          <Tooltip target="assetName">{invoiceDetails.assetName}</Tooltip>
        </div>
      )}
      <div className="d-flex flex-column">
        <span className="row-header-2 mb-1">
          {Labels.Payments.Invoices.Details.supplier}
        </span>
        <div className="d-flex align-items-center">
          <span
            id="supplierName"
            className="text-center truncate-data-item-text"
          >
            {invoiceDetails.supplierName}
          </span>
          <Tooltip target="supplierName">{invoiceDetails.supplierName}</Tooltip>
          {invoiceDetails?.isContactVerified === false && (
            <>
              <FontAwesomeIcon
                id="supplier-verification-icon"
                icon={faExclamationCircle}
                className="ml-1 color-negative"
              />
              <Tooltip target="supplier-verification-icon" placement="top">
                {`This ${toReadableString(
                  invoiceDetails.invoiceType
                )} cannot be submitted for approval if the Supplier ${
                  invoiceDetails.supplierName
                } is not verified.`}
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <div className="d-flex flex-column">
        <span className="row-header-2 mb-1">
          {Labels.Payments.Invoices.Details.InvoiceStatus}
        </span>
        <Badge
          className={`border m-0 text-center small-text ${getInvoiceStatusClass(
            invoiceDetails.invoiceStatus
          )}`}
        >
          {invoiceDetails.invoiceStatus}
        </Badge>
      </div>
      <div className="d-flex flex-column">
        <span className="row-header-2 mb-1">
          {invoiceDetails.isNegativeInvoice || isCreditNote()
            ? Labels.Payments.received
            : Labels.Payments.paid}
        </span>
        <span className="text-center">
          {formatAmount(
            invoiceDetails.amountPaid,
            getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)
          )}
        </span>
      </div>
      <div className="d-flex flex-column">
        <span className="row-header-2 mb-1">{Labels.Payments.due}</span>
        <span className="text-center">
          {formatAmount(
            invoiceDetails.amountPayable - invoiceDetails.amountPaid,
            getSymbolFromCurrencyCode(invoiceDetails.invoiceCurrency)
          )}
        </span>
      </div>

      {!!queryParams.get("getLinks") ? (
        <div className="d-flex flex-column">
          <span className="row-header-2 mb-1 text-center">
            {Labels.CommonModals.invoices}
          </span>
          <span className="text-center truncate-data-item-text d-flex align-items-center justify-content-right pb-2">
            <button
              className={classNames("btn btn-link m-0 p-0", {
                "disabled-link": getLinkedInvoiceData(Labels.CommonModals.prev)
                  .isDisabled
              })}
              disabled={
                getLinkedInvoiceData(Labels.CommonModals.prev).isDisabled
              }
              onClick={(event) => {
                if (
                  !getLinkedInvoiceData(Labels.CommonModals.prev).isDisabled
                ) {
                  if (!isFormChanged) {
                    setFormErrors([]);
                    setSuccessMsgVisible(false);
                    // prevInvoiceStatusRef.current = null;
                  }
                  updateAmountsOnlyRef.current = null;
                  history.replace(
                    getLinkedInvoiceData(Labels.CommonModals.prev).to
                  );
                }
              }}
              data-testid="prev-invoice-btn"
            >
              <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
            </button>
            <span className="">
              {`${invoiceDetails.currentNumber || "-"} of ${
                invoiceDetails.totalElements || "-"
              }`}
            </span>
            <button
              className={classNames("btn btn-link m-0 p-0", {
                "disabled-link": getLinkedInvoiceData(Labels.CommonModals.next)
                  .isDisabled
              })}
              disabled={
                getLinkedInvoiceData(Labels.CommonModals.next).isDisabled
              }
              onClick={(event) => {
                if (
                  !getLinkedInvoiceData(Labels.CommonModals.next).isDisabled
                ) {
                  if (!isFormChanged) {
                    setFormErrors([]);
                    setSuccessMsgVisible(false);
                    // prevInvoiceStatusRef.current = null;
                  }
                  updateAmountsOnlyRef.current = null;
                  history.replace(
                    getLinkedInvoiceData(Labels.CommonModals.next).to
                  );
                }
              }}
              data-testid="next-invoice-btn"
            >
              <FontAwesomeIcon className="ml-1" icon={faChevronRight} />
            </button>
          </span>
        </div>
      ) : null}
      <div>
        <div>{formControls}</div>
      </div>
      {isRejectionModalOpen ? (
        <InvoiceRejectionReasonModal
          isOpen={isRejectionModalOpen}
          toggle={toggleRejectionModal}
          updateInvoice={() => {
            // Clear api response message after updating invoice data
            onSuccessDismiss();
            onErrorsDismiss();
            setIsFormChanged(false);
            refreshInvoiceData();
            // moveToNextInvoice();
            // return;
          }}
          comment={comment}
          setComment={setComment}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          assetId={invoiceDetails.assetId}
          invoiceId={invoiceDetails.id}
          isFormChanged={isFormChanged}
          handleAction={handleAction}
          selectedOptionInSave={selectedOptionInSaveRef.current}
          handleSaveAndAction={handleSaveAndAction}
          isLoading={actionIsLoading || mutation.isLoading}
        />
      ) : null}
      {isCancellationModalOpen ? (
        <InvoiceCancellationModal
          isOpen={isCancellationModalOpen}
          toggle={toggleCancellationModal}
          updateInvoice={() => {
            // Clear api response message after updating invoice data
            onSuccessDismiss();
            onErrorsDismiss();
            setIsFormChanged(false);
            refreshInvoiceData();
            // moveToNextInvoice();
            // return;
          }}
          assetId={invoiceDetails.assetId}
          invoiceId={invoiceDetails.id}
          isFormChanged={isFormChanged}
          handleAction={handleAction}
          selectedOptionInSave={selectedOptionInSaveRef.current}
          handleSaveAndAction={handleSaveAndAction}
          isLoading={actionIsLoading || mutation.isLoading}
          comment={comment}
          setComment={setComment}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
        />
      ) : null}
      {isVoidActionWarningModalOpen ? (
        <VoidActionWarningModal
          isOpen={isVoidActionWarningModalOpen}
          dispatch={dispatch}
          toggle={() => {
            toggleVoidActionWarningModal();
            voidAndCreateDraftRef.current = false;
            onSuccessDismiss();
            setComment("");
          }}
          payments={payments}
          updateInvoice={() => {
            // Clear api response message after updating invoice data
            setComment("");
            onErrorsDismiss();
            setIsFormChanged(false);
            refreshInvoiceData();
            voidAndCreateDraftRef.current = false;
          }}
          assetId={invoiceDetails.assetId}
          orgId={invoiceDetails.orgId}
          invoiceId={invoiceDetails.id}
          isOnboardingComplete={assetQuery.data?.isOnboardingComplete}
          isFormChanged={isFormChanged}
          handleAction={handleAction}
          selectedOptionInSave={selectedOptionInSaveRef.current}
          handleSaveAndAction={handleSaveAndAction}
          isLoading={actionIsLoading || mutation.isLoading}
          comment={comment}
          setComment={setComment}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isPCN={isCreditNote()}
          invoice={invoiceDetails}
          voidAndCreateDraftRef={voidAndCreateDraftRef}
          currentActionNameRef={currentActionNameRef}
          setSuccessMsgVisible={setSuccessMsgVisible}
          isPoLinked={state?.lineItems.some(
            (lineItem) => lineItem?.budgetingOption === "poNumber"
          )}
          isPoAutomationEnabled={isPoAutomationEnabled}
        />
      ) : null}
      {isActionConfirmationModalOpen ? (
        <InvoiceActionConfirmModal
          isOpen={isActionConfirmationModalOpen}
          handleAction={handleAction}
          selectedOptionInSaveRef={selectedOptionInSaveRef}
          handleSaveAndAction={handleSaveAndAction}
          isLoading={actionIsLoading || mutation.isLoading}
          currentActionRef={currentActionRef}
          toggle={toggleActionConfirmationModal}
          actionName={currentActionNameRef.current}
          isFormValid={!!isFormValid()}
        />
      ) : null}
      {isPrepaymentScheduleModalOpen && prepaymentdataQuery.isSuccess ? (
        <PrepaymentScheduleModal
          isOpen={isPrepaymentScheduleModalOpen}
          toggle={() => {
            selectAllPrepaymentJournals(prepaymentdataQuery.data);
            prepaymentScheduleModalHandlers.toggle();
          }}
          invoiceId={invoiceDetails.id}
          prepaymentdataQuery={prepaymentdataQuery}
          currencySymbol={getSymbolFromCurrencyCode(
            invoiceDetails.assetCurrency
          )}
          selectedLineItemMonths={selectedLineItemMonths}
          setSelectedLineItemMonths={setSelectedLineItemMonths}
          updateInvoice={() => {
            // Clear api response message after updating invoice data
            setComment("");
            onErrorsDismiss();
            setIsFormChanged(false);
            refreshInvoiceData();
            queryClient.invalidateQueries(
              QueryKeys.getWithholdingTaxPaymentOutstanding
            );
            voidAndCreateDraftRef.current = false;
          }}
          primaryOrgId={primaryOrgId}
        />
      ) : null}
      {isLoaderOpen ? (
        <LoaderWithMessage
          message={Labels.CommonModals.approvalInProgress}
          isOpen={isLoaderOpen}
          hideLoader={() => setIsLoaderOpen(false)}
        />
      ) : null}
    </div>
  );
}
