import InvoiceStatuses from "../constants/InvoiceStatuses";
import TaskStatuses from "../constants/TaskStatuses";
import JournalStatuses from "../constants/JournalStatuses";
import PurchaseOrderStatuses from "../constants/PurchaseOrderStatuses";
import SalesCreditNotePaymentStatuses from "../constants/SalesCreditNotePaymentStatuses";
import SalesInvoiceStatuses from "../constants/SalesInvoiceStatuses";
import TransactionStatuses from "../constants/TransactionStatuses";
import FixedAssetRegisterStatuses from "../constants/FixedAssetRegisterStatuses";
import PaymentStatuses from "../constants/PaymentStatuses";
import ContractStatuses from "../constants/ContractStatuses";
import ProjectInvoiceStatuses from "../constants/ProjectInvoicesStatuses";
import TaggingInvoiceStatuses from "../constants/TaggingInvoiceStatuses";

/**
 *
 * @param {string} str
 *
 * @returns {String} Converts a string to title case
 *
 */
export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/**
 *
 * @param {string} str
 *
 * @returns {string} Converted string : "Awaiting Approval" -> AWAITING_APPROVAL
 */
export function toStringConstant(str) {
  return str.toUpperCase().replace(/(\s)/g, "_");
}

/**
 *
 * @param {string} str
 *
 * @returns {string} Converted string : AWAITING_APPROVAL -> "Awaiting Approval"
 */
export function toReadableString(str = "") {
  return toTitleCase(str.replace(/_/g, " "));
}

/**
 * @param {string} str
 *
 * @returns {boolean} Returns true if string has alphanumerics and single space between words without spaces at beginning or ending
 *
 */
export function validateStringForAlphaNumericsWithSpace(str) {
  const regex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/gi;
  return regex.test(str.trim());
}

/**
 * @param {string} str
 *
 * @returns {string} Returns file name excluding file extension
 *
 */
export function getFilenameWithoutExtension(fileName = "") {
  const regex = /^(.*)\.[^.]+$/gm;
  const fileNameWithoutExtension = fileName.includes(".")
    ? regex.exec(fileName)?.[1] || ""
    : fileName;
  return fileNameWithoutExtension;
}

/**
 * @param {string} str
 *
 * @returns {string} Returns file extension
 *
 */
export function getFileExtension(fileName) {
  const fileExtension = fileName.includes(".")
    ? fileName.match(/\.[a-z]+$/i)?.[0] || ""
    : "";
  return fileExtension;
}

/**
 * @param {object} object
 *
 * @returns {string} Returns query params string with given object's properties as key and value pairs
 *
 */
export function buildQueryParamsString(obj = {}) {
  const params = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    // Filter undefined values
    if (value !== undefined && value !== null) {
      params.set(key, value);
    }
  });

  return params.toString();
}

/**
 *
 * @param {array} list
 * @param {string} key
 * @returns {array}
 *
 * Returns sorted object list in alphabetical order by given key
 */
export const sortObjectListByKey = (list = [], key) => {
  if (!Array.isArray(list)) {
    throw new Error("List given for sorting needs to be any array of objects");
  }
  const compareFn = (objA, objB) => {
    const stringA = objA[key].toString().toLowerCase();
    const stringB = objB[key].toString().toLowerCase();
    if (stringA > stringB) {
      return 1;
    } else if (stringA < stringB) {
      return -1;
    } else {
      return 0;
    }
  };

  return [...list].sort(compareFn);
};

export const formatAddress = (addrObj) => {
  let address = "";
  for (const key in addrObj) {
    if (Object.hasOwnProperty.call(addrObj, key)) {
      const prop = addrObj[key];
      address += prop ? prop + ", " : "";
    }
  }

  return address.trim().replace(/,$/g, ".");
};

export const getPurchaseOrderStatusClass = (purchaseOrderStatus) => {
  let cls;
  switch (purchaseOrderStatus) {
    case PurchaseOrderStatuses.DRAFT:
      cls = "badge-info";
      break;
    case PurchaseOrderStatuses.AWAITING_APPROVAL:
      cls = "badge-lime";
      break;
    case PurchaseOrderStatuses.AWAITING_REVIEW:
      cls = "badge-warning";
      break;
    case PurchaseOrderStatuses.AWAITING_GRN:
    case PurchaseOrderStatuses.RECEIPTED:
    case PurchaseOrderStatuses.INVOICED:
      cls = "badge-success";
      break;
    case PurchaseOrderStatuses.VOIDED:
    case PurchaseOrderStatuses.CANCELLED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getFixedAssetRegisterStatusClass = (registerStatus) => {
  let cls;
  switch (registerStatus) {
    case FixedAssetRegisterStatuses.DRAFT:
      cls = "badge-info";
      break;
    case FixedAssetRegisterStatuses.REGISTERED:
      cls = "badge-success";
      break;
    case PurchaseOrderStatuses.VOIDED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getProjectInvoiceStatus = (invoiceStatus) => {
  let cls;
  switch (invoiceStatus) {
    case ProjectInvoiceStatuses.DRAFT:
      cls = "badge-info";
      break;
    case ProjectInvoiceStatuses.ASSIGNED:
      cls = "badge-success";
      break;
    case ProjectInvoiceStatuses.ARCHIVED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getTaggingInvoiceStatus = (invoiceStatus) => {
  let cls;
  switch (invoiceStatus) {
    case TaggingInvoiceStatuses.NOT_TAGGED:
      cls = "badge-info";
      break;
    case TaggingInvoiceStatuses.TAGGED:
      cls = "badge-success";
      break;
    case TaggingInvoiceStatuses.ARCHIVED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getInvoiceStatusClass = (invoiceStatus) => {
  let cls;
  switch (invoiceStatus) {
    case InvoiceStatuses.DRAFT:
      cls = "badge-info";
      break;
    case InvoiceStatuses.AWAITING_APPROVAL:
      cls = "badge-lime";
      break;
    case InvoiceStatuses.AWAITING_PAYMENT:
    case InvoiceStatuses.AWAITING_RECEIPT:
      cls = "badge-warning";
      break;
    case InvoiceStatuses.PAID:
    case InvoiceStatuses.RECEIVED:
    case SalesInvoiceStatuses.APPROVED:
      cls = "badge-success";
      break;
    case InvoiceStatuses.VOIDED:
    case InvoiceStatuses.CANCELLED:
    case InvoiceStatuses.IN_DISPUTE:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getJournalStatusClass = (journalStatus) => {
  let cls;
  switch (journalStatus) {
    case JournalStatuses.DRAFT:
      cls = "badge-info";
      break;
    case JournalStatuses.AWAITING_APPROVAL:
      cls = "badge-warning";
      break;
    case JournalStatuses.POSTED:
      cls = "badge-success";
      break;
    case JournalStatuses.CANCELLED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getTaskStatusClass = (taskStatus) => {
  let cls;
  switch (taskStatus) {
    case TaskStatuses.NEW:
      cls = "badge-info";
      break;
    case TaskStatuses.IN_PROGRESS:
      cls = "badge-warning";
      break;
    case TaskStatuses.COMPLETED:
      cls = "badge-success";
      break;
    case TaskStatuses.ARCHIVED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getTransactionStatusClass = (transactionStatus) => {
  let cls;
  switch (transactionStatus) {
    case TransactionStatuses.DRAFT:
      cls = "badge-info";
      break;
    case TransactionStatuses.AWAITING_APPROVAL:
      cls = "badge-lime";
      break;
    case TransactionStatuses.RECONCILED:
      cls = "badge-success";
      break;
    case TransactionStatuses.VOIDED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getSalesPaymentStatusClass = (paymentStatus) => {
  let cls;
  switch (toReadableString(paymentStatus)) {
    case SalesCreditNotePaymentStatuses.DRAFT:
      cls = "badge-info";
      break;
    case SalesCreditNotePaymentStatuses.AWAITING_APPROVAL:
      cls = "badge-lime";
      break;
    case SalesCreditNotePaymentStatuses.AWAITING_PAYMENT:
      cls = "badge-warning";
      break;
    case SalesCreditNotePaymentStatuses.PAID:
      cls = "badge-success";
      break;
    case SalesCreditNotePaymentStatuses.VOIDED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getPaymentStatusClass = (paymentStatus) => {
  let cls;
  switch (paymentStatus) {
    case PaymentStatuses.DRAFT:
      cls = "badge-info";
      break;
    case PaymentStatuses.AWAITING_APPROVAL:
      cls = "badge-lime";
      break;
    case PaymentStatuses.AWAITING_PAYMENT:
    case PaymentStatuses.AWAITING_RECEIPT:
      cls = "badge-warning";
      break;
    case PaymentStatuses.PAID:
    case PaymentStatuses.RECEIVED:
      cls = "badge-success";
      break;
    case PaymentStatuses.VOIDED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

export const getContractStatusClass = (contractStatus) => {
  let cls;
  switch (toReadableString(contractStatus)) {
    case ContractStatuses.DRAFT:
      cls = "badge-info";
      break;
    case ContractStatuses.AWAITING_APPROVAL:
      cls = "badge-warning";
      break;
    case ContractStatuses.APPROVED:
      cls = "badge-success";
      break;
    case ContractStatuses.CANCELLED:
    case ContractStatuses.VOIDED:
      cls = "badge-danger";
      break;
    default:
      cls = "badge-info";
  }
  return cls;
};

/**
 * @param {string} 10000 - Turbine
 *
 * @returns {string} Returns 10000 by splitting by hyphen
 *
 */
export const getGLCodeFromGLName = (glName) => {
  if (glName !== undefined && glName !== null) {
    return glName.split(" - ")[0];
  }
  return null;
};

/**
 * @param {string}
 *
 * @returns {string} Escape single quote, double qoute and slash before encoding the string
 *
 */
export const fixedEncodeURIComponent = (str) => {
  let result = str
    .replaceAll("\\", "\\")
    .replaceAll("'", "\\'")
    .replaceAll('"', '\\"');
  return encodeURIComponent(result);
};
