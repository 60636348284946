//Types of comments .. more of filters
export const COMMENTS_TYPES = {
  ALL: "All",
  COMMENTS: "Comments",
  HISTORY: "History"
};
// fiter type to send to server to get the appropriate data
export const QUERY_PARAMS_TO_GET_DATA = {
  All: "ALL",
  Comments: "COMMENT",
  History: "HISTORY"
};
