import moment, { isDate } from "moment";

/**
 *
 * @param {string} dateStr
 *
 * @returns {string} MM-YY format based on the date passed
 */
export function getCurrentPeriodDateLabel(dateStr) {
  const date = moment(dateStr).toDate();
  let year = date.getFullYear().toString().substr(2);
  const month = date.toLocaleString("en-US", { month: "short" });
  return month + "-" + year;
}

/**
 *
 * @param {string} dateStr
 * @param {number} period
 *
 * @returns {string} MM-YY format by substracting number of months(i.e period) from the date passed
 */
export function getPreviousPeriodDateLabel(dateStr, period) {
  const date = moment(dateStr).toDate();
  let targetDate = moment(date).subtract(period, "months").toDate();
  let year = targetDate.getFullYear().toString().substr(2);
  const month = targetDate.toLocaleString("default", { month: "short" });
  return month + "-" + year;
}

/**
 *
 * @param {date} date
 *
 * @returns {string} full date with month in 1-12 notation instead of 0-11
 */
export function getFullDateFromMonthYear(date) {
  if (date !== null) {
    let year = date.getFullYear().toString();
    let month = getTwoDigitMonth(date.getMonth() + 1);
    return year + "-" + month + "-01";
  }
}

/**
 *
 * @param {number} month
 *
 * @returns {number} two digit month by prefixing 0 for months 1 to 9
 */
export function getTwoDigitMonth(month) {
  return month < 10 ? "0" + month : month;
}

/**
 * @returns {date} current month minus one
 */
export function getMonthEndingForReports() {
  return moment().subtract(1, "months").toDate();
}

/**
 * @returns {date} passed day minus one week
 */
export function getDayMinusOneWeek(date) {
  return moment(date).subtract(1, "weeks").toDate();
}

/**
 * @returns {date} passed day minus one week
 */
export function getDatePlusOneMonth(date) {
  return moment(date).add(1, "months").toDate();
}

/**
 * @returns {str} date in user readable format
 *
 * @example getDateInDisplayFormat(2020-01-01) => (01 Jan 2020)
 */
export function getDateInDisplayFormat(date, format = "DD MMM YYYY") {
  return date ? moment(date).format(format) : null;
}

/**
 * @returns {str} datetime in user readable format
 *
 * @example getDateInDisplayFormat(2020-01-01) => (01 Jan 2020 12:00:00)
 */
export function getDateTimeInDisplayFormat(
  date,
  format = "DD MMM YYYY HH:MM:SS"
) {
  return moment(date).format(format);
}

/**
 * @returns {mm-dd} first month of current financial year
 *
 * @example getFirstMonthOfCurrentFinancialYear(03-31) => (April 2020)
 * @example getFirstMonthOfCurrentFinancialYear(12-31) => (Jan 2020)
 */
export function getFirstMonthOfCurrentFinancialYear(assetFinancialYearEndDate) {
  let fullAssetFinancialYearEndDate = moment(
    moment().toDate().getFullYear() + "-" + assetFinancialYearEndDate
  ).toDate();
  // Go back by one year if the year end date has december
  if (fullAssetFinancialYearEndDate.getMonth() === 11) {
    fullAssetFinancialYearEndDate = moment(
      moment().toDate().getFullYear() - 1 + "-" + assetFinancialYearEndDate
    ).toDate();
  }
  return moment(fullAssetFinancialYearEndDate).add(1, "months").toDate();
}

/**
 * @returns {date} converts utc to local
 */
export function convertDateFromUTCToLocal(date, format) {
  return moment.utc(date).local().format(format);
}

/**
 * @returns {date timestamp} converts YYYY-MM-DD to timestamp
 */
export function convertDateFromStringToUTC(date) {
  return moment.utc(date).valueOf();
}

/**
 * @returns {dateStr} converts date obj to passed string format
 */
export function convertDateToString(date, format) {
  return moment(date).format(format);
}

/**
 * @returns {String} converts Jun-20 to 2020-06-01
 */
export function convertShortDateToDateString(shortDateStr) {
  let dateStr = "";
  if (shortDateStr) {
    let shorDateArr = shortDateStr.split("-");
    if (shorDateArr.length > 0) {
      dateStr =
        "20" +
        shorDateArr[1] +
        "-" +
        moment().month(shorDateArr[0]).format("MM") +
        "-01";
    }
  }
  return moment(dateStr).format("YYYY-MM-DD");
}

export const getMonthName = (month, fullMonth = false) => {
  return moment()
    .month(month - 1)
    .format(fullMonth ? "MMMM" : "MMM");
};

export const getInstrumentStartAndEndDates = ({
  isOnboardingComplete,
  openingPeriodDate,
  conversionPeriodDate
}) => {
  // Return nulls if any of these two are not valid dates
  if (!isDate(openingPeriodDate) || !isDate(conversionPeriodDate)) {
    return {
      start: null,
      end: null
    };
  }

  if (isOnboardingComplete) {
    // If onboarding is completed
    // range: (opening period month end, today]
    return {
      start: moment(openingPeriodDate).add("month", 1).toDate(),
      end: moment().toDate()
    };
  } else {
    // If onboarding is not complete
    // range: (opening period month end, conversion period month start)
    return {
      start: moment(openingPeriodDate).toDate(),
      end: moment(conversionPeriodDate).subtract("day", 1).toDate()
    };
  }
};

/**
 *
 * @param {Date} date
 * @returns  {string} date in YYYY-MM-DD format
 */
export const serializeDate = (date) => {
  return isDate(date) ? moment(date).format("YYYY-MM-DD") : null;
};

/**
 * @param {string} startDate
 * The start date should in the format of "YYYY-MM-DD"
 * @param {string} endDate
 * The end date should in the format of "YYYY-MM-DD"
 * @param {string} fyEnding
 * The fyEnding should in the format of "MM-DD"
 * @returns {array} [{label:Dec-2022,value:2022},{label:Dec-2023,value:2023},...]
 */
export const fyYearsWithLabels = (startDate, endDate, fyEnding) => {
  let startFyYear = moment(startDate).year() + "-" + fyEnding;
  let endFyYear = moment(endDate).year() + "-" + fyEnding;
  let fyMonth = moment(moment().year() + "-" + fyEnding).format("MMM");

  // Start date should be included in startFyYear
  if (moment(startDate).valueOf() - moment(startFyYear).valueOf() > 0) {
    startFyYear = moment(startDate).year() + 1 + "-" + fyEnding;
  }

  // End date should be included in endFyYear
  if (moment(endDate).valueOf() - moment(endFyYear).valueOf() > 0) {
    endFyYear = moment(endDate).year() + 1 + "-" + fyEnding;
  }

  let fyYears = [];
  for (
    let year = moment(startFyYear).year();
    year <= moment(endFyYear).year();
    year++
  ) {
    fyYears.push({
      label: fyMonth + "-" + year,
      value: year
    });
  }

  return fyYears;
};
