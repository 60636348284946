import React from "react";
import { Labels } from "../../../../../constants/Constants";
import useGetEmailBody from "../../../../../hooks/useGetEmailBody";
import DOMPurify from "dompurify";

const EmailBody = ({
  emailCloudStorageUrl,
  documentCloudStorageUrl,
  fromEmailAddress
}) => {
  const query = useGetEmailBody(emailCloudStorageUrl, documentCloudStorageUrl);

  if (!emailCloudStorageUrl) {
    return null;
  }

  return (
    <div className="email-body">
      <div className="body-primary">
        <details>
          <summary className="link-primary fv-500 view-email-body-link">
            {Labels.Payments.Invoices.Details.viewEmailContent}
          </summary>
          {fromEmailAddress ? (
            <p className="mt-2">
              {`${Labels.Payments.Invoices.from}: `}{" "}
              <strong>{fromEmailAddress}</strong>
            </p>
          ) : null}
          <p
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(query.data) }}
          ></p>
        </details>
      </div>
    </div>
  );
};

export default EmailBody;
