import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import useGetOrganizations from "../../../../../hooks/useGetOrganizations";
import { useState } from "react";
import { useMemo } from "react";
import DisplayAlerts from "../../../../../elements/DisplayAlerts";
import useAssignAssetMutation from "../../useAssignAssetMutation";

export default NiceModal.create(
  ({
    params,
    selectedAssetIds,
    setAssignedCategoryToAsset,
    assetsHaveTagsIds
  }) => {
    const handleConfirm = () => {
      modal.resolve();
      modal.remove();
    };
    const mutation = useAssignAssetMutation(params, {
      onSuccess: () => {
        handleConfirm();
        setAssignedCategoryToAsset(true);
      }
    });
    const modal = useModal();

    const [search, setSearch] = useState("");
    const organisationsQuery = useGetOrganizations();
    const organizationsList = useMemo(() => {
      if (!organisationsQuery.data) {
        return [];
      }

      let selectionList = [];
      // Remove orgs without any assets
      selectionList = organisationsQuery.data.filter(
        (org) => org.organizationAssets.length > 0
      );

      // Filter by search text
      if (search) {
        return organisationsQuery.data
          .reduce((acc, org) => {
            let isOrgMatched = org.name
              .toLowerCase()
              .includes(search.toLowerCase());
            if (isOrgMatched) {
              acc.push(org);
            } else {
              acc.push({
                ...org,
                organizationAssets: org.organizationAssets.filter((asset) =>
                  asset.displayName.toLowerCase().includes(search.toLowerCase())
                )
              });
            }
            return acc;
          }, [])
          .filter((org) => org.organizationAssets.length > 0);
      }

      // TODO: Show assets only under primary org
      // return organisationsQuery.data.map((org) => ({
      //   ...org,
      //   organizationAssets: org.organizationAssets.filter()
      // }));

      return selectionList;
    }, [organisationsQuery, search]);

    const [selectedAssets, setSelectedAssets] = useState(selectedAssetIds);

    const handleAssetSelection = (assetId) => {
      if (selectedAssets.includes(assetId)) {
        return void setSelectedAssets(
          selectedAssets.filter((id) => id !== assetId)
        );
      }

      setSelectedAssets([...selectedAssets, assetId]);
    };

    const handleOrgSelection = (orgId) => {
      const orgAssetIds = organizationsList
        .find((org) => org.id === orgId)
        .organizationAssets.map((asset) => asset.id);

      // If every asset of the org present in selectedAssets list then remove all of them else include all of them
      if (orgAssetIds.every((assetId) => selectedAssets.includes(assetId))) {
        return void setSelectedAssets(
          selectedAssets.filter(
            (assetId) =>
              assetsHaveTagsIds.includes(assetId) ||
              !orgAssetIds.includes(assetId)
          )
        );
      }

      setSelectedAssets([...selectedAssets, ...orgAssetIds]);
    };

    const isOrgChecked = (orgId) => {
      const orgAssetIds = organizationsList
        .find((org) => org.id === orgId)
        .organizationAssets.map((asset) => asset.id);

      return orgAssetIds.every((assetId) => selectedAssets.includes(assetId));
    };

    const isOrgDisabled = (organizationAssets) => {
      return organizationAssets.every((asset) =>
        assetsHaveTagsIds.includes(asset.id)
      );
    };

    const handleSave = () => {
      mutation.mutate(selectedAssets);
    };

    return (
      <div>
        <Modal
          isOpen={modal.visible}
          toggle={modal.remove}
          backdrop="static"
          fade={false}
          centered
        >
          <ModalHeader className="pb-0" toggle={modal.remove}>
            <h2 className="color-dark modal-title">
              {Labels.Tagging.categoryAssignToAssets}
            </h2>
          </ModalHeader>
          <ModalBody className="pb-2">
            {mutation.isError ? (
              <DisplayAlerts
                className="mt-0"
                alerts={mutation.error}
                toggle={() => mutation.reset()}
              />
            ) : null}
            <Input
              placeholder={Labels.Tagging.searchByOrgOrAsset}
              className="w-100"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            <div className="scrollbar-primary tagging assign-category-to-asset-modal my-3">
              {organizationsList.map((org) => {
                return (
                  <div key={org.id} className="d-flex flex-column px-2 pt-1">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        checked={isOrgChecked(org.id)}
                        onChange={() => handleOrgSelection(org.id)}
                        id={org.name + org.id}
                        disabled={isOrgDisabled(org.organizationAssets)}
                      />
                      <Label for={org.name + org.id} className="pl-2 pt-2">
                        {org.name}
                      </Label>
                    </div>
                    <div className="d-flex flex-column">
                      {org.organizationAssets?.length > 0 &&
                        org.organizationAssets.map((asset) => {
                          return (
                            <div className="d-flex px-3" key={asset.id}>
                              <input
                                type="checkbox"
                                checked={selectedAssets.includes(asset.id)}
                                onChange={() => handleAssetSelection(asset.id)}
                                id={asset.displayName + asset.id}
                                disabled={assetsHaveTagsIds.includes(asset.id)}
                              />
                              <Label
                                for={asset.displayName + asset.id}
                                className="pl-2 pt-2 fw-400"
                              >
                                {asset.displayName}
                              </Label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
            <ModalFooter className="pb-0 px-0">
              <div className="d-flex justify-content-end mr-0">
                <Button className="mr-2" color="primary" onClick={handleSave}>
                  {Labels.CommonModals.save}
                </Button>
                <Button onClick={modal.remove}>
                  {Labels.CommonModals.cancel}
                </Button>
              </div>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </div>
    );
  }
);
