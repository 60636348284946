import React from "react";
import { Labels } from "../../../../constants/Constants";
import { formatAmountWithThousandSeparatorsToPrecision } from "../../../../utils/number";

export default function IncurredAmountsColumn(props) {
  let rowData = props.cellProperties.instance.getSourceDataAtRow(
    props.cellProperties.instance.toPhysicalRow(props.row)
  );
  let amount = props.value;

  props.TD.className = "htDimmed text-right color-text-primary fw-500";

  const getDrilldownData = (columnName, rowData) => {
    switch (true) {
      case columnName === "approvedPOsAmountYTD":
        props.setColumDataProp("poNumber");
        props.setAmountDrilldownTitle(
          Labels.Assets.PurchaseOrders.approvedPurchaseOrderAmountYTD
        );
        return rowData.approvedPOsYTD;
      case columnName === "approvedPOsAmountMTD":
        props.setColumDataProp("poNumber");
        props.setAmountDrilldownTitle(
          Labels.Assets.PurchaseOrders.approvedPurchaseOrderAmountMTD
        );
        return rowData.approvedPOsMTD;
      case columnName === "invoicedAmountYTD":
        props.setColumDataProp("invoiceNumber");
        props.setAmountDrilldownTitle(
          Labels.Assets.PurchaseOrders.approvedPurchaseInvoiecsYTD
        );
        return rowData.invoicedYTD;
      case columnName === "invoicedAmountMTD":
        props.setColumDataProp("invoiceNumber");
        props.setAmountDrilldownTitle(
          Labels.Assets.PurchaseOrders.approvedPurchaseInvoiecsMTD
        );
        return rowData.invoicedMTD;
      case columnName === "bankReconTransactionsAmountYTD":
        props.setColumDataProp("date");
        props.setAmountDrilldownTitle(
          Labels.Assets.PurchaseOrders.bankReconciliationTransactionsAmountYTD
        );
        return rowData.bankReconTransactionsYTD;
      case columnName === "bankReconTransactionsAmountMTD":
        props.setColumDataProp("date");
        props.setAmountDrilldownTitle(
          Labels.Assets.PurchaseOrders.bankReconciliationTransactionsAmountMTD
        );
        return rowData.bankReconTransactionsMTD;
    }
  };

  return (
    <React.Fragment>
      {
        <a
          href={"#"}
          target="_blank"
          rel="noopener noreferrer"
          className="text-underline"
          onClick={(event) => {
            event.preventDefault();
            props.setIsMainTableHidden(true);
            props.setAmountDrilldownData(getDrilldownData(props.prop, rowData));
          }}
        >
          {formatAmountWithThousandSeparatorsToPrecision(amount)}
        </a>
      }
    </React.Fragment>
  );
}
