import React from "react";
import fileReader from "../../utils/fileReader";
import { httpRequest } from "../../utils/http/httpRequest";
import PropTypes from "prop-types";
import Button from "reactstrap/lib/Button";

// download call method with axiosConfig
export const download = function (options) {
  const httpOptions = { ...{ method: "get" }, ...options };
  return httpRequest(httpOptions)
    .then((response) => {
      if (response.headers["content-type"] === "application/force-download") {
        // trigger file downloading
        let fileName =
          response.headers["content-disposition"].split("filename=")[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch(async function (error) {
      // // Read file
      let errors;
      try {
        const file = await fileReader(error.data);
        // Parse content and retrieve 'errors'
        errors = (JSON.parse(file) || {}).errors;
      } catch (e) {
        return Promise.reject([
          { errors: [{ code: "anonymous-error", message: error }] }
        ]);
      }
      // return errors
      return Promise.reject(errors);
    });
};

/**
 * @export
 * @param {*} props: DownloadLink.propTypes
 * @return Link
 */
export function DownloadLink(props) {
  //
  const downloadHandler = () => {
    onClickHandler();
    download(options)
      .then((resp) => {
        props.onSuccess && props.onSuccess(resp);
      })
      .catch((error) => {
        props.onError && props.onError(error);
      });
  };

  const options = { ...props };
  const linkProps = { ...(options.linkProps || {}) };
  const onClickHandler = (linkProps || {}).onClick || (() => {});
  // delete unnecesary properties to form AxiosConfig object
  delete options.linkProps;
  delete options.onError;
  delete options.onSuccess;
  delete options.children;

  return (
    <span
      {...linkProps}
      onClick={downloadHandler}
      role="download"
      className="cursor-pointer download-link"
    >
      {props.children}
    </span>
  );
}

// For actual prop types refer  { AxiosRequestConfig } from "axios"
DownloadLink.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.oneOf([
    "get",
    "GET",
    "delete",
    "DELETE",
    "head",
    "HEAD",
    "options",
    "OPTIONS",
    "post",
    "POST",
    "put",
    "PUT",
    "patch",
    "PATCH",
    "link",
    "LINK",
    "unlink",
    "UNLINK"
  ]),
  baseURL: PropTypes.string,
  transformRequest: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ]),
  transformResponse: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func)
  ]),
  headers: PropTypes.any,
  params: PropTypes.any,
  paramsSerializer: PropTypes.func,
  data: PropTypes.any,
  timeout: PropTypes.number,
  timeoutErrorMessage: PropTypes.string,
  withCredentials: PropTypes.bool,
  adapter: PropTypes.func,
  auth: PropTypes.exact({
    username: PropTypes.string,
    password: PropTypes.string
  }),
  responseType: PropTypes.oneOf([
    "arraybuffer",
    "blob",
    "document",
    "json",
    "text",
    "stream"
  ]),
  xsrfCookieName: PropTypes.string,
  xsrfHeaderName: PropTypes.string,
  onUploadProgress: PropTypes.func,
  onDownloadProgress: PropTypes.func,
  maxContentLength: PropTypes.number,
  validateStatus: PropTypes.func,
  maxRedirects: PropTypes.number,
  socketPath: PropTypes.string,
  httpAgent: PropTypes.any,
  httpsAgent: PropTypes.any,
  proxy: PropTypes.oneOf(
    [
      {
        host: PropTypes.string,
        port: PropTypes.number,
        auth: {
          username: PropTypes.string,
          password: PropTypes.string
        },
        protocol: PropTypes.string
      }
    ],
    null
  ),
  cancelToken: PropTypes.oneOf([
    {
      promise: typeof Promise,
      reason: PropTypes.oneOf([
        {
          message: PropTypes.string
        }
      ]),
      throwIfRequested: PropTypes.func
    }
  ]),
  linkProps: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    title: PropTypes.string
  }),
  onError: PropTypes.func,
  onSuccess: PropTypes.func
};

/**
 * @export
 * @param {*} props: DownloadBtn.propTypes
 * @return Button
 */
export function DownloadBtn(props) {
  const options = { ...props };

  const downloadOptions = { ...(props.downloadOptions || {}) };
  const downloadHandler = () => {
    onClickHandler();
    download(downloadOptions)
      .then((resp) => {
        downloadOptions.onSuccess && downloadOptions.onSuccess(resp);
      })
      .catch((error) => {
        downloadOptions.onError && downloadOptions.onError(error);
      });
  };
  // to keep Button PropTypes Only
  delete options.downloadOptions;

  const onClickHandler = (options || {}).onClick || (() => {});
  delete options.onClick;

  return (
    <Button {...options} onClick={downloadHandler} role="downloadbtn">
      {props.children}
    </Button>
  );
}

DownloadBtn.propTypes = {
  ...Button.propTypes,
  downloadOptions: PropTypes.shape(DownloadLink.propTypes).isRequired
};
