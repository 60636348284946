import { useState } from "react";
import { nanoid } from "nanoid";

/**
 * Returns unique Id using nanoid
 * @param {string} prefix
 * @returns string
 */
export default function useUniqueId(prefix = "") {
  const [id] = useState(prefix + nanoid());
  return id;
}
