import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { Labels } from "../../constants/Constants";
import QueryKeys from "../../constants/QueryKeys";
import useToggle from "../../hooks/useToggle";
import { getDateInDisplayFormat } from "../../utils/date";
import { httpRequest } from "../../utils/http/httpRequest";
import { getFileExtension } from "../../utils/string";
import DocsViewerModal from "../modals/DocsViewerModal";
import UploadAttachmentsComponent from "./UploadAttachmentsComponent";
import useGetInvoiceAttachments from "./useGetInvoiceAttachments";

export default function ViewAttachments({
  disableFileUpload,
  endpoints,
  module
}) {
  const params = useParams();
  const attachmentsQuery = useGetInvoiceAttachments(endpoints.getAttachments);
  const [isFileUploadModalOpen, toggleFileUploadModal] = useToggle();
  const [isDocsViewerModalOpen, toggleDocsViewerModal] = useToggle();
  const selectedAttachmentRef = useRef(null);
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    ({ attachmentId }) =>
      httpRequest({
        method: "DELETE",
        url: endpoints.deleteAttachment(attachmentId)
      }).catch((err) => Promise.reject(err.data.errors[0].errorMessage)),
    {
      onSuccess: () => {
        if (module === "purchaseOrders") {
          queryClient.invalidateQueries([QueryKeys.getPOAttachments]);
        }
        attachmentsQuery.refetch();
      }
    }
  );

  if (!attachmentsQuery.data) {
    return null;
  }

  const openDocument = (document) => {
    selectedAttachmentRef.current = [
      {
        fileName: document.fileName,
        fileURL: document.cloudStorageUrl,
        fileFormat: getFileExtension(document.cloudStorageUrl)
      }
    ];
    toggleDocsViewerModal();
  };

  const onUploadSuccess = () => {
    attachmentsQuery.refetch();
  };

  const getUploadDataKey = (module) => {
    switch (module) {
      case "journals":
        return "journalDocument";
      case "tasks":
        return "attachmentFile";
      case "purchaseOrders":
        return "purchaseOrderAttachmentFile";
      default:
        return "invoiceAttachmentFile";
    }
  };

  return (
    <div>
      <div className="text-right mb-2 mt-3">
        <a
          href="#"
          className={`${
            disableFileUpload ? "disabled-link" : "link-primary"
          } prepend-plus`}
          onClick={(event) => {
            event.preventDefault();
            toggleFileUploadModal();
          }}
        >
          {Labels.Payments.AccountsReceivables.addAttachment}
        </a>
      </div>
      {deleteMutation.isError ? (
        <Alert color="danger" className="mb-0">
          {deleteMutation.error}
        </Alert>
      ) : null}
      {attachmentsQuery.isSuccess && attachmentsQuery.data.length ? (
        <div className="sales-invoice-history-comments scrollbar-primary">
          <div className="sales-invoice-table mt-0 pt-0">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th>{Labels.Payments.AccountsReceivables.name}</th>
                  <th>{Labels.Payments.AccountsReceivables.dateAdded}</th>
                  <th className="text-right">
                    {Labels.Payments.AccountsReceivables.action}
                  </th>
                </tr>
              </thead>
              <tbody>
                {attachmentsQuery.data.map((attachment) => (
                  <tr key={attachment.id}>
                    <td>
                      <button
                        className="btn btn-link m-0 p-0"
                        onClick={(evt) => {
                          evt.preventDefault();
                          openDocument(attachment);
                        }}
                      >
                        {attachment.fileName}
                      </button>
                    </td>
                    <td>{getDateInDisplayFormat(attachment.uploadedDate)}</td>
                    <td className="text-right">
                      <button
                        className="btn btn-link border-0 m-0 p-0"
                        disabled={
                          module === "purchases" || module === "purchaseOrders"
                            ? attachment.uploaded === false // In purchase invoices inbox attachment (one which is not uploaded) and purchase order quote file (on which is uploaded while creation of PO) can't be deleted
                            : false
                        }
                        onClick={(evt) => {
                          evt.preventDefault();
                          deleteMutation.mutate({
                            attachmentId: attachment.id
                          });
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="color-negative"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="">
          {Labels.Payments.AccountsReceivables.noAttachments}
        </div>
      )}
      {isDocsViewerModalOpen ? (
        <DocsViewerModal
          containerId={"attachments" + params.invoiceId + params.assetId}
          isDocsViewerModalOpen={isDocsViewerModalOpen}
          toggleDocsViewerModal={toggleDocsViewerModal}
          documents={selectedAttachmentRef.current}
        />
      ) : null}
      {isFileUploadModalOpen ? (
        <UploadAttachmentsComponent
          toggle={toggleFileUploadModal}
          isOpen={isFileUploadModalOpen}
          onUploadSuccess={onUploadSuccess}
          url={endpoints.uploadAttachment}
          uploadDataKey={getUploadDataKey(module)}
          module={module}
        />
      ) : null}
    </div>
  );
}
