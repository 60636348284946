import React from "react";
import { Labels } from "../../../../constants/Constants";
import { FormValue } from "./SalesInvoiceInitialFormState";

export default function ErrorMessage({ field = new FormValue() }) {
  return FormValue.isInvalid(field) ? (
    <span className="error-message">
      {field.error || Labels.CommonModals.pleaseFillInThisField}
    </span>
  ) : null;
}
