import React from "react";
import HotTable, { HotColumn } from "@handsontable/react";
import { Labels } from "../../../constants/Constants";

export default function POApproversLimitTable({
  hotTableComponentRef,
  approversTableData,
  rulesetOptions,
  setApproversTableData,
  setIsApproversTableValid,
  setIsformTouched,
  areFieldsDisabled
}) {
  function handleAfterChange(changes, source) {
    if (source === "loadData") {
      setApproversTableData(this.getSourceData());
    } else if (changes !== null && source === "edit") {
      setIsformTouched(true);
      setApproversTableData(this.getSourceData());
    } else {
      const isSaveEnabled =
        this.getSourceData().length >= 1 &&
        this.getSourceData().every((row) => !!row.userName && !!row.ruleset);
      setIsApproversTableValid(isSaveEnabled);
    }
    this.render();
  }

  function handleAfterRowCreateOrRemove() {
    const isSaveEnabled =
      this.getSourceData().length > 0 &&
      this.getSourceData().every((row) => !!row.userName && !!row.ruleset);

    setTimeout(() => {
      setIsformTouched(true);
      setIsApproversTableValid(isSaveEnabled);
      setApproversTableData(this.getSourceData());
    }, 500);
  }

  const approversDataSchema = {
    userName: null,
    ruleset: null,
    userId: null,
    rulesetId: null
  };

  return (
    <>
      <HotTable
        ref={hotTableComponentRef}
        data={approversTableData}
        dataSchema={approversDataSchema}
        fillHandle={false}
        width={"auto"}
        height={200}
        stretchH="all"
        className="asset-settings-hot-table mb-4"
        disableVisualSelection={false}
        manualColumnResize={false}
        licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
        renderAllRows={false}
        afterChange={handleAfterChange}
        manualColumnFreeze={true}
        autoRowSize={false}
        autoColumnSize={false}
        afterRemoveRow={handleAfterRowCreateOrRemove}
        afterCreateRow={handleAfterRowCreateOrRemove}
      >
        <HotColumn
          title={Labels.AssetCard.assetSettings.approver}
          data="userName"
          type="text"
          width="15%"
          readOnly={true}
        />
        <HotColumn
          title={Labels.AssetCard.assetSettings.ruleset}
          data="ruleset"
          type="dropdown"
          source={rulesetOptions}
          allowEmpty={false}
          allowInvalid={false}
          readOnly={areFieldsDisabled}
        />
      </HotTable>
    </>
  );
}
