/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import {
  getInvoicePayments,
  getPaymentById,
  getPaymentHistory
} from "../../../../../utils/http/endpoints";
import InvoicePaymentsTable from "./InvoicePaymentsTable";
import InvoicePaymentForm from "./InvoicePaymentForm";
import InvoicePaymentHistory from "./InvoicePaymentHistory";
import { Labels, Privileges } from "../../../../../constants/Constants";
import { Alert } from "reactstrap";
import InvoiceStatuses from "../../../../../constants/InvoiceStatuses";
import PaymentStatuses from "../../../../../constants/PaymentStatuses";
import Auth from "../../../../../auth/Auth";
import AllocationRecords from "./AllocationRecords";
import { PurchaseInvoiceTypesEnum } from "../../../../../constants/PurchaseInvoiceTypes";
import useGetOutStandingPaymentLineItems from "./useGetOutStandingPaymentLineItems";
import useGetWithholdingTaxPaymentOutstanding from "./useGetWithholdingTaxPaymentOutstanding";

export default function InvoicePayments(props) {
  const { payments, setPayments } = props;
  const outStandingPaymentsQuery = useGetOutStandingPaymentLineItems(
    props.match.params.assetId,
    props.match.params.invoiceId
  );
  const outStandingWithholdingPaymentsQuery =
    useGetWithholdingTaxPaymentOutstanding(
      props.match.params.assetId,
      props.match.params.invoiceId
    );
  const [historyList, setHistoryList] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [formMode, setFormMode] = useState("");
  const [isCreatePaymentFormVisible, setIsCreatePaymentFormVisible] =
    useState(false);

  const [isPaymentsTableVisible, setIsPaymentsTableVisible] = useState(true);
  const [selectedLineItems, setSelectedLineItems] = useState([]);
  const [isPaymentsHistoryVisible, setIsPaymentsHistoryVisible] =
    useState(false);
  const [apiErrors, setApiErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [areAlertsVisible, setAreAlertsVisible] = useState(true);
  const [outStandingLineItems, setOutStandingLineItems] = useState([]);
  const [outStandingWithHoldindLineItems, setOutStandingWithHoldindLineItems] =
    useState([]);
  const onAlertsDismiss = () => {
    setAreAlertsVisible(false);
    setApiErrors([]);
  };

  const loadInvoicePayments = () => {
    httpRequest({
      method: "get",
      url: getInvoicePayments(props.match.params)
    })
      .then((response) => {
        let paymentsList = response.data;
        setPayments(paymentsList);
      })
      .catch((error) => {
        setApiErrors(error.data.errors);
      });
  };

  let isCreatePaymentAllowed = false;
  // Check if user can create payment
  if (
    props.invoiceDetails.invoiceStatus === InvoiceStatuses.AWAITING_PAYMENT &&
    payments?.every((payment) =>
      [PaymentStatuses.PAID, PaymentStatuses.VOIDED].includes(payment.status)
    ) &&
    Auth.getUserPrivileges().includes(Privileges.CREATE_PAYMENT) &&
    !props.invoiceDetails.isNegativeInvoice
  ) {
    isCreatePaymentAllowed = true;
  }

  const getLineItems = (lineItems) => {
    return lineItems.map((lineItem) => {
      if (!lineItem.amount) {
        lineItem.amount = lineItem.grossAmount;
      }
      return lineItem;
    });
  };

  // Checking the outstanding line items those are not in created payment  and making them available for Edit
  const getEditableLineItems = (paymentLineItems) => {
    let editLineItems = [...paymentLineItems].map((each) => {
      each.isChecked = true;
      return each;
    });
    outStandingLineItems?.forEach((eachOutStandingLI) => {
      if (
        !paymentLineItems.find(
          (editLI) =>
            editLI.invoiceLineItemId === eachOutStandingLI.invoiceLineItemId
        )
      ) {
        editLineItems.push({ ...eachOutStandingLI, isChecked: false });
      }
    });
    return editLineItems;
  };

  const handleCreatePayment = (event) => {
    event.preventDefault();
    setSelectedLineItems(getLineItems(outStandingLineItems));
    setOutStandingWithHoldindLineItems([
      {
        dueAmount: outStandingWithholdingPaymentsQuery?.data,
        amount: outStandingWithholdingPaymentsQuery?.data,
        isChecked: false
      }
    ]);
    setFormMode("CREATE");
    setSelectedPayment({});
    setIsCreatePaymentFormVisible(true);
    setSuccessMessage("");
    setAreAlertsVisible(false);
  };

  const handleAllocations = () => {
    setSuccessMessage("");
    setAreAlertsVisible(false);
  };

  const togglePaymentsTable = (flag) => {
    setIsPaymentsTableVisible(flag);
    setIsCreatePaymentFormVisible(!flag);
    setIsPaymentsHistoryVisible(!flag);
  };

  const handleEditPayment = (payment) => {
    if (!payment?.id) {
      return;
    }
    httpRequest({
      method: "get",
      url: getPaymentById(
        props.match.params.assetId,
        props.match.params.invoiceId,
        payment.id
      )
    })
      .then((response) => {
        if (response.data?.isWithholdingTaxPayment) {
          setOutStandingWithHoldindLineItems([
            {
              amount: response.data.amount,
              dueAmount: response.data.withholdingTaxDueAmount,
              isChecked: true
            }
          ]);
          setSelectedLineItems([]);
        } else {
          setSelectedLineItems(
            getEditableLineItems(response?.data?.purchasePaymentLineItems || [])
          );
          setOutStandingWithHoldindLineItems([]);
        }
        setSelectedPayment(response.data);
        setIsCreatePaymentFormVisible(true);
        setIsPaymentsHistoryVisible(false);
        setFormMode("EDIT");
        loadInvoicePayments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleViewPayment = (payment) => {
    if (!payment?.id) {
      return;
    }
    httpRequest({
      method: "get",
      url: getPaymentById(
        props.match.params.assetId,
        props.match.params.invoiceId,
        payment.id
      )
    })
      .then((response) => {
        if (response.data?.isWithholdingTaxPayment) {
          setOutStandingWithHoldindLineItems([
            {
              amount: response.data.amount,
              dueAmount: response.data.withholdingTaxDueAmount,
              isChecked: true
            }
          ]);
          setSelectedLineItems([]);
        } else {
          setSelectedLineItems(
            getEditableLineItems(response?.data?.purchasePaymentLineItems || [])
          );
          setOutStandingWithHoldindLineItems([]);
        }
        setSelectedPayment(response.data);
        setIsCreatePaymentFormVisible(true);
        setIsPaymentsHistoryVisible(false);
        setFormMode("VIEW");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleViewPaymentHistory = (payment) => {
    httpRequest({
      method: "get",
      url: getPaymentHistory(
        props.match.params.assetId,
        props.match.params.invoiceId,
        payment.id
      )
    })
      .then((response) => {
        setHistoryList(response.data);
        setIsPaymentsHistoryVisible(true);
        setIsPaymentsTableVisible(false);
        setIsCreatePaymentFormVisible(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadInvoicePayments();
    togglePaymentsTable(true);
  }, [props.invoiceDetails]);

  useEffect(() => {
    if (outStandingPaymentsQuery?.data?.length) {
      setOutStandingLineItems(
        outStandingPaymentsQuery?.data.filter(
          (each) => each.grossAmount !== 0
        ) || []
      );
    }
  }, [outStandingPaymentsQuery?.data]);

  useEffect(() => {
    if (!!outStandingWithholdingPaymentsQuery?.data) {
      setOutStandingWithHoldindLineItems([
        {
          dueAmount: outStandingWithholdingPaymentsQuery?.data,
          amount: outStandingWithholdingPaymentsQuery?.data,
          isChecked: false
        }
      ]);
    }
  }, [outStandingWithholdingPaymentsQuery?.data]);

  return (
    <div className="invoice-payments-container">
      <div className="fv-box mt-3">
        {!isCreatePaymentAllowed && isPaymentsTableVisible && (
          <Alert color="info" className="mb-3" isOpen={!isCreatePaymentAllowed}>
            {props.invoiceDetails.isNegativeInvoice
              ? Labels.Payments.Invoices.Payments.negativeInvoicePaymentsInfoMsg
              : Labels.Payments.Invoices.Payments
                  .createPaymentNotAllowedInfoMsg}
          </Alert>
        )}
        {apiErrors.length !== 0 ? (
          <Alert
            color="danger"
            className="mb-2"
            isOpen={areAlertsVisible}
            toggle={onAlertsDismiss}
          >
            <ul className="error-list">
              {apiErrors.map((error, index) => (
                <li key={index}>
                  {error.errorMessage || Labels.CommonModals.unknownError}
                </li>
              ))}
            </ul>
          </Alert>
        ) : null}
        <Alert
          color="success"
          className="mt-2"
          isOpen={!!successMessage}
          toggle={() => setSuccessMessage()}
        >
          {successMessage}
        </Alert>
        {isPaymentsTableVisible && (
          <React.Fragment>
            <div className="clearfix border-bottom-fv-border-2px">
              <div className="float-left">
                <h3 className="color-dark">
                  {Labels.Payments.Invoices.Payments.payments}
                </h3>
              </div>
              <div className="float-right">
                <div
                  className={`create-payment ${
                    !isCreatePaymentAllowed ? "cursor-not-allowed" : ""
                  }`}
                >
                  <a
                    href="#"
                    onClick={(event) => handleCreatePayment(event)}
                    className={`link-primary prepend-plus ${
                      !isCreatePaymentAllowed ? "disabled-link" : ""
                    }`}
                  >
                    {Labels.Payments.Invoices.Payments.createPayment}
                  </a>
                </div>
              </div>
            </div>

            <div className="payments-table-container small-bottom-bordered-table-container">
              <InvoicePaymentsTable
                payments={payments}
                invoiceDetails={props.invoiceDetails}
                handleEditPayment={handleEditPayment}
                handleViewPayment={handleViewPayment}
                handleViewPaymentHistory={handleViewPaymentHistory}
                refreshPayments={loadInvoicePayments}
                refreshInvoiceData={props.refreshInvoiceData}
                outStandingPaymentsQuery={outStandingPaymentsQuery}
                outStandingWithholdingPaymentsQuery={
                  outStandingWithholdingPaymentsQuery
                }
                setApiErrors={setApiErrors}
                setAreAlertsVisible={setAreAlertsVisible}
              />
            </div>
          </React.Fragment>
        )}
        {isPaymentsHistoryVisible && (
          <InvoicePaymentHistory
            historyList={historyList}
            togglePaymentsTable={togglePaymentsTable}
          />
        )}
      </div>
      {isCreatePaymentFormVisible && (
        <InvoicePaymentForm
          payments={payments}
          formMode={formMode}
          selectedPayment={selectedPayment}
          invoiceDetails={props.invoiceDetails}
          selectedLineItems={selectedLineItems}
          setSelectedLineItems={setSelectedLineItems}
          outStandingWithHoldindLineItems={outStandingWithHoldindLineItems}
          setOutStandingWithHoldindLineItems={
            setOutStandingWithHoldindLineItems
          }
          outStandingPaymentsQuery={outStandingPaymentsQuery}
          outStandingWithholdingPaymentsQuery={
            outStandingWithholdingPaymentsQuery
          }
          isCreatePaymentFormVisible={isCreatePaymentFormVisible}
          setIsCreatePaymentFormVisible={setIsCreatePaymentFormVisible}
          {...props}
          togglePaymentsTable={togglePaymentsTable}
          refreshPayments={loadInvoicePayments}
          refreshInvoiceData={props.refreshInvoiceData}
        />
      )}
      <div className="fv-box mt-3">
        <AllocationRecords
          isAllocationsAllowed={isCreatePaymentAllowed}
          invoiceDetails={props.invoiceDetails}
          setApiErrors={setApiErrors}
          setSuccessMessage={setSuccessMessage}
          setIsAllocationsFormVisible={handleAllocations}
          type={PurchaseInvoiceTypesEnum.PURCHASE_INVOICE}
          refreshInvoiceData={props.refreshInvoiceData}
        />
      </div>
    </div>
  );
}
