import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { SelectBox as Select } from "../../../../../elements/select/Select";
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";
import { Labels } from "../../../../../constants/Constants";
import QueryKeys from "../../../../../constants/QueryKeys";
import DragAndDropFileUpload from "../../../../../elements/file-upload/DragAndDropFileUpload";
import {
  getUtilizationTemplate,
  createUtilisationTemplate
} from "../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../utils/http/httpRequest";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(
  ({ params, template, setSuccessfulMsg, assetCurrency, toggle }) => {
    const modal = useModal();
    const { assetId } = params;
    const [errors, setErrors] = useState([]);
    const [document, setDocument] = useState([]);
    const queryClient = useQueryClient();
    const [templateName, setTemplateName] = useState("");
    const [templateCurrency, setTemplateCurrency] = useState("");

    const resetErrors = () => {
      setErrors([]);
    };

    const uploadOptions = {
      accept: ".docx",
      maxFiles: 1,
      minSize: 0,
      maxSize: 10485760
    };
    useEffect(() => {
      if (template) {
        setTemplateName(template.name);
        const [currency] = assetCurrency.filter(
          ({ value }) => value === template.currency
        );
        setTemplateCurrency(currency);
      }
    }, []);

    const onFilesSelection = (files = []) => {
      setDocument(files);
    };

    const errorCodeDesc = {
      "file-invalid-type":
        Labels.Payments.Utilisations.createTemplateFileTypeError,
      "file-too-large": Labels.CommonModals.attachmentSizeError
    };

    const onError = (errorList) => {
      const errors = {};
      errorList.forEach((errorObj) => {
        errorObj.errors.forEach((error) => {
          errors[error.code] = {
            errorMessage: errorCodeDesc[error.code] || error.message
          };
        });
      });
      setErrors([...Object.values(errors)]);
    };
    const save = () => {
      const formData = new FormData();
      formData.append("currency", templateCurrency.value);
      formData.append("name", templateName);
      if (document[0]) {
        formData.append("document", document[0]);
      }
      if (template) {
        formData.append("templateId", template.templateId);
      }
      formData.append("status", "ACTIVE");
      httpRequest({
        method: "post",
        url: template
          ? getUtilizationTemplate(assetId, template.templateId)
          : createUtilisationTemplate(assetId),
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          modal.remove();
          queryClient.invalidateQueries(QueryKeys.getUtilizationTemplates);
          setSuccessfulMsg(
            template
              ? Labels.Payments.Utilisations.templateSavedSuccessfully
              : Labels.Payments.Utilisations.templateCreatedSuccessfully
          );
          toggle("Active");
        })
        .catch((err) => {
          err.data
            ? setErrors(err.data.errors)
            : setErrors([{ errorMessage: err }]);
        });
    };

    const isInvalid = () => {
      return template
        ? !(document.length > 0)
        : !templateName ||
            !(Object.keys(templateCurrency).length > 0) ||
            !(document.length > 0);
    };
    return (
      <Modal
        isOpen={true}
        centered={true}
        toggle={modal.remove}
        fade={false}
        keyboard={false}
        backdrop="static"
      >
        <div className="modal-header">
          <h2 className="color-dark modal-title">
            {template
              ? Labels.Payments.Utilisations.updateTemplate
              : Labels.Payments.Utilisations.createTemplate}
          </h2>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={modal.remove}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody className="py-0 px-3">
          {errors.length ? (
            <div>
              <Alert color="danger" toggle={resetErrors}>
                <ul className="error-list">
                  {errors.map((error, index) => {
                    return <li key={index}>{error.errorMessage}</li>;
                  })}
                </ul>
              </Alert>
            </div>
          ) : null}
          <Row>
            <Col className="row-header-2 my-auto">
              {Labels.Payments.Utilisations.name}
            </Col>
            <Col>
              <Input
                value={templateName}
                name="templateName"
                maxLength="255"
                onChange={(event) => setTemplateName(event?.target?.value)}
                disabled={!!template}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="row-header-2">
              {Labels.Payments.Utilisations.currency}
            </Col>
            <Col>
              <Select
                id="templateCurrency"
                value={templateCurrency}
                onChange={(val) => setTemplateCurrency(val)}
                options={assetCurrency || []}
                isDisabled={!!template}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="row-header-2">
              {template
                ? Labels.Payments.Utilisations.replaceTemplateFile
                : Labels.Payments.Utilisations.templateFile}
            </Col>
          </Row>
          <Row className="my-2">
            <Col>
              <DragAndDropFileUpload
                onFileSelection={onFilesSelection}
                onError={onError}
                label={Labels.CommonModals.uploadFileLabel}
                button={true}
                uploadConfig={uploadOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col className="my-2">
              <p>
                <span className="row-header-2">{Labels.CommonModals.note}</span>
                {template &&
                  Labels.Payments.Utilisations.replaceTemplateFileMsg + " "}
                {Labels.Payments.Utilisations.documentPlaceholdersNote}
              </p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isInvalid()} onClick={save}>
            {template ? Labels.CommonModals.save : Labels.CommonModals.create}
          </Button>
          <Button color="secondary" onClick={modal.remove}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
