import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Labels } from "../../constants/Constants";
import DocsViewer from "../../elements/DocsViewer";

export default function DocsViewerModal(props) {
  return (
    <Modal
      size="xl"
      scrollable={false}
      keyboard={false}
      backdrop="static"
      isOpen={props.isDocsViewerModalOpen}
      toggle={props.toggleDocsViewerModal}
      className="docs-viewer-modal"
    >
      <ModalHeader className="pb-0" toggle={props.toggleDocsViewerModal}>
        <div>
          <h2 className="color-dark modal-title">
            {props.documents?.length > 1
              ? Labels.Assets.IncomeStatement.attachments
              : Labels.CommonModals.attachment}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody className="m-0 p-0">
        <DocsViewer
          containerId={props.containerId}
          documents={props.documents}
          viewerHeight="750px"
        />
      </ModalBody>
    </Modal>
  );
}
