const PurchaseOrderStatuses = {
  DRAFT: "Draft",
  AWAITING_REVIEW: "Awaiting Review",
  AWAITING_APPROVAL: "Awaiting Approval",
  VOIDED: "Voided",
  AWAITING_GRN: "Awaiting GRN",
  CANCELLED: "Cancelled",
  RECEIPTED: "Receipted",
  INVOICED: "Invoiced"
};

export const PurchaseOrderStatusesList = Object.entries(
  PurchaseOrderStatuses
).map(([key, value]) => ({
  value,
  label: value
}));

export default PurchaseOrderStatuses;
