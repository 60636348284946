/**
 *
 * @param {number} amount
 * @param {boolean} handleNegativeAmounts
 *
 * @returns {String} Formats the number with commas and returns
 * amount in braces for -ve amounts if true is passed for the second param
 *
 * @example formatAmountWithThousandSeparators(-10000, true) => (10,000)
 * @example formatAmountWithThousandSeparators(20000, true) => 20,000
 */
export function formatAmountWithThousandSeparators(
  amount,
  handleNegativeAmounts
) {
  const nfObject = new Intl.NumberFormat("en-US");
  if (handleNegativeAmounts) {
    const isAmountNegative = amount < 0 ? true : false;
    const formattedAmount = nfObject.format(Math.abs(amount));
    return isAmountNegative ? "(" + formattedAmount + ")" : formattedAmount;
  } else {
    return nfObject.format(amount);
  }
}

//This function is written separately on purpose to handle precision. Don't try to merge it with above function.
export function formatAmountWithThousandSeparatorsToPrecision(
  amount,
  handleNegativeAmounts = true,
  precision = 2
) {
  const nfObject = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision
  });
  if (handleNegativeAmounts) {
    const isAmountNegative = amount < 0 ? true : false;
    const formattedAmount = nfObject.format(Math.abs(amount));
    return isAmountNegative ? "(" + formattedAmount + ")" : formattedAmount;
  } else {
    return nfObject.format(amount);
  }
}

// Returns float type value if passed argument is valid number or number string else returns 0
export function convertToFloat(number, precision) {
  if (isNaN(parseFloat(number))) {
    return 0;
  } else {
    return precision
      ? parseFloat(parseFloat(number).toFixed(precision))
      : parseFloat(number);
  }
}
/**
 *
 * @param {number} value
 *
 * @returns {String} Formats the number with commas
 *
 * @example formatNumberWithThousandSeparators(20000) => 20,000
 */
export function formatNumberWithThousandSeparators(value) {
  const nfObject = new Intl.NumberFormat("en-US");
  return nfObject.format(value);
}

/**
 *
 * @param {number} amount
 * @param {string} currencySymbol
 * @returns {string} Returns formatted amount
 */
export const formatAmount = (amount, currencySymbol, precision = 2) => {
  if (!isNaN(parseFloat(amount)) && isFinite(amount)) {
    const formattedAmount = formatAmountWithThousandSeparatorsToPrecision(
      parseFloat(amount),
      true,
      precision
    );
    return (currencySymbol || "") + " " + formattedAmount;
  } else {
    return "-";
  }
};

// Checks if the passed value(s) is a valid number(s)
export const isNumber = (value) => {
  // If given parameter is array, then check if all the elements are numbers
  if (Array.isArray(value)) {
    return !!value.length && value.every(isNumber);
  }

  // https://stackoverflow.com/questions/825402/why-does-isnan-string-with-spaces-equal-false
  return !isNaN(value) && isFinite(value) && value !== "" && value !== null;
};
