import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import { useQueryClient } from "react-query";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Auth from "../../../auth/Auth";
import QueryKeys from "../../../constants/QueryKeys";
import { MultiSelect } from "../../multiselect/MultiSelect";
import POApproversLimitTable from "./POApproversLimitTable";
import { httpRequest } from "../../../utils/http/httpRequest";
import { Labels, Privileges } from "../../../constants/Constants";
import useGetUsersUnderAsset from "../../../hooks/useGetUsersUnderAsset";
import { setAssetUserConfiguration } from "../../../utils/http/endpoints";
import { getSymbolFromCurrencyCode } from "../../../utils/currency/currency";
import useGetApproverLimitRulesets from "../../../hooks/useGetApproverLimitRulesets";
import useGetAssetUserConfiguration from "../../../hooks/useGetAssetUserConfiguration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useUniqueId from "../../../hooks/useUniqueId";
import Tooltip from "../../ToolTips/Tooltip";
import DisplayAlerts from "../../DisplayAlerts";

export default NiceModal.create(
  ({ orgId, assetId, setSuccessfulMsg, assetName }) => {
    const modal = useModal();
    const queryClient = useQueryClient();
    const hotTableComponentRef = useRef(null);
    const [errors, setErrors] = useState([]);
    const [canClearFormErrors, setCanClearFormErrors] = useState(false);

    const [canCreatorApprove, setCanCreatorApprove] = useState(false);
    const [selectedReviewers, setSelectedReviewers] = useState([]);
    const [selectedReceivers, setSelectedReceivers] = useState([]);
    const [selectedVerifiers, setSelectedVerifiers] = useState([]);
    const [selectedApprovers, setSelectedApprovers] = useState([]);
    const [approversTableData, setApproversTableData] = useState([]);
    const [isApproversTableValid, setIsApproversTableValid] = useState(false);
    const [isFormTouched, setIsformTouched] = useState(false);

    const areFieldsDisabled = !Auth.getUserPrivileges().includes(
      Privileges.UPDATE_ASSET_USER_CONFIG
    );
    const [assetUsersMasterData, setAssetUsersMasterData] = useState([]);
    const [privilegeBasedAssetUsers, setPrivilegeBasedAssetUsers] = useState(
      []
    );
    const [userNameOptions, setUserNameOptions] = useState([]);
    const [rulesetOptions, setRulesetOptions] = useState([]);
    const uniqueId = useUniqueId();

    const usersUnderAssetQuery = useGetUsersUnderAsset(assetId);
    const privilegeBasedAssetUsersQuery = useGetUsersUnderAsset(
      assetId,
      false,
      Privileges.UPDATE_CONTACT
    );

    const {
      data: additionalSettingsData,
      isLoading: isAdditionalSettingsDataLoading,
      isSuccess: isAdditionalSettingsDataSuccess,
      error: additionalSettingsDataError,
      isError: isAdditionalSettingsDataError
    } = useGetAssetUserConfiguration({ assetId });
    const approversRulesetsQuery = useGetApproverLimitRulesets(assetId, {
      refetchOnWindowFocus: true
    });

    const resetErrors = () => {
      setErrors([]);
      setCanClearFormErrors(false);
    };

    useEffect(() => {
      // to set modal data
      if (isAdditionalSettingsDataError) {
        setErrors(errors.concat(additionalSettingsDataError));
        setApproversTableData([]);
      } else if (usersUnderAssetQuery.isError) {
        setErrors(errors.concat(usersUnderAssetQuery.error));
        setApproversTableData([]);
      } else if (
        isAdditionalSettingsDataSuccess &&
        usersUnderAssetQuery.isSuccess &&
        usersUnderAssetQuery?.data?.length > 0
      ) {
        const activeUsersCanApprovePO =
          additionalSettingsData.usersCanApprovePO.filter((approver) =>
            usersUnderAssetQuery.data.some((user) => user.id === approver.id)
          );

        const activeUsersCanReviewPO =
          additionalSettingsData.usersCanReviewPO.filter((reviewer) =>
            usersUnderAssetQuery.data.some((user) => user.id === reviewer.id)
          );

        const activeUsersCanReceiveGoods =
          additionalSettingsData.usersCanReceiveGoods.filter((reviewer) =>
            usersUnderAssetQuery.data.some((user) => user.id === reviewer.id)
          );

        const userNameLables = activeUsersCanApprovePO.map(
          (user) => user.label
        );
        const data = activeUsersCanApprovePO.map((user) => {
          let approvalLimitStringsArray = [];

          for (const currnecy in user.approverLimitRuleMap) {
            approvalLimitStringsArray.push(
              `${getSymbolFromCurrencyCode(currnecy)}${
                user.approverLimitRuleMap[currnecy]
              }`
            );
          }

          if (user.approverLimitRuleId) {
            return {
              userName: `${user.firstName} ${user.lastName}`,
              ruleset: `${user.approverLimitRuleName} (${
                Labels.AssetCard.assetSettings.approvalLimit
              } ${approvalLimitStringsArray.join(", ")})`,
              userId: user.id,
              rulesetId: user.approverLimitRuleId,
              rulesetString: `${user.approverLimitRuleName} (${
                Labels.AssetCard.assetSettings.approvalLimit
              } ${approvalLimitStringsArray.join(", ")})`
            };
          }
        });

        setAssetUsersMasterData(usersUnderAssetQuery.data);
        setCanCreatorApprove(
          additionalSettingsData.canCreatorApproveInstruments
        );
        setSelectedApprovers(activeUsersCanApprovePO);
        setUserNameOptions(userNameLables);
        setSelectedReviewers(activeUsersCanReviewPO);
        setSelectedReceivers(activeUsersCanReceiveGoods);
        setApproversTableData(data.length > 0 && data[0] ? data : []);
        if (
          privilegeBasedAssetUsersQuery.isSuccess &&
          privilegeBasedAssetUsersQuery?.data?.length
        ) {
          const activeUsersCanVerifySuppliers =
            additionalSettingsData.usersCanVerifySuppliers.filter((Verifier) =>
              privilegeBasedAssetUsersQuery.data.some(
                (user) => user.id === Verifier.id
              )
            );
          setPrivilegeBasedAssetUsers(privilegeBasedAssetUsersQuery.data);
          setSelectedVerifiers(activeUsersCanVerifySuppliers);
        }
        if (approversRulesetsQuery.isError) {
          setErrors(errors.concat(approversRulesetsQuery.error));
        } else if (privilegeBasedAssetUsersQuery.isError) {
          setErrors(errors.concat(privilegeBasedAssetUsersQuery.error));
        } else {
          resetErrors();
        }
      }
    }, [
      isAdditionalSettingsDataLoading,
      usersUnderAssetQuery.data,
      privilegeBasedAssetUsersQuery.data
    ]);

    useEffect(() => {
      if (
        approversRulesetsQuery.isSuccess &&
        approversRulesetsQuery?.data?.length > 0
      ) {
        const options = approversRulesetsQuery.data.map((ruleset) => {
          const approvalLimitString = ruleset.ruleCurrencyAmountList
            .map(
              (limit) =>
                `${getSymbolFromCurrencyCode(limit.currency)}${limit.amount}`
            )
            .join(", ");

          return `${ruleset.name} (${
            Labels.AssetCard.assetSettings.approvalLimit
          } ${approvalLimitString.trim()})`;
        });
        setRulesetOptions(options);
      }
    }, [approversRulesetsQuery.data]);

    useEffect(() => {
      const selectedUsersInTable = approversTableData.map(
        (row) => row?.userName
      );
      const newlyAddedUserNames = userNameOptions
        .filter((userName) => !selectedUsersInTable.includes(userName))
        .map((userName) => {
          return {
            userName,
            rulesetId: "",
            userId: "",
            ruleset: "",
            rulesetString: ""
          };
        });

      const deletedUserNames = selectedUsersInTable.filter(
        (userName) => !userNameOptions.includes(userName)
      );

      if (
        (newlyAddedUserNames.length > 0 || deletedUserNames.length > 0) &&
        userNameOptions.length > 0
      ) {
        const data = approversTableData
          .concat(newlyAddedUserNames)
          .filter(
            (row) =>
              !deletedUserNames.some((userName) => userName === row.userName)
          );
        setApproversTableData(data);
      }
    }, [userNameOptions]);

    const handleApproversChange = (options = []) => {
      const userNameLables = options.map((user) => user.label);
      if (options.length === 0) {
        setApproversTableData([]);
      }
      setUserNameOptions(userNameLables);
      setIsformTouched(true);
      setSelectedApprovers(options);
    };

    const handleSave = (event) => {
      event.preventDefault();
      let postData = {};
      let gridData =
        rulesetOptions.length > 0 && selectedApprovers.length > 0
          ? hotTableComponentRef.current.hotInstance.getSourceData()
          : [];

      postData = {
        canCreatorApproveInstruments: canCreatorApprove,
        usersCanReviewPO: selectedReviewers.map((user) => user.id),
        usersCanReceiveGoods: selectedReceivers.map((user) => user.id),
        usersCanVerifySuppliers: selectedVerifiers.map((user) => user.id),
        usersCanApprovePOWithLimitMap: {}
      };

      const rulesetData = approversRulesetsQuery.data.map((ruleset) => {
        const approvalLimitString = ruleset.ruleCurrencyAmountList
          .map(
            (limit) =>
              `${getSymbolFromCurrencyCode(limit.currency)}${limit.amount}`
          )
          .join(", ");

        return {
          ...ruleset,
          approvalLimitString: `${ruleset.name} (${
            Labels.AssetCard.assetSettings.approvalLimit
          } ${approvalLimitString.trim()})`
        };
      });

      let dataObj = {};
      gridData.forEach((row) => {
        if (row.userId && row.rulesetId && row.rulesetString === row.ruleset) {
          dataObj[row.userId] = row.rulesetId;
        } else {
          dataObj[
            assetUsersMasterData.filter(
              (user) => user.label === row.userName
            )[0].id
          ] = rulesetData.filter(
            (ruleset) => ruleset.approvalLimitString === row.ruleset
          )[0].id;
        }
      });
      postData.usersCanApprovePOWithLimitMap = dataObj;

      httpRequest({
        method: "put",
        url: setAssetUserConfiguration(assetId),
        data: postData
      })
        .then(() => {
          queryClient.invalidateQueries(QueryKeys.getAssetUserConfiguration);
          setSuccessfulMsg(Labels.AssetCard.assetSettings.settingsSaveMessage);
          modal.remove();
          window.scrollTo({ top: 0, behavior: "smooth" });
          resetErrors();
        })
        .catch((err) => {
          err.data
            ? setErrors(err.data.errors)
            : setErrors([{ errorMessage: err }]);
          setCanClearFormErrors(true);
        });
    };

    const isSaveDisabled = () =>
      !errors.length &&
      (selectedApprovers.length > 0
        ? isApproversTableValid &&
          isFormTouched &&
          selectedReceivers.length > 0 &&
          selectedReviewers.length > 0 &&
          selectedVerifiers.length > 0 &&
          selectedApprovers.length > 0
        : isFormTouched);

    const clickHereElement = () => (
      <a
        href={`#/org/${orgId}/assets/asset/${assetId}/settings/rulesets`}
        className="link-primary"
        target={"_blank"}
      >
        {Labels.AssetCard.assetSettings.clickHere}
      </a>
    );

    return (
      <Modal
        isOpen={true}
        centered={true}
        toggle={modal.remove}
        fade={false}
        keyboard={false}
        size="xl"
        backdrop="static"
        modalClassName="asset-settings"
      >
        <ModalHeader className="pb-2" toggle={modal.remove}>
          <h2 className="color-dark modal-title">
            {`${assetName} - ${Labels.AssetCard.assetSettings.additionalSettings}`}
          </h2>
        </ModalHeader>
        <ModalBody className="py-0 px-3">
          {errors.length ? (
            <DisplayAlerts
              className="mt-0"
              alerts={errors}
              type="danger"
              onAlertDismiss={canClearFormErrors ? resetErrors : null}
            />
          ) : null}
          <fieldset className="mt-3">
            <Row>
              <Col xs={12} md={4} className="row-header-2 my-auto">
                <Label>
                  {Labels.AssetCard.assetSettings.canCreatorApprove}
                  <FontAwesomeIcon
                    id={"creatorApprove" + uniqueId}
                    icon={faInfoCircle}
                    className={"ml-1"}
                  />
                  <Tooltip target={"creatorApprove" + uniqueId} placement="top">
                    {Labels.AssetCard.assetSettings.canCreatorApproveTooltipMsg}
                  </Tooltip>
                </Label>
              </Col>
              <Col>
                <Row>
                  <Col xs={12} md={4} className="d-flex">
                    <Input
                      type="radio"
                      id="creatorApprovalYes"
                      className="ml-1"
                      checked={canCreatorApprove === true}
                      onChange={() => {
                        setIsformTouched(true);
                        setCanCreatorApprove(true);
                      }}
                      value={true}
                      disabled={areFieldsDisabled}
                    />
                    <Label id="creatorApprovalYes" className="my-0 ml-4">
                      {Labels.AssetCard.assetSettings.yes}
                    </Label>
                  </Col>
                  <Col xs={12} md={4} className="d-flex">
                    <Input
                      id="creatorApprovalNo"
                      type="radio"
                      className="ml-1"
                      checked={canCreatorApprove === false}
                      onChange={() => {
                        setIsformTouched(true);
                        setCanCreatorApprove(false);
                      }}
                      value={false}
                      disabled={areFieldsDisabled}
                    />
                    <Label id="creatorApprovalNo" className="my-0 ml-4">
                      {Labels.AssetCard.assetSettings.no}
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-4 d-flex align-items-center">
              <Col xs={12} md={4} className="row-header-2 pr-0">
                <Label for="usersCanReviewPO" className="m-0">
                  {Labels.Assets.PurchaseOrders.whoCanReviewPO}
                  <FontAwesomeIcon
                    id={"usersCanReview" + uniqueId}
                    icon={faInfoCircle}
                    className={"ml-1"}
                  />
                  <Tooltip target={"usersCanReview" + uniqueId} placement="top">
                    {Labels.AssetCard.assetSettings.poReviewersTooltipMessage}
                  </Tooltip>
                </Label>
              </Col>
              <Col xs={12} md={4}>
                <MultiSelect
                  isMulti
                  value={selectedReviewers}
                  id="usersCanReviewPO"
                  onChange={(options) => {
                    setIsformTouched(true);
                    setSelectedReviewers(options);
                  }}
                  hasSelectAllOption={!areFieldsDisabled}
                  isClearable={!areFieldsDisabled}
                  className="react-multi-select"
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  valuesNotToBeDeselected={
                    areFieldsDisabled ? selectedReviewers : []
                  }
                  options={assetUsersMasterData}
                  isOptionDisabled={() => areFieldsDisabled}
                  isLoading={
                    usersUnderAssetQuery.isLoading ||
                    isAdditionalSettingsDataLoading
                  }
                  optionsLabel={
                    Labels.Assets.PurchaseOrders
                      .purchaseOrderReviewerOptionsLabel
                  }
                />
              </Col>
            </Row>
            <Row className="my-4 d-flex align-items-center">
              <Col xs={12} md={4} className="row-header-2">
                <Label for="usersCanReceiveGoods" className="m-0">
                  {Labels.Assets.PurchaseOrders.whoWillGoodsBeReceivedBy}
                  <FontAwesomeIcon
                    id={"usersCanReceiveGoods" + uniqueId}
                    icon={faInfoCircle}
                    className={"ml-1"}
                  />
                  <Tooltip
                    target={"usersCanReceiveGoods" + uniqueId}
                    placement="top"
                  >
                    {
                      Labels.AssetCard.assetSettings
                        .goodsReceiversTooltipMessage
                    }
                  </Tooltip>
                </Label>
              </Col>
              <Col xs={12} md={4}>
                <MultiSelect
                  isMulti
                  value={selectedReceivers}
                  id="usersCanReceiveGoods"
                  onChange={(options) => {
                    setIsformTouched(true);
                    setSelectedReceivers(options);
                  }}
                  hasSelectAllOption={!areFieldsDisabled}
                  isClearable={!areFieldsDisabled}
                  className="react-multi-select"
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  options={assetUsersMasterData}
                  valuesNotToBeDeselected={
                    areFieldsDisabled ? selectedReceivers : []
                  }
                  isOptionDisabled={() => areFieldsDisabled}
                  isLoading={
                    usersUnderAssetQuery.isLoading ||
                    isAdditionalSettingsDataLoading
                  }
                  optionsLabel={
                    Labels.Assets.PurchaseOrders
                      .purchaseOrderGoodsReceiverOptionsLabel
                  }
                />
              </Col>
            </Row>
            <Row className="my-4 d-flex align-items-center">
              <Col xs={12} md={4} className="row-header-2">
                <Label for="usersCanVerifySuppliers" className="m-0">
                  {Labels.Assets.PurchaseOrders.whoCanVerifySuppliers}
                  <FontAwesomeIcon
                    id={"usersCanVerifySuppliers" + uniqueId}
                    icon={faInfoCircle}
                    className={"ml-1"}
                  />
                  <Tooltip
                    target={"usersCanVerifySuppliers" + uniqueId}
                    placement="top"
                  >
                    {
                      Labels.AssetCard.assetSettings
                        .supplierVerifiersTooltipMessage
                    }
                  </Tooltip>
                </Label>
              </Col>
              <Col xs={12} md={4}>
                <MultiSelect
                  isMulti
                  value={selectedVerifiers}
                  id="usersCanVerifySuppliers"
                  onChange={(options) => {
                    setIsformTouched(true);
                    setSelectedVerifiers(options);
                  }}
                  hasSelectAllOption={!areFieldsDisabled}
                  isClearable={!areFieldsDisabled}
                  className="react-multi-select"
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  options={privilegeBasedAssetUsers}
                  valuesNotToBeDeselected={
                    areFieldsDisabled ? selectedVerifiers : []
                  }
                  isOptionDisabled={() => areFieldsDisabled}
                  isLoading={
                    privilegeBasedAssetUsersQuery.isLoading ||
                    isAdditionalSettingsDataLoading
                  }
                  optionsLabel={
                    Labels.Assets.PurchaseOrders.supplierVerifiersOptionsLabel
                  }
                />
              </Col>
            </Row>
            <Row className="my-4 d-flex align-items-center">
              <Col xs={12} md={4} className="row-header-2">
                <Label for="usersCanApproveToolTip" className="m-0">
                  {Labels.Assets.PurchaseOrders.whoCanApprovePO}
                  <FontAwesomeIcon
                    id={"usersCanApprove" + uniqueId}
                    icon={faInfoCircle}
                    className={"ml-1"}
                  />
                  <Tooltip
                    target={"usersCanApprove" + uniqueId}
                    placement="top"
                  >
                    {Labels.AssetCard.assetSettings.poApproversTooltipMessage}
                  </Tooltip>
                </Label>
              </Col>
              <Col xs={12} md={4}>
                <MultiSelect
                  isMulti
                  value={selectedApprovers}
                  id="usersCanApproveToolTip"
                  onChange={(options) => handleApproversChange(options)}
                  hasSelectAllOption={!areFieldsDisabled}
                  isClearable={!areFieldsDisabled}
                  className="react-multi-select"
                  hideSelectedOptions={false}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  options={assetUsersMasterData}
                  valuesNotToBeDeselected={
                    areFieldsDisabled ? selectedReceivers : []
                  }
                  isOptionDisabled={() => areFieldsDisabled}
                  isLoading={
                    usersUnderAssetQuery.isLoading ||
                    isAdditionalSettingsDataLoading
                  }
                  optionsLabel={
                    Labels.Assets.PurchaseOrders
                      .purchaseOrderApproverOptionsLabel
                  }
                />
              </Col>
            </Row>
            {selectedApprovers.length > 0 && !approversRulesetsQuery.isError ? (
              <Row className="mt-4 d-flex align-items-center">
                <Col sm={12} className="row-header-2 mb-2 pb-2">
                  <Label for="usersCanApprovePO" className="m-0">
                    {Labels.AssetCard.assetSettings.assignRulesetToApprover}
                    <FontAwesomeIcon
                      id={"assignRuleset" + uniqueId}
                      icon={faInfoCircle}
                      className={"ml-1"}
                    />
                    <Tooltip
                      target={"assignRuleset" + uniqueId}
                      placement="top"
                    >
                      {Labels.AssetCard.assetSettings.assignRulesetInfoMsg}
                    </Tooltip>
                  </Label>
                  {rulesetOptions.length === 0 &&
                  selectedApprovers.length > 0 ? (
                    <Alert className="mt-3 mb-2" color="info">
                      {
                        Labels.AssetCard.assetSettings
                          .noRulesetsDataInAssetInfoMsg.line1
                      }
                      {clickHereElement()}
                      {
                        Labels.AssetCard.assetSettings
                          .noRulesetsDataInAssetInfoMsg.line2
                      }
                    </Alert>
                  ) : null}
                </Col>
                {rulesetOptions.length > 0 && selectedApprovers.length > 0 ? (
                  <Col sm={12}>
                    <POApproversLimitTable
                      hotTableComponentRef={hotTableComponentRef}
                      approversTableData={approversTableData}
                      rulesetOptions={rulesetOptions}
                      setApproversTableData={setApproversTableData}
                      setIsApproversTableValid={setIsApproversTableValid}
                      setIsformTouched={setIsformTouched}
                      areFieldsDisabled={areFieldsDisabled}
                    />
                  </Col>
                ) : null}
              </Row>
            ) : null}
          </fieldset>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="primary"
            onClick={handleSave}
            disabled={!isSaveDisabled()}
          >
            {Labels.CommonModals.save}
          </Button>
          <Button color="secondary" onClick={modal.remove}>
            {Labels.CommonModals.cancel}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);
