import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal, ModalBody, Row, Col, ModalFooter, Button } from "reactstrap";
import moment from "moment";
import { Labels } from "../../../../constants/Constants";
import { SelectBox as Select } from "../../../../elements/select/Select";
import CustomDatePicker from "../../../../elements/form-fields/inputs/CustomDatePicker";

export default NiceModal.create(({ downloadPayRuns }) => {
  const modal = useModal();
  const fileFormatOptions = [{ id: 1, label: "SEPA", value: "SEPA" }];
  const [fileFormatValue, setFileFormatValue] = useState(fileFormatOptions[0]);
  const [dateOfPayment, setDateOfPayment] = useState(moment().toDate());

  return (
    <Modal
      isOpen={true}
      toggle={modal.remove}
      centered={true}
      fade={false}
      keyboard={false}
      backdrop="static"
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">
          {Labels.Assets.PayRuns.downloadPayrun}
        </h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={modal.remove}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="py-0 px-3">
        <Row>
          <Col className="row-header-2 my-auto">
            {Labels.Assets.PayRuns.fileFormat}
          </Col>
          <Col className="column-header-2">
            <Select
              value={fileFormatValue}
              options={fileFormatOptions}
              onChange={(val) => {
                setFileFormatValue(val);
              }}
              isDisabled={true}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col className="row-header-2 my-auto">
            {Labels.Assets.PayRuns.dateOfPayment}
          </Col>
          <Col className="column-header-2">
            <CustomDatePicker
              selected={dateOfPayment}
              minDate={moment().toDate()}
              onChange={(date) => {
                setDateOfPayment(date);
              }}
              dateFormat="dd/MM/yyyy"
              className="month-ending"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            downloadPayRuns(dateOfPayment);
            modal.remove();
          }}
        >
          {Labels.Assets.PayRuns.downloadPayrun}
        </Button>
        <Button color="secondary" onClick={modal.remove}>
          {Labels.Assets.PayRuns.cancel}
        </Button>
      </ModalFooter>
    </Modal>
  );
});
