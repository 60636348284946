const ProjectInvoiceStatuses = {
  ALL: "ALL",
  DRAFT: "DRAFT",
  ASSIGNED: "ASSIGNED",
  ARCHIVED: "ARCHIVED"
};

// For dropdowns
export const ProjectInvoiceStatusList = Object.entries(
  ProjectInvoiceStatuses
).map(([k, v]) => ({ label: v, value: v }));

export default ProjectInvoiceStatuses;
