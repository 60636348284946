import React from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import moment from "moment";
import { Labels } from "../../constants/Constants";
import JobTypes from "../../constants/JobTypes";

export default NiceModal.create(({ checksData }) => {
  const modal = useModal();

  return (
    <Modal
      isOpen={modal.visible}
      size={"lg"}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader className="pb-0" toggle={modal.remove}>
        <div>
          <h2 className="color-dark modal-title">{Labels.Assets.checks}</h2>
        </div>
      </ModalHeader>
      <ModalBody className="py-2">
        <div>{Labels.Checks.belowChecksGenerated}</div>
        <ul className="mt-1">
          {checksData.map((obj) => {
            return (
              <li key={obj.id}>
                {obj.jobParameters.hasOwnProperty("assetId") && (
                  <a
                    href={
                      obj.jobType === JobTypes.GENERATE_ERROR_CHECK_REPORT
                        ? `/#/org/${obj.jobParameters.orgId}/assets/asset/${obj.jobParameters.assetId}/reports/checks/completeness`
                        : `/#/org/${obj.jobParameters.orgId}/assets/asset/${obj.jobParameters.assetId}/reports/checks/revenue`
                    }
                    target="_blank"
                  >
                    <p className="color-primary font-size-12">
                      <i>
                        <strong>{obj.jobParameters.assetName}</strong>
                      </i>
                      {"  "}
                      {Labels.CommonModals.from}{" "}
                      <i>
                        <strong>
                          {moment(obj.jobParameters.fromDate).format("MMM-YY")}
                        </strong>
                      </i>
                      {"  "}
                      {Labels.CommonModals.to}{" "}
                      <i>
                        <strong>
                          {moment(obj.jobParameters.toDate).format("MMM-YY")}
                        </strong>
                      </i>
                    </p>
                  </a>
                )}
                {!obj.jobParameters.hasOwnProperty("assetId") && (
                  <a
                    href={`/#/orgs/org/${obj.jobParameters.orgId}/reports/checks/completeness`}
                    target="_blank"
                  >
                    <p className="color-primary font-size-12">
                      <i>
                        <strong>{obj.jobParameters.orgName}</strong>
                      </i>
                      {"  "}
                      {Labels.CommonModals.from}{" "}
                      <i>
                        <strong>
                          {moment(obj.jobParameters.fromDate).format("MMM-YY")}
                        </strong>
                      </i>
                      {"  "}
                      {Labels.CommonModals.to}{" "}
                      <i>
                        <strong>
                          {moment(obj.jobParameters.toDate).format("MMM-YY")}
                        </strong>
                      </i>
                    </p>
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={modal.remove}>
          {Labels.CommonModals.close}
        </Button>
      </ModalFooter>
    </Modal>
  );
});
