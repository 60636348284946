import React from "react";
import { Labels } from "../../../constants/Constants";
import { convertDateFromUTCToLocal } from "../../../utils/date";

export default function HistoryTile({ history }) {
  return (
    <div className="history-item">
      <div className="clearfix mb-3">
        <div className="float-left username">{history.changedBy}</div>
        <div className="float-right timestamp">
          {convertDateFromUTCToLocal(history.timestamp, "DD MMM YYYY HH:mm:ss")}
        </div>
      </div>
      <div className="description">
        {history.field !== "Comment" && (
          <React.Fragment>
            {Labels.HistoryTile.changed} <strong>{history.field}</strong>{" "}
            {history.from !== null && (
              <>
                {Labels.HistoryTile.from} <i>{String(history.from)}</i>
                &nbsp;&nbsp;
              </>
            )}
            {Labels.HistoryTile.to} <i>{String(history.to)}</i>
          </React.Fragment>
        )}
        {history.field === "Comment" && (
          <React.Fragment>
            {Labels.HistoryTile.added} <strong>{history.field}</strong>{" "}
            <i>{history.to}</i>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
