// @ts-check
import PropTypes from "prop-types";

// Render the child component if all given conditions are met
const ConditionalRenderer = ({
  children,
  conditions,
  conditionsCheck = "every"
}) => {
  const isTrue = conditions[conditionsCheck]((condition) => Boolean(condition));
  return isTrue ? children : null;
};

ConditionalRenderer.propTypes = {
  children: PropTypes.node.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.bool).isRequired
};

export default ConditionalRenderer;
