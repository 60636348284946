import React from "react";
import { useState } from "react";
import { Tooltip as ReactstrapTooltip } from "reactstrap";

export default function Tooltip({
  children,
  fade = true,
  autohide = false,
  placement = "bottom",
  trigger = "hover",
  ...props
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return children ? (
    <ReactstrapTooltip
      isOpen={tooltipOpen}
      toggle={toggle}
      placement={placement}
      autohide={autohide}
      fade={fade}
      trigger={trigger}
      {...props}
    >
      {children}
    </ReactstrapTooltip>
  ) : null;
}
