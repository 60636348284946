import React from "react";
import { Labels } from "../../../../../constants/Constants";

export default function POColumn(props) {
  let rowData = props.cellProperties.instance.getSourceDataAtRow(
    props.cellProperties.instance.toPhysicalRow(props.row)
  );
  let url;
  props.TD.className = "htDimmed";

  if (props.assetId !== undefined) {
    url = `/#/org/${props.orgId}/assets/asset/${props.assetId}/payruns/purchaseorders/edit-po/${rowData.purchaseOrderId}`;
  } else {
    url = `/#/orgs/org/${props.orgId}/payruns/purchaseorders/edit-po/${props.assetId}/${rowData.purchaseOrderId}`;
  }

  let renderHtml;
  let description =
    rowData.budgetLineItemDescription === null
      ? Labels.CommonModals.NA
      : rowData.budgetLineItemDescription;
  if (rowData.isPOLineItem) {
    renderHtml = (
      <a
        href={url}
        className="text-underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {description}
      </a>
    );
  } else {
    renderHtml = <span>{description}</span>;
  }

  return <React.Fragment>{renderHtml}</React.Fragment>;
}
