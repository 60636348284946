import React from "react";
import { formatAmountWithThousandSeparators } from "../../../../utils/number";

export default function PrepaymentDrillDownAmountColumn(props) {
  let rowData = props.cellProperties.instance.getSourceDataAtRow(props.row);
  props.TD.style.textAlign = "right";
  props.TD.className = "htDimmed";
  let amount = props.value;
  if (amount !== "") {
    amount = formatAmountWithThousandSeparators(amount, true);
  }

  let url;
  let renderHtml;

  if (rowData.journalId !== null) {
    url = `/#/org/${props.orgId}/assets/asset/${props.assetId}/accounting/journals/manual/journal/${rowData.journalId}`;
    renderHtml = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="text-underline"
      >
        {amount}
      </a>
    );
  } else {
    renderHtml = <span>{amount}</span>;
  }

  return <React.Fragment>{renderHtml}</React.Fragment>;
}
