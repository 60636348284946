import { CurrencyMap } from "./currencyMap";

export function getSymbolFromCurrencyCode(currencyCode) {
  if (typeof currencyCode !== "string") {
    return "";
  }
  var code = currencyCode.toUpperCase();
  if (!CurrencyMap.hasOwnProperty(code)) {
    // If symbol is not present return code itself
    return code;
  }
  return CurrencyMap[code];
}
