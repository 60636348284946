import React from "react";
import { Labels } from "../constants/Constants";
import { Link } from "react-router-dom";

const PostingPeriodInfoMessage = ({
  orgId,
  assetId,
  showClosingPeriodMsg = false,
  postingPeriod,
  paymentsVoidMsg,
  transactionUnreconcileMsg,
  POLinkedPIVoidMsg,
  message = Labels.PostingPeriods.postingPeriodVoidMessage // Primarily this info message is shown for void action, so if no message is given then show the message of void action
}) => {
  const closingPeriodPath = `/org/${orgId}/assets/asset/${assetId}/settings/closingperiod`;

  return (
    <>
      {message ||
      paymentsVoidMsg ||
      transactionUnreconcileMsg ||
      POLinkedPIVoidMsg ? (
        <div className="posting-period-info-message">
          <p className="mt-2 pt-1">
            <span className="row-header-2">{Labels.CommonModals.note}</span>
            {transactionUnreconcileMsg ? transactionUnreconcileMsg + " " : ""}
            {message}
            {postingPeriod ? postingPeriod + ". " : ""}
            {showClosingPeriodMsg && assetId && orgId ? (
              <>
                <Link
                  to={closingPeriodPath}
                  target="_blank"
                  className="link-primary"
                >
                  {Labels.PostingPeriods.clickHere}
                </Link>{" "}
                {Labels.PostingPeriods.PostingPeriodReopenMessage}{" "}
              </>
            ) : (
              ""
            )}
            {paymentsVoidMsg ? paymentsVoidMsg + " " : ""}
            {POLinkedPIVoidMsg ? POLinkedPIVoidMsg + " " : ""}
          </p>
        </div>
      ) : null}
    </>
  );
};

export default PostingPeriodInfoMessage;
