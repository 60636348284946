import React, { useContext, useEffect } from "react";
import InvoiceFormContext from "./InvoiceFormContext";
import InvoiceFormControls from "./InvoiceFormControls";
import LineItemForm from "./LineItemForm";
import TotalInvoiceAmount from "./TotalInvoiceAmount";
import WithholdingTaxes from "./WithholdingTaxes";
import {
  TOGGLE_LINEITEM_CARD,
  INVOICE_API_ERRORED,
  UPDATE_LINEITEMS_FROM_API_RESPONSE,
  UPDATE_LINEITEM_DATA
} from "./actionsTypes";
import useGetPurchaseOrdersForAssetAndSupplier from "../../../../../../hooks/useGetPurchaseOrdersForAssetAndSupplier";
import useGetBudgetLineItemsList from "../../../../../../hooks/useGetBudgetLineItemsList";
import moment from "moment";
import useGetGLCodesList from "../../../../../../hooks/useGetGLCodesList";
import { useQuery } from "react-query";
import { Labels } from "../../../../../../constants/Constants";
import QueryKeys from "../../../../../../constants/QueryKeys";
import { httpRequest } from "../../../../../../utils/http/httpRequest";
import { getMappingDetailsForAllocations } from "../../../../../../utils/http/endpoints";
import { useParams } from "react-router-dom";
import { PurchaseInvoiceTypesEnum } from "../../../../../../constants/PurchaseInvoiceTypes";

export default function InvoiceForm({
  invoiceDetails,
  currency,
  assetDetails,
  isFormReadOnly,
  refreshInvoiceData,
  saveInvoiceForm,
  isFormChanged,
  invoiceSavedMessage,
  payments,
  isLineItemCardHasErrors
}) {
  const { state, dispatch, updateAmountsOnlyRef } =
    useContext(InvoiceFormContext);

  const { lineItems, otherDeductions, openedLineItemId, isUpdateBudgetNeeded } =
    state;

  const handleApiError = (apiErrors) =>
    dispatch({
      type: INVOICE_API_ERRORED,
      payload: {
        apiErrors
      }
    });

  const { data: budgetLineItemsList, isSuccess: isBudgetLineItemsListSuccess } =
    useGetBudgetLineItemsList({
      assetId: invoiceDetails.assetId,
      month: moment(invoiceDetails.invoiceDate).format("M"),
      financialYear: moment(invoiceDetails.invoiceDate).format("Y"),
      includeAllSystemAccounts: false,
      prependWithGlCode: true,
      onError: handleApiError
    });

  const { data: glCodesList, isSuccess: isGlCodesListSuccess } =
    useGetGLCodesList({
      assetId: invoiceDetails.assetId,
      glSections: [],
      includeAllSystemAccounts: false,
      onError: handleApiError,
      systemAccountsToInclude: {
        includeInterCompanyAccounts: false
      }
    });

  const { data: poOptionsList } = useGetPurchaseOrdersForAssetAndSupplier({
    assetId: invoiceDetails.assetId,
    supplierId: invoiceDetails.supplierId,
    currencyCode: invoiceDetails.invoiceCurrency
  });

  const calNetInvoiceAmount = () => {
    const otherDeductionsValue = isNaN(parseFloat(otherDeductions.value))
      ? 0
      : parseFloat(otherDeductions.value);
    return invoiceDetails.netAmount - otherDeductionsValue;
  };
  const calNetPayableAmount = () => {
    const taxAmountValue = isNaN(parseFloat(invoiceDetails.taxAmount))
      ? 0
      : parseFloat(invoiceDetails.taxAmount);

    return calNetInvoiceAmount() + taxAmountValue;
  };

  const handleLineItemToggle = (lineItemId) => {
    dispatch({
      type: TOGGLE_LINEITEM_CARD,
      payload: {
        lineItemId: lineItemId
      }
    });
  };

  const params = useParams();

  const isCreditNote = () =>
    invoiceDetails?.invoiceType ===
    PurchaseInvoiceTypesEnum.PURCHASE_CREDIT_NOTE;
  const purchaseCreditNoteAllocations = useQuery({
    queryKey: [QueryKeys.getAllocationRecords, invoiceDetails?.invoiceType],
    queryFn: () => {
      return httpRequest({
        method: "get",
        url: getMappingDetailsForAllocations(
          params?.assetId,
          params?.invoiceId,
          invoiceDetails?.supplierId
        )
      });
    },
    enabled: !!isCreditNote
  });

  useEffect(() => {
    if (updateAmountsOnlyRef.current) {
      dispatch({
        type: UPDATE_LINEITEM_DATA,
        payload: {
          invoiceDetails
        }
      });
      return;
    }

    dispatch({
      type: UPDATE_LINEITEMS_FROM_API_RESPONSE,
      payload: {
        invoiceDetails
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invoiceDetails,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    invoiceDetails?.invoiceBudgetLineItems[0].glCodeLineItemData
  ]);

  return (
    <>
      <div className="divider-thick"></div>
      <div className="mt-3">
        <h3 className="color-dark">
          {Labels.Payments.Invoices.Details.lineItems}
        </h3>
      </div>
      <div className="my-3">
        {lineItems.map((lineItem) => (
          <LineItemForm
            key={lineItem.lineItemId || lineItem.id}
            invoiceDetails={invoiceDetails}
            lineItemId={lineItem.lineItemId}
            budgetLineItemsList={budgetLineItemsList}
            isBudgetLineItemsListSuccess={isBudgetLineItemsListSuccess}
            isLineItemCardHasErrors={isLineItemCardHasErrors(
              lineItem.lineItemId
            )}
            handleApiError={handleApiError}
            poOptionsList={poOptionsList}
            lineItem={lineItem}
            currency={currency}
            isOpen={
              lineItem.lineItemId === openedLineItemId || !lineItem.lineItemId
            }
            toggle={() => handleLineItemToggle(lineItem.lineItemId)}
            isFormReadOnly={isFormReadOnly}
            isUpdateBudgetNeeded={isUpdateBudgetNeeded}
            glCodesList={glCodesList}
            isGlCodesListSuccess={isGlCodesListSuccess}
            purchaseCreditNoteAllocations={purchaseCreditNoteAllocations}
            assetDetails={assetDetails}
          />
        ))}
      </div>
      <TotalInvoiceAmount invoiceDetails={invoiceDetails} currency={currency} />
      {!isCreditNote() && (
        <WithholdingTaxes
          invoiceDetails={invoiceDetails}
          isFormReadOnly={isFormReadOnly}
          assetDetails={assetDetails}
          isCreditNote={isCreditNote()}
        />
      )}
      <InvoiceFormControls
        calNetInvoiceAmount={calNetInvoiceAmount}
        calNetPayableAmount={calNetPayableAmount}
        currency={currency}
        payments={payments}
        invoiceDetails={invoiceDetails}
        isFormChanged={isFormChanged}
        refreshInvoiceData={refreshInvoiceData}
        invoiceSavedMessage={invoiceSavedMessage}
        saveInvoiceForm={saveInvoiceForm}
        isFormReadOnly={isFormReadOnly}
      />
    </>
  );
}
