import NiceModal, { useModal } from "@ebay/nice-modal-react";
import HotTable, { HotColumn } from "@handsontable/react";
import { Modal, ModalBody } from "reactstrap";
import { Labels } from "../../../../../constants/Constants";

export default NiceModal.create(
  ({ varianceBudgetData, title, duration, period }) => {
    const modal = useModal();

    let readOnlySetting = {
      readOnly: true
    };
    const varianceBudgetDrilldownDataSchema = {
      glName: null,
      budgetLineItemDescription: null,
      contactName: null,
      amount: null
    };
    return (
      <Modal
        keyboard={false}
        backdrop="static"
        centered
        fade={false}
        size="xl"
        isOpen={modal.visible}
        toggle={modal.remove}
      >
        <div className="modal-header">
          <h2 className="color-dark modal-title">{`${title} ${
            duration > 1 ? "from" : "as on"
          } ${period}`}</h2>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={modal.remove}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody className="mt-0 pt-0">
          <div className="income-statement-transactions-table">
            <HotTable
              data={varianceBudgetData}
              dataSchema={varianceBudgetDrilldownDataSchema}
              fillHandle={false}
              height={"600"}
              colWidths={100}
              disableVisualSelection={false}
              manualColumnResize={false}
              licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
              renderAllRows={true}
            >
              <HotColumn
                title={Labels.Assets.IncomeStatement.glName}
                className="text-break"
                data="glName"
                settings={readOnlySetting}
                width={300}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.budgetLineItemDescription}
                placeholder={Labels.CommonModals.NA}
                data="budgetLineItemDescription"
                settings={readOnlySetting}
                width={300}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.contactName}
                data="contactName"
                settings={readOnlySetting}
                width={300}
              />
              <HotColumn
                title={Labels.Assets.IncomeStatement.amount}
                className="text-right"
                data="pnlAmount"
                settings={readOnlySetting}
                width={205}
              />
            </HotTable>
          </div>
        </ModalBody>
      </Modal>
    );
  }
);
