/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import { Alert, Button, Col, Row } from "reactstrap";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { Labels } from "../../../../../../constants/Constants";
import useCustomForm from "../../../../../../elements/form-fields/useCustomForm";
import { SelectBox } from "../../../../../../elements/select/Select";
import { useParams } from "react-router-dom";
import { FormInputWarning } from "../../../../../../elements/Error/FormInputWarning";
import { httpRequest } from "../../../../../../utils/http/httpRequest";
import { getCreatePurchaseCreditNoteReceipt } from "../../../../../../utils/http/endpoints";
import { useMutation, useQueryClient } from "react-query";
import { sortObjectListByKey } from "../../../../../../utils/string";
import useGetBankAccountDetailsForAsset from "../../../../../../hooks/useGetBankAccountDetailsForAsset";
import QueryKeys from "../../../../../../constants/QueryKeys";
import { getSymbolFromCurrencyCode } from "../../../../../../utils/currency/currency";

export default function CreateReceipt({ type, onGoBack, ...props }) {
  const [apiErrors, setApiErrors] = useState([]);

  const params = useParams();
  const [fromBankAcList, setFromBankAcList] = useState([]);
  const queryClient = useQueryClient();
  const goBack = (event) => {
    event.preventDefault();
    onGoBack({
      successMessage: Labels.Payments.CreditNotes.successfulReceiptMessage
    });
  };
  const receiptRecords = props?.receiptRecords || [];
  const totalAllocationAmount =
    props?.invoiceDetails?.invoiceAllocationDataList.reduce(
      (total, curr) => (total += curr.allocationAmount),
      0
    );
  const totalReceiptAmount =
    receiptRecords.reduce((total, curr) => (total += curr.receiptAmount), 0) +
    totalAllocationAmount;
  let dueAmount = Number(
    props?.invoiceDetails?.grossAmount - totalReceiptAmount
  ).toFixed(2);
  if (dueAmount < 0) {
    dueAmount = 0;
  }

  const {
    handleSubmit,
    control,
    register,
    formState,
    shouldSubmitBtnBeDisabled
  } = useCustomForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      receiptDate: moment().toDate(),
      receiptAmount: dueAmount
    }
  });
  const saveReceiptMutation = useMutation((data) => {
    setApiErrors([]);
    data.receiptAmount = Number(data.receiptAmount);
    httpRequest({
      method: "post",
      url: getCreatePurchaseCreditNoteReceipt(params.assetId, params.invoiceId),
      data: data
    })
      .then((res) => {
        queryClient.invalidateQueries([
          QueryKeys.getPurchaseCreditNoteReceipts
        ]);
        props.refreshInvoiceData();
        onGoBack();
      })
      .catch((err) => {
        err.data
          ? setApiErrors(err.data.errors)
          : setApiErrors([{ errorMessage: err }]);
      });
  });
  const onSubmit = (data) => {
    data.receiptDate = moment(data.receiptDate).format("YYYY-MM-DD");
    saveReceiptMutation.mutate(data);
  };

  useGetBankAccountDetailsForAsset({
    assetId: params.assetId,
    onSuccess: (data) => {
      setFromBankAcList(sortObjectListByKey(data, "label"));
    }
  });

  return (
    <div className="create-receipt">
      <div className="clearfix border-bottom-fv-border-2px">
        <div className="float-left">
          <h3 className="color-dark">{Labels.Payments.CreditNotes.create}</h3>
        </div>
        <div className="float-right">
          <div className="create-payment">
            <a href="#" className="back-link" onClick={goBack}>
              &nbsp;{Labels.Payments.CreditNotes.backTo}
            </a>
          </div>
        </div>
      </div>
      <div className="form-container-default create-receipt-form mb-0">
        {apiErrors.length > 0 ? (
          <div className="my-2">
            <Alert color="danger" toggle={() => setApiErrors([])}>
              <ul className="error-list">
                {apiErrors.map((error, index) => {
                  return <li key={index}>{error.errorMessage}</li>;
                })}
              </ul>
            </Alert>
          </div>
        ) : null}
        <form onSubmit={handleSubmit(onSubmit)} name="allocations" noValidate>
          <Row>
            <Col xs={8} className="px-0">
              <label>{Labels.CommonModals.date}</label>
            </Col>
            <Col>
              <div className="receiptDate">
                <Controller
                  control={control}
                  name="receiptDate"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <ReactDatePicker
                      selected={value}
                      placeholderText={Labels.CommonModals.date}
                      onChange={onChange}
                      dateFormat="dd/MM/yyyy"
                      maxDate={moment().toDate()}
                      minDate={props?.invoiceDetails?.invoiceDate}
                      className="w-100"
                    />
                  )}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={8} className="px-0">
              <label>{Labels.Payments.CreditNotes.receiptAmt}</label>
            </Col>
            <Col className="d-flex align-items-baseline">
              {getSymbolFromCurrencyCode(props.invoiceDetails.invoiceCurrency)}
              &nbsp;
              <div>
                <input
                  type="number"
                  step="0.01"
                  className="form-control w-100"
                  {...register("receiptAmount", {
                    required: true,
                    min: {
                      value: "0.01",
                      message:
                        Labels.Payments.CreditNotes.minimumReceiptAmountError
                    },
                    pattern: {
                      value: /^[0-9]*(?:\.\d{1,2})?$/,
                      message: Labels.CommonModals.allowMaxTwoDecimals
                    },
                    max: dueAmount
                  })}
                  data-testid="create-receipt-amount"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={8} className="px-0">
              <label>{Labels.Payments.CreditNotes.bankAccount}</label>
            </Col>
            <Col>
              <Controller
                control={control}
                name="bankAccountId"
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, value, ref } }) => (
                  <SelectBox
                    value={fromBankAcList.find(
                      (bankOption) => +bankOption.value === +value
                    )}
                    onChange={(val) => onChange(+val.value)}
                    multi={false}
                    // isLoading={supplierBankAcListIsLoading}
                    options={fromBankAcList}
                    className="float-right bank-account-field bankAccountId w-100"
                    aria-label="create-receipt-bank-account"
                  />
                )}
              />
            </Col>
          </Row>
          <Row className="flex-column mt-2 border-0 mb-0">
            <Col>
              {/* <textarea name="comment" rows="5" className="comment-input form-control" ></textarea> */}
              <div className="comments-box my-0">
                <label htmlFor="comments">
                  {Labels.Payments.Invoices.Payments.Comments}
                </label>
                <textarea
                  id="comments"
                  className="form-control"
                  rows={10}
                  {...register("comment")}
                  // defaultValue={comments}
                  // onChange={(event) => handleCommentsChange(event)}
                ></textarea>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-right mt-2">
              <Button
                color="primary"
                disabled={shouldSubmitBtnBeDisabled() || !formState.isDirty}
              >
                {Labels.CommonModals.save}
              </Button>
              <Button
                color="secondary"
                type="button"
                className="ml-2"
                onClick={onGoBack}
              >
                {Labels.CommonModals.cancel}
              </Button>
            </Col>
          </Row>
        </form>
      </div>
      <FormInputWarning formState={formState} />
    </div>
  );
}
