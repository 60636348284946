import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import { renameInvoiceDocument } from "../utils/http/endpoints";
// import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";
import Tooltip from "./ToolTips/Tooltip";
import { Labels } from "../constants/Constants";

export default function RenameTitle({
  title,
  documentFilenameExtension,
  setTitle,
  invoiceId,
  documentId,
  assetId
}) {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  // Save given initial title in a ref, to use it later for resetting the title name
  const titleRef = useRef("");
  if (titleRef.current === "") {
    titleRef.current = title;
  }

  const mutation = useMutation(
    () =>
      nonBlockingHttpRequest({
        url: renameInvoiceDocument(assetId, invoiceId, documentId),
        method: "put",
        data: {
          fileName: `${title}${documentFilenameExtension}`
        }
      }),
    {
      onSuccess: () => {
        setIsEditing(false);
        titleRef.current = title;
      }
    }
  );

  const handleTitleChange = (event) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setIsEditing(false);
    setTitle(titleRef.current);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    setIsEditing(true);
  };

  // Check if api request can be sent or not
  const canSendApiRequest =
    !mutation.isLoading &&
    title !== titleRef.current &&
    title?.replace(/\s+/, "") !== "" &&
    documentId !== null;

  const handleSave = (event) => {
    event.preventDefault();

    if (!(invoiceId && documentId && assetId)) {
      return;
    }

    if (canSendApiRequest && documentId) {
      mutation.mutate();
    } else if (documentId) {
      setTitle(titleRef.current);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (isEditing === true) {
      inputRef.current.select();
    }
  }, [isEditing]);

  return (
    <div className="rename-title mb-1">
      {isEditing ? (
        <div className="d-flex align-items-center mb-2">
          <input
            type="text"
            ref={inputRef}
            value={title}
            maxLength="79"
            className="rename-title-input inline-block py-2"
            onChange={handleTitleChange}
          />
          <span className="title-extension ml-1 align-middle">
            {documentFilenameExtension}
          </span>
          {invoiceId ? (
            <>
              <button type="button" id="saveIcon" onClick={handleSave}>
                <FontAwesomeIcon icon={faSave} className="fa-icon" />
              </button>
              <Tooltip target="saveIcon">{Labels.CommonModals.save}</Tooltip>
            </>
          ) : null}
          <button
            type="button"
            id="cancelIcon"
            className="mr-0 my-1"
            onClick={handleCancel}
          >
            <FontAwesomeIcon icon={faTimes} className="fa-icon text-danger" />
          </button>
          <Tooltip target="cancelIcon">{Labels.CommonModals.cancel}</Tooltip>
        </div>
      ) : (
        <div className="d-flex align-items-center">
          <h3
            id="renameDocumentTitle"
            className="inline-block text-truncate mr-auto color-dark my-auto"
          >
            {`${title}${documentFilenameExtension}`}
          </h3>
          <Tooltip target="renameDocumentTitle">
            {`${title}${documentFilenameExtension}`}
          </Tooltip>
          <button
            id="editIcon"
            type="button"
            className="inline-block mr-0 my-0"
            onClick={handleEdit}
          >
            <FontAwesomeIcon icon={faEdit} className="fa-icon" />
          </button>
          <Tooltip target="editIcon">{Labels.CommonModals.edit}</Tooltip>
        </div>
      )}
    </div>
  );
}

RenameTitle.propTypes = {
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  invoiceId: PropTypes.number,
  documentId: PropTypes.number,
  assetId: PropTypes.number,
  documentFilenameExtension: PropTypes.string
};
