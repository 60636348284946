import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { HotColumn, HotTable } from "@handsontable/react";
import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Labels } from "../../../../constants/Constants";
import DownloadColumn from "./DownloadColumn";

export default NiceModal.create(({ historyData, assetId }) => {
  const modal = useModal();

  function handleAfterGetColHeader(col, TH) {
    if (col < 4) {
      TH.className = "htLeft";
    } else {
      TH.className = "htCenter";
    }
  }

  const historyDataSchema = {
    id: null,
    changedBy: null,
    sentOn: null,
    periodRange: null,
    status: null,
    actualRequestPresent: null,
    commitmentRequestPresent: null
  };

  return (
    <Modal
      isOpen={modal.visible}
      size={"xl"}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader className="pb-0" toggle={modal.remove}>
        <div>
          <h2 className="color-dark modal-title">
            {Labels.Assets.ExportData.history}
          </h2>
        </div>
      </ModalHeader>
      <ModalBody className="py-2">
        {historyData.length > 0 && (
          <div className="pb-3">
            <HotTable
              data={historyData}
              dataSchema={historyDataSchema}
              height={"auto"}
              stretchH={"all"}
              fillHandle={false}
              autoRowSize={false}
              autoColumnSize={false}
              manualColumnResize={false}
              disableVisualSelection={true}
              afterGetColHeader={handleAfterGetColHeader}
              licenseKey={process.env.REACT_APP_HOT_TABLE_LICENSE_KEY}
            >
              <HotColumn
                title={Labels.Assets.ExportData.user}
                data="changedBy"
                readOnly={true}
                width={120}
              />

              <HotColumn
                title={Labels.Assets.ExportData.sentOn}
                data="sentOn"
                readOnly={true}
                width={120}
              />

              <HotColumn
                title={Labels.Assets.ExportData.periodRange}
                data="periodRange"
                readOnly={true}
                width={120}
              />
              <HotColumn
                title={Labels.Assets.ExportData.status}
                data="status"
                readOnly={true}
                width={120}
              />
              <HotColumn
                title={Labels.Assets.ExportData.actual}
                readOnly={true}
                width={50}
              >
                <DownloadColumn assetId={assetId} hot-renderer />
              </HotColumn>
              <HotColumn
                title={Labels.Assets.ExportData.commitment}
                readOnly={true}
                width={50}
              >
                <DownloadColumn assetId={assetId} hot-renderer />
              </HotColumn>
            </HotTable>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
});
