import { useQuery } from "react-query";
import QueryKeys from "../../../../../constants/QueryKeys";
import { getOrgBudgets } from "../../../../../utils/http/endpoints";
import { httpRequest } from "../../../../../utils/http/httpRequest";

export default function useGetOrgBudgets(orgId) {
  const query = useQuery({
    queryKey: QueryKeys.getOrgBudgets,
    queryFn: () =>
      httpRequest({
        method: "get",
        url: getOrgBudgets(orgId)
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors))
  });

  return query;
}
