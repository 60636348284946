import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getPOEstimatedInvoices } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";

export default function useGetPOEstimatedInvoices(assetId, poId) {
  const query = useQuery({
    queryKey: [QueryKeys.getPOEstimatedInvoices, +assetId, +poId],
    queryFn: () =>
      httpRequest({
        method: "get",
        url: getPOEstimatedInvoices(assetId, poId)
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    select: (data) => ({
      ...data,
      estimatedInvoices: data.estimatedInvoices.map((invoice, index) => ({
        ...invoice,
        lineItems: invoice.lineItems.map((lineItem) => ({
          ...lineItem,
          invoiceId: invoice.id,
          linkedInvoiceId: lineItem.invoiceId,
          glCodeDescription: lineItem.glCodeDescription || ""
        }))
      }))
    }),
    enabled: !!(assetId && poId)
  });

  return query;
}
