import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Labels } from "../../../../constants/Constants";
import fileReader from "../../../../utils/fileReader";
import { getActualAndCommitmentDataSyncHistoryJSON } from "../../../../utils/http/endpoints";
import { httpRequest } from "../../../../utils/http/httpRequest";

export default function DownloadColumn(props) {
  let rowData = props.cellProperties.instance.getSourceDataAtRow(
    props.cellProperties.instance.toPhysicalRow(props.row)
  );
  props.TD.className = "htDimmed htCenter";

  const handleDownloadIconClick = () => {
    httpRequest({
      method: "get",
      url: getActualAndCommitmentDataSyncHistoryJSON(
        props.assetId,
        rowData.id,
        props.col === 4 ? "ACTUAL" : "COMMITMENT"
      ),
      responseType: "blob"
    })
      .then((response) => {
        if (response.headers["content-type"] === "application/force-download") {
          let fileName =
            response.headers["content-disposition"].split("filename=")[1];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch(async function (error) {
        // Read file
        const file = await fileReader(error.data);
        // Parse content and retrieve 'errors'
        const { errors } = JSON.parse(file);
        props.setErrors(errors);
        props.setAreAlertsVisible(true);
        console.log(errors);
      });
  };

  let renderHtml = "";
  if (
    (props.col === 4 && rowData.actualRequestPresent) ||
    (props.col === 5 && rowData.commitmentRequestPresent)
  ) {
    renderHtml = (
      <FontAwesomeIcon
        icon={faFileDownload}
        onClick={handleDownloadIconClick}
        className="fa-1point5 cursor-pointer"
      />
    );
  } else {
    renderHtml = <span>{Labels.CommonModals.NA}</span>;
  }

  return <React.Fragment>{renderHtml}</React.Fragment>;
}
