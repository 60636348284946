import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import useGetAzureSasToken from "./useGetAzureSasToken";

export async function fetchEmailBody(url) {
  try {
    const res = await fetch(url, {
      method: "GET"
    });
    return await res.text();
  } catch (error) {
    return error;
  }
}

export default function useGetEmailBody(
  cloudStorageUrl,
  documentCloudStorageUrl
) {
  const { data: messageAccessUrl } = useGetAzureSasToken(
    cloudStorageUrl,
    documentCloudStorageUrl
  );

  const query = useQuery({
    queryKey: [QueryKeys.getEmailMessage, cloudStorageUrl],
    queryFn: () => fetchEmailBody(messageAccessUrl),
    enabled: !!(messageAccessUrl && cloudStorageUrl)
  });

  return query;
}
