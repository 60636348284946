/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";
import { components } from "react-select";
import { Labels } from "../../constants/Constants";
import { SelectBox } from "../select/Select";
import PropTypes from "prop-types";
import classNames from "classnames";

export const MultiSelect = (props) => {
  const customProps = { ...props };

  const getValue = (val) => {
    if (val?.length === props.options.length) {
      return customProps.options;
    } else {
      return val;
    }
  };

  const getSortedOptions = () => {
    const values = props?.value || [];
    let selectOption = [];
    if (
      props?.hasSelectAllOption &&
      customProps?.options?.length > 1 &&
      customProps.options[0].value === -1
    ) {
      selectOption = customProps.options.slice(0, 1);
    }
    return [
      ...selectOption,
      ...(props.value || []),
      ...customProps.options.filter(
        (v) => !values.find((val) => val?.value === v?.value)
      )
    ];
  };

  customProps.getValue = getValue;
  customProps.options = useMemo(() => {
    const options = props?.doNotSortOptionsOnSelect
      ? [...customProps.options]
      : getSortedOptions();
    if (props.hasSelectAllOption && options.length > 1) {
      options.splice(0, 0, {
        label: Labels.CommonModals.selectAll,
        value: -1
      });
    }
    return options;
  }, [props.options, props?.value?.length]);

  const onChange = (value, modifiedOption) => {
    const val = value || [];
    if (modifiedOption.action === "select-option") {
      if (
        modifiedOption.option.value === -1 ||
        val.length === customProps.options.length - 1
      ) {
        return props.options;
      } else {
        return val;
      }
    } else if (modifiedOption.action === "clear") {
      return [];
    } else {
      if (modifiedOption?.option?.value === -1) {
        return [];
      } else {
        const selectedOptions = val.filter((v) => {
          return v.value !== -1;
        });
        return selectedOptions;
      }
    }
  };
  //for disable appearence when an item cannot be deselected
  const notToBeDeselectedStyles = {
    option: (actual, state) => {
      const disabledStyle = {
        opacity: 0.5,
        backgroundColor: "none",
        cursor: "unset",
        ":active": {
          backgroundColor: "none"
        }
      };
      if (state.selectProps.valuesNotToBeDeselected?.length > 0) {
        if (
          state.selectProps.valuesNotToBeDeselected.find(
            (val) => val?.value === state?.data?.value
          )
        ) {
          return { ...actual, ...disabledStyle };
        }
      }
      return actual;
    }
  };
  //adding styles for valuesNotToBeDeselected
  if (
    Array.isArray(props.valuesNotToBeDeselected) &&
    props.valuesNotToBeDeselected.length > 0
  ) {
    customProps.styles = {
      ...(customProps.styles || {}),
      ...notToBeDeselectedStyles
    };
  }
  if (customProps)
    customProps.value = (customProps.value || []).filter(
      (v) => v?.value && v?.label
    );

  return (
    <SelectBox
      {...customProps}
      onChange={(val, modifiedOption) => {
        let value = val;
        // dont include dummy select all option in selected values
        if (props.hasSelectAllOption) {
          value = onChange(val, modifiedOption);
        }
        // to make values in `valuesNotToBeDeselected` not to be unselected
        if (
          Array.isArray(props.valuesNotToBeDeselected) &&
          props.valuesNotToBeDeselected.length > 0
        ) {
          props.valuesNotToBeDeselected.forEach((val) => {
            if ((value || []).findIndex((v) => v?.value === val?.value) < 0) {
              value = [...(value || []), val];
            }
          });
        }
        props.onChange(value, modifiedOption);
      }}
      value={getValue(customProps.value)}
      isMulti
      components={{
        ValueContainer: CustomValueContainer,
        Option: CustomOption
      }}
    />
  );
};

const getDisplayLabel = (length, optionsLabel) => {
  return `${length !== "0" ? length : "No"} ${
    optionsLabel || Labels.CommonModals.options
  } ${Labels.CommonModals.selected}`;
};

function CustomValueContainer(props) {
  const selectedOptionsLength = props.getValue().length;
  const length =
    props?.selectProps?.hasSelectAllOption &&
    props.selectProps.options.length !== 1
      ? selectedOptionsLength === props.selectProps.options.length &&
        props.selectProps.options.length > 0
        ? selectedOptionsLength - 1
        : selectedOptionsLength
      : selectedOptionsLength;
  const displayLabel = getDisplayLabel(
    (selectedOptionsLength === props.selectProps.options.length &&
    props.selectProps.options.length > 0
      ? `${Labels.CommonModals.all} `
      : "") + length,
    props.selectProps.optionsLabel
  );

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue && displayLabel}
      {React.Children.map(props.children, (child) => {
        return child?.type === components.Input ? child : null;
      })}
    </components.ValueContainer>
  );
}

function CustomOption(props) {
  return (
    <div className="custom-options">
      <components.Option {...props}>
        <input
          type="checkbox"
          className="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label
          className={classNames("mb-0 label pl-1", {
            "fv-text-primary": props.isDisabled
          })}
        >
          {" "}
          {props.label}{" "}
        </label>
      </components.Option>
    </div>
  );
}

MultiSelect.propTypes = {
  ...SelectBox.propTypes,
  hasSelectAllOption: PropTypes.bool,
  valuesNotToBeDeselected: PropTypes.oneOfType([
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    PropTypes.any
  ]),
  optionsLabel: PropTypes.string,
  doNotSortOptionsOnSelect: PropTypes.bool
};
