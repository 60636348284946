import React from "react";
import { Label } from "reactstrap";
import { Labels } from "../constants/Constants";
import { PropTypes } from "prop-types";

export default function OptionalLabel(props) {
  const labelProps = { ...props };
  //dut to thrown warning from react, had to delete
  delete labelProps.isNotOptional;
  return (
    <Label {...labelProps}>
      {labelProps.children}
      {props.isNotOptional ? "" : Labels.CommonModals.optional}
      {/* {props.isConditional && <sup>*</sup>} */}
    </Label>
  );
}

OptionalLabel.propTypes = {
  label: PropTypes.string,
  isNotOptional: PropTypes.bool,
  ...Label.propTypes
};
