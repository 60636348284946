import React, { useContext } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import CopyInstrumentSuccessMessage from "../../../../../elements/CopyInstrumentSuccessMessage";
import { SET_INVOICE_SAVED_MESSAGE } from "./operational-operational/actionsTypes";
import InvoiceFormContext from "./operational-operational/InvoiceFormContext";
import { PrepaymentDataErrorMessage } from "./operational-operational/PrepaymentDataErrorMessage";

export default function InvoiceAlerts({
  isSuccessMsgVisible,
  onSuccessDismiss,
  formErrors,
  onErrorsDismiss,
  assetDetails,
  prepaymentdataQuery
}) {
  const { state, dispatch } = useContext(InvoiceFormContext);
  const { invoiceSavedMessage } = state;
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (formErrors?.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [formErrors?.length]);

  return (
    <div className="col-12">
      {isSuccessMsgVisible &&
      invoiceSavedMessage &&
      !invoiceSavedMessage?.message ? (
        <Alert
          color="success"
          isOpen={isSuccessMsgVisible}
          toggle={onSuccessDismiss}
          className="mb-0 mt-3"
        >
          {invoiceSavedMessage}
        </Alert>
      ) : null}

      {invoiceSavedMessage?.message ? (
        <Alert
          color="success"
          toggle={() => {
            dispatch({
              type: SET_INVOICE_SAVED_MESSAGE,
              payload: {
                userAction: ""
              }
            });
          }}
          className="mb-0 mt-3"
        >
          <CopyInstrumentSuccessMessage
            url={history.location.pathname.replace(
              params.invoiceId,
              invoiceSavedMessage?.id
            )}
            message={invoiceSavedMessage.message}
          />
        </Alert>
      ) : null}

      <PrepaymentDataErrorMessage
        className={"mt-3"}
        errors={prepaymentdataQuery.error}
        primaryOrgId={
          assetDetails.assetOrganizations?.find(
            (asset) => asset?.isPrimaryOrganization
          )?.organizationId
        }
      />

      {formErrors?.length > 0 ? (
        <Alert
          color="danger"
          className="mb-0 mt-3"
          isOpen={formErrors?.length > 0}
          toggle={onErrorsDismiss}
        >
          <ul className="error-list">
            {formErrors.map((error, index) => {
              return <li key={index}>{error.errorMessage}</li>;
            })}
          </ul>
        </Alert>
      ) : null}
    </div>
  );
}
