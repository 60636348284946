//@ts-check
import { useQuery } from "react-query";
import { Labels } from "../constants/Constants";
import QueryKeys from "../constants/QueryKeys";
import { getBankAccountDetailsForAsset } from "../utils/http/endpoints";
import { httpRequest } from "../utils/http/httpRequest";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

export default function useGetBankAccountDetailsForAsset({
  assetId,
  isBlocking = true,
  includeInterCompanyAccounts = false,
  // On True, returns bank accounts & intercompany accounts for grouped react-select dropdown
  returnGroupedData = false,
  ...options
}) {
  const request = isBlocking ? httpRequest : nonBlockingHttpRequest;
  const query = useQuery({
    queryKey: [
      QueryKeys.getBankAccountDetailsForAsset,
      { assetId, includeInterCompanyAccounts }
    ],
    queryFn: () =>
      request({
        method: "get",
        url: getBankAccountDetailsForAsset({
          assetId,
          includeInterCompanyAccounts
        })
      })
        .then((res) => Promise.resolve(res.data))
        .catch((err) => Promise.reject(err.data.errors)),
    select: (data) => {
      if (returnGroupedData) {
        return [
          {
            label: Labels.interCompany.bankAccounts,
            options: data.bankAccounts.map((bankAccount) => ({
              ...bankAccount,
              label: bankAccount.bankAccountName,
              value: bankAccount.id
            }))
          },
          {
            label: Labels.interCompany.intercompanyAccounts,
            options: data.interCompanyAccounts.map((interCompanyGLAccount) => ({
              ...interCompanyGLAccount,
              label: `${interCompanyGLAccount.glCode} - ${interCompanyGLAccount.glName}`,
              value: interCompanyGLAccount.id,
              interCompanyAccountId: interCompanyGLAccount.id
            }))
          }
        ];
      }

      // Modified to return the data as needed for the dropdown
      return [
        ...data.bankAccounts.map((bankAccount) => ({
          ...bankAccount,
          label: bankAccount.bankAccountName,
          value: bankAccount.id
        }))
      ];
    },
    enabled: !!assetId,
    ...options
  });
  return query;
}
