import { useQuery } from "react-query";
import QueryKeys from "../constants/QueryKeys";
import { getAzureSasTokenUrl } from "../utils/http/endpoints";
import { nonBlockingHttpRequest } from "../utils/http/nonBlockingHttpRequest";

export default function useGetAzureSasToken(cloudStorageUrl, uniqueKey) {
  const query = useQuery({
    queryKey: [QueryKeys.appendSASToken, cloudStorageUrl, uniqueKey],
    // cloudStorageUrl is same for few emails, this causes token cached which can be exipired(no token refresh process in place)
    // Add this uniqueKey will fix the issue. Ref: https://dev.azure.com/Fincovi1/Platform%20Development/_workitems/edit/4653/
    queryFn: () => {
      return nonBlockingHttpRequest({
        method: "get",
        url: getAzureSasTokenUrl(encodeURI(cloudStorageUrl))
      })
        .then((res) => res.data)
        .catch((err) => Promise.reject(err.data.errors));
    },
    enabled: !!cloudStorageUrl
  });

  return query;
}
