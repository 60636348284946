import { toTitleCase } from "../utils/string";

const JournalStatuses = {
  DRAFT: "Draft",
  AWAITING_APPROVAL: "Awaiting Approval",
  CANCELLED: "Cancelled",
  APPROVED: "Approved",
  REVERSED: "Reversed"
};

export const journalStatusList = Object.entries(JournalStatuses).map(
  ([k, v]) => ({
    id: k,
    value: k,
    label: toTitleCase(v)
  })
);

export const RepeatingJournalStatuses = {
  NEW: "New"
};

export default JournalStatuses;
