const CountriesList = [
  { id: "Austria", value: "Austria", label: "Austria" },
  { id: "Belgium", value: "Belgium", label: "Belgium" },
  { id: "Bulgaria", value: "Bulgaria", label: "Bulgaria" },
  { id: "Croatia", value: "Croatia", label: "Croatia" },
  { id: "Cyprus", value: "Cyprus", label: "Cyprus" },
  { id: "Czechia", value: "Czechia", label: "Czechia" },
  { id: "Denmark", value: "Denmark", label: "Denmark" },
  { id: "Estonia", value: "Estonia", label: "Estonia" },
  { id: "Finland", value: "Finland", label: "Finland" },
  { id: "France", value: "France", label: "France" },
  { id: "Germany", value: "Germany", label: "Germany" },
  { id: "Greece", value: "Greece", label: "Greece" },
  { id: "Hungary", value: "Hungary", label: "Hungary" },
  { id: "Ireland", value: "Ireland", label: "Ireland" },
  { id: "Italy", value: "Italy", label: "Italy" },
  { id: "Latvia", value: "Latvia", label: "Latvia" },
  { id: "Lithuania", value: "Lithuania", label: "Lithuania" },
  { id: "Luxembourg", value: "Luxembourg", label: "Luxembourg" },
  { id: "Malta", value: "Malta", label: "Malta" },
  { id: "Netherlands", value: "Netherlands", label: "Netherlands" },
  { id: "Poland", value: "Poland", label: "Poland" },
  { id: "Portugal", value: "Portugal", label: "Portugal" },
  { id: "Romania", value: "Romania", label: "Romania" },
  { id: "Slovakia", value: "Slovakia", label: "Slovakia" },
  { id: "Slovenia", value: "Slovenia", label: "Slovenia" },
  { id: "Spain", value: "Spain", label: "Spain" },
  { id: "Sweden", value: "Sweden", label: "Sweden" },
  { id: "UK", value: "UK", label: "UK" }
];

export default CountriesList;
