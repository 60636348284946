import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import PDFObject from "pdfobject";
import { useEffect, useRef } from "react";
import { Labels } from "../../../../constants/Constants";

export default NiceModal.create(({ fileName, file }) => {
  // Use a hook to manage the modal state
  const modal = useModal();

  return (
    <Modal
      keyboard={false}
      backdrop="static"
      centered
      fade={false}
      size="xl"
      isOpen={modal.visible}
      toggle={modal.remove}
    >
      <div className="modal-header">
        <h2 className="color-dark modal-title">{fileName}</h2>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={modal.remove}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className="mt-0 pt-0">
        <div>
          <PDFPreview file={file} />
        </div>
      </ModalBody>
      <ModalFooter>
        <a href={file} download={fileName} className="btn btn-primary m-0 mr-2">
          {Labels.Payments.AccountsReceivables.download}
        </a>
        <Button color="secondary" onClick={modal.remove}>
          {Labels.CommonModals.close}
        </Button>
      </ModalFooter>
    </Modal>
  );
});

function PDFPreview({ file }) {
  const ref = useRef(null);
  useEffect(() => {
    PDFObject.embed(file, ref.current, {
      height: "80vh"
    });
  }, [file]);
  return <div ref={ref}></div>;
}
